import {Box, Grid, Typography as T, useTheme} from '@mui/material';

const RiskKeyValue = (
  {label, count, countColor}:
    {label: string, count: number, countColor: any}) => {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={7}
        sx={{
          display: 'flex',
          // gap: theme.spacing(3),
          // flex: 1,  // accomplishes nothing with respect to parent flex
        }}
      >
        <T
          sx={{
            color: theme.palette.common.black,
            // otherwise the Access and User Count columns
            // have inexplicably different widths
            minWidth: '10rem',
          }}
        >
          {label}
        </T>
      </Grid>
      <Grid item xs={5}>
        <T
          sx={{
            color: countColor,
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {count}
        </T>
      </Grid>
    </>
  );
};

export const CountSummaryColumn = (
  {title, data, countColor}:
  {title: string, data: any, countColor: any}
) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1), // vertical spacing
        // maxWidth: '325px', // this moves every thing to the right !?
        // margin: 'auto',
        // alignItems: 'stretch', // accomplishes nothing
      }}
    >
      <T
        sx={{
          color: theme.palette.common.black,
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        {title}
      </T>
      <Grid
        container
        rowSpacing={1}
        // spacing={1}
        sx={{
          minWidth: '11rem',
          // maxWidth: '34rem', // any value here makes things worse
        }}
        columnSpacing={{xs: 3, sm: 3, md: 3}}
      >
        <RiskKeyValue label={data[0].label} count={data[0].count}
                      countColor={countColor} />
        <RiskKeyValue label={data[1].label} count={data[1].count}
                      countColor={countColor}/>
        <RiskKeyValue label={data[2].label} count={data[2].count}
                      countColor={countColor}/>
        <RiskKeyValue label={data[3].label} count={data[3].count}
                      countColor={countColor}/>
      </Grid>
    </Box>
  )
}
