import {gql} from "graphql-request";

export const dailyDriftQuery = gql`
query DailyDrift($driftFilter: daily_drift_bool_exp) {
  daily_drift(where: $driftFilter, order_by:{day:desc}) {
    count
    day
    new_value
    old_value
    subject
  }
}
`

export const dailyInactiveUserDriftQuery = gql`
query InactiveUserDailyDrift($clientBoolExp: client_bool_exp){
  inactive_zombie_drift_count(
      where:{client_saas:{client:$clientBoolExp}},
      order_by:{day:desc}
    ) {
    day
    inactive_disabled_count
    new_inactive_count
    new_zombie_count
  }
}
`