import {Menu, MenuItem} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {ellipsisMenuProps, EllipsisRender} from "src/components/common/Tables/Utilities/Ellipsis";
import {RemediationMenuBox} from "./styles";

interface theMenuProps extends ellipsisMenuProps {
  app: any,
  remediationLink: string,
}

export const AppRemediationMenu = ({
  // General menu control
  anchorEl,
  open,
  doOpenMenu,
  handleClose,

  // specific menu props
  app,
}: theMenuProps) => {
  // console.log({user})

  const iconSize = '1.3rem';

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        onClick={
          () => window.open(
            appRemediationLink(app,),
            '_blank'
          )
        }
      >
        <RemediationMenuBox>
          <ExitToAppIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Check ${app.display_name} Permissions`}
          </div>
        </RemediationMenuBox>
      </MenuItem>

    </Menu>
  );
};

// example - actual calendly link
// https://portal.azure.com/#view/Microsoft_AAD_IAM/ManagedAppMenuBlade/~/Permissions/objectId/99515c66-0ffd-4e13-9974-900a58d49c46/appId/5d8ff822-cb79-4b83-b43b-d006565f85ab/preferredSingleSignOnMode~/null/servicePrincipalType/Application

const linkTemplate = 'https://portal.azure.com/#view/Microsoft_AAD_IAM/ManagedAppMenuBlade/~/Permissions/objectId/EXTERNAL_ID/appId/APP_ID/preferredSingleSignOnMode~/null/servicePrincipalType/Application';

const appRemediationLink = (
  app: any,
) => {
  let link = linkTemplate?.replace('EXTERNAL_ID', app.external_id);

  link = link?.replace('APP_ID', app.application_id);
  return link;
};

// renderCell function for UserGrid
export const renderAppRemediationEllipsis = (
  params: GridRenderCellParams,
) => {
  return (
    <EllipsisRender
      menu={AppRemediationMenu}
      menuProps={{
        app: params.row
      }}
    />
  );
};
