import React, {FC, useState} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {MenuItem, Typography} from '@mui/material';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {useDashboardLayoutHeaderAccountStyles} from 'src/components/MainLayout/styles';
import {usePortalContext} from "../../hooks/PortalContext";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ICompanyAccess} from "../../types/Company";

export const Account: FC = () => {
  const { headerAccount } = useDashboardLayoutHeaderAccountStyles();

  const {loggedInUser, clientInfo, setClient} = usePortalContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClientChange = (clientId: string) => {
    setClient(clientId);
    setAnchorEl(null);
  }

  const hasMultipleClients = loggedInUser.accessTo.length > 1;

  return (
    <div className={headerAccount}>
      <div>
        { // If there's more than one client, make the client name a dropdown so users can change it.  Otherwise, just plain text for the Company name
          hasMultipleClients ? <><Typography onClick={handleClick}>{clientInfo.name} <ArrowDropDownIcon sx={{marginBottom: '-8px'}} /></Typography>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {
            loggedInUser.accessTo.map((client: ICompanyAccess) => {
              return (
                <MenuItem key={client.id} onClick={() => handleClientChange(client.id)}>{client.name}</MenuItem>
              );
            })
          }
        </Menu>
        </>
          : <Typography>{clientInfo.name}</Typography> }
      </div>

      <DtxSpacer orientation="vertical" />
      <AccountCircleIcon color="primary" />
      <Typography>{loggedInUser.userInfo.name}</Typography>
    </div>
  );
};

