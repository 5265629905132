import {Box} from '@mui/material';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {DefaultStack} from 'src/components/common/DefaultStack';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {MainNavigationBar} from 'src/components/NavigationBar/MainNavigationBar';
import {AppUsersPanel} from './Discovery/AppUsersPanel/';
import {
  riskTypeIdentifiers,
  riskTypeToDisplay,
  searchParamKeys,
} from 'src/routes';
import {SummaryPanel} from './Discovery/SummaryPanel';
import {DashboardSubHeading} from "./DashboardSubHeading";
import {OnboardingAssistance} from "./OnboardingAssistance";
import {ComingSoonPanel} from "./ComingSoonPanel";
import {planTypes} from "../../utils/constants";
import {usePortalContext} from "src/hooks/PortalContext";
import {RiskRowBox} from "./DashboardWidgets/RiskRowBox";
import {InactiveUsersPanel} from "./DashboardPanels/InactiveUsersPanel";
import {ZombieCount} from "./DashboardWidgets/ZombieCount";
import {ZombiePanel} from "./DashboardPanels/ZombiePanel";
import {GhostUserCount} from "./DashboardWidgets/GhostUserCount";
import {GhostUsersPanel} from "./DashboardPanels/GhostUsersPanel";
import {MFADisabledPanel} from "./DashboardPanels/MFADisabledPanel";
import {NewAppsInOrgCount} from "./DashboardWidgets/NewAppsInOrgCount";
import {NewUserAppsCount} from "./DashboardWidgets/NewUserAppsCount";
import {NewAppsInOrgPanel} from "./DashboardPanels/NewAppsInOrgPanel";
import {NewUserAppsPanel} from "./DashboardPanels/NewUserAppsPanel";
import {AppsPanel} from "./Discovery/AppsPanel/AppsPanel";
import {AppDriftsPanel} from "./Discovery/AppDriftsPanel/AppDriftsPanel";
import {AppInfoPanel} from "./Discovery/AppInfoPanel";
import {InactiveUserChart} from "./DashboardWidgets/InactiveUserChart";
import {MFADisabledChart} from "./DashboardWidgets/MFADisabledChart";


export const DashboardComponent = ({
  sot,
  clientFeatures,
}: any) => {
  // Get whether this client is a trial from the context
  const { isTrial , clientInfo} = usePortalContext();
  const planType = clientInfo.planType;

  const [searchParams] = useSearchParams();

  const isShowingDiscoveryDetails = !!searchParams?.get(
    searchParamKeys.discovery
  )

    const appId = searchParams?.get(searchParamKeys.appId)
    const summaryPanelId = 'summary-panel';

  let panelNumber = 1; // useRef(1);
  const nextPanelNumber = () => {
    let result = panelNumber
    panelNumber++;
    return result;
  }

  const getDashboardLayout = () => {
        // There are 4 ways this page can be presented depending on the querystring
        if(!!riskTypeToDisplay(searchParams)) {
            // When there is a "risk" parameter in the querystring, we show a Grid view of that risk
            switch (riskTypeToDisplay(searchParams)) {
                case riskTypeIdentifiers.newAppsInOrg:
                  return <NewAppsInOrgPanel />
                case riskTypeIdentifiers.newUserApps:
                  return <NewUserAppsPanel />
                case riskTypeIdentifiers.zombies:
                    return <ZombiePanel />
                case riskTypeIdentifiers.ghosts:
                  return <GhostUsersPanel sot={sot}/>
                case riskTypeIdentifiers.inactive:
                    return <InactiveUsersPanel />
                case riskTypeIdentifiers.mfaDisabled:
                  return <MFADisabledPanel/>
            }
        }
        else if (isShowingDiscoveryDetails) {
            // When there is a "discovery" parameter in the querystring, we show the discovery details
            // Additionally, when there is an app id in the querystring, show the details of the app
            return (<>
              { !!appId ? <>
                  <AppInfoPanel appPubId={appId} />
                    <DtxSpacer />
                  <AppUsersPanel appPubId={appId} />
                    <DtxSpacer />
                  <AppDriftsPanel appPubId={appId}/>
                </>
                 : <>
                    <DashboardSubHeading text="App Discovery Summary"></DashboardSubHeading>
                    <SummaryPanel
                      id={summaryPanelId}
                      isShowingDiscoveryDetails={isShowingDiscoveryDetails}
                      isFeatureOn={clientFeatures.apps}
                      showViewDetails={!isTrial}
                    />
                    <DtxSpacer />
                    <AppsPanel sot={sot} />
                </>}
            </>)
        }
        else {

          return (<>
                <DashboardSubHeading text="Current State Summary"></DashboardSubHeading>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '20px',
                  }}
                >
                  <InactiveUserChart isFeatureOn={clientFeatures.activity}></InactiveUserChart>
                  <MFADisabledChart isFeatureOn={clientFeatures.mfa}></MFADisabledChart>
                </Box>
                <DtxSpacer />

              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                  }}
              >
                  <GhostUserCount isFeatureOn={clientFeatures.ghost} sot={sot} numberInCircle={nextPanelNumber()}></GhostUserCount>
                  <DtxSpacer />
                      <RiskRowBox>
                        <ComingSoonPanel/>
                      </RiskRowBox>
                  <DtxSpacer />
                  <RiskRowBox>
                      <ComingSoonPanel/>
                  </RiskRowBox>
              </Box>
                <DtxSpacer />
            <DashboardSubHeading text="App Discovery Summary"></DashboardSubHeading>
            <SummaryPanel
              isFeatureOn={clientFeatures.apps}
              id={summaryPanelId}
              isShowingDiscoveryDetails={isShowingDiscoveryDetails}
              showViewDetails={!isTrial}
            />
            <DtxSpacer />
              <DtxSpacer />
              <DashboardSubHeading text="Changes (Rolling 28 days)"></DashboardSubHeading>
              <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                  }}
              >
                  <NewAppsInOrgCount isFeatureOn={clientFeatures.apps} numberInCircle={nextPanelNumber()}></NewAppsInOrgCount>
                  <NewUserAppsCount isFeatureOn={clientFeatures.apps} numberInCircle={nextPanelNumber()}></NewUserAppsCount>
                  <ZombieCount isFeatureOn={clientFeatures.activity} numberInCircle={nextPanelNumber()}></ZombieCount>
              </Box>
              </>
            )
        }
}
  // width limited to 1500 px for Andy's updated dashboard design
  // TODO: incorporate this into DefaultStack
  return (
    <Box
      sx={{
        maxWidth: '1500px',
        minWidth: '1400px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <DefaultStack isFlexItem>
        {
          // Trials and "Feature based" plans don't get a menu (for now)
          planType === planTypes.Unlimited && <MainNavigationBar />
        }
        <DefaultStack isFlexItem>
          {getDashboardLayout()}
          <OnboardingAssistance summaryPanelId={summaryPanelId} />
         </DefaultStack>
      </DefaultStack>
     </Box>
  )
};
