import {Route, Routes} from 'react-router';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {AuthExceptionsSummaryPage} from 'src/pages/AuthExceptionsPage';
import {DriftsPage} from 'src/pages/DriftsPage';
import {
  driftsRouteFragment,
  exceptionRouteFragment,
} from 'src/routes';
import {RouteNotFound} from 'src/RoutesManager/index';
import {AuthExceptionsPage} from 'src/pages/AuthExceptionsPage/AuthExceptionsPage';

// For /company/auth-exception...
export function RoutesManagerAuthException(): JSX.Element {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute
            component={AuthExceptionsSummaryPage}
          />
        }
      />
      <Route
        path={`${driftsRouteFragment}`}
        element={
          <PrivateRoute
            component={DriftsPage}
          />
        }
      />
      <Route
        path={`${driftsRouteFragment}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={DriftsPage}
          />
        }
      />
      {/*these two routes will handle ?risk=mfa&level=hpu*/}
      <Route
        path={`${exceptionRouteFragment}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={AuthExceptionsPage}
          />
        }
      />
      <Route
        path={`${exceptionRouteFragment}`}
        element={
          <PrivateRoute
            component={AuthExceptionsPage}
          />
        }
      />
      <Route
        path="*"
        element={<>
          <h3>RoutesManagerAuthException</h3>
          <RouteNotFound path={window.location.pathname} />
        </>}
      />
    </Routes>
  );
}
