import React, {FC, useState} from "react";

import {ConfirmDialog} from "../Dialog/ConfirmDialog";
import {FeatureUpgradeMessage} from "./FeatureUpgradeMessage";

export const useUpgradeFeatureDialog = ({
  onCancel
}: {
  onCancel: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return {
    dialog: UpgradeFeatureDialog({
      onCancel,
      isOpen,
      setIsOpen
    }),
    setIsOpen,
  };
}

type propTypes = {
  isOpen: boolean;
  setIsOpen: any;
  onCancel: () => void;
};

const UpgradeFeatureDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
}) => {
  return (

    <ConfirmDialog
      isOpen={isOpen}
      isSpinning={false}
      setIsOpen={setIsOpen}
      cancelButtonName="Close"
      onCancelButtonClick={onCancel}
      heading=""
    >
      <FeatureUpgradeMessage/>
    </ConfirmDialog>

  )
};


