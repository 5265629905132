import {useCallback, useMemo} from 'react';
import {To, useLocation} from 'react-router';
import {useNavigate} from 'react-router';
import {DefaultSelect} from 'src/components/common/DefaultSelect';
import {
  dashboardRoute,
  companyRouteFragment,
  saasRouteFragment,
  saasRoute,
  userRouteFragment,
  userRouteBase,
  positionInPathOfMainAppSection,
  privilegeSummaryRoute,
} from 'src/routes';

const userViewOption = {
  value: userRouteBase,
  label: 'User View',
};

const dashboardOption = {
  value: `${dashboardRoute}`,
  label: 'Dashboard',
};

const companyOption = {
  value: privilegeSummaryRoute,
  label: 'Company View',
};

const saasOption = {
  value: saasRoute,
  label: 'SaaS View',
};

const mainViewOptions = [
  dashboardOption,
  companyOption,
  saasOption,
  userViewOption,
];

export function NavigationBarMainViewSelect(): JSX.Element {
  const {pathname} = useLocation();
  // console.log('NavigationBarMainViewSelect', {pathname});
  const navigate = useNavigate();

  // Get the current main view option value,
  // fallback to company by default
  const mainView = useMemo(() => {
    // This is obsolete - discard along with the code in graveyard, below
    // we currently only want the User option on the User View page
    // addOrRemoveUserViewOption(pathname);

    let currentView = dashboardOption; // set a fallback value

    // get part one of the path - company or saas or dashboard
    const partOne = pathname.split('/')[positionInPathOfMainAppSection];

    if (partOne === companyRouteFragment)
      currentView = companyOption;
    else if (partOne === saasRouteFragment)
      currentView = saasOption;
    else if (partOne === userRouteFragment)
      currentView = userViewOption;

    // console.log('NavigationBarMainViewSelect', {currentView});
    return currentView.value;
  }, [pathname]);

  const handleMainViewChange = useCallback(
    (event: { target: { value: To; }; }) => {
      if (!event?.target?.value) {
        return;
      }
      // TODO does this cause dead navigations sometimes? ///
      // Only navigate to new page if pathname is different
      if (pathname !== event.target.value) {
        // pause(1000).then(
          // () =>
            navigate(event.target.value)
        //);
      }
    }, [navigate, pathname]
  );

  return (
    <DefaultSelect
      onChange={handleMainViewChange}
      options={mainViewOptions}
      value={mainView}
    />
  );
}



/* Graveyard

// We add the User option to the Nav popup when we're
// in the user path and remove it when we're elsewhere
const addOrRemoveUserViewOption = (pathname: string) => {
  const hasUserViewOption = mainViewOptions.find(
      ({value}) => value === userRoute);
  if(
    pathname.startsWith(userRoute)
  ) {
    if(!hasUserViewOption) {
      mainViewOptions.push(userViewOption);
    }
  }
  else {
    if (hasUserViewOption) {
      mainViewOptions = mainViewOptions.filter(function(option) {
          return option.value !== userRoute;
      });
    }
  }
}

*/
