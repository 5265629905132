import {getDLPs} from 'src/services/shared/DLPService';
import {getExternalShares} from 'src/services/shared/ExternalShareService';
import {IExternalShare, IRiskPolicyTrigger} from 'src/types/SaasUser';
import {driftFromDefault} from 'src/utils/constants';
import {QueryContext} from "../../hooks/TanstackHooks";


// does two queries, risk policy triggers (dlp) and external shares
// used by Data Sharing page
type dataSharing = {
  externalShares?: IExternalShare;
  dlps?: IRiskPolicyTrigger;
};

export const getDataSharing = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
) => {
  const {
    driftFrom = driftFromDefault(),
    saasIdentifier,
    userPubId,
  } = parameters;

  const result: dataSharing = {};

  // get transformed shares
  result.externalShares = await
    getExternalShares({accessToken, queryContext, parameters: {}});

  // get transformed DLPs
  result.dlps = await getDLPs({
      accessToken,
      queryContext,
      parameters: {saasIdentifier, userPubId, driftFrom}
    }
  );

  return result;
};



// obsolete - used by old Data Sharing page
// const transformShare = (
//   clientSaas: IClientSaasGraphQLResponse
// ): ISaasDataSharing => {
//   const saas = clientSaas.saas
//   const privlegeLevels = saas.privilege_levels;
//   // console.log({saas}, {privlegeLevels})
//   const result = {
//     saas: transformSaasResponse(clientSaas.saas),
//
//     dlpEvent: {
//       highlyPrivilegedUser: findNoOfUsersData(
//         privlegeLevels,
//         'Highly Privileged User',
//         'dlp'
//       ),
//       privilegedUser: findNoOfUsersData(
//         privlegeLevels,
//         'Privileged User',
//         'dlp'
//       ),
//       enabledUser: findNoOfUsersData(privlegeLevels, 'User', 'dlp'),
//     },
//
//     externalSharing: {
//       highlyPrivilegedUser: findNoOfUsersData(
//         privlegeLevels,
//         'Highly Privileged User',
//         'external_file_shares'
//       ),
//       privilegedUser: findNoOfUsersData(
//         privlegeLevels,
//         'Privileged User',
//         'external_file_shares'
//       ),
//       enabledUser: findNoOfUsersData(
//         privlegeLevels,
//         'User',
//         'external_file_shares'
//       ),
//     },
//   }
//   // console.log({result});
//   return result;
// };

// obsolete
// export const getDataSharings = async (): Promise<ISaasDataSharing[]> => {
//   const client = await graphQLClient();
//
//   const query = gql`
//     query DataSharing {
//       client_saas (order_by: {saas: {name: asc}}) {
//         saas{
//           ...Saas
//           privilege_levels {
//             privilege_level
//             dlp
//             external_file_shares
//           }
//         }
//       }
//     }
//     ${saasFragment}
//   `;
//
//   return client
//     .request(query)
//     .then(({client_saas}) => {
//       // console.log({client_saas});
//       const result = client_saas.map(transformShare);
//       // console.log({result});
//       return result;
//     })
// };
