import {adminRoute} from "../../routes";
import {AdminNavLink} from "./styles";
import {DefaultContainer} from "../../components/common/DefaultContainer";
import {Box} from "@mui/material";
import React from "react";
import {h5FontSize} from "../../theme";
import {portalUserRoles} from "../../utils/constants";

export const adminPanelUrlFragments = {
  saas: 'saas',
  notifications: 'notifications',
  domain: 'domain',
  clients: 'clients',
  finishConnectionSetup: 'finish-connection-setup',
  users:'users',
}
export const finishConnectionSetupRoute = `${adminRoute}/${adminPanelUrlFragments.finishConnectionSetup}`

const userNavigationItems = [
  {
    url: `${adminRoute}/${adminPanelUrlFragments.saas}`,
    label: 'SaaS Connections',
  },
  {
    url: `${adminRoute}/${adminPanelUrlFragments.domain}`,
    label: 'Internal Domains',
  },
];

const orgAdminNavigationItems = [
  {
    url: `${adminRoute}/${adminPanelUrlFragments.users}`,
    label: 'Users',
  },
];

const adminNavigationItems = [
  {
    url: `${adminRoute}/${adminPanelUrlFragments.clients}`,
    label: 'Clients',
  },
];

export const AdminNavigation = ({
                                  userRole,
}:{
  userRole: string,
}) => {
  const theNavLink = (
    {url, label,}:
      { url: string, label: string }
  ) => (
    <li key={url}>
        <AdminNavLink to={url}>
          {label}
        </AdminNavLink>
      </li>
  )

  const navUlStyle = {
    listStyle: 'none',
    paddingLeft: '21px',
  }

  return <DefaultContainer>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        width: '150px',
      }}
    >
      <Box
          sx={{
            fontSize: `${h5FontSize}`,
            fontWeight: 'bold',
              marginTop: '-5px',
          }}
      >
        Admin
      </Box>
      <ul style={navUlStyle} data-testid="admin-menu-items-list">
        {
          userNavigationItems.map(theNavLink)
        }
        {
          // Show the priviliged items if the user is not a "user"
          // ie. Admin or OrgAdmin
          userRole !== portalUserRoles.user &&
          orgAdminNavigationItems.map(theNavLink)
        }
        {
          // Show the admin only items if the user is an admin
          userRole === portalUserRoles.admin &&
          adminNavigationItems.map(theNavLink)
        }
      </ul>
    </Box>
  </DefaultContainer>;
}
