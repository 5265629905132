import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {Box, FormControlLabel, styled} from '@mui/material';
import React, {useState} from 'react';
import {
  UserInputDialog
} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/UserInputDialog';
import {DefaultButton} from 'src/components/common/DefaultButton';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {addSaasGuideCmsRoute} from 'src/routes';
import {
  borderRadiusSmall,
  linkStyleFragment,
  tableBorder
} from 'src/theme';
import {DefaultContainer} from "../../../components/common/DefaultContainer";
import {BackToPrevious} from "src/components/common/BackToPrevious";

const FinishSetupBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  fontFamily: 'Lato, serif',
  color: 'hsl(0, 0%, 34%)',
  width: 'clamp(550px, 50%, 800px)',
  backgroundColor: 'white',
  border: tableBorder,
  borderRadius: borderRadiusSmall,
  padding: '20px 50px 20px 60px',
  'a': {...linkStyleFragment}
});

export const FinishSetupPanel = (
  {
    connectedSaases,
    connectionId
  }:
  {
    connectedSaases: any[],
    connectionId: string,
  }
) => {
  const [shouldShowSetupDialog, setShouldShowSetupDialog] = useState(false);

  const connectionToFinish = connectedSaases.find(
    (connectedSaas) => connectedSaas.connectionId === connectionId
  )

  return (
    <Box
      sx={{
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',

      }}
    >
      <DtxSpacer space={8}/>
      <DefaultContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '70vh',
            height: '27vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
        <DtxSpacer orientation="vertical" space={2}/>
        <BackToPrevious />
        <DtxSpacer orientation="vertical" space={12}/>
        <Box
          sx={{
            // marginTop: '-21px',
            display: 'flex',
            // flexDirection: 'column',
            // width: '70vh',
            // height: '34vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FinishSetupBox>
            <FormControlLabel
              sx={{
                flex: '0 0 17%',
                alignSelf: 'flex-start',
              }}
              control={
                <UserInputDialog
                  open={shouldShowSetupDialog}
                  onClose={() => setShouldShowSetupDialog(false)}
                  saas={{...connectionToFinish.saas}}
                  connectionId={connectionToFinish.connectionId}
                />
              }
              label={`${connectionToFinish.saas.calculatedName} requires further setup`}
            />
            <DtxSpacer />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <DefaultButton
                icon={AddBoxOutlinedIcon}
                onClick={()=>{setTimeout(() => window.open(
                  `${addSaasGuideCmsRoute}/${connectionToFinish.saas.identifier}`,
                  `Finish ${connectionToFinish.saas.name} Setup`
                  )?.focus(), // do we need this focus?
                  1000
                )}}
                sx={{
                  marginLeft: '-13px',
                  paddingLeft: 0,
                  alignSelf: 'flex-start',
                }}
              >
                  {connectionToFinish.saas.name} Setup Guide
              </DefaultButton>
              <DefaultButton
                variant="contained"
                onClick={()=>setShouldShowSetupDialog(true)}
              >
                Finish Setup
              </DefaultButton>
            </Box>
          </FinishSetupBox>
        </Box>
        </Box>
      </DefaultContainer>
    </Box>
  )
}
