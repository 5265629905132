import {styled, Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {Link} from 'react-router-dom';
import {clickableDivStyles} from 'src/theme';

export const StyledSaasContainer = styled('span')(
  ( ) => ({
    display: 'flex',
    alignItems: 'center',
  })
)

export const StyledSaaSLogo = styled('div')(
  ( ) => ({
    // these three removed 15 Sep 22
    // maxWidth: 20,
    // minWidth: 20,
    // paddingRight: theme.spacing(1.5),
    '& img': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  })
)

export const useMfaCellStyles = makeStyles<Theme>(
  ({ palette }) => {
   const {
      error, success,
    } = palette;
    return {
      mfaEnabled: {
        color: success.main,
      },
      mfaDisabled: {
        color: error.main,
      },
      mfaUndefined: {
      }
  }}
);

export const useTableCellStyle = makeStyles<Theme>(
  ({ spacing }: Theme) => ({
    tableCellStyles: {
      borderBottom: '1px solid #ccc !important',
      padding: spacing(1, 2),
    },
  })
);


export const useDateTimeStyle = makeStyles<Theme>(
  () => ({
    dateTime: {
      display: 'inline-grid',
      gridTemplateColumns: '55% 45%',
      width: '10rem',
      minWidth: '10rem',
    },
  })
);
export const UserNameLink = styled((Link))(({theme}) => ({
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  ...clickableDivStyles,
}));
