import styled from '@emotion/styled';
import {FC, ReactNode, useCallback} from 'react';
import {Table, TableBody, TableHead} from '@mui/material';
import {dataGridRowBorder} from 'src/theme';
import {ColumnData, TableSort} from 'src/types/tables';
import {DefaultTableHeadCell} from './DefaultTableHeadCell';
import {DefaultTableLoadingRow} from './DefaultTableLoadingRow';
import {getTableOrderDirection} from './helper';
import {StyledTableRow} from './DefaultTableRow';

export const StyledTableHeaderRow = styled(
  StyledTableRow,
  {shouldForwardProp: (prop) => prop !== "shouldUseCompactVersion"}
)(
  ({shouldUseCompactVersion, theme}: any) => {
    const result: any = {
      backgroundColor: theme.palette.tableHeader,
      borderBottom: dataGridRowBorder,
    }
    if (shouldUseCompactVersion) {
      result['backgroundColor'] = 'white';
      result['color'] = theme.palette.common.black;
      // result['borderCollapse'] = false;
      result['borderBottomWidth'] = `1.2px`;
    }
    return result;
   }
);

export type DefaultTableProps = {
  id?: string;
  columns: ColumnData[];
  children?: ReactNode;
  onSortColumn?: ({ orderBy, order }: TableSort) => void;
  isLoading?: boolean;
  order?: TableSort['order'];
  orderBy?: TableSort['orderBy'];
  columnAlignment?: string;
  shouldUseCompactVersion?: boolean
  classes?: {
    header?: string;
    headerCell?: string;
  };
  sx?: any;
};

export const DefaultTable: FC<DefaultTableProps> = ({
  id,
  columns,
  children,
  order,
  orderBy,
  isLoading,
  onSortColumn,
  classes,
  shouldUseCompactVersion,
  columnAlignment = 'left',
  sx = {}
}) => {
  // const {
  //   table,
  //   // headerRow
  // } = useDefaultTableStyles({
  //   columnAlignment,
  // });

  const handleSortColumn = useCallback(
    (newOrderBy: string) => {
      if (!onSortColumn) {
        return;
      }

      const newOrder = getTableOrderDirection(newOrderBy, order, orderBy);

      onSortColumn({ orderBy: newOrderBy, order: newOrder });
    },
    [onSortColumn, order, orderBy]
  );

  return (
    <Table
      // classes={{root: table}}
      id={id}
      sx={{
        borderCollapse: 'collapse',
        '& td': {
          textAlign: columnAlignment as any,
        },
        '& th': {
          textAlign: columnAlignment as any,
        },
        ...sx
      }}
    >
      <TableHead className={classes && classes.header}>
        <StyledTableHeaderRow
          shouldUseCompactVersion={shouldUseCompactVersion}
        >
          {columns.map((column) => (
            <DefaultTableHeadCell
              isSortable={column.isSortable}
              key={column.id}
              column={column}
              onClick={handleSortColumn}
              orderBy={orderBy}
              order={order}
              shouldUseCompactVersion={shouldUseCompactVersion}
              className={classes && classes.headerCell}
            />
          ))}
        </StyledTableHeaderRow>
      </TableHead>
      <TableBody>
        {isLoading ? (
          <DefaultTableLoadingRow colSpan={columns.length} />
        ) : (
          children
        )}
      </TableBody>
    </Table>
  );
};
