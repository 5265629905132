import {Box, Button, ButtonProps, Typography} from '@mui/material';
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  useGridApiContext
} from '@mui/x-data-grid';
import {createSvgIcon} from '@mui/material/utils';
// import PrintIcon from '@mui/icons-material/Print';
import React from 'react';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {TablePanelLabel} from 'src/components/common/Tables/Utilities/TablePanelLabel';
import {clickableDivStyles, h5FontSize, theme} from 'src/theme';

const textContent = (elem: React.ReactElement | string): string => {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  // Debugging for basic content shows that props.children, if any, is either a
  // ReactElement, or a string, or an Array with any combination. Like for
  // `<p>Hello <em>world</em>!</p>`:
  //
  //   $$typeof: Symbol(react.element)
  //   type: "p"
  //   props:
  //     children:
  //       - "Hello "
  //       - $$typeof: Symbol(react.element)
  //         type: "em"
  //         props:
  //           children: "world"
  //       - "!"
  const children = elem.props?.children;
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  let result = textContent(children);
  if (!result) {
    result = elem.props?.exportNameFragment;
  }
  return result
}

const ExportIcon = createSvgIcon(
  <path
    d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
  />,
  'SaveAlt',
);

// const getRowsFromCurrentPage = ({apiRef}: GridCsvGetRowsToExportParams) =>
//   gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

const getAllRowsSorted = ({apiRef}: GridCsvGetRowsToExportParams) => gridFilteredSortedRowIdsSelector(apiRef);

export const GridHeadingWithExport = ({
  heading,
  exportFileName,
  singlePanelUrl,
  shouldShowBackToPrevious = true,
  shouldShowExportButton = true,
  tableDescription,
}: {
  heading: any,
  exportFileName?: string,
  singlePanelUrl?: string,
  shouldShowBackToPrevious?: boolean,
  shouldShowExportButton?: boolean,
  tableDescription?: React.ReactNode,
}) => {
  // console.log({heading});
  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'large',
  };

  const apiRef = useGridApiContext();
  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options);

  // const handlePrint = (options: GridCsvExportOptions) =>
  //   apiRef.current.exportDataAsPrint(options);

  return (
      <>
    <TablePanelLabel
      shouldShowBackToPrevious={shouldShowBackToPrevious}
      heading={
        <GridToolbarContainer>
          <Box
            sx={{
              marginBottom: '3px',
              fontSize: `${h5FontSize}`,
            }}
          >
            {heading}
          </Box>
          <DtxSpacer orientation="vertical" space={3} />
          {
            singlePanelUrl ?
            (
              <DefaultLink
                to={singlePanelUrl}
                sx={{
                  /// textDecoration: 'none',
                  position: 'absolute',
                  left: 'clamp(380px, 36%, 480px)',
                  // marginLeft: '21px',
                  ...clickableDivStyles,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: `${h5FontSize}`,
                    // marginBottom: '4px'
                } }
                >
                  View All
                </Typography>
              </DefaultLink>
            ) :
            <DtxSpacer orientation="vertical" space={13} />
          }
          {
            shouldShowExportButton &&
            <Button
              sx={{
                position: 'absolute',
                // right: 'clamp(115px, 13%, 200px)',
                right: '30px',
                textTransform: 'none',
                margin: 0,
                padding: 0,
              }}
              {...buttonBaseProps}
              startIcon={<ExportIcon/>}
              onClick={
                () => handleExport({
                  fileName: exportFileName ?
                    exportFileName :
                    `${document.title} - ${textContent(heading)}`,
                  getRowsToExport: getAllRowsSorted
                })
              }
            >
              Export as CSV
            </Button>
          }
        </GridToolbarContainer>
      }
    />
    {
      tableDescription &&
        <Box style={{paddingLeft: shouldShowBackToPrevious ? '30px' : '5px', paddingBottom: theme.spacing(2)}} >
          {tableDescription}
        </Box>
    }
    </>
  );
};



// if the print feature returns -- after we've bought a licence

          // {/*<DtxSpacer*/}
          // {/*  space={8}*/}
          // {/*  orientation='vertical'*/}
          // {/*/>*/}
          // {/*<Button*/}
          // {/*  sx={{*/}
          // {/*    position: 'absolute',*/}
          // {/*    right: '30px', // 'clamp(600px, 45%, 850px)',*/}
          // {/*    textTransform: 'none',*/}
          // {/*    margin: 0,*/}
          // {/*    padding: 0,*/}
          // {/*  }}*/}
          // {/*  {...buttonBaseProps}*/}
          // {/*  startIcon={<PrintIcon />}*/}
          // {/*  onClick={*/}
          // {/*    () => handlePrint({*/}
          // {/*      getRowsToExport: getAllRowsSorted*/}
          // {/*    })*/}
          // {/*  }*/}
          // {/*>*/}
          // {/*  Print*/}
          // {/*</Button>*/}
