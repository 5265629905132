import React, {FC} from 'react';
import {DefaultTableProps,} from 'src/components/common/DefaultTable';
import {ISaasUser} from 'src/types/SaasUser';
import {lastLoginColumnLabel} from 'src/utils/constants';
import {UserGrid} from '../UserGrid/UserGrid';

type props = Omit<
  DefaultTableProps,
  'columns' | 'children' // seems to be a columns prop lurking
> & {
  id?: string;
  users: ISaasUser[];
  heading: any; // ReactNode | string;
  tableDescription?: React.ReactNode,
  isLoading?:boolean;
  saas?: any;
  shouldShowPrivilegeColumn?: boolean;
  shouldShowSharedItemColumn?: boolean;
  shouldShowSotColumn?: boolean;
  shouldShowMfaColumn?: boolean;

  // to show the federation column alone (not currently used)
  shouldShowFederationColumn?: boolean;

  shouldShowBackToPrevious?: boolean;
  shouldShowStatusColumn?: boolean;
  shouldShowSotLastUpdatedColumn?: boolean;
  shouldShowActionColumn?: boolean;
  shouldHighlightInactiveDates?: boolean;
  shouldHideSaasLogoInHeading?: boolean;
  shouldUseCompactVersion?: boolean
  singlePanelUrl?: string; // may exist if NOT single-panel mode
  shouldSortByPrivilege?: boolean;
  DomainFilter?: any;
  sx?: any;

};

export const UserConfigTable: FC<props> = (
  {
    id,
    users=[],
    saas,
    heading,
    tableDescription,
    isLoading = false,
    shouldShowPrivilegeColumn = true,
    shouldShowSotColumn,
    shouldShowMfaColumn,
    shouldShowFederationColumn,
    shouldShowBackToPrevious = true,
    shouldShowStatusColumn,
    shouldShowSotLastUpdatedColumn,
    shouldShowActionColumn,
    shouldHighlightInactiveDates,
    singlePanelUrl,
    shouldSortByPrivilege,
    DomainFilter = () => <span></span>,
  }
) => {

  return (
    <UserGrid
      id={id}
      rows={users}
      heading={heading}
      tableDescription={tableDescription}
      shouldShowSaasColumn={!saas}
      saas={saas}
      shouldShowPrivilegeColumn={shouldShowPrivilegeColumn}
      shouldShowSotColumn={shouldShowSotColumn}
      shouldShowMfaColumn={shouldShowMfaColumn}
      shouldShowFederationColumn={shouldShowFederationColumn}
      shouldShowServiceColumn={shouldShowFederationColumn}
      shouldShowStatusColumn={shouldShowStatusColumn}
      shouldShowSotLastUpdatedColumn={shouldShowSotLastUpdatedColumn}
      shouldShowActionColumn={shouldShowActionColumn}
      shouldShowBackToPrevious={shouldShowBackToPrevious}
      dateColumnLabel={lastLoginColumnLabel}
      dateFieldKey="lastLogin"
      shouldHighlightInactiveDates={shouldHighlightInactiveDates}
      singlePanelUrl={singlePanelUrl}
      shouldSortByPrivilege={shouldSortByPrivilege}
      shouldShadeRowForDisabledUser={true}
      DomainFilter={DomainFilter}
      isLoading={isLoading}
    />
  );
}