import {gql} from "graphql-request";
import {doPublicQuery} from "src/services/shared/doQuery";

const getConsentUrlAction = gql`
  query GetConsentURL($azureToken: String!) {
    get_azure_consent_url(token: $azureToken) {
      url
    }
  }
`
export const getConsentUrl = async ({
  azureToken,
  signal,
}: {
  azureToken: string,
  signal: any,
}) => {
  // console.log('getSubscriptionDetails', {azureToken});

  const variables = {
    azureToken
  }

  console.log('Azure consent query', {variables});

  const queryFunction = async (client: any) => {
    return await client.request(
      getConsentUrlAction,
      variables,
      signal,
    );
  };

  try {
    // console.log('about to call doPublicQuery');
    const response = await doPublicQuery({queryFunction});
    // console.log('doPublicQuery returned', response)
    const result = response.get_azure_consent_url?.url;

    return result;
  }
  catch(error) {
    console.log('getConsentUrl throwing', (error));
    throw(error)
  }
};
