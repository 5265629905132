import React, { FC} from 'react';
import {DateTableCell} from 'src/components/common/Tables/Utilities/tableCells/DateTableCell';

type props = {
  lastLogin?: string;
  shouldUseCompactVersion?: boolean;
};

export const LastLoginTableCell: FC<props> = ({
  lastLogin,
  shouldUseCompactVersion,
}) => {
  return (
    <DateTableCell
      date={lastLogin}
      shouldShowTime={!shouldUseCompactVersion}
    />
  );
};
