import {stringForMissingText} from "src/utils/constants";

export const isEmptyString = (
  aString: string | null | undefined
) => {
  let result = false;
  if (
    aString === '' ||
    aString === undefined ||
    aString === null
  ) {
    result = true;
  }
  return result;
}

// Title case, i.e. each word capitalized.  Doesn't force lower case first
export const toTitleCase = (aString: string) => {
  return aString/*.toLowerCase()*/.split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}
export const capitalizeFirstLetter = (
  aString: string) => aString?.replace(/^./, str => str.toUpperCase())

// return s or nothing
export const plural = (count: number) => count !== 1 ? 's' : '';

export const truncatedMiddleString = (aString: string, n: number) => {
  if (
    !aString
    || !aString.length
  ) return stringForMissingText;

  const length = aString.length;

  if (length > n) {
    return `${
      aString.substring(0, n / 2)
    } ... ${
      aString.substring(length - n / 2, length)
    }`;
  } else {
    return aString;
  }
};

export const truncatedString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const valueOrStringForMissingText = (
  value: string | null | undefined
) => {
  let result = value;
  if (isEmptyString(value)) {
    result = stringForMissingText;
  }
  return result;
}
