import {DashboardComponent} from 'src/pages/DashboardPage/DashboardComponent';
import {usePortalContext} from "src/hooks/PortalContext";

// handles data acquisition and decides whether to
// return the page, loading or error component
export const DashboardPage = () => {
  const documentTitle = 'Dashboard - Detexian';
  document.title = documentTitle;

  // Get the client information (including source of truth)
  const { clientInfo } = usePortalContext();

  return (
    <DashboardComponent
      sot={clientInfo.sourceOfTruth}
      clientFeatures={clientInfo.configuration.features}
    />
  );
};
