import {gql} from 'graphql-request';
import {graphQLClient} from 'src/services/shared/GraphQLService';
import {findNoOfUsersData} from 'src/services/shared/sharedTransforms';
import {IGraphQLConnectedSaasResponse, PrivilegeLevelUserData} from 'src/types/Saas';
import {ISaasPrivilegedAccess} from 'src/types/SaasPrivilegedAccess';
import {driftFromDefault, privilegeStrings} from 'src/utils/constants';
import {connectedSaasFromResponse} from "src/services/AuthExceptions/AuthExceptions";
import {QueryContext} from "../hooks/TanstackHooks";
import {nullCollapsableClientPubId} from "./shared/nullCollapsableUtilities";

// Used by Privileged Access Summary page

const transformSaasPrivilegedAccessResponse = (clientSaas
: IGraphQLConnectedSaasResponse & {
  privilege_levels: PrivilegeLevelUserData[];
}): ISaasPrivilegedAccess => {
  const connectedSaas = connectedSaasFromResponse(clientSaas); // transformSaasResponse({...clientSaas})
  console.log({connectedSaas})
  return {
    // @ts-ignore
    saas: connectedSaas.saas,
    connectedSaas, // : connectedSaasFromResponse(clientSaas),
    highlyPrivilegedUser: findNoOfUsersData(
      clientSaas.privilege_levels,
      privilegeStrings.highlyPrivileged,
    ),
    privilegedUser: findNoOfUsersData(clientSaas.privilege_levels, privilegeStrings.privileged),
    enabledUser: findNoOfUsersData(clientSaas.privilege_levels, 'User'),
    drift: clientSaas.drifts_aggregate?.aggregate?.count || 0,
  }
};

const getPrivilegedAccessesQuery = gql`
  query PrivilegedAccessView(
    $driftFrom: timestamp,
    $clientBoolExp: client_bool_exp
  ) {
    client_saas (
      where: {
        saas: {},
        client: $clientBoolExp
      }
      order_by: {saas: {name: asc}}
    ) {
      privilege_levels {
        privilege_level
        enabled
        enabled_rating
      }
      drifts_aggregate(
        where:{
          subject: {_eq:"PRIVILEGE_CHANGE"}, 
          drift_time: {_gt:$driftFrom}
          _or:[
            {suppressed_until:{_is_null: true}}, 
            {suppressed_until:{_lt:"now()"}}
          ],
        }
      ){
        aggregate {
          count
        }
      }
      saas {
        name
        description
        # can remove privilege_levels
        privilege_levels {
          privilege_level
          enabled
          enabled_rating
        }
      }
      pub_id # the connection id
      nick_name
    }
  }
`;

export const getPrivilegedAccesses = async  ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
): Promise<
  ISaasPrivilegedAccess[]
> => {
  const driftFrom = driftFromDefault();
  const client = await graphQLClient({accessToken});

  return client
    ?.request(
      getPrivilegedAccessesQuery,
      {
        driftFrom,
        ...nullCollapsableClientPubId(queryContext.client.id)
      }
    )
    .then(({client_saas}) => {
      // console.log('PrivilegedAccessView', {client_saas});

      const result = client_saas.map(transformSaasPrivilegedAccessResponse);
      return result;
    });
};


