import {graphQLClient} from 'src/services/shared/GraphQLService';
import {nullCollapsableClientPubId, nullCollapsableUserPubId} from 'src/services/shared/nullCollapsableUtilities';
import {transformDrift} from './ActivityLogTransform';
import {activityLogQuery} from './ActivityLogQuery';
import {QueryContext} from "../../hooks/TanstackHooks";

// Fetch Activity Log
export const getActivityLog = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
) => {
  const {
    saasIdentifier,
    startDate,
    userPubId,
    filter,
  } = parameters
  // console.log('getActivityLog', {parameters});

  const client = await graphQLClient({accessToken});

  const query = activityLogQuery(
    filter
  );

  // console.log({query});

  const userPubIdBoolExp = nullCollapsableUserPubId(userPubId);

  let activityLog = await client?.request(
    query,
    {
      startDate,
      saasIdentifier,
      userPubIdBoolExp,
      ...nullCollapsableClientPubId(queryContext.client.id)
    }
  );

  activityLog.drift = activityLog.drift.map(transformDrift);

  return activityLog;
}

/* Sample response
{
  "data": {
    "drift": [
      {
        "id": 666,
        "client_saas": {
          "saas": {
            "pub_id": "a757835e-957a-42f6-b1fe-89124363ca0e",
            "name": "atlassian",
            "description": "Atlassian"
          }
        },
        "generic_user": {
          "name": "Joan Pierce",
          "email": "iMontgomery@Feedfire.name"
        },
        "subject": "PRIVILEGE_CHANGE",
        "old_value": "Highly Privileged User",
        "new_value": "User",
        "drift_time": "2022-06-03T03:17:11",
        "pub_id": "cd33877f-43bd-4a60-916c-51bcfd135fdf"
      },
      {
        "id": 1249,
        "saas": {
 */




// de-duplication code - no longer used
/*
  let duplicateCount = 0;

  const deduplicatedDrift = activityLog.drift.filter(
  // @ts-ignore
    (currentDrift, index, array) => {
      const {subject, old_value, new_value, generic_user, drift_time} = currentDrift
      const nextDrift = array[index +1];
      const result =  !(
        generic_user.email === nextDrift?.generic_user.email &&
        subject === nextDrift?.subject &&
        old_value === nextDrift?.old_value &&
        new_value === nextDrift?.new_value &&
        drift_time === nextDrift?.drift_time
      )
      if (!result) {
        console.log(
          'de-duplicated drift\n',
          {currentDrift},
          '\nit duplicates\n',
          {nextDrift},
        );
        duplicateCount++;
      }
      return result;
    }
  )
*/
