import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultLinkStyles = makeStyles<Theme>(({ palette }) => ({
  linkStyle: {
    textDecoration: 'none',
    color: palette.primary.main,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.3)',
      opacity: '60%'
    },
  },
  linkActiveStyle: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.3)',
    '&:hover': {
      color: palette.secondary,
    },
  },
}));
