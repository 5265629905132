import React, {useState} from 'react';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {
    largerRowsPerPageOptions, renderDate, renderYesNoFromBoolean,
} from '../DefaultGrid/dataGridUtilities';
import {GridHeadingWithExport} from 'src/components/common/Tables/DefaultGrid/GridHeadingWithExport';
import {DefaultGrid} from '../DefaultGrid/DefaultGrid';
import {CustomFooter, renderAppName, renderUserName} from "../DefaultGrid";
import {tableCellHeadings} from "src/utils/constants";
import {userRouteBase} from "src/routes";

const renderAppDriftChange = (
  params: GridRenderCellParams,
) => {
    let value = "-";
    if (!params.row.previous && params.row.current)
    {
        value = "Added";
    }
    else if(params.row.previous && !params.row.current)
    {
        value = "Removed";
    }

    return value;
};

type propTypes = {
  headerName: string;
  rows: any;
  showAppColumn: boolean;
  showUserColumn: boolean;
  showChangeColumn: boolean;
  showNewToOrgColumn: boolean;
  exportFileName?: string;
  shouldShowBackToPrevious: boolean;
  hasUserUrl: boolean;
  isLoading: boolean;
}

export const AppDriftGrid = ({
  headerName,
  rows = [],
  showAppColumn,
  showUserColumn,
  showChangeColumn,
  showNewToOrgColumn,
  exportFileName,
  shouldShowBackToPrevious,
  hasUserUrl,
  isLoading,
 }: propTypes) => {

  const defaultPageSize = 50;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });

  const dataGridColumns = (): GridColDef [] => {
      // The app is shown when viewing all the drifts but not when on an individual app page
      // @ts-ignore
      return [...(showAppColumn ? [{
                      headerName: tableCellHeadings.application,
                      field: 'applicationName',
                      minWidth: 200,
                      flex: 2,
                      valueGetter: ({row}: any) => row.applicationName ? row.applicationName : "-",
                      renderCell: ({ row }: any) => {return renderAppName(row.applicationName, row.appPubId) },
          }]:[]),
          // The "User" column is shown on the Discovery page but not on the User View
          // @ts-ignore
          ...(showUserColumn ? [
              {
                  headerName: 'Consenting User',
                  field: 'user.name',
                  valueGetter: ({row}: any) => row.user?.name,
                  renderCell: ({ row }: any) => {return renderUserName(row.user?.name, row.user?.pubId, userRouteBase, hasUserUrl)},
                  minWidth: 200,
                  flex: 1.3,
              }
          ] : []),
          // @ts-ignore
          ...(showChangeColumn ? [
              {
                  headerName: 'Change',
                  // @ts-ignore
                  field:'previous',
                  valueGetter: renderAppDriftChange,
                  flex: 1,
              }
          ]: [])
          ,
          // @ts-ignore
          {
              headerName: 'IT-Approved',
              field: 'isEnterprise',
              flex: 1.0,
              valueGetter: (params) => renderYesNoFromBoolean(params),
          },
          // @ts-ignore
          {
              headerName: 'Admin consented',
              field: 'isAdminConsented',
              flex: 1.0,
              valueGetter: (params) => renderYesNoFromBoolean(params),
          },
          // @ts-ignore
          ...(showNewToOrgColumn ? [
              {
                  headerName: 'New to Org',
                  field: 'isNewToOrg',
                  flex: 1.0,
                  valueGetter: (params: any) => renderYesNoFromBoolean(params),
              }
          ] : [])
          ,
          // @ts-ignore
          {
              headerName: 'Detected',
              field: 'driftTime',
              flex: 1.0,
              renderCell: (params) => renderDate(params, false),
          },
      ];
  };

  return <>

    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      <DefaultGrid
        rows={rows}
        getRowId={(row: { [x: string]: any; }) => row.id}
        columns={dataGridColumns()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={largerRowsPerPageOptions}
        length={rows?.length}
        minRowsPerPageOption={defaultPageSize}
        initialState={{
          sorting: {
            sortModel: [{
              field: 'driftTime',
              sort: 'desc',
            }],
          },
        }}
        toolbar={() =>
          <GridHeadingWithExport
            heading={headerName}
            shouldShowBackToPrevious={shouldShowBackToPrevious}
            exportFileName={exportFileName}
          />
        }
       footer={() => CustomFooter({})}
        isLoading={isLoading}
      />
    </DefaultTableContainer>
  </>;
};

