import styled from '@emotion/styled';
import {FC, useCallback, useMemo} from 'react';
import {compactRowPadding} from 'src/theme';
import {useTableHeadStyles} from './styles';
import {DefaultTableCell} from './DefaultTableCell';
import {ColumnData, TableSortOrder} from 'src/types/tables';
import {TableSortLabel} from '@mui/material';
import {ArrowDropDownRounded} from '@mui/icons-material';

const StyledTableHeaderCell = styled(
  DefaultTableCell,
  {shouldForwardProp: (prop) => prop !== "shouldUseCompactVersion"}
)(
  ({shouldUseCompactVersion, theme}: any) => {
    const result: any = {
      position: 'relative',
      // SJS 6 Apr 22 - for the gray table headers (Andy)
      color: theme.palette.tableHeaderText,
      fontSize: theme.more.tableHeaderFontSize,
      flexDirection: 'column',
    }
    if (shouldUseCompactVersion) {
      result['color'] = theme.palette.text.secondary;
      result['paddingTop'] = compactRowPadding;
      result['paddingBottom'] = compactRowPadding;
    }
    return result;
   }
);

interface TableHeadCellProps {
  column: ColumnData;
  order?: TableSortOrder;
  orderBy?: string;
  onClick: (columnId: string) => void;
  className?: string;
  shouldUseCompactVersion?: boolean;
  isSortable?: boolean;
  sx?: any;
}

export const DefaultTableHeadCell: FC<TableHeadCellProps> = ({
  column,
  orderBy,
  order,
  onClick,
  shouldUseCompactVersion,
  className,
  isSortable
}) => {

  const {
    // tableHeadCell,
    sortLabelContainer,
    sortLabelContainerActive,
    sortIcon
  } = useTableHeadStyles();

  const {
    id: columnId,
    label,
    width,
    columnAlignOverride,
  } = column;

  const handleColumnClick = useCallback(() => {
    onClick(columnId);
  }, [onClick, columnId]);

  const headerContent = useMemo(
    () =>
      isSortable ? (
        <TableSortLabel
          active={orderBy === columnId && !!order}
          direction={order || 'asc'}
          onClick={handleColumnClick}
          IconComponent={ArrowDropDownRounded}
          classes={{
            root: sortLabelContainer,
            active: sortLabelContainerActive,
            icon: sortIcon
          }}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      ),
    [
      order,
      orderBy,
      handleColumnClick,
      label,
      columnId,
      sortIcon,
      sortLabelContainer,
      sortLabelContainerActive,
      isSortable
    ]
  );

  return (
    <StyledTableHeaderCell
      className={className}
      columnAlignment={columnAlignOverride}
      width={width}
      shouldUseCompactVersion={shouldUseCompactVersion}
      // sx={{color: 'white'}}
    >
      {headerContent}
    </StyledTableHeaderCell>
  );
};
