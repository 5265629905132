import {Box, Typography as T} from '@mui/material';
import {useNavigate} from 'react-router';
import {LogoSize, SaasLogo} from 'src/components/SaasLogo/SaasLogo';
import {
  StyledSaasContainer,
  StyledSaaSLogo,
} from 'src/components/common/Tables/Utilities/tableCells/styles';
import {saasRoute} from 'src/routes';
import {
  andyBlue,
  clickableDivStyles,
  compactInfoFontSize,
  menuFontSize
} from 'src/theme';
import {ConnectedSaas} from 'src/types/Saas';
import {DtxSpacer} from '../common/DtxSpacer';
import {usePortalContext} from "../../hooks/PortalContext";
import {planTypes} from "../../utils/constants";

type propTypes = {
  saas?: ConnectedSaas;
  service?: string;
  size?: LogoSize;
  handleClick?: any;
  urlQueryParams?: string;
  shouldUseCompactVersion?: boolean;
  shouldUseCompactFont?: boolean
  shouldLinkToSaasView?: boolean;
  shouldShowCalculatedName?: boolean;
}

export const SaasLogoAndName = ({
  saas,
  size,
  service,
  urlQueryParams = '',
  shouldUseCompactVersion = false,
  shouldLinkToSaasView = true,
  shouldUseCompactFont = true,
  shouldShowCalculatedName = true,
}: propTypes) => {
  // console.log({saas})
  const {clientInfo} = usePortalContext();
  // The SaaS view should only be shown for Unlimited and Trial plans, so hide it from FeatureBased
  const showSaaSViewLink = shouldLinkToSaasView && clientInfo?.planType !== planTypes.FeatureBased;
  const navigate = useNavigate();

  const textColor = showSaaSViewLink ? andyBlue : 'inherited';

  const handleClick = () => {
    if (saas?.identifier && showSaaSViewLink) {
      const connectionId = saas?.connectionId;
      // connectionId && console.log({connectionId});
      navigate(
        `${
          saasRoute
        }/${
          saas.identifier
        }${
          connectionId ?  '/'+connectionId : ''
        }${
          urlQueryParams
        }`
      )
    }
  }

  const textStyle = {
    whiteSpace: 'nowrap',
    fontSize: shouldUseCompactFont ? compactInfoFontSize : menuFontSize,
  }

  return <StyledSaasContainer
    sx={
      // (handleClick && clickableDivStyles)
      showSaaSViewLink ? clickableDivStyles : {}
    }
    onClick={handleClick}
  >
    <StyledSaaSLogo>
      {saas?.identifier ?
        <SaasLogo
          saasIdentifier={saas.identifier}
          size={shouldUseCompactVersion ? 's' : size}
        />
        :
        'error:'
      }
    </StyledSaaSLogo>
    <DtxSpacer
      orientation="vertical"
      space={shouldUseCompactVersion ? 1.8 : 3.5}
    />
    <Box
      sx={{
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <T color={textColor}
         sx={{
           ...textStyle
         }}
      >
        {(saas?.calculatedName) && shouldShowCalculatedName ?
            saas.calculatedName :
            saas?.name ?
              saas.name :
              'no saas'
        }
      </T>
      {
        service && <T
          color={textColor}
          sx={{
            ...textStyle,
          }}
        >
          Exchange
        </T>
        }
    </Box>
  </StyledSaasContainer>;
};

