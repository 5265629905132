import {Box, Typography as T, useTheme} from '@mui/material';
import {useMemo} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useParams} from 'react-router';
import Check from '@mui/icons-material/Check';
import {SaasLogo} from 'src/components/SaasLogo';
import {cacheKeyForClient, cacheKeys} from 'src/services/shared/serviceConstants';
import {ISaas} from 'src/types/Saas';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {capitalizeFirstLetter} from "src/utils/string";
import {usePortalContext} from "../../../../hooks/PortalContext";
import {useAllSaases} from "../../../../hooks/UseSaas";

export const SuccessPage = ({
                                isUpdate
                            }: {
    isUpdate: boolean,
}) => {
  const theme = useTheme();
  const {
    data: allSaases,
    isLoading,
  } = useAllSaases();

  const {clientInfo} = usePortalContext();

  const {saasIdentifier} = useParams();

    const saas: ISaas = useMemo(() => {
        const saas_: ISaas = allSaases?.find((aSaas: ISaas) => aSaas.identifier === saasIdentifier)

        return saas_;
    }, [allSaases, saasIdentifier]);

  // to update the connected status in the Add SaaS menu
  const queryClient = useQueryClient();
  queryClient.invalidateQueries([cacheKeyForClient(cacheKeys.connectedSaasList, clientInfo.id)]);

    // check for a connection_id payload - only used by Employment Hero

    const capitalizedSaasIdentifier = capitalizeFirstLetter(saasIdentifier || '')

    // return !saas ? <LoadingScreen /> :
    return (<Box
        p={10}
        width="100%"
        maxWidth="40rem"
        marginLeft="auto"
        marginRight="auto"
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
    >
        <Box style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1.0rem',
        }}>
            <Check
                sx={{
                    color: theme.more.andyGreen,
                    fontSize: '2.5rem',
                    fontWeight: 'bold',
                }}
            />
            <h3>
                Successfully {isUpdate ? "updated" : "added"} {
                saas ?
                    saas.name :
                    capitalizedSaasIdentifier
            }
            </h3>
            {saas ? <SaasLogo
                saasIdentifier={saasIdentifier}
                size="m"
            /> : isLoading ? <LoadingScreen/> :
                <>
                    <DtxSpacer/>
                    <T
                        sx={{
                            color: theme.more.sjsOrange,
                            width: '5rem',
                        }}
                    >
                        {capitalizedSaasIdentifier} logo not found
                    </T>
                </>
            }
        </Box>
        <DtxSpacer/>
        {!isUpdate &&
            <div>Expect a delay of at least an hour while we gather information and produce
                your {saas ? saas?.name : capitalizedSaasIdentifier} reports.</div>
        }
    </Box>)

}
