import {doPublicQuery} from "../shared/doQuery";
import {transformStripeProducts} from "./SubscriptionTransforms";
import {createStripeSubscriptionMutation, getStripeProductsQuery, getStripeSessionQuery} from "./SubscriptionQueries";

export const getStripeProducts = async ({
 signal,
}: {
  signal: any,
}) => {
  const queryFunction = async (client: any) => {
    return await client.request(
      getStripeProductsQuery,
      {},
      signal,
    );
  };

  try {
    const response = await doPublicQuery({queryFunction});
    const result = transformStripeProducts(response.stripe_product);

    return result;
  }
  catch(error) {
    console.error('getSubscriptions throwing', (error));
    throw(error)
  }
};

export const getStripeSession = async ({
                                          variables,
                                          signal,
                                        }: {
  variables: any,
  signal: any,
}) => {
  const queryFunction = async (client: any) => {
    return await client.request(
      getStripeSessionQuery,
      variables,
      signal,
    );
  };

  try {
    const response = await doPublicQuery({queryFunction});
    const result = response.get_stripe_session.status;

    return result;
  }
  catch(error) {
    console.error('getStripeSession throwing', (error));
    throw(error)
  }
};

export const createStripeSubscription = async ({
  variables,
}: {
  variables: any,
}) : Promise<string> => {
  const queryFunction = async (client: any): Promise<string> => {
    return await client.request(
      createStripeSubscriptionMutation,
      variables,
    );
  };

  try {
    const response = await doPublicQuery({queryFunction});
    const result = response.create_subscription.clientSecret;
    return result;
  }
  catch(error) {
    console.error('createStripeSubscription throwing', (error));
    throw(error)
  }
};