import {GridColDef} from "@mui/x-data-grid";
import {StyledDataGrid, styledDataGridSxValues} from "./dataGridStyles";
import React from "react";

export const DefaultGrid = ({
  rows,
  getRowId,
  columns,
  processRowUpdate,
  onProcessRowUpdateError,
  pagination = true,
  paginationModel,
  onPaginationModelChange,
  pageSizeOptions,
  length,
  initialState,
  minRowsPerPageOption,
  rowClassName,
  toolbar,
  footer,
  isLoading = false,
  ...props
  }: {
  rows: any,
  getRowId: (row: any) => any,
  columns: GridColDef[],
  processRowUpdate?: (newRow: any, oldRow: any) => Promise<any> | any,
  onProcessRowUpdateError?: (error: any) => void
  pagination?: any
  paginationModel: {
    pageSize: number;
    page: number
  },
  onPaginationModelChange: any,
  pageSizeOptions: (number | number)[],
  length: any,
  initialState?: any,
  minRowsPerPageOption: number,
  rowClassName?: (params: any) => (string),
  toolbar: () => JSX.Element,
  footer: () => JSX.Element,
  isLoading?: boolean,
}) => {
  return <StyledDataGrid
    rows={rows}
    getRowId={getRowId}
    columns={columns}
    processRowUpdate={processRowUpdate}
    onProcessRowUpdateError={onProcessRowUpdateError}
    pagination={pagination}
    paginationModel={paginationModel}
    onPaginationModelChange={onPaginationModelChange}
    pageSizeOptions={pageSizeOptions}

    getRowClassName={
      rowClassName
    }

    initialState={initialState}
    // These calculations are shared by all our grids
    components={{
      Toolbar: toolbar,
      Footer: footer
    }}

    // These attributes are shared by all our grids
    sx={styledDataGridSxValues}
    sortingOrder={["desc", "asc"]}
    hideFooter={length <= minRowsPerPageOption}
    disableRowSelectionOnClick={true}
    rowSelection={false}
    autoHeight={true}
    rowSpacingType="border"
    disableColumnMenu={false}
    localeText={{
      // @ts-ignore - how does this even work?
      filterOperatorNoContain: "does not contain"
    }}
    loading={isLoading}
    // By default, the MUI datagrid only renders the first 3 columns when being executed in a unit test.
    // To render all columns disableVirtualization needs to be set to true
    // We only want to do this in the tests though as it can cause performance issues in the browser
    disableVirtualization={process.env.NODE_ENV === 'test'}
  />;
}
