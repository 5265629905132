import {useCallback, useState} from 'react';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getAllSaas, getConnectedSaasDetails} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {AdminComponent} from './AdminComponent';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {ISaas} from "../../types/Saas";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const AdminPage = () => {
  const documentTitle = 'Admin - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  // SSPM-999: Temporary filter while clients convert from Gsuite to GoogleWS.
  const getAllFilteredSaas = async ({accessToken} : {accessToken?: any}): Promise<ISaas[]> => {
      const response = await getAllSaas({accessToken});
      return response.filter((s: ISaas) => s.identifier !== 'gsuite')
  }

  const {data: allSaases} = useQueryWithAccessToken({
      queryKey: [cacheKeys.filteredAllSaasList],
      queryFunction: getAllFilteredSaas,
      queryFunctionVariables: {},
      tanstackOptions: {
        onError: () => {
          setUserErrorMessge('error accessing all saases');
        }
      }
    }
  );

  const queryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.connectedSaasDetailsList],
      queryFunction: getConnectedSaasDetails,
      queryFunctionVariables: {},
      tanstackOptions: {
        enabled: !!allSaases,
        onError: () => {
          setUserErrorMessge('error accessing connected saases');
        }
      }
    }
  );

  // can sometimes show a table with no rows
  // this log is an attempt to diagnose
  // console.log({allSaases}, {queryResult});

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () =>
      <AdminComponent
        allSaases={allSaases}
        connectedSaases={queryResult.data}
      />
    ,
    [allSaases, queryResult.data]
  );

  return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  })
};
