import {Route, Routes, useLocation} from 'react-router';
import {privileges, wordForChange} from 'src/utils/constants';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {
  DriftsPage
} from 'src/pages/DriftsPage';
import {
  SingleSaasRolePage,
} from 'src/pages/SingleSaasRolePage';
import {RouteNotFound} from 'src/RoutesManager/index';
import {PrivilegedAccessPage} from 'src/pages/PrivilegedAccessPage';

// For /company/privilege...
// Moved from RoutesManagerCompany to declutter it
export function RoutesManagerPrivilege(): JSX.Element {
  const location = useLocation();

  return (
    <Routes>
       <Route
        path={`summary`}
        element={
          <PrivateRoute component={PrivilegedAccessPage}/>
        }
      />
      <Route
        path={wordForChange}
        element={
          <PrivateRoute
            component={DriftsPage}
          />
        }
      />
      <Route
        path={`${wordForChange}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={DriftsPage}
          />
        }
      />
      <Route
        path={`role/${privileges.high}/:saasIdentifier`}
        element={
          <PrivateRoute
            key={privileges.high}
            component={SingleSaasRolePage}
          />
        }
      />
      <Route
        path={`role/${privileges.some}/:saasIdentifier`}
        element={
          <PrivateRoute
            key={privileges.some}
            component={SingleSaasRolePage}
          />
        }
      />
      <Route
        path={`role/${privileges.none}/:saasIdentifier`}
        element={
          <PrivateRoute
            key={privileges.none}
            component={SingleSaasRolePage}
          />
        }
      />
      <Route
        path="*"
        element={<>
          <h3>RoutesManagerPrivilege</h3>
          <RouteNotFound path={location.pathname} />
        </>}
      />
    </Routes>
  );
}
