import {CircularProgress, Link, Typography} from "@mui/material";
import {clickableDivStyles} from "../../theme";
import {DtxSpacer} from "../../components/common/DtxSpacer";
import React from "react";
import {getStripeSession} from "../../services/Subscriptions/SubscriptionService";
import {useQuery} from "@tanstack/react-query";
import {useSearchParams} from "react-router-dom";
import {stripeSubscriptionState} from "../../utils/constants";
import {useTheme} from "@mui/styles";

export const SubscriptionConfirmPage = () => {
  const [searchParams] = useSearchParams();

  const variables = {"req" : {"session_id": searchParams.get('session_id')} }

  const {data, isLoading, isError} = useQuery({
    queryKey: [], // We don't want to cache this as we're polling
    queryFn: ({signal}) => getStripeSession({
      variables,
      signal,
    }),
    onError: (e) => {
      // Error message will be shown on the page too as isError will be true
      console.error('Error loading stripe session', e);
    },
    refetchInterval: 5000, // Poll every 5 seconds
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  // We need to fetch the state of the session,
  // so we'll show this message if we haven't loaded the session state yet
  let content = (<WaitingMessage header={"Thank you"} message={"We are checking your subscription. Please wait..."}/>)

  if (isError) {
    // If there is an error then show a message asking to contact support
    content = (<WaitingMessage header={"Subscription failed"} message={"We could not create your subscription. Please contact support."} showSupport={true} />)
  } else if (!isLoading && data) {
    // We have finished loading the session state
    switch (data) {
      case stripeSubscriptionState.Ready:
        content = (<SubscriptionReady />)
        break;
      case stripeSubscriptionState.Incomplete:
      case stripeSubscriptionState.Processing:
        content = (<WaitingMessage header={"Thank you"} message={"We are checking your subscription"} showSpinner={true}/>)
        break;
      case stripeSubscriptionState.Failed:
        content = (<WaitingMessage header={"Subscription failed"} message={"We could not create your subscription. Please contact support."} showSupport={true} />)
        break;
    }
  }

  return content;
}

// UI to show if the subscription is "ready"
// ie. The user can log in and see their products
const SubscriptionReady = () => {
  const theme = useTheme();
  return (<>
    <Typography
      variant="h1"
      sx={{
        ...clickableDivStyles,
        paddingTop: '0px',
        marginTop: '-70px',
        fontSize: '3.7rem',
        fontWeight: '900',
      }}
    >
      Thank you
    </Typography>
    <DtxSpacer space={2} />

    <Typography
      variant="h1"
      sx={{
        paddingTop: '15px',
        fontSize: theme.typography.h4.fontSize
      }}
    >
      Your subscription has been activated
    </Typography>
    <Typography
      variant="h1"
      sx={{
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.text.primary,
        paddingTop: '30px',
        fontWeight: 'normal'
      }}
    >
      An email has been sent containing a link to set your password
    </Typography>
  </>)
}

// UI to show error/loading states
const WaitingMessage = ({ header, message, showSpinner, showSupport}
      :
      {
        header:string,
        message?:string,
        showSpinner?: boolean, // True to show a spinner
        showSupport?: boolean // True to show the "Email support" link
      }) => {
  const theme = useTheme();
  return (<><Typography
    variant="h1"
    sx={{
      ...clickableDivStyles,
      paddingTop: '0px',
      marginTop: '-70px',
      fontSize: '3.7rem',
      fontWeight: '900',
    }}
  >
    {header}
  </Typography>

    {(message) && (<>
      <DtxSpacer space={5} />
      {showSpinner && (<><CircularProgress  size={45} /><DtxSpacer space={2} /></>)}
      <Typography
        variant="h1"
        sx={{
          paddingTop: '15px',
          fontSize: theme.typography.h4.fontSize
        }}
      >
        {message}
      </Typography>
      {showSupport && <Link href={'mailto:support@detexian.com'}>support@detexian.com</Link>}
    </>)}
    </>)
}