import clsx from 'clsx';
import {NavLink} from 'react-router-dom';
import {useDefaultLinkStyles} from './styles';

export const DefaultNavLink = ({
  children,
  className,
  // activeClassName,
  ...props
}: any) => {
  const {linkStyle, linkActiveStyle} = useDefaultLinkStyles();

  return (
    <NavLink
      className={
        ({isActive}) => isActive ?
          clsx(linkActiveStyle, className) :
          clsx(linkStyle, className)
      }
      end={true}
      {...props}
    >
      {children}
    </NavLink>
  );
}
