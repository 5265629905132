import React from 'react';
import {riskTypeIdentifiers} from "../../../routes";
import {RiskRowBox} from "./RiskRowBox";
import {useQueryWithAccessToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink} from "../DashboardPanels/panelCommon";
import {TotalPanel} from "../TotalPanel";
import {getNewUserAppsCount} from "../../../services/Discovery/Discovery";

interface NewUserAppsCountProps extends FeatureCountProps {
  numberInCircle: number;
}
export const NewUserAppsCount = (props: NewUserAppsCountProps) => {

  const {isLoading, data, isError} = useQueryWithAccessToken({
      queryKey: [cacheKeys.newUserAppsCount],
      queryFunction: getNewUserAppsCount,
      queryFunctionVariables:{}
    }
  );

  const {isTrial} = usePortalContext();

  return (<RiskRowBox>
    <TotalPanel
      totalCount={data}
      title="New user signup to App"
      description={<span><em>Users that have signed up to a 3rd party app in the last 28 days</em></span>}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.newUserApps, data, !isTrial)}
      numberInCircle={props.numberInCircle}
      featureEnabled={props.isFeatureOn}
      isLoading={isLoading}
      isError={isError}
    />
  </RiskRowBox>)
};
