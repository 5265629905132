import {Box, CircularProgress, Typography} from "@mui/material";
import {clickableDivStyles, h2FontSize, h5FontSize, theme} from "../../theme";
import {DtxSpacer} from "../../components/common/DtxSpacer";
import React, {ReactNode, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getStripeProducts} from "../../services/Subscriptions/SubscriptionService";
import {IStripePrice, IStripeProduct} from "../../types/Subscription";
import {CheckoutDialog} from "./CheckoutDialog";
import {cacheKeys} from "../../services/shared/serviceConstants";
import {useTheme} from "@mui/styles";
import {featureNameMap} from "../../services/shared/ClientService";
import {DefaultButton} from "../../components/common/DefaultButton";

export const SubscriptionPage = () => {
  const [isCheckoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  const [selectedStripeProduct, setSelectedStripeProduct] = useState<IStripeProduct>();
  const [selectedStripePrice, setSelectedStripePrice] = useState<IStripePrice>();

  const {data, isLoading, isError} = useQuery({
    queryKey: [cacheKeys.stripeProducts],
    queryFn: ({signal}) => getStripeProducts({
      signal,
    }),
    onError: (e) => {
      console.error('Error loading subscriptions', e);
    },
  })

  const buildSubscriptions = (products:IStripeProduct[]):ReactNode => {
    return products.map((product: IStripeProduct) => {
      // Each price needs to have a SubscriptionPanel
      const prices = product.prices.map((price: IStripePrice) => {
        return (<SubscriptionPanel
          key={price.nickname}
          price={price}
          onSelectPlan={() => {
            // When the plan is selected we need to set the product, the price,
            // and then open the dialog
            setSelectedStripeProduct(product);
            setSelectedStripePrice(price);
            setCheckoutDialogOpen(true);}
        }
        />)
      });

      return (<div key={product.name}>
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontSize: theme.typography.h3.fontSize,
          }}
        >
          {product.name}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            color: theme.palette.text.secondary,
            fontSize: theme.typography.h4.fontSize,
          }}
        >
          {product.description}
        </Typography>
        <DtxSpacer />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '20px',
            maxWidth: '900px',
            minWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {prices}
        </Box>
      </div>)
    });
  }


  // Show the content section as a spinner to start with
  let content : ReactNode = (<CircularProgress size={31} />)

  if (isError) {
    // If the call to the API fails, then show an error message
    content = (<Typography>Error: Could not load available subscriptions. Please try again later.</Typography>)
  }
  else if (!isLoading && data){
    // If it has loaded, then build the subscription panels
    content = buildSubscriptions(data)
  }

  return (<>
      {// Make sure there is a product and price selected before adding the dialog
         (selectedStripeProduct && selectedStripePrice) &&
      <CheckoutDialog stripeProduct={selectedStripeProduct}
                      stripePrice={selectedStripePrice}
                      isOpen={isCheckoutDialogOpen}
                      setIsOpen={setCheckoutDialogOpen}
                      onCancel= {() => {setCheckoutDialogOpen(false);}} />
      }
      <Typography
        variant="h1"
        sx={{
          ...clickableDivStyles,
          paddingTop: '0px',
          marginTop: '-70px',
          fontSize: '3.7rem',
          fontWeight: '900',
        }}
      >
        Subscriptions
      </Typography>
      <DtxSpacer />
    <DtxSpacer space={2} />
     {content}
      <DtxSpacer space={10} />
    </>
  );
}

const SubscriptionPanel = ({price, featureHeader, onSelectPlan}:{price: IStripePrice, featureHeader?: string, onSelectPlan: Function}) => {
  const theme = useTheme();

  const featureList = price.features.map((feature:string) => {
    let featureName = feature;
    if(Object.hasOwn(featureNameMap, feature)) {
      featureName = featureNameMap[feature];
    }
    return (<li key={feature}>{featureName}</li>)
  });

  return (<Box
    sx={{
      flexGrow: 1,
      textAlign: 'center',
      boxShadow: theme.defaultShadow,
      paddingBottom: '50px',
      position: 'relative'
    }}
  >
    <Typography sx={{
      color: theme.palette.text.primary,
      fontSize: theme.typography.h4.fontSize,
      padding: '25px 0 15px 0'}}>
      {price.nickname}
    </Typography>
    <Typography sx={{...theme.typography.h1, fontSize: h2FontSize}}>
      ${new Intl.NumberFormat('en-US').format(price.price / 100)}
    </Typography>
    <Typography sx={{...theme.typography.h1, fontSize: h5FontSize}}>
      /{price.interval}
    </Typography>

    <ul style={{listStyle: 'none', padding: 0, color: theme.palette.text.secondary, marginBottom: '20px'}}>
      {featureHeader ? <li><strong>{featureHeader}</strong></li> : <li>&nbsp;</li>}
      {featureList}
    </ul>
    <Box sx={{
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width:'100%',
    }}>
      <DefaultButton
        sx={{
          marginBottom: '20px',
        }}
        variant="contained"
        type="submit"
        data-testid={`select-plan-${price.priceId}`}
        onClick={() => {onSelectPlan(price.priceId)}}
      >
        Select plan
      </DefaultButton>
    </Box>
  </Box>)
}