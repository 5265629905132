import {FC} from "react";
import {
  Box,
  Dialog,
  Divider,
  Typography as T,
  useTheme
} from "@mui/material";
import {
  SaasLogoContainer
} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/styles";
import {SaasLogo} from "src/components/SaasLogo";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {DefaultButton} from "src/components/common/DefaultButton";

type propTypes = {
  isOpen: boolean;
  setIsOpen: any;
  defaultButtonName?: string
  onDefaultButtonClick: () => void;
  onSecondButtonClick?: () => void;
  saas: any;
  nickname?: string;
};

export const SharedDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  defaultButtonName = 'Cancel',
  onDefaultButtonClick,
  onSecondButtonClick,
  saas,
  nickname,
  children,
  ...props
}) => {
  // isOpen && console.log({saas})
  const theme = useTheme();
  const saasIdentifier = saas?.identifier || '';

  const handleDefaultButtonClick = () => {
    setIsOpen(false);
    onDefaultButtonClick();
  }

  const handleConfirm = () => {
    setIsOpen(false);
    onSecondButtonClick && onSecondButtonClick();
  }

  return (
    <Dialog
      // This styles the whole page - the background to the dialog
      // sx={{borderRadius: theme.more.borderRadius}}
      {...props}
      open={isOpen}
    >
      <Box
        sx={{
          height: '270px',
          width: '450px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            // why does 60px give a nice margin
            // when the icon size is 80, below
            justifyItems: 'start',
            alignItems: 'center',
          }}
        >
          <SaasLogoContainer
            spacing={0}
          >
            {saasIdentifier ?
              <SaasLogo
                saasIdentifier={saasIdentifier}
              />
              :
              'error:'
            }
          </SaasLogoContainer>
          <T
            variant='h2'
            color="textPrimary"
            sx={{marginLeft: '-21px'}}
          >
            {saas?.name ? saas?.name : 'no saas'}
          </T>
          {/*<IconButton onClick={onClose} size="small">*/}
          {/*  <CloseIcon color="primary" />*/}
          {/*</IconButton>*/}
        </Box>
        <DtxSpacer space={1}/>
        <Divider />
        <DtxSpacer space={1}/>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            borderRadius: theme.more.borderRadius,
          }}
        >
          {children}
          <DtxSpacer space={4}/>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderRadius: theme.more.borderRadius,
            }}
          >
            {onSecondButtonClick !== undefined && <>
              <DefaultButton
                sx={{
                  // marginRight: 0,
                  backgroundColor: theme.more.riskyRed
                }}
                variant="contained"
                onClick={handleConfirm}
              >
                Remove
              </DefaultButton>

              <DtxSpacer orientation="vertical" />
            </>}
            <DefaultButton
              // sx={{marginRight: theme.spacing()}}
              variant="contained"
              onClick={handleDefaultButtonClick}
              type="submit"
            >
              {defaultButtonName}
            </DefaultButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
