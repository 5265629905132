import {Box, Typography as T, useTheme} from "@mui/material";
import {
  ValidatedField
} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

export const DomainDialog = ({
  placeHolder,
  errorLabel,

  // these two fields prompt the user for what's expected
  urlPrefix, // displayed before the input field, after "https://
  urlSuffix, // displayed after the input field

  // e.g. urlPrefix="github.com", urlSuffix="/" gives
  // https://github.com/ <input field> /

  // e.g. urlPrefix="", urlSuffix=".egnyte.com" gives
  // https:// <input field> .egnyte.com

}: {
  placeHolder: string,
  errorLabel: string,
  urlPrefix: string,
  urlSuffix: string,
}) => {
  const theme = useTheme();
  return <Box
    sx={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <T
      sx={{
        color: theme.palette.text.secondary
      }}
    >
      https://{urlPrefix}&nbsp;
    </T>
    <ValidatedField
      valueKey="url"
      placeholder={placeHolder}
      errorLabel={errorLabel}
      fullWidth={false}
      sx={{width: "185px"}}
    />
    <T
      sx={{
        color: theme.palette.text.secondary
      }}
    >
      &nbsp;{urlSuffix}
    </T>
  </Box>;
}
