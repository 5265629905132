import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useDefaultStackStyles = makeStyles<Theme>(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerFlexItem: {
    flex: 1,
  },
}));
