import {accessCount, fiveTo10, lessThan5, over25, tenTo25} from "./mockAggregation";
import {ApplicationSummary} from "../DashboardQueryTypes";

const apps = [
  "Google Calendar",
  "Standup.ly Bot",
  "Workast",
  "Outlook Calendar",
  "OneDrive and SharePoint",
  "Polly",
  "Cisco Webex Meetings",
  "Box",
  "Quip",
  "Standup bot by Geekbot",
  "Salesforce",
  "BirthdayBot",
  "Lunch Train",
  "1Password",
  "BlueJeans",
  "Envoy",
  "Guru Wiki",
  "DocuSign eSignature",
  "Standup and Prosper",
  "Webex Call",
  "Lingvanex: Language Translation Bot",
  "Poppins",
  "Stormboard",
  "Cisco Jabber",
  "Form Director",
  "Surveys",
  "Atrium",
  "Veryfi Receipts & Expenses",
  "atSpoke",
  "BitglassHappyTeams",
  "Thanks",
  "Lift Heavy Run Long",
  "Actagan",
  "ServiceNow",
  "dei.ai",
  "Appraisd",
  "Outline",
  "Stack Overflow for Teams",
  "Google Sheets for Workflow Builder",
  "huminos",
];

export const randomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomBool = () => {
  const anInt = randomInt(0,1);
  let result = true;
  switch (anInt) {
    case 0: result = false;
  }
  return result;
}

const randomElementsFromArray = (
  souceArrray: any[],
  desiredCount: number
) => {
  const result = new Array(desiredCount);
  let len = souceArrray.length
  const taken = new Array(len);
  if (desiredCount > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (desiredCount--) {
      var x = Math.floor(Math.random() * len);
      result[desiredCount] = souceArrray[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}


const randomRows = (): ApplicationSummary[]  => {
  const randomApps = randomElementsFromArray(
    apps,
    randomInt(3, 40)
  )
  return randomApps.map(
    (app, index) => {
      const result = {
        application_pub_id: index + '',
        application_name: app,
        is_enterprise: randomBool(),
        user_count: randomInt(1, 255),
        write_access: randomBool(),
        email_access: randomBool(),
        calendar_access: randomBool(),
        file_access: randomBool(),
      }
      return result;
    }
  )
}

const mockRows = randomRows();

const inSevenDays = () => {
  const now = new Date();
// Add 7 days
  now.setDate(now.getDate() + 7);
  return `${now.toLocaleDateString()}`
}


const application_aggregate_enterprise = {
  is_enterprise: true,
  email_access: 0,
  file_access: 0,
  calendar_access: 0,
  write_access: 0,
  over25_count: over25(true, mockRows),
  tenTo25_count: tenTo25(true, mockRows),
  fiveTo10_count: fiveTo10(true, mockRows),
  lessThan5_count: lessThan5(true, mockRows),
}

const application_aggregate_user = {
  is_enterprise: false,
  email_access: accessCount("email_access", mockRows),
  file_access: accessCount("file_access", mockRows),
  calendar_access: accessCount("calendar_access", mockRows),
  write_access: accessCount("write_access", mockRows),
  over25_count: over25(false, mockRows),
  tenTo25_count: tenTo25(false, mockRows),
  fiveTo10_count: fiveTo10(false, mockRows),
  lessThan5_count: lessThan5(false, mockRows),
}

export const mockResponse = () => {
  const result = {
    name: 'elon.musk@twitter.com',
    subscriber_name: 'Elon',
    expires: inSevenDays(),
    application_summaries: mockRows,
    application_aggregates: [
      application_aggregate_enterprise,
      application_aggregate_user,
    ],
  }
  return result;
}

