import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton, Popover, Typography} from '@mui/material';
import {useState} from 'react';
import {LightTooltip} from 'src/components/common/LightTooltip';
import {compactInfoFontSize} from 'src/theme';
import {stringForMissingText} from 'src/utils/constants';
import {truncatedMiddleString, truncatedString} from "src/utils/string";

export const maxNameLength = 32;
export const maxEmailLength = 38;
export const maxOtherLength = 32; // risk policy, shared item

// Suggested amount by which to reduce the above for compact mode.
// Optionally used by clients
export const extraTruncationForCompactMode = 13;

// Use the above for comparison but truncate additionally by
// this number of characters.  Mostly to make room for the
// copy-to-clipboard icon button
const truncationOffset = 8;

const estimatedCharWidth = 7;

type PropTypes = {
  shouldTruncate?: undefined | boolean;
  value: string;
  maxLength?: number;
  extraWidth?: number;
  shouldUseCompactMode?: boolean;
  displayComponent?: Function;
  truncateFunction?: typeof truncatedMiddleString | typeof truncatedString;
}

// Truncates a string and adds a tool tip and copy-to-clipboard
// icon button.  Most props have nice default values.  Only the
// value is required.
export const TruncatableString = ({
    shouldTruncate = true,
    maxLength = maxOtherLength,
    shouldUseCompactMode,
    extraWidth = 0, // allows adjusting truncation based on cell width
    value,

    // optional render prop - e.g. a string in a link,
    // see UserNameTableCell
    displayComponent = (aValue: any) => (<span>{aValue}</span>),

    // UserNameTC uses TruncatedString
    truncateFunction = truncatedMiddleString,
  }: PropTypes
) => {
  // const ref = useRef(null);
  extraWidth = extraWidth > 0 ? extraWidth : 0;
  const effectiveMaxLength = (maxLength -
      (shouldUseCompactMode ? extraTruncationForCompactMode : 0)) +
      (extraWidth / estimatedCharWidth)

  // useEffect(() => {
  //   // @ts-ignore
  //   console.log(ref?.current?.parentElement?.offsetWidth);
  // }, []);

  const willTruncate = shouldTruncate && value?.length > effectiveMaxLength;
  const truncatedValue = willTruncate ?
    truncateFunction(
      value,
      effectiveMaxLength - truncationOffset
    ) :
    value ? value : stringForMissingText;

  // copy-confirm popper stuff
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleCopyClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(value);
  };
  const handleCopyConfirmClose = () => {
    setAnchorEl(null);
  };

  const id = open ? 'copy confirmation' : undefined;
  return <span
    // ref={ref}
    style={{
      whiteSpace: 'nowrap'
    }}
  >
    <LightTooltip
      title={willTruncate ? value : ''}
      placement="top-start"
    >
      {
        // the truncated value, in a tooltip which shows the whole
        displayComponent(truncatedValue)
      }
    </LightTooltip>
    {
      (willTruncate) &&
      <>
        <LightTooltip
          title="Copy to Clipboard"
          placement="top-start"
        >
          <IconButton
            aria-label="copy"
            size="small"
            onClick={
              handleCopyClick
            }
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>
        </LightTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCopyConfirmClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography
            sx={{
              p: 1,
              fontSize: compactInfoFontSize,
            }}
          >
            <span><strong>Copied </strong><em>${value}</em><strong>&nbsp; to the clipboard</strong></span>.
          </Typography>
        </Popover>
      </>
    }
  </span>;
};
