import {ErrorScreen} from 'src/components/common/ErrorScreen/ErrorScreen';
import {ConnectedSaas, SaasIdentifier} from 'src/types/Saas';
import {Atlassian, GitHub} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/index';
import {BambooHR} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/UrlAndSecret/BambooHR';
import {Okta} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/Okta';
import {Salesforce} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/index';
import {TenantId} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/index';
import {Token} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/Token';
import {Zendesk} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/DomainDialog/Zendesk';
import {Zoom} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/Zoom';
import {Smartsheet} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/Smartsheet';
import {GoogleServiceAccount} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/GoogleServiceAccount';
import {HiBob} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/HiBob';
import {Egnyte} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/DomainDialog/Egnyte";

type props = {
  saas: ConnectedSaas;
}

// This component chooses and configures the "field
// component" for the selected SaaS. "Field component"
// meaning a component with the fields required to add
// the selected SaaS, e.g. token and name.
export const FieldComponentForSaas = ({saas}: props) => {
  const pageToDisplay = () => {
    let result = (
      <ErrorScreen
        message={`${saas.name} not implemented`}
      />
    )
    switch(saas.identifier) {
      case SaasIdentifier.sendgrid:
        result = <Token />
        break;
      case SaasIdentifier.smartsheet:
        result = <Smartsheet />
        break;
      case SaasIdentifier.zoom:
        result = <Zoom />
        break;
      case SaasIdentifier.github:
        result = <GitHub />
        break;
      case SaasIdentifier.bamboohr:
        result = <BambooHR />
        break;
      case SaasIdentifier.box:
        result = <TenantId />
        break;
      case SaasIdentifier.atlassian:
        result = <Atlassian />
        break;
      case SaasIdentifier.egnyte:
          result = <Egnyte />
          break;
      case SaasIdentifier.okta:
        result = <Okta />
        break;
      case SaasIdentifier.zendesk:
        result = <Zendesk />
        break;
      case SaasIdentifier.salesforce:
        result = <Salesforce />
        break;
     case SaasIdentifier.hibob:
        result = <HiBob />
        break;
      case SaasIdentifier.googlews:
        result = <GoogleServiceAccount saas={saas}/>
        break;
    }
    return result;
  }
  const result = pageToDisplay();
  // console.log({result})
  return result;
};

