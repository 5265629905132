import {useQueryWithAccessToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import React from "react";
import {getNewUserApps} from "../../../services/Discovery/Discovery";
import {AppDriftGrid} from "../../../components/common/Tables/AppDriftGrid/AppDriftGrid";
import {usePortalContext} from "../../../hooks/PortalContext";
import {getGridHeadingCount} from "../../../components/common/Tables/Utilities";

export const NewUserAppsPanel = () => {
  const {clientInfo} = usePortalContext();
  const {isLoading, data} = useQueryWithAccessToken({
      queryKey: [cacheKeys.newUserApps],
      queryFunction: getNewUserApps,
      queryFunctionVariables:{},
      tanstackOptions:{
        // Only perform the query if the feature is turned on
        enabled: clientInfo?.configuration?.features?.apps
      }
    }
  );

  return <>
    {
      <AppDriftGrid
        headerName={getGridHeadingCount(data, 'New user signup to App')}
        shouldShowBackToPrevious={true}
        showAppColumn={true}
        showChangeColumn={false}
        showNewToOrgColumn={true}
        rows={data}
        showUserColumn={true}
        isLoading={isLoading}
        hasUserUrl={true}
      />
    }
  </>;
}