import {Box, Menu, MenuItem} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import React from "react";
import {
  ellipsisMenuProps,
  EllipsisRender
} from "src/components/common/Tables/Utilities/Ellipsis";
import {IPortalUser} from "../../../../types/PortalUser";
import {portalUserRoles} from "../../../../utils/constants";

const menuItemStyling = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '13px',
}

interface theMenuProps extends ellipsisMenuProps {
  portalUser: IPortalUser,
  onUpdateClick: Function
}

export const PortalUserActionMenu = ({
  // General menu control
  anchorEl,
  open,
  handleClose: handleCloseActionMenu,

  // specific menu props
   portalUser,
   onUpdateClick,
}: theMenuProps) => {

  const handleUpdateClick = () => {
    handleCloseActionMenu();
    setTimeout(onUpdateClick(portalUser),0)
  }

  const iconSize = '1.3rem';


  return (<>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseActionMenu}
    >
      <MenuItem onClick={handleUpdateClick}>
        <Box sx={menuItemStyling}>
          <ManageAccountsIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Update`}
          </div>
        </Box>
      </MenuItem>
    </Menu>
  </>);
};

// renderCell function for UserGrid
export const renderPortalUserEllipsis = ({
  portalUser,
  userRole,
                                           onUpdateClick
}: {
  portalUser: any,
  userRole: string,
  onUpdateClick: any,
}) => {
  // If you are an admin you can update anybody
  // If you aren't an admin, you can only update users that also aren't an admin
  if (userRole === portalUserRoles.admin || portalUser.role !== portalUserRoles.admin) {
    return (
      <EllipsisRender
        menu={PortalUserActionMenu}
        menuProps={{
          portalUser: portalUser,
          onUpdateClick: onUpdateClick
        }}
      />
    );
  }

  return <></>
};
