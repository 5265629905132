import {useCallback} from 'react';
import {UserAccessExceptionSummaryTable} from 'src/pages/UserAccessExceptionsPage/UserAccessExceptionSummaryTable';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {getUserAccessExceptions} from 'src/services/UserAccessExceptions/UserAccessExceptions';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../hooks/PortalContext";


export const UserAccessExceptionsSummaryPage = () => {
  const documentTitle = 'User-Access Exceptions - Detexian';
  document.title = documentTitle;

  const { clientInfo } = usePortalContext();

  const {data: connectedSaases} = useQueryWithAccessToken({
      queryKey: [cacheKeys.connectedSaasList],
      queryFunction: getConnectedSaases,
      queryFunctionVariables:{},
    }
  );

  const queryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.userAccessExceptions],
      queryFunction: getUserAccessExceptions,
      queryFunctionVariables: {sot: clientInfo.sourceOfTruth},
      tanstackOptions:{
        // Only run the query if there are connectedSaases and a source of truth
        enabled: !!(connectedSaases && clientInfo && clientInfo.sourceOfTruth)
      }
    }
  );

  const pageLoadFunction = useCallback(
    () => {
      return (
          <UserAccessExceptionSummaryTable
            saasesExceptions={queryResult.data}
            connectedSaases={connectedSaases}
          />
      );
    },
    [connectedSaases, queryResult.data]
  );

 return useComponentForQueryResult({
    queryResult: queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing user access exceptions',
  })
};
