import {MenuItem, Paper} from '@mui/material';
import {IOption} from 'src/types/options';
import {DefaultSelect} from 'src/components/common/DefaultSelect';
import {wordForChange} from 'src/utils/constants';
import {NavigationBarMainViewSelect} from './NavigationBarMainViewSelect';
import {
  useCompanyNavigationBarStyles,
  useNavigationBarContainerStyles,
} from './styles';
import {useCallback, useMemo} from 'react';
import {useLocation, matchPath, useNavigate} from 'react-router';
import {NavigationBarSubNav} from './NavigationBarSubNav';
import {
  authenticationRouteFragment,
  authenticationDriftRoute,
  authenticationRoute,
  companyRoute,
  privilegeRoute,
  forwardsRouteFragment,
  sharingRouteFragment,
  userAccessRouteFragment,
  privilegeSummaryRoute,
  privilegeSummaryRouteFragment,
} from 'src/routes';

import {capitalizeFirstLetter} from "src/utils/string";

export const pageNames = {
  dashboard: 'Dashboard',
  privilege: 'Privileged Access',
  authentication: 'Authentication',
  email: 'Business Email',
  sharing: 'Data Protection',
  access: 'User Access',
}

const privilegedAccessOption = {
  label: pageNames.privilege,
  value: `/${privilegeSummaryRouteFragment}`,
}

const authenticationOption = {
  label: pageNames.authentication,
  value: `/${authenticationRouteFragment}`,
}

const businessEmail = {
  label: pageNames.email,
  value: `/${forwardsRouteFragment}`,
}

const dataSharing = {
  label: pageNames.sharing,
  value: `/${sharingRouteFragment}`,
}

const userAccess = {
  label: pageNames.access,
  value: `/${userAccessRouteFragment}`,
}

const subviewOptions: IOption<string>[] & { isSubView?: boolean } = [
  privilegedAccessOption,
  authenticationOption,
  businessEmail,
  dataSharing,
  userAccess,
];

export function CompanyNavigationBar(): JSX.Element {
  const { subViewItem, subViewSelect } = useCompanyNavigationBarStyles();
  const { container, mainViewSelect } = useNavigationBarContainerStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isPrivilegedAccessPage = matchPath(
    {path: `${privilegeRoute}/*`},
    pathname
  )

  const isAuthenticationPage = useCallback(
    () => !!pathname.includes(authenticationRouteFragment),
    [pathname]
  )

  const isUserAccessExceptionPage = useCallback(
    () => !!pathname.includes(userAccessRouteFragment),
    [pathname]
  )

  // Set the secondary menu from the subviewOptions, above
  const currentCompanyView = useMemo(
    () => {
      const notFoundOption = {label: '', value: ''}

      // This works with simple pages with no "sub pages"
      // soon obsolete
      let currentView =
        subviewOptions.find(({value}) =>
          matchPath(
            {path: `${companyRoute}${value}`},
            pathname,
          )
        );

      // otherwise
      if (!currentView) {
        if (isPrivilegedAccessPage) {
          currentView = privilegedAccessOption
        }
        else if (isAuthenticationPage()) {
          currentView = authenticationOption;
        }
        else if (isUserAccessExceptionPage()) {
          currentView = userAccess;
        }
        else {
          currentView =  notFoundOption;
        }
      }

      return currentView.value;
    },
    [
      isAuthenticationPage,
      isPrivilegedAccessPage,
      isUserAccessExceptionPage,
      pathname
    ]
  );


  // Summary | Drift links
  const detailNavLinks = useMemo(
    () => {

    // show nav links for privileged access and authentication
    // Later, maybe for User Access Exception page

    // The Summary and Drift links for the Priv Access page
    const privilegeAccessUrls = [
      {
        url: `${privilegeSummaryRoute}`,
        label: 'Summary',
      },
      {
        url: `${privilegeRoute}/${wordForChange}`,
        label: capitalizeFirstLetter(wordForChange)
      },
    ];

    if (isPrivilegedAccessPage) {
      return <NavigationBarSubNav links={privilegeAccessUrls} />;
    }

    // will want this later when we have Authentication Changes page


    // The Summary and Drift links for the Auth Exception page
    // Had to set end={true} in the DefaultNavLink Navlink
    // object true to get the showing and hiding  of the
    // Summmary and Drift links to work.
    // That wasn't required for the privileged access.  Why?
    const authenticationUrls = [
      {
        url: `${authenticationRoute}`,
        label: 'Summary'
      },
      {
        url: authenticationDriftRoute,
        label: capitalizeFirstLetter(wordForChange)
      },
    ];

    if (isAuthenticationPage()) {
      return <NavigationBarSubNav links={authenticationUrls}/>;
    }

    return '';
  } , [isAuthenticationPage, isPrivilegedAccessPage]);

  const handleCompanyViewChange = useCallback(
    (event: any) => {
      if (
        event?.target?.value === undefined ||
        event?.target?.value === null) {
        return;
      }

      // Only navigate to new page if pathname is different
      if (pathname !== event.target.value) {
        navigate(`${companyRoute}${event.target.value}`);
      }
    },
    [navigate, pathname]
  );

  const renderSubViewMenuItem = useCallback(
    ({
      label,
      value,
      isSubView
    }: any) => (
      <MenuItem
        className={isSubView ? subViewItem : ''}
        key={value}
        value={value}
      >
        {label}
      </MenuItem>
    ),
    [subViewItem]
  );

  // and finally, the output
  return (
    <Paper className={container}>
      <div className={mainViewSelect}>
        <NavigationBarMainViewSelect />
      </div>
      <div className={subViewSelect}>
        <DefaultSelect
          value={currentCompanyView}
          options={subviewOptions}
          renderItem={renderSubViewMenuItem}
          onChange={handleCompanyViewChange}
        />
      </div>
      <div>{detailNavLinks}</div>
    </Paper>
  );
}




// Forgotten Subview menu items

  // {
  //   label: 'Privileged Access Changes',
  //   value: `/${privilegedAccessPartialRoute}/${wordForChange}`,
  //   // isSubView: true,
  // },
  // {
  //   label: '/ Configuration Changes - MFA',
  //   value: '/configuration-changes-mfa',
  //   isSubView: true,
  // },
  // {
  //   label: 'Usage Optimization',
  //   value: '/usage-optimization',
  // },
  // {
  //   label: 'Cyber Training',
  //   value: '/cyber-training',
  // },
  // {
  //   label: 'Cyber Awareness',
  //   value: '/cyber-awareness',
  // },
