import {useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import {NestedMenuItem} from "mui-nested-menu";
import {useNavigate} from "react-router";
import {
  activityLogRoute, adminRoute,
  authenticationRoute,
  dashboardRoute,
  forwardsRoute,
  privilegeSummaryRoute,
  sharingRoute,
  userAccessRoute,
  userRouteBase
} from "../../routes";
import {cacheKeys} from "../../services/shared/serviceConstants";
import {
  getConnectedSaases
} from "src/services/SaasService/Saases";
import {pageNames} from "../NavigationBar";
import {ConnectedSaas} from "../../types/Saas";
import {SaasLogoAndName} from "../SaasLogo";
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

interface theMenuProps {
  anchorEl: any,
  open: boolean,
  doOpenMenu: any,
  handleClose: any,
}

const TheMenu = ({
  anchorEl,
  open,
  doOpenMenu,
  handleClose,
}: theMenuProps) => {
  const navigate = useNavigate();

  const goToDashboard = (event: any) => {
    navigate(dashboardRoute);
    handleClose(event);
  }

  const goToUserView = (event: any) => {
    navigate(userRouteBase);
    handleClose(event);
  }

  const goToPrivilege = (event: any) => {
    navigate(privilegeSummaryRoute);
    handleClose(event);
  }

  const goToAuth = (event: any) => {
    navigate(authenticationRoute);
    handleClose(event);
  }

  const goToForwards = (event: any) => { // "Business Email"
    navigate(forwardsRoute);
    handleClose(event);
  }

  const goToSharing = (event: any) => { // "Data Protection"
    navigate(sharingRoute);
    handleClose(event);
  }

  const goToAccess = (event: any) => {
    navigate(userAccessRoute);
    handleClose(event);
  }

  const {data: connectedSaases} = useQueryWithAccessToken({
      queryKey: [cacheKeys.connectedSaasList],
      queryFunction: getConnectedSaases,
      queryFunctionVariables:{},
    }
  );

  // nested menus hang a little to the right maybe because
  // we aren't using a left icon.  So...
  const leftMarginHackForNestedMenuParent = '4.5px';

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // None of these remove the need to click the Security Posture
        // button twice to actually navigate - first click closes menu
        // Solution in work here https://github.com/mui/material-ui/issues/32088
        // autoFocus={false}
        // disableAutoFocusItem={true}
        // disablePortal={true}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        // transformOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
      >
        <MenuItem onClick={goToDashboard}>Dashboard</MenuItem>
        <NestedMenuItem
          // leftIcon={<AdbIcon />}
          // rightIcon={<FlutterDashIcon />}
          label="Company View"
          parentMenuOpen={open}
          sx={{
            marginLeft: leftMarginHackForNestedMenuParent,
          }}
        >
          <MenuItem onClick={goToPrivilege}>{pageNames.privilege}</MenuItem>
          <MenuItem onClick={goToAuth}>{pageNames.authentication}</MenuItem>
          <MenuItem onClick={goToForwards}>{pageNames.email}</MenuItem>
          <MenuItem onClick={goToSharing}>{pageNames.sharing}</MenuItem>
          <MenuItem onClick={goToAccess}>{pageNames.access}</MenuItem>
        </NestedMenuItem>
        <NestedMenuItem
          label="SaaS View"
          parentMenuOpen={open}
          sx={{
            marginLeft: leftMarginHackForNestedMenuParent,
          }}
        > {
          connectedSaases?.map((saas: ConnectedSaas) => {
            return <MenuItem
              onClick={handleClose}
              key={saas?.pubId}
            >
              <SaasLogoAndName
                shouldUseCompactVersion={true}
                shouldUseCompactFont={false}
                saas={saas}/>
            </MenuItem>
          })
        }
        </NestedMenuItem>
        <MenuItem onClick={goToUserView}>User View</MenuItem>
      </Menu>
    </div>
  );
};
export const dummyHandler = (event: any) => {
};
export const useSecurityPostureMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const doOpenMenu = (event: any) => {
    // the setTimeout gets null currentTarget without this, I think.
    const currentTarget = event.currentTarget;
    setTimeout(
      () => {
        // console.log({event}, {currentTarget});
        setAnchorEl(currentTarget);
      },
      800
    )
  }
  const handleClose = (event: any) => {
    setAnchorEl(null);
    // this always returns the backdrop, not ever the Security Posture button
    // console.log(document.elementFromPoint(event.clientX, event.clientY));

  };

  const LoadedMenu = () => {
    return TheMenu({
      anchorEl,
      open,
      doOpenMenu,
      handleClose,
    })
  }
  return {
    SecurityPostureMenu: LoadedMenu,
    // open,
    doOpenMenu,
  }
}
// We need a way to know that we're on one of the
// security posture pages.  So far, that's simple -
// anything but the Activity Log.
export const isSecurityPostureRoute = (path: string) => {
  const result = (
    path !== activityLogRoute &&
    !path.includes(adminRoute)
  );
  // console.log({path}, {result});
  return result;
}
