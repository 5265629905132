import {DefaultNavLink} from 'src/components/common/DefaultNavLink';
import {useNavigationBarSubNavStyles} from './styles';

type props = {
  links: Array<{ url: string; label: string }>;
};

export function NavigationBarSubNav({
  links,
}: props): JSX.Element {
  const { container, link } = useNavigationBarSubNavStyles();
  return (
    <div className={container}>
      {links.map(({ label, url }) => (
        <DefaultNavLink className={link} key={url} to={url}>
          {label}
        </DefaultNavLink>
      ))}
    </div>
  );
}
