import {Box, Typography as T} from "@mui/material";
import React from "react";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {LoadingSpinner} from "src/components/common/LoadingSpinner";
import {preferredDateFormat} from "src/utils/dates";
import {stringForMissingText} from "src/utils/constants";

import {isEmptyString} from "src/utils/string";

const detailsFontSize = '1.0rem'
const defaultLabelWidth = 144; // used in two places

const DetailsLine = ({
  label,
  labelWidth = defaultLabelWidth,
  value,
  valuePositionOffset
}: {
  label: string,
  labelWidth?: number
  value: string,
  valuePositionOffset?: number,

}) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <T
        sx={{
          minWidth: `${labelWidth + (valuePositionOffset || 0)}px`,
          fontSize: detailsFontSize,
        }}
      >
        {label}:
      </T>
      <T
        sx={{
          fontSize: detailsFontSize,
        }}
      >
        {value}
      </T>
    </Box>
  )
}

const humanReadableBillingCycle = (
  billingCode: string
) => {
  if (isEmptyString(billingCode))
    return stringForMissingText
  switch (billingCode) {
    case 'P1M':
      return 'Monthly';
    case 'P1Y':
      return 'Yearly';
    default:
      return billingCode;
  }
}

export const SubscriptionDetails = ({
  subscriptionDetails,
}: {
  subscriptionDetails: any,
}) => {
  // console.log({subscriptionDetails})
  const subscription = subscriptionDetails?.subscription;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/*<DetailsLine*/}
      {/*  label="Name"*/}
      {/*  value="name not available"*/}
      {/*/>*/}
      {subscriptionDetails ? <>
        {/*<DetailsLine*/}
        {/*  label="Subscription Name"*/}
        {/*  value={subscriptionDetails?.subscriptionName || "-"}*/}
        {/*/>*/}
        <DetailsLine
          label="Offer Id"
          value={subscriptionDetails?.offerId || "-"}
        />
        <DetailsLine
          label="Email"
          value={subscription?.beneficiary?.emailId || "-"}
        />
        <DtxSpacer />
        <T
          sx={{
            fontSize: detailsFontSize,
            // fontWeight: 600,
          }}
        >
          Term
        </T>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '13px',
          }}
        >
        <DetailsLine
          label="Billing"
          labelWidth={defaultLabelWidth-13}
          value={humanReadableBillingCycle(subscription?.term?.termUnit)}
        />
        <DetailsLine
          label="Start Date"
          labelWidth={defaultLabelWidth-13}
          value={preferredDateFormat(subscription?.term?.startDate)}
        />
        <DetailsLine
          label="End Date"
          labelWidth={defaultLabelWidth-13}
          value={preferredDateFormat(subscription?.term?.endDate)}
        />
        </Box>
        {/*<DetailsLine*/}
        {/*  label="Free Trial"*/}
        {/*  value={subscription?.isFreeTrial ? 'Yes' : 'No'}*/}
        {/*/>*/}
        <DtxSpacer />
        <DetailsLine
          label="AutoRenew"
          value={subscription?.autoRenew ? 'Yes' : 'No'}
        />
      </>: <LoadingSpinner/>}
      <DtxSpacer/>

      {/*<T*/}
      {/*  sx={{*/}
      {/*    ...linkStyleFragment,*/}
      {/*    fontSize: '1.0rem',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Change account details*/}
      {/*</T>*/}
    </Box>
  )
}


