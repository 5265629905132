import {Menu, MenuItem} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";
import {ISaas, SaasIdentifier} from "src/types/Saas";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {ellipsisMenuProps, EllipsisRender} from "src/components/common/Tables/Utilities/Ellipsis";
import {userStatusStrings} from "src/utils/constants";
import { RemediationMenuBox } from "./styles";

interface theMenuProps extends ellipsisMenuProps {
  saas: ISaas,
  user: any,
  remediationLink: string,
}

export const UserRemediationMenu = ({
  // General menu control
  anchorEl,
  open,
  doOpenMenu,
  handleClose,

  // specific menu props
  saas,
  user,
}: theMenuProps) => {
  // console.log({user})

  const iconSize = '1.3rem';
  const remediationLink = saasRemediationLink(
    saas?.identifier,
    user,
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        onClick={
          () => window.open(remediationLink, '_blank')
        }
      >
        <RemediationMenuBox>
          <ExitToAppIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Disable ${user.name}`}
            {/*&#8230; ellipsis*/}
          </div>
        </RemediationMenuBox>
      </MenuItem>

    </Menu>
  );
};

const saasIdentifiersLinkTemplates: any[] = [
  {
    identifier: SaasIdentifier.microsoft365,
    linkTemplate: 'https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/UNIQUE_ID',
  },
  {
    identifier: SaasIdentifier.zoom,
    linkTemplate: 'https://zoom.us/user/UNIQUE_ID/profile',
  },
  {
    identifier: SaasIdentifier.salesforce,
    linkTemplate: 'https://ap1.salesforce.com/UNIQUE_ID?noredirect=1&isUserEntityOverride=1',
  },
];

const saasRemediationLink = (
  saasIdentifier: string,
  user: any,
) => {
  let template = saasIdentifiersLinkTemplates.find(
    (aTemplate) => saasIdentifier === aTemplate.identifier,
  );

  const link = template?.linkTemplate.replace('UNIQUE_ID', user.userId);
  return link;
};

// renderCell function for UserGrid
export const renderUserRemediationEllipsis = (
  params: GridRenderCellParams,
  saas?: any,
) => {
  // console.log({params})
  // console.log({saas});
  const user = params.row;
  const remediationLink = saasRemediationLink(
    saas ? saas.identifier : params.row.saas?.identifier,
    user,
  );

  const userIsEnabled = params.row.status === userStatusStrings.enabled;

  return (remediationLink && userIsEnabled &&
    <EllipsisRender
      menu={UserRemediationMenu}
      menuProps={{
        // saas: saas ? saas.identifier : params.row.saas?.identifier,
        saas: params.row.saas,
        user: params.row,
        // remediationLink
      }}
    />
  );
};
