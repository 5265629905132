import {IStripePrice, IStripeProduct} from "../../types/Subscription";

// Takes the graphQL response and transforms/maps it to the portal types
export const transformStripeProducts = (stripeProducts: any) :IStripeProduct[] =>  {
  return stripeProducts.map((product: any) : IStripeProduct => {
    return {
      name: product.name,
      description: product.description,
      productId: product.product_id,
      prices: product.price.map((price: any) : IStripePrice => {
        return {
          nickname: price.nickname,
          price: price.price,
          priceId: price.price_id,
          features: price.features,
          interval: price.interval,
          currency: price.currency
        }
      })
    };
  });
}