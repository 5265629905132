import {Box, CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import React, {useState} from "react";

import {compactInfoFontSize} from "../../../../theme";
import {DefaultButton} from "../../../../components/common/DefaultButton";

// This is the longest a domain can be
const maxDomainLength = 253;

interface addDomainProps {
  showValidation: boolean,     // Whether to show a validation message or not
  validationMessage: string,   // The text of the validation message.
  onAddDomain: Function,       // Function to execute when clicking "Add"
  isSpinning?: boolean,        // Whether the spinner is showing
}

export const AddDomain = ({
  showValidation,
  validationMessage,
  onAddDomain,
  isSpinning,
}: addDomainProps) => {
  const theme = useTheme();
  const [addingDomainName, setAddingDomainName] = useState('');

  // Event handlers
  const handleChange = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    let value = event.target.value;
    if (value.length > maxDomainLength)
      value = value.substring(0,maxDomainLength)
    setAddingDomainName(value || '');
  }

  // If the user presses enter after typing the domain name we want to treat it as pressing "Add"
  const keyDownHandler = (
      event: React.KeyboardEvent
  ) => {
    event.stopPropagation();

    if (event.key === 'Enter') {
      handleAddDomain();
    }
  }

  const handleAddDomain =() => {
    onAddDomain(addingDomainName);
  }

  // Set the validation component as an empty element
  let validation = <></>;

  // If there is a validation error, set the validation component with the message
  if (showValidation) {
    validation = (<Typography style={{
      color: theme.palette.error.main,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    }}>
      {validationMessage}
    </Typography>)
  }

  // The button turns in to a spinner depending on isSpinning
  // Set it as a button to start with
  let buttonOrSpinner = (<DefaultButton
      sx={{
        height: '1.8rem',
        width: '3rem',
        fontWeight: '500',
        marginBottom: '8px',
        marginLeft: '10px',
      }}
      autoFocus
      variant="contained"
      onClick={handleAddDomain}
  >
    Add
  </DefaultButton>);

  // If isSpinning is true then replace the button with a spinner
  if (isSpinning)
    buttonOrSpinner = (<CircularProgress size={31} style={{marginLeft: theme.spacing(1)}} />)

  return (<>
    <Box>
      {validation}
      <TextField
          name="new-domain"
          // style
          size="medium"
          fullWidth={false}
          // important example of styling MUI low-level components
          sx={{
            '.MuiInputBase-input': {
              fontSize: compactInfoFontSize,
              padding: '6px 13px',
              width: '300px',
            }
          }}
          value={addingDomainName}
          onChange={handleChange}
          onKeyDown={keyDownHandler}
      />
      {buttonOrSpinner}
    </Box>
  </>);
};
