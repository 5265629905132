import {Box} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {StyledMoreVertIcon} from "src/components/common/Tables/DefaultGrid/StyledMoreVertIcon";
import {StyledErrorOutlineIcon} from "../DefaultGrid/StyledErrorOutlineIcon";

// custom content, usually a menu, within the ellipsis menu
// must inherit from this interface
export interface ellipsisMenuProps {
  anchorEl: any,
  open: boolean,
  doOpenMenu: any,
  handleClose: any,
}

const useEllipsisMenu = (EllipsisMenuContents: any, menuProps: any) => {
  const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null);
  const open = Boolean(anchorEl);

  const doOpenMenu = (event: any) => {
    // console.log({event});
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  // wrapping this in a useCallback prevented a "flicker"
  // when rendering the menu for the first time
  const LoadedMenu = useCallback(() => {
    return EllipsisMenuContents({
      // General menu control
      anchorEl,
      open,
      doOpenMenu,
      handleClose,

      // specific action menu props
      ...menuProps,
    });
  }, [EllipsisMenuContents, anchorEl, menuProps, open]);

  return {
    EllipsisMenu: LoadedMenu,
    doOpenMenu,
  };
};

export const EllipsisRender = ({
  menu, menuProps
}: {
  menu: any, menuProps: any
}) => {
  // console.log({menuProps})
  const {
    EllipsisMenu,
    doOpenMenu,
  } = useEllipsisMenu(menu, menuProps);

  return (
    <Box>
      { // If the menu is in an error state show a warning icon rather than the VertIcon
        menuProps.errorState ?
          <StyledErrorOutlineIcon onClick={doOpenMenu} />
          :
          <StyledMoreVertIcon onClick={doOpenMenu} />
      }
      <EllipsisMenu />
    </Box>
  );
};

export const actionColumnLabel = 'Action';
