import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {clickableDivStyles, compactInfoFontSize} from 'src/theme';

export const useDashboardLayoutHeaderStyles = makeStyles<Theme>(
  ({ spacing }) => ({
    headerAppBar: {
      background: '#fff',
      padding: spacing(0, 3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      boxShadow: '0px 0px 30px 5px rgba(36, 143, 221, 0.4)',
    },
    headerLeft: {
      display: 'flex',
    },
    headerRight: {
      display: 'flex',
      alignItems: 'center',
    },
    appBarLogo: {
      paddingTop: spacing(2.5),
      paddingBottom: spacing(2.5),
      paddingRight: spacing(3),
    },
    appBarLogoImage: {
      maxWidth: 155,
    },
  }),
  { index: 1 }
);

const blueGradientTop =
  'linear-gradient(180deg, rgba(66,168,210,0.26934523809523814) 0%, rgba(66,168,210,0) 40%, rgba(66,168,210,0) 100%)';

const borderColor = '#f1f1f1';
const border = `1px solid ${borderColor}`;
const dividerColor = '#D7D7D7';
const divider = `1px solid ${dividerColor}`;

const headerAndFooterHeight = 90;

export const navigationItemMinWidth = '144px';

const sharedNavLinkStyles: any = {
  position: 'relative',
  textDecoration: 'none',
  fontSize: compactInfoFontSize, // 14,
  fontWeight: 700,
  display: 'inline-flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}

export const useDashboardLayoutHeaderNavigationStyles = makeStyles<Theme>(
  ({ spacing, palette: { primary, text } }) => ({
    navigationContainer: {
      display: 'flex',
    },
    navigationAddSaaS: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      /// textDecoration: 'none',
      color: primary.main,
      minWidth: navigationItemMinWidth,

      position: 'relative',
      '& div': {
        height: 42,
        borderLeft: divider,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      '& svg': {
        color: primary.main,
        marginRight: spacing(),
      },
      '& span': {
        fontSize: compactInfoFontSize, // 14,
        fontWeight: 700,
      },
      ...clickableDivStyles
    },
    navigationAddSaasActive: {
      color: text.secondary,
    },
    navigationMenu: {
      display: 'flex',
      listStyle: 'none',
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 0,
    },
    navigationItem: {
      borderRight: border,
      '&:first-child': {
        borderLeft: border,
      },
    },
    navigationItemLink: {
      ...sharedNavLinkStyles,
      padding: spacing(2.5, 2),
      color: primary.main,
      '&:hover': {
        color: text.secondary,
      },
    },
    navigationItemLinkDisabled: {
      ...sharedNavLinkStyles,
      padding: spacing(2.5, 2),
      color: text.secondary,
      '&:hover': {
        color: text.secondary,
      },
    },
    navigationItemLinkActive: {
      ...sharedNavLinkStyles,
      padding: spacing(2.5, 2),
      color: text.secondary,
      background: blueGradientTop,
      '&:before': {
        content: '""',
        width: '100%',
        height: 6,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: primary.main,
      },
    },
  })
);

export const useDashboardContentStyles = makeStyles<Theme>(({ spacing }) => ({
  contentContainer: {
    paddingTop: headerAndFooterHeight, // App bar height
    // necessary?
    minHeight: `calc(100vh - ${headerAndFooterHeight.toString()}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const useDashboardLayoutFooterStyles = makeStyles<Theme>(
  ({ spacing, palette: { primary } }) => ({
    footerContainer: {
      background: '#fff',
      padding: spacing(3),
      height: headerAndFooterHeight,
      display: 'flex',
      justifyContent: 'center',
    },
    footerContent: {
      display: 'flex',
      alignItems: 'center',
    },
    footerCopyright: {
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: compactInfoFontSize, // 14,
      paddingLeft: spacing(),
      paddingRight: spacing(),
      lineHeight: 1,
    },
    footerLink: {
      fontSize: compactInfoFontSize, // 14,
      paddingLeft: spacing(),
      paddingRight: spacing(),
      color: primary.main,
      textDecoration: 'none',
      borderLeft: '1px solid #ccc',
      lineHeight: 1,
    },
  })
);

export const useDashboardLayoutHeaderAccountStyles = makeStyles<Theme>(
  ({ spacing }) => ({
    headerAccount: {
        display: 'flex',
        paddingRight: spacing(3),
        paddingLeft: spacing(3),
        '& svg': {
        marginRight: spacing(0.5),
        },
    },
  })
);

export const useLayoutHeaderTrialInfoStyles = makeStyles<Theme>(
    ({ spacing, palette: { text, error, common } }) => ({
        headerTrialInfo: {
            display: 'flex',
            paddingRight: spacing(3),
            paddingLeft: spacing(3),
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            border: `1px solid ${text.primary}`,
            borderRadius: '20px',
        },headerTrialInfoUrgent: {
            display: 'flex',
            paddingRight: spacing(3),
            paddingLeft: spacing(3),
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            background: error.main,
            borderRadius: '20px',
            color: common.white,
        },
    })
);
