import {gql} from 'graphql-request';

// unused, I think
export const saasExceptionCountFragment = gql`
  fragment SaasExceptionCount on ExceptionCountData {
    noOfExceptions
    rating
  }
`;

export const saasFragment = gql`
  fragment Saas on saas {
    pub_id
    name
    description
  }
`;

export const connectionFragment = gql`
  fragment Connection on latest_saas_user {
    connection {
      pub_id
      nick_name
    }
  }
`;

export const userCoreFragment = gql`
  fragment UserCore on latest_saas_user {
    name
    email
    privilege_level
    last_login
    mfa_status
    federated_user {
      mfa_status
    }
    status
    domain
    unique_id
  }
`

// // with an 's'
// export const usersCoreFragment = gql`
//   fragment UsersCore on latest_saas_user {
//     ${coreUserFields}
//   }
// `

// This isn't as globally useful as it could be. See,
// for instance, the Saas View and User View queries.
export const saasUserFragment = gql`
  fragment User on latest_saas_user {
    ...UserCore
    saas{
      ...Saas
    }
    ...Connection
    generic_user {
      pub_id
    }
  }
  ${saasFragment}
  ${userCoreFragment}
  ${connectionFragment}
`;
