import React from 'react';
import {AppDriftGrid} from "../../../../components/common/Tables/AppDriftGrid/AppDriftGrid";
import {useApplicationDrifts} from "../../../../services/DriftService/DriftsService";
import {getGridHeadingCount} from "../../../../components/common/Tables/Utilities";

export const AppDriftsPanel = ({appPubId}:{appPubId?:string}) => {
  const queryResult = useApplicationDrifts(appPubId);

    return (
      <AppDriftGrid
        rows={queryResult.data}
        showAppColumn={!appPubId}   // Show the app name if this panel isn't for a specific app (or otherwise each row will all be the same name)
        showUserColumn={true}
        showChangeColumn={true}
        showNewToOrgColumn={true}
        headerName={getGridHeadingCount(queryResult.data, 'App drift')}
        shouldShowBackToPrevious={false}
        isLoading={queryResult.isLoading}
        hasUserUrl={true}
      />)
}



