import React, {FC} from 'react';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {UserNameLink} from './styles';
import {
  maxNameLength,
  TruncatableString,
} from '../TruncatableString';
import {useWidthForRef} from 'src/hooks/useWidthForRef';
import {userRouteBase} from 'src/routes';

import {truncatedString} from "src/utils/string";

type UserNameTableCellProps = {
  hasUserUrl: any;
  name: any;
  pubId: any;
  shouldUseCompactVersion?: boolean;
};

export const UserNameTableCell: FC<UserNameTableCellProps> = ({
  hasUserUrl,
  name,
  pubId,
  shouldUseCompactVersion,
}) => {

  // console.log('UserNameTableCell', {name}, {hasUserUrl}, {pubId});

  // Note had to modify DefaultTableCell to foward a ref,
  // React.forwardRef, to get this to work.

  const {ref, width} = useWidthForRef();

  const baseWidth = 250;

  return (
    <DefaultTableCell
      ref={ref}
    >
      <TruncatableString
       maxLength={maxNameLength}
        truncateFunction={truncatedString}
        value={name}
        shouldUseCompactMode={shouldUseCompactVersion}
        extraWidth={width - baseWidth}
        displayComponent={
          (value: any) =>  hasUserUrl ? (
            <UserNameLink
              to={`${userRouteBase}/${pubId}`}
            >
              {value}
            </UserNameLink>
          ) : (
            <span>{value}</span>
          )
        }
      />
    </DefaultTableCell>
  );
};
