import React from 'react';
import {DefaultTableCell, DefaultTableRow} from 'src/components/common/DefaultTable';
import {LinkedUserCountWidget} from 'src/components/UserCountWidget';
import {SaasLogoAndName} from 'src/components/SaasLogo';
import {externalRouteFragment, ghostRouteFragment, inactiveRouteFragment,} from 'src/routes';
import {ConnectedSaas, ISaas} from 'src/types/Saas';
import {ISaasException} from 'src/types/SaasExceptions';

type props = {
  saasException: ISaasException;
  saas: ISaas;
  connectedSaas: ConnectedSaas;
  showSaasLogo?: boolean;
  noOfExceptions: number;
};

export const UserAccessExceptionSummaryTableRow = ({
  saasException,
  saas,
  connectedSaas,
  showSaasLogo = false,
  noOfExceptions,
}: props) => {
  const {
    name,
    highlyPrivilegedUser,
    privilegedUser,
    enabledUser,
  } = saasException;

  const riskUrlFragmentForExceptionName = (name: string) => {
    // assume its one of these three
    // TODO make constants.
    let result = inactiveRouteFragment;
    if (name === 'External Users') result = externalRouteFragment;
    else if (name === 'Ghost Users') result = ghostRouteFragment;
    return result;
  }

  // output: <external|ghost|inactive>/<saas>/?risk=<hpu|pu|u>
  const buildLink = (
    privilegeCode: string, // hpu | pu | u
  ) => {
    const riskFrag = riskUrlFragmentForExceptionName(saasException.name);
    const result = `${riskFrag}/${saas.identifier}?privilege=${privilegeCode}`;
    return result;
  }

  return (
    <DefaultTableRow shouldHaveBottomBorder={true}>
      {showSaasLogo && (
      <DefaultTableCell
        align="center"
        width="200px"
        rowSpan={noOfExceptions}
        sx={{
          border: '0px'
       }}
      >
      <SaasLogoAndName
        saas={connectedSaas}
        // handleClick={handleClick}
      />
      </DefaultTableCell>
      )}
      <DefaultTableCell
        align="center"
        width="20%"
      >
        {name}
      </DefaultTableCell>
      <DefaultTableCell
        align="center"
        width="20%"
      >
       <LinkedUserCountWidget
          accessCount={highlyPrivilegedUser.count}
          link={highlyPrivilegedUser.count > 0 && buildLink('hpu')}
        />
      </DefaultTableCell>
      <DefaultTableCell align="center" width="20%">
        <LinkedUserCountWidget
          accessCount={privilegedUser.count}
          link={privilegedUser.count > 0 && buildLink('pu')}
        />
      </DefaultTableCell>
      <DefaultTableCell>
        <LinkedUserCountWidget
          accessCount={enabledUser.count}
          link={enabledUser.count > 0 && buildLink('u')}
        />
      </DefaultTableCell>
   </DefaultTableRow>
  );
};
