import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import {startDateRadioKey, dateSpanKeys} from './ActivityLogPanel';

// selects date span for query
type RadioProps = {
  value: string;
  handleChange: any;
};
export const StartDateRadioGroup = ({
  value,
  handleChange,
}: RadioProps) => {
  // console.log('StartDateRadioGroup', {value})
  return (
    <FormControl sx={{
      marginTop: '8px',
      marginRight: '0',
      paddingRight: 0,
    }}>
      <FormLabel
        id="time-span-label"
      >
        Date Span
      </FormLabel>
      <RadioGroup
        name={startDateRadioKey}
        row
        aria-labelledby="time-span-radio-buttons-group-label"
        // defaultValue="month"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value={dateSpanKeys.month}
          control={<Radio />}
          label="Month" />
        <FormControlLabel
          value={dateSpanKeys.quarter}
          control={<Radio />}
          label="Quarter" />
        <FormControlLabel
          value={dateSpanKeys.year}
          control={<Radio />}
          label="Year"
        />
        <FormControlLabel
          value={dateSpanKeys.all}
          control={<Radio />}
          label="All"
        />
      </RadioGroup>
    </FormControl>
  );
};
