import {Paper, TableContainer} from '@mui/material';
import {forwardRef} from 'react';
import {useDefaultTableContainerStyles} from './styles';

type propTypes = {
  id?: string;
  children: React.ReactNode;
  heading?: React.ReactNode;
  shouldUseTableContainer?: boolean;
};

export const DefaultTableContainer = forwardRef(({
  id,
  children,
  heading,
  shouldUseTableContainer = true,
}: propTypes,
ref: any
) => {
  // console.log({heading});

  const {
    containerStyles,
    tableContainerStyles,
  } = useDefaultTableContainerStyles();

  return (
    <Paper
      id={id}
      className={containerStyles}
      ref={ref}
    >
      {heading}
      {shouldUseTableContainer ? (
        <TableContainer
          className={tableContainerStyles}
          component={Paper}
        >
          {children}
        </TableContainer>
      ) : (
        children
      )}
    </Paper>
  );
}
);
