import {FC} from 'react';
import {Typography} from '@mui/material';
import {useLayoutHeaderTrialInfoStyles} from 'src/components/MainLayout/styles';
import {usePortalContext} from "../../hooks/PortalContext";

export const TrialInfo: FC = () => {
  const {clientInfo, isTrial} = usePortalContext();
  const {
    trialRemainingDays,
    hasAccess,
  } = clientInfo;

  const { headerTrialInfoUrgent, headerTrialInfo } = useLayoutHeaderTrialInfoStyles();
  let message = "";
  let style = headerTrialInfoUrgent;

  // If it isn't a trial, or we don't have an end date, we don't want to show anything
  if (!isTrial || trialRemainingDays === null){
    return <></>
  }

  if (!hasAccess) {
    // The trial has expired
    // Keep default style "Urgent"
    message = "Trial expired";
  }
  else if (trialRemainingDays < 1){
    // We are in the last partial day of the trial
    // Keep default style "Urgent"
    message = "Trial expires today";
  }
  else {
    // If there is one day left, day is singular, otherwise plural
    let days =  (trialRemainingDays === 1) ? "day" : "days";

    message = `Trial expires in ${trialRemainingDays} ${days}`;

    if (trialRemainingDays >= 7) {
      // There's 7 or more full days left, so style more conservative
      style = headerTrialInfo;
    }
  }

  return (
      <div className={style} data-testid="trial-info-box">
        <Typography>{message}</Typography>
      </div>
  );
};

