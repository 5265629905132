import {graphQLClient} from 'src/services/shared/GraphQLService';
import {gql} from "graphql-request";

const updateSaasNicknameMutation = gql`
  mutation UpdateNickname(
    $client_id: uuid,
    $req: UpdateConnectionNickNameRequest!) {
    update_nickname(client_id: $client_id, req: $req) {
      saas
      connection_id
      nick_name
    }
  }
`;

export const updateSaasNickname = async (
  parameters: any
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    saas,
    nickname, // = {nickname: ''},
    connectionId,
  } = parameters

  const saasIdentifier = saas.identifier;

  if (!saasIdentifier) throw new Error('saas required');
  if (!connectionId) throw new Error('connectionId required');

  const client = await graphQLClient({
    accessToken: auth0HasuraAccessToken
  });

  const variables = {
    client_id: client_id,
    req: {
      saas: saasIdentifier,
      nick_name: (nickname || ''),
      connection_id: connectionId,
    }
  };

  let result = {};

  try {
    const {
      data,
      errors,
    } = await client?.rawRequest(
          updateSaasNicknameMutation,
          variables
        )

    if (errors) {
      console.error('updateSaasNickname throwing error', {errors})
      throw(errors)
    }
    result = data
  }
  catch (error) {
    console.error('updateSaasNickname rethrowing', {error});
    throw(error);
  }

  return result;
}
