import React from 'react';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {SecretField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/SecretField";
import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

// Despite the mutation fields being defined as url and
// secret - the saases using these are never labeled as such.

export const UrlAndSecret = ({
  urlLabel = "url",
  secretLabel = "secret"
}) => {

  return <>
    <ValidatedField
      valueKey = 'url'
      placeholder={urlLabel}
      errorLabel={`${urlLabel} required`}
    />
    <DtxSpacer space={2}/>
    <SecretField
      valueKey="secret"
      placeholder ={secretLabel}
      errorLabel={`${secretLabel} required`}
    />
   </>;
};
