import {SaasIdentifier} from 'src/types/Saas';

// currently unused - maybe useful as a tooltip in Add-Saas menu?
export const saasBlurb = {
  [SaasIdentifier.atlassian]:
    'Atlassian improve software development, project management, collaboration, and code quality.',
  [SaasIdentifier.bamboohr]:
    'BambooHR all-in-one HR software is built with employees in mind.',
  [SaasIdentifier.box]:
    'Box is changing how you manage content across your business from simple file sharing to building custom apps.',
  [SaasIdentifier.hibob]:
    'Modern HR for modern business. The HR platform that powers productivity, engagement, and retention.',
  [SaasIdentifier.docusign]:
    'Docusign is Document-signing software is technology that lets you sign documents online. It uses an electronic signature and requires no paper, printing, scanning or faxing of your documents. ',
  [SaasIdentifier.dropbox]:
    'Dropbox is a modern workspace designed to reduce busywork-so you can focus on the things that matter. Sign in and put your creative energy to work.',
  [SaasIdentifier.egnyte]:
    'Egnyte provides secure Enterprise File Sharing and Content Governance built from the Cloud down.',
  [SaasIdentifier.employment_hero]:
    'Employment Hero the smarter way to manage HR, people, payroll and productivity. For small and medium businesses' +
    ' on the up.',
  [SaasIdentifier.github]:
    'GitHub brings together the world’s largest community of developers to discover, share, and build better software.',
  [SaasIdentifier.gitlab]:
    'From project planning and source code management to CI/CD and monitoring, GitLab is a complete DevOps platform, delivered as a single application.',
  [SaasIdentifier.googlews]:
    'An integrated suite of secure, cloud-native collaboration and productivity apps powered by Google AI. Includes Gmail, Docs, Drive, Calendar, Meet and more.',
  [SaasIdentifier.hubspot]:
    'HubSpot offers a full platform of marketing, sales, customer service, and CRM software — plus the methodology, resources, and support — to help businesses grow better.',
  [SaasIdentifier.lucid]:
    'Lucid suite offers a complete set of visual tools for intelligent diagramming and virtual whiteboards and to help teams turn complexity into clarity.',
  [SaasIdentifier.microsoft365]:
    'From home to business, from desktop to web and the devices in between, Microsoft 365 delivers the tools you need to create your best work.',
  [SaasIdentifier.monday]:
    'The Work OS that lets you workflows, your way.',
  [SaasIdentifier.okta]:
    'The World’s #1 Identity Platform. Consistently named a Leader by major analyst firms. Trusted by 15,000+' +
    ' customers to secure digital interactions.',
  [SaasIdentifier.office365]:
    'From home to business, from desktop to web and the devices in between, Office 365 delivers the tools you need' +
    ' to create your best work.',
  [SaasIdentifier.salesforce]:
    'Grow your business with CRM and Cloud solutions with the best all-in-one platform for Sales, Service, Marketing and more.',
  [SaasIdentifier.sendgrid]:
    'Deliver Exceptional Email Experiences with SendGrid. Leverage the email service customer-first brands trust for reliable inbox delivery at scale.',
  [SaasIdentifier.servicenow]:
    'ServiceNow makes the world of work, work better for people. We deliver digital workflows that create great experiences and unlock productivity.',
  [SaasIdentifier.slack]:
    "Slack is where work flows. It's where the people you need, the information you share, and the tools you use come together to get things done.",
    [SaasIdentifier.smartsheet]: "Welcome to modern work management Manage projects, automate" +
    " processes, and scale up to enterprise-level programs and portfolios, all with one platform.",
  [SaasIdentifier.xero]:
    'Xero connects you to all things business. Its online accounting software connects you to accountants and bookkeepers, your bank, and a huge range of business apps.',
  [SaasIdentifier.zendesk]:
    'Customer service software and support ticketing system by Zendesk. Cloud-based help desk solution used by more than 200,000 organizations worldwide.',
  [SaasIdentifier.zoom]:
    'The Preferred Virtual Meeting Platform for Over a Decade. Simplified video conferencing and messaging across' +
    ' any device.'

    // Obsolete
  // [SaasProviderName.myob]:
  //   'Online accounting solutions for tax, payroll, super and invoicing.',
  // [SaasProviderName.dynamics365]:
  //   'Go beyond traditional CRM and ERP applications with Microsoft Dynamics 365—the connected business cloud that brings data, people, operations, and customers together.',
  // doesn't exist
  // [SaasProviderName.sumologic]:
  //   "Sumo Logic is the industry's leading, secure, cloud-based service for logs & metrics management for modern apps, providing real-time analytics and insights.",
};

