import {Box, useTheme} from '@mui/material';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {stringForMissingText} from 'src/utils/constants';
import {preferredDateFormat, preferredTimeFormat} from 'src/utils/dates';

type FormattedDateProps = {
  date: string;
  width?: string;
  shouldShowTime?: boolean;
  shouldHighlight?: boolean;
};

export const FormattedDate = ({
  date,
  width,
  shouldShowTime = true,
  shouldHighlight = false,
}: FormattedDateProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'inline-grid',
        gridTemplateColumns: shouldShowTime ? '55% 45%' : '90%',
        width: width,
        minWidth: shouldShowTime ? '10rem' : '5rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: shouldHighlight ? theme.more.riskyRed : undefined,
      }}
    >
      <div>{preferredDateFormat(date)}</div>
      {shouldShowTime && <div>{preferredTimeFormat(date)}</div>}
    </Box>
  );
}

type DateTableCellProps = {
  date?: string;
  width?: string | undefined;
  shouldShowTime?: boolean;
};

export const DateTableCell = ({
  date,
  width,
  shouldShowTime = true,
}: DateTableCellProps) => {
  return (
    <DefaultTableCell
      // width={width || shouldShowTime ? '11rem' : '8rem'}
    >
      {date ?
        <FormattedDate
          date={date}
          shouldShowTime={shouldShowTime}
          width={width || shouldShowTime ? '11rem' : '8rem'}
        /> :
        stringForMissingText}
    </DefaultTableCell>
  );
};
