import {DomainDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/DomainDialog/DomainDialog";

export const Egnyte = () => {
  return (
    <DomainDialog
      placeHolder="Your Egnyte Domain"
      errorLabel="Egnyte Domain required"
      urlPrefix=""
      urlSuffix=".egnyte.com"
    />
  )
};
