import React from "react";
import {Theme, Typography as T} from "@mui/material";
import {GridColDef, GridRenderCellParams,} from '@mui/x-data-grid';
import {DefaultLink} from "src/components/common/DefaultLink";
import {dashboardRoute, isShowingApp, searchParamKeys,} from 'src/routes';
import {saasNameFieldName} from "src/services/Discovery/QueryTypes";
import {compactInfoFontSize} from "src/theme";
import {tableCellHeadings} from 'src/utils/constants';
import {resolvePathIntoObject} from 'src/utils/other';
import {noDataString} from "./Applications";
import {renderAppRemediationEllipsis} from "src/components/common/Tables/Utilities/RemediationMenus/AppRemediationMenu";
import {ConnectedSaas} from "src/types/Saas";
import {sotIsMicrosoft} from "src/utils/saasUtils";
import {FormattedDate} from "src/components/common/Tables/Utilities";
import {renderAppName, renderYesNoFromBoolean} from "src/components/common/Tables/DefaultGrid";

export const userCountFieldPath = 'user_count.aggregate.count';

const sharedColumnSettings: any = {
  // width: 80,
  editable: false,
  headerAlign: 'center',
  align: 'center',
  flex: 1,
}

const displayValueFromBool = (bool: boolean) =>
  (bool === true) ? 'Yes' :
    (bool === false) ?
      '' :
      noDataString;

const colorFromBool = (bool: boolean, theme: Theme) =>
  (bool === true) ? theme.more.riskyRed :
    (bool === false) ?
      theme.more.andyGreen :
      theme.palette.text.secondary;

const accessRenderCell = (access: boolean, theme: Theme) => {
  return (
    <T
      variant='body2'
      sx={{
        color: colorFromBool(access, theme)
        // color: access ? theme.more.riskyRed : theme.more.andyGreen
      }}
    >
      {displayValueFromBool(access)}
    </T>
  )
}

const actionRenderCell = (action: string, theme: Theme) => {
  return (
    <T
      variant='body2'
      sx={{
        color: theme.more.sjsOrange,
        lineHeight: compactInfoFontSize,
      }}
    >
      {action}
    </T>
  );
}

const userCountRenderCell = (
  {
    app,
    theme,
    countFieldPath = userCountFieldPath,
  }: {
    app: any,
    theme: Theme,
    countFieldPath?: string,
  }
) => {
  // console.log({app}, {countFieldPath})
  return (
    <DefaultLink
      // to={`/${dashboardRouteFragment}/${usersForAppRouteFragment}/${app?.pub_id}`}
      to={`${dashboardRoute}?${searchParamKeys.discovery}=${isShowingApp}&${searchParamKeys.appId}=${app?.pub_id}`}
      style={{textDecoration: 'none'}}
    >
      <T variant='body2' >
        {resolvePathIntoObject(app, countFieldPath, 0)}
      </T>
    </DefaultLink>
  );
}

const statusColumn = (
  fieldName: string,
  headerName: string,
  theme: Theme
) => {
  return {
    field: `access.${fieldName}`,
    headerName: headerName,
    ...sharedColumnSettings,
    renderCell: (params: GridRenderCellParams) => {
      const userAccess = params.row['access'];
      // console.log({userAccess});
      const accessBoolean = userAccess ?
        userAccess?.[fieldName] :
        undefined
      ;
      // console.log({accessBoolean});
      return accessRenderCell(
        accessBoolean, // accessBoolean,
        theme,
      );
    },
    valueGetter: ({row}: any) => row.access?.[fieldName],
  }
}

const lastLoginColumn = (
    forUser: boolean,
): GridColDef[] => {
  return (
      forUser ?
          [{
            field: 'last_login',
            headerName: 'Last login',
            renderCell : (params: GridRenderCellParams) => (
                <FormattedDate
                    date={params.row.last_login}
                    shouldShowTime={false}
                />
            ),
            flex: 1.3,
          }] :
          []
  )
}

const remediationColumn = (
  headerName: string,
  sot: ConnectedSaas,
): GridColDef[] => {
  return (
    sotIsMicrosoft(sot) ?
      [{
        align: 'right',
        headerName,
        field: 'not_applicable_2',
        valueGetter: ({row}: any) => '...',
        renderCell: (params: GridRenderCellParams) =>
          renderAppRemediationEllipsis(params),
        // 'hello',
        sortable: false,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        disableColumnMenu: true,

        flex: 0.2,
      }] :
      []
  )
}

const appColumn = (flex: number): GridColDef => {
  return {
    field: saasNameFieldName,
    headerName: tableCellHeadings.application,
    flex,
    editable: false,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params: GridRenderCellParams) =>
      renderAppName(params.row[saasNameFieldName], params.row.pub_id),
    // for sorting
    valueGetter: ({row}: any) => row[saasNameFieldName]
  };
}

export const buildUserAppColumns = ({
  theme,
  countFieldPath = userCountFieldPath,
  sot,
  forUser,
}: {
  theme: Theme,
  countFieldPath?: string,
  sot: ConnectedSaas,
  forUser: boolean,
}): GridColDef [] => {

  const columns: GridColDef [] = [
    appColumn(2.0),
    {
      field: countFieldPath,
      headerName: 'User Count',
      ...sharedColumnSettings,
      renderCell: (params: GridRenderCellParams) =>
        userCountRenderCell({
          app: params.row,
          theme: theme,
          countFieldPath,
        }
      ),
      // needed to make the sort work
      valueGetter: ({row}: any) =>
        resolvePathIntoObject(row, countFieldPath, 0)
    },
    statusColumn('write_access', 'Write Access', theme),
    statusColumn('email_access', 'Email Access', theme),
    statusColumn('calendar_access', 'Calendar Access', theme),
    statusColumn('file_access', 'File Access', theme),
    ...lastLoginColumn(forUser),
    {
      field:"is_admin_consented",
      headerName: "Admin consented",
      flex: 1.0,
      valueGetter: (params) => renderYesNoFromBoolean(params),
    },
    // Action column
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      width: 350,
      renderCell: (params: GridRenderCellParams) =>
        actionRenderCell(
          params.row['action'],
          theme
        ),
    },
  ...remediationColumn('', sot)
  ];
  return columns;
}

export const actionColumnIntialValue = '';

export const addReviewItem = (
  existingText: string,
  newText: string
) => {
  let result = existingText;
  if (result !== actionColumnIntialValue) {
    result += ', '
  } // comma between items
  result += newText;
  return result;
}
