import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {clickableDivStyles, menuFontSize, widgetAdviceColor} from 'src/theme';

/*
export const useTableStyles = makeStyles<Theme>(
  ({ palette, spacing, defaultShadow }) => ({
    container: {
      border: `1px solid ${palette.divider}`,
      backgroundColor: 'white',
      padding: spacing(3),
      boxShadow: defaultShadow,
    },
    tableContainer: {
      borderRadius: 7,
    },
  })
);
*/

const driftBoxBaseStyles = {
  color: 'white',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 40,
  borderRadius: 12,
  fontSize: menuFontSize,
}

export const useRowStyles = makeStyles<Theme>(
  ({ palette, spacing }) => ({
    tableCellStyles: {
      paddingLeft: spacing(5),
      paddingRight: spacing(5),
    },

    // for centering the widget in the column
    // this is now built into the widgit
    // widgetDivStyles: {
    //   display: 'flex',
    //   justifyContent: 'center',
    // },

    driftBoxLinkStyles: {
      ...driftBoxBaseStyles,
      backgroundColor: palette.tableHeader,
      ...clickableDivStyles,
    },
    driftBoxNoLinkStyles: {
      ...driftBoxBaseStyles,
      backgroundColor: palette.tableHeader,
      userSelect: 'none',
    },

    saasLogoContainerStyles: {
      display: 'flex',
      alignItems: 'center',
    },
    saasLogoStyles: {
      width: 80,
      paddingRight: spacing(2.5),
      '& img': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
      },
    },

    // fontWeight/Size copied from DefaultTableNumberCell
    // TODO - share all the settings, below
    numberTextStyles: {
      fontWeight: 700,
      fontSize: '1.5rem',
      color: widgetAdviceColor,
      // cursor: 'pointer',
      // '&:hover': {
      //   opacity: 0.6
      // },
      ...clickableDivStyles,
    },
  })
);
