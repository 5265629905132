import {useTheme} from '@mui/styles';
import {ComponentType} from 'react';
import {Button, ButtonProps, styled} from '@mui/material';
import {compactInfoFontSize} from 'src/theme';

type props = ButtonProps & {
  icon?: ComponentType<any>;
  iconProps?: { className?: string; color?: string };
  onClick?: any;
  target?: string;
  component?: any;
};

const CompoundIconButton
  = ({
  className,
  icon: IconComponent,
  iconProps,
  children,
  component,
  onClick,
  ...props
}: props) => {
  const theme = useTheme();

  return (
    <Button
      size="small"
      component={component}
      className={className}
      onClick={onClick}
      {...props}
    >
      {IconComponent && <IconComponent
        sx={{
          marginRight: theme.spacing()
        }}
      />}
      {children}
    </Button>
  );
};

export const DefaultButton = styled(CompoundIconButton)(
  ({theme}) => ({
    borderRadius: 5,
    boxShadow: 'none',
    textTransform: 'none',
    padding: theme.spacing(0.5, 3),
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: compactInfoFontSize,
    '&:hover': {
      opacity: '60%',
      boxShadow: 'none'
    }
  })
);
