import {createClient} from 'contentful';

const createContentfulClient = () => {
  if (
    !process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
    !process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
  ) {
    console.warn('CONTENTFUL details missing');
    return;
  }

  return createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    // This is the access token for this space.
    // Normally you get both ID and the token in the Contentful web app
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });
};

const contentfulClient = createContentfulClient();

export const getSetupGuide = async (saasIdentifier: string | undefined) => {
  // console.log('checking for contentful client');
  if (!contentfulClient) {
    console.warn('Unitialized Contentful client');
    return;
  }

  if (!saasIdentifier) {
    console.warn('missing saas identifier');
    return;
  }

  // console.log('querying Contentful with', {saasIdentifier});

  const result = await contentfulClient.getEntries({
    content_type: 'setupGuide',
    'fields.title[match]': saasIdentifier
  });

  console.log({result});

  if(result.items.length === 0)
    throw new Error(`no Contentful Intructions found for ${saasIdentifier}`);

  return result?.items?.[0];
};
