import {userDomainStrings} from 'src/services/shared/serviceConstants';

// Build where-clause-comparison targets dynamically,
// handling missing arguments by not filtering on
// them - by "collapsing to null"

// Used wherever we need optional privilege & saas filtering
// see examples in getMfDisabled, getInactiveUsers, getGhosts

// for saasIdentifier
export const nullCollapsableSaasIdentifier = (
  saasIdentifier: string | undefined
) => {
  const result = saasIdentifier == null ?
    {} : // no filtering
    {'name': {'_eq': saasIdentifier}};
  // console.log({result});
  return result;
}

// used to query on connection ID aka pub_id
export const nullCollapsableSaasPubId = (
  saasPubId: string | undefined
) => saasPubId == null ?
  {} : // no filtering
  {"pub_id": {"_eq": saasPubId}};

// for user pub_id
export const nullCollapsableUserPubId = (userPubId: string) =>
  userPubId == null ?
    {} : // no filtering
    {"pub_id": {"_eq": userPubId}};

export const nullCollapsablePrivilegeLevel = (
  privilegeLevel?: string
) => privilegeLevel === null ||
  privilegeLevel === undefined ?
    {} : // no filtering
    {"_eq": privilegeLevel};

// a combined one used by ghosts and maybe others
export const nullCollapsableArguments = ({
  saasIdentifier,
  privilegeLevel,
}: {
  saasIdentifier?: string,
  privilegeLevel?: string,
}) => {
  // const privilegeLevelBoolExp = privilegeLevel === null ||
  // privilegeLevel === undefined ?
  //   {} : // no filtering
  //   {"_eq": privilegeLevel};

  return {
    privilegeLevelBoolExp: nullCollapsablePrivilegeLevel
      (privilegeLevel),
    saasIdentifierBoolExp: nullCollapsableSaasIdentifier
      (saasIdentifier),
  };
}

export const nullCollapsableIncludeExternal = (
  shouldIncludeExternal: boolean
) =>
  shouldIncludeExternal ?
    {} : // no filtering
    ({"_neq": userDomainStrings.external});

export const nullCollapsableIncludeInternal = (
  shouldIncludeInternal: boolean
) =>
  shouldIncludeInternal ?
    {} : // no filtering
    ({"_neq": userDomainStrings.internal});

export const nullCollapsableConnectionId = (
  connectionId?: string
) => {
  return connectionId ? {_eq: connectionId} : {}
}

export const nullCollapsableClientPubId = (client_id: string) => {
  return {clientBoolExp: client_id ? {pub_id :{_eq: client_id}} : {}}
}
