import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {compactInfoFontSize, tableBorder, tableContainerBorderRadius} from 'src/theme';

// export const useDefaultTableStyles = makeStyles<
//   Theme,
//   {columnAlignment: string}
// >(
//   ({
//       palette: {
//         divider,
//         tableHeader,
//         // tableHeaderText
//       }
//   }) => ({
//     table: ({ columnAlignment }) => ({
//       borderCollapse: 'collapse',
//       '& td': {
//         textAlign: columnAlignment as any,
//       },
//       '& th': {
//         textAlign: columnAlignment as any,
//       },
//       minWidth: '1100px'
//     }),
//     // headerRow: {
//     //   // SJS 6 Apr 22 - for the grey table headers (Andy)
//     //   backgroundColor: tableHeader,
//     //   // borderBottom: `solid 1px ${divider}`,
//     //   border: `solid 1px ${divider}`,
//     // },
//   }),
//   { index: 1 }
// );

export const useTableHeadStyles = makeStyles<Theme>(
  ({ palette: {
    text,
    primary,
    divider,
    tableHeaderText
  }, spacing }) => ({
    // moved to StyledTableHeaderRow in Default Table
    // tableHeadCell: {
    //   position: 'relative',
    //   // SJS 6 Apr 22 - for the gray table headers (Andy)
    //   color: tableHeaderText,
    //   fontSize: 14,
    //   flexDirection: 'column',
    // },
    sortLabelContainerActive: {
      'span&&': {
        color: text.secondary,
      },
      // Compensates for the absurd double emphasis in MUI classes
      'span&$sortLabelContainer&$sortLabelContainer $sortIcon': {
        color: primary.main,
      },
    },
    sortLabelContainer: {
      // color: text.secondary, // gives sortable colums the wrong heading color
      fontSize: compactInfoFontSize, // 14,
      marginBottom: spacing(-4),
    },
    sortIcon: {
      color: primary.main,
      position: 'relative',
      top: spacing(-2),
      fontSize: '2.5em',
    },
  }),
  { index: 1 }
);

// Experimentation indicates the tableCell column alignment
// does nothing - maybe it can't override the Table columnAlignment.
// But the tableCellLabel has the desired effect.

// columnAlignment is a prop on DefaultTable.
// It's is captured, Themed and TypeScripted
// here to align the column, inc the header

// It can also be set via the columns prop to the DefaultTable
// columnAlignOverride property ends up here to override the
// Table columnAlignment prop.

// Here's an example of the override from PrivilegedAccessSummaryTable

// {id: 'app', label: 'Application', columnAlignOverride: 'left'},

// export const useTableCellStyles = makeStyles<
//   Theme, {
//     columnAlignment?: string;
//     width?: string | number;
//   }
// >(() => ({
//   tableCell: ({ width, columnAlignment = 'left' }) => ({
//     textAlign: columnAlignment as any,
//     fontSize: '0.875rem',
//     // has no effect - color: 'white',
//   }),
//   tableCellLabel: ({ columnAlignment = '' }) => ({
//     textAlign: columnAlignment as any,
//     fontSize: '0.875rem',
//     // this works for the header but applies everywhere - color: 'white',
//   }),
// }));

export const useDefaultTableContainerStyles = makeStyles<Theme>(
  ({ shape, spacing, majorContainerBorderRadius, palette: { divider } }) => ({
    containerStyles: {
      borderRadius: majorContainerBorderRadius, // shape.borderRadius,
      padding: spacing(3),
      width: '100%', // makes the Dashboard work, doesn't seem to break others
      // has no effect - backgroundColor: 'white',
    },
    tableContainerStyles: {
      border: tableBorder, // `solid 1px ${divider}`,
      borderRadius: tableContainerBorderRadius,
    },
  })
);

export const useDefaultTableNumberCellStyles = makeStyles<Theme>(
  ({ spacing, palette }) => ({
    numberTextStyles: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    subTextStyles: {
      color: palette.text.disabled,
      fontSize: 12,
      fontWeight: 500,
      fontStyle: 'italic',
      paddingLeft: '0px', paddingRight: '0px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap', // freaks it out, can't remember how
      width: '100%',
    },
  })
);
