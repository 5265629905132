import {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Typography as T,
} from '@mui/material';
import {DefaultButton} from 'src/components/common/DefaultButton';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {compactInfoFontSize} from "src/theme";

const installUrl = 'https://auth.monday.com/oauth2/authorize?client_id=dc491ec624f3898cf52812c75a997ddc&response_type=install';

const MondayDialog = ({
  isOpen,
  setIsOpen,
  doConnection,
}: any) => {

  const theme = useTheme();

  const [didClickInstall, setDidClickInstall] = useState(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleInstall = () => {
    setDidClickInstall(true);
    window.open(installUrl, '_blank');
    // setIsOpen(false);
  };

  const handleConnect = () => {
    // setDidClickInstall(true);
    // window.open(installUrl, '_blank');
    setIsOpen(false);
    doConnection();
  };

  return (
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Install Monday.com OAuth Application?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: theme.palette.text.primary
            }}
          >
            <>
            <T>
              Detexian requires permission to install the Monday.com OAuth Application.
            </T>
              <DtxSpacer />
            <T
              sx={{
                fontSize: compactInfoFontSize,
                fontStyle: 'italic',
                color: theme.palette.text.secondary,
              }}
            >
              The installer will open in a new browser tab.<br />Return here when you've finished installing.
            </T>
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DefaultButton
            onClick={handleCancel}
            variant="contained"
          >
            Cancel
          </DefaultButton>
          <DefaultButton
            onClick={handleInstall}
            disabled={didClickInstall}
            autoFocus
            variant="contained"
          >
            Install
          </DefaultButton>
          <DefaultButton
            onClick={handleConnect}
            autoFocus
            variant="contained"
            disabled={!didClickInstall}
          >
            Connect
          </DefaultButton>
       </DialogActions>
      </Dialog>
  );
}

export const useMondayDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  return {
    isMondayDialogOpen: isOpen,
    setIsMondayDialogOpen: setIsOpen,
    MondayDialog,
  }
}
