import {Divider, DividerProps, useTheme} from '@mui/material';

// export const VSpacer = ({space = 1}: {space?: number}) => {
//   const theme = useTheme();
//   return <Box sx={{height: theme.spacing(space)}}>&nbsp;</Box>;
// };

interface propTypes extends DividerProps {
  space?: number;
  component?: string;
}

export const DtxSpacer = (props: propTypes) => {
  const {space = 2.5, sx, ...rest} = props;
  const theme = useTheme();
  const direction = rest?.orientation === 'vertical' ? 'borderRightWidth' : 'borderBottomWidth'
  return <Divider
    sx={{borderColor: 'transparent',
      ...sx,
      ...{[direction]: theme.spacing(space)}
    }}
    {...rest}
  />;
};
