import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

// Only used by Box
export const TenantId = () => {

  const label = 'Enterprise ID'
  return <>
    <ValidatedField
      valueKey ={'tenant_id'}
      placeholder={label}
      errorLabel={`${label} required`}
      // errorText='Please enter a valid tenant ID'
    />
   </>;
};
