import {
  connectedSaasesDetailQuery,
  connectedSaasesQuery,
  getAllSaasQuery,
} from 'src/services/SaasService/SaasUtilityQueries';
import {transformSaasFlat} from 'src/services/shared/sharedTransforms';
import {ConnectedSaas, ISaasGraphQLResponse} from 'src/types/Saas';
import {combineSaasNicknameWithName} from "src/utils/saasUtils";
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {nullCollapsableClientPubId} from "../shared/nullCollapsableUtilities";
import {QueryContext} from "../../hooks/TanstackHooks";


function mapConnectedSaaS(pub_id: string, saas: ISaasGraphQLResponse, nick_name: string, secret_version?: Array<any>) {
  const calculatedName = combineSaasNicknameWithName(
    saas.description,
    nick_name
  );
  let result = {
    identifier: saas.name,
    name: saas.description,
    calculatedName,
    pubId: saas.pub_id,
    secret_version: secret_version,
    // same as the above but packaged for certain uses
    // TODO return one or the other - probably this one
    // See if anyone uses the one above one "loose" ones
    saas: {
      identifier: saas.name,
      name: saas.description,
      pubId: saas.pub_id,
      calculatedName,
    },
    connectionId: pub_id,
    nickname: nick_name,
  };

  return result;
}

export const getConnectedSaases = async ({accessToken, queryContext}:{
  accessToken: any,
  queryContext: QueryContext}
) : Promise<ConnectedSaas[]> => {
  const theQuery = async (client: any) => {
    const result = client.request(
      connectedSaasesQuery,
      nullCollapsableClientPubId(queryContext.client.id)
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken // , signal
    );
  }
  catch (error) {
    console.log('getConnectedSaases failed')
    throw (error)
  }

  const result = response?.client_saas.map((
    {
      saas,
      pub_id,
      nick_name,
    }: {
      saas: ISaasGraphQLResponse; // IClientSaasGroupedGraphQLResponse;
      pub_id: string;
      nick_name: string;
    }
  ) => {
    return mapConnectedSaaS(pub_id, saas, nick_name);
  })
  return result;
}

export const getConnectedSaasDetails = async ({accessToken, queryContext}:{
  accessToken: any,
  queryContext: QueryContext}
) : Promise<ConnectedSaas[]> => {
  const theQuery = async (client: any) => {
    const result = client.request(
      connectedSaasesDetailQuery,
      nullCollapsableClientPubId(queryContext.client.id)
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken // , signal
    );
  }
  catch (error) {
    console.log('getConnectedSaasDetails failed')
    throw (error)
  }

  const result = response?.client_saas.map((
    {
      saas,
      pub_id,
      nick_name,
      secret_version,
    }: {
      saas: ISaasGraphQLResponse; // IClientSaasGroupedGraphQLResponse;
      pub_id: string;
      nick_name: string;
      secret_version: Array<any>;
    }
  ) => {
    return mapConnectedSaaS(pub_id, saas, nick_name, secret_version);
  })
  return result;
}

// All SaaSes
export const getAllSaas = async  ({accessToken}:{
  accessToken: any}
) => {
  const theQuery = async (client: any) => {
    const result = client.request(
      getAllSaasQuery,
      {}
    );
    return result;
  }

  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken // , signal
    );
  }
  catch (error) {
    console.error('getAllSaas failed')
    throw (error)
  }

  const result = response.saas.map(transformSaasFlat);
  return result;
};
