import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import KeyIcon from '@mui/icons-material/Key';
import {Box, Typography as T, useTheme} from '@mui/material';
import React, {useState} from "react";
import {
    ValidatableComponentProps,
    ValidatedComponent,
} from '../sharedFields/ValidatedComponent';
import {DefaultButton} from 'src/components/common/DefaultButton';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {
    clickableDivStyles,
    compactInfoFontSize,
    generalBorder
} from 'src/theme';
import {ConnectedSaas} from 'src/types/Saas';

const toString = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

// Allow user to finish Google Workspace setup.
// Facilitate upload of private key for onboarding with
// service account

// Uploaded file goes to field google_svc_account.
// Passed to ValidatedComponent, below.
// This is in here as a lazy way to access the saas
const UploadFile = ({
                        valueKey,
                        values,
                        label,
                        localValidState,
                        setValueDirectly,
                        onBlur,
                        onFocus,
                    }: ValidatableComponentProps
) => {
    const theme = useTheme();

    // only used to display the file name
    const [selectedFile, setSelectedFile] = useState<File>();

    const handleFileUploaded = async (event: any) => {
        if (event.target.files.length === 0) {
            return
        }
        const uploadedFile = event.target.files[0];
        setSelectedFile(uploadedFile);
        try {
            const jsonBlob = await toString(uploadedFile);
            if (typeof jsonBlob !== "string") {
                throw Error("binary file uploaded")
            }
            var blobData = JSON.parse(jsonBlob);
            setValueDirectly(valueKey, blobData);
        } catch (error) {
            localValidState = false
            console.error('handleFileUploaded', error);
        }
    }

    var fileStateDisplay;
    if (!selectedFile)
        // File has not been set ever.
        fileStateDisplay = <>
            <CloudUploadIcon
                sx={{
                    color: localValidState ?
                        theme.palette.warning.main :
                        theme.palette.error.main,
                    fontSize: '1.7rem',
                }}
            />
            {
                !localValidState && <>
                    <DtxSpacer orientation="vertical"/>
                </>
            }
        </>
    else if (!selectedFile)
        fileStateDisplay = "upload"
    else if (localValidState)
        // File is uploaded and is valid.
        fileStateDisplay = <>
            <CloudDoneIcon
                sx={{
                    color: theme.more.andyGreen,
                    fontSize: '1.7rem',
                }}
            />
            <DtxSpacer orientation="vertical"/>
            <T
                sx={{
                    textAlign: 'left',
                    fontSize: compactInfoFontSize,
                    paddingBottom: 0,
                    marginBottom: '-5px',
                }}
            >
                <span>Uploaded file <em>{selectedFile.name}</em></span>
                {values.google_svc_account ?
                    <>
                        <DtxSpacer space={1}/>
                        <span>Project ID: <em>{values.google_svc_account.project_id}</em></span>
                        <DtxSpacer space={1}/>
                        <span>Client ID: <em>{values.google_svc_account.client_id}</em></span>
                        <DtxSpacer space={1}/>
                        <span>Email: <em>{values.google_svc_account.client_email}</em></span>
                    </> : <></>
                }
            </T>
        </>
    else
        // File is uploaded, but not valid
        fileStateDisplay = <>
            <CloudOffIcon
                sx={{
                    color: theme.palette.error.main,
                    fontSize: '1.7rem',
                }}
            />
            <DtxSpacer orientation="vertical"/>
            <T
                sx={{
                    textAlign: 'left',
                    fontSize: compactInfoFontSize,
                    paddingBottom: 0,
                    marginBottom: '-5px',
                }}
            >
                <span>Uploaded file <em>{selectedFile.name}</em> is invalid</span>
            </T>
        </>


    return <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }}>
        <DefaultButton
            sx={{
                backgroundColor: 'rgba(170, 170, 170, 1)',
                minWidth: '185px',
                whiteSpace: 'nowrap',
                border: generalBorder,
                ...clickableDivStyles,
            }}
            variant="contained"
            icon={KeyIcon}
            component="label" // makes the upload work

            // experiment - for validation
            onBlur={onBlur}
            onFocus={onFocus}
        >
            {/*This hidden field does the upload*/}
            <input
                hidden
                type="file"
                accept=".json"
                name="JSON Key"
                onChange={handleFileUploaded}
            />
            <span>Upload JSON file</span>
        </DefaultButton>
        <DtxSpacer orientation="vertical"/>
        {fileStateDisplay}
    </Box>;
}

const requiredFields = ["auth_provider_x509_cert_url", "auth_uri", "client_email", "client_id", "client_x509_cert_url", "project_id", "private_key_id", "private_key", "token_uri", "type", "universe_domain"]

const validateGoogleServiceAccount = (value: any) => {
    if (value === undefined) {
        return false
    }
    return requiredFields.every((k) => value.hasOwnProperty(k))
};

export const GoogleServiceAccount = (
    {saas}: { saas: ConnectedSaas }
) => {
    const label_jsonfile = 'JSON File'

    return <>
        <DtxSpacer space={2}/>
        <ValidatedComponent
            ValidatableComponent={UploadFile}
            valueKey="google_svc_account"

            // we might not use these
            placeholder={label_jsonfile}
            errorLabel={`${label_jsonfile} required`}
            validationFunction={validateGoogleServiceAccount}
        />
    </>;
};


// export const StyledUploadButton = styled(DefaultButton)(
//   ({theme}) => ({
//     fontSize: '1.0rem',
//     backgroundColor: theme.palette.common.white,
//     color: theme.more.andyBlue,
//     border: generalBorder,
//   })
// );

