import {useCallback, useState} from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {planTypes, stringForMissingText} from "src/utils/constants";
import {CustomFooter, DefaultGrid, GridHeadingWithExport, renderSaas} from "src/components/common/Tables/DefaultGrid";
import {DefaultTableContainer} from "src/components/common/DefaultTable";
import {ISaas} from "src/types/Saas";
import {andyGreen} from "src/theme";

import {renderConnectionEllipsis} from "./ConnectedSaasActionMenu";
import {ConsentDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/ConsentDialog";
import {useConfirmationDialog} from "./useConfirmationDialog";
import {useAddSaaSErrorDialog} from "./useAddSaaSErrorDialog";
import {removeConnection} from "src/services";
import {renderNickNameAsTextField} from "./NicknameTextField";
import {useMutationWithAccessToken} from "src/hooks/TanstackHooks";
import {tableCellHeadings} from "src/utils/constants";
import {usePortalContext} from "../../../../../hooks/PortalContext";

// Can't disable pagination in the free licence
// so this is the next-best thing
const minRowsPerPageOption = 100 // largest allowed in free licence
const rowsPerPageOptions = [minRowsPerPageOption]

export const ConnectedSaases = ({
  connectedSaases,
  allSaases,
}: {
  connectedSaases: any[],
  allSaases: ISaas[],
}) => {
  const {planType} = usePortalContext().clientInfo;

  const [
    isOAuth2ConsentDialogOpen,
    setIsOAuth2ConsentDialogOpen
  ] = useState(false);

  const [selectedSaas, setSelectedSaas] = useState(
    {
      name: '',
      identifier: '',
      connectionId: '',
      calculatedName: '',
      secret_version: [],
    }
  );

  const [selectedConnectionId, setSelectedConnectionId] = useState('');

  const [selectedNickname, setSelectedNickname] = useState(undefined);

  // Temporary array of recently-removed saas connectionIds.
  // Used to immediatly remove a saas from the Connected list.
  const [
    removedConnectionIdArray, setRemovedConnectionIdArray
  ] = useState<string[]>([])

  const filteredRows = () => {
    return connectedSaases.filter(
      (row: {connectionId: any;}) => !removedConnectionIdArray.some(
        (connectionId: string) => row.connectionId === connectionId
      )
    );
  }

  const {
    dialog: errorDialog,
    setIsOpen: setIsErrorDialogOpen,
    setErrorMessage,
  } = useAddSaaSErrorDialog({
    saas: selectedSaas,
    nickname: selectedNickname,
    onDismiss: ()=>{},
  })

  const {
    // isLoading, isSuccess, error,
    mutate: doRemoveConnection,
  } = useMutationWithAccessToken({
    mutation: removeConnection,
    tanstackOptions: {
      // temporarily hide the removed connection -
      // see filteredRows, above
      onSuccess: () => {
        console.log('doRemoveConnection - onSuccess');
        setRemovedConnectionIdArray([
          ...removedConnectionIdArray,
          selectedConnectionId
        ])
      },
      onError: (error: any) => {
        console.log('doRemoveConnection - onError');
        setIsErrorDialogOpen(true);
        setErrorMessage('on remove');
      }
    }
  });

  const onConfirmRemoveConnection = () => {
    // @ts-ignore
    doRemoveConnection({
      saas: selectedSaas,
      connectionId: selectedConnectionId,
    });
  }

  const {
    dialog: confirmationDialog,
    setIsOpen: setIsConfirmationDialogOpen,
  } = useConfirmationDialog({
    saas: selectedSaas,
    nickname: selectedNickname,
    onCancel: ()=>{},
    onConfirm: onConfirmRemoveConnection,
  })

  const columns = useCallback(
    (): GridColDef [] => {
      const handleUpdateConnection = () => {
        setIsOAuth2ConsentDialogOpen(true);
      }

      return [
        {
          headerName: tableCellHeadings.saas,
          field: 'name',
          // for export and sort
          valueGetter: ({row}: any) => {
            const result =  row.saas?.name || stringForMissingText;
            return result;
          },
          renderCell: (params) => {
            const modifiedParams = {...params};
            modifiedParams.row = {
              saas: {
                ...params.row,
              },
              shouldDisableLink: planType !== planTypes.Unlimited,  // Disabled links for trial and feature_based
            };
            return renderSaas({
              params: modifiedParams
            });
          },
          flex: 1.1,

        },
        {
          headerName: 'Status',
          field: 'connectionStatus',

          renderCell: (params: GridRenderCellParams) => { return (
            <Box
             sx={{color: andyGreen}}
            >
              Connected
            </Box>
          )},
          // minWidth: 200,
          flex: 1.1,
        },
        {
          headerName: 'Nickname',
          field: '',
          valueGetter: (params: GridRenderCellParams) => params.row.nickname,
          renderCell: (params: GridRenderCellParams) =>
            renderNickNameAsTextField (
              params,
              params.row.saas,
              setSelectedSaas,
              setIsErrorDialogOpen,
              setErrorMessage,
            ),
          width: 200,
          // flex: 1.1,
        },
        {
          headerName: 'Action',
          field: 'action',
          renderCell: (params: GridRenderCellParams) =>
          renderConnectionEllipsis({
            params,
            saas: params.row.saas,
            setSelectedSaas,
            setSelectedConnectionId,
            setSelectedNickname,
            setIsOAuth2ConsentDialogOpen,
            setIsConfirmationDialogOpen,
            handleUpdateConnection,
          }),
          sortable: false,
          disableExport: true,
          disableReorder: true,
          filterable: false,
          disableColumnMenu: true,
          flex: 0.3,
        },

      ];
    },
    [setErrorMessage, setIsConfirmationDialogOpen, setIsErrorDialogOpen, planType]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (<>
    {confirmationDialog}
    {errorDialog}
    <ConsentDialog
      open={isOAuth2ConsentDialogOpen}
      onClose={
        () => {
          setIsOAuth2ConsentDialogOpen(false);
        }
      }
      saas={selectedSaas}
      connectionId={selectedConnectionId}
    />

    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      <DefaultGrid
        rows={filteredRows()}
        getRowId={row => row.connectionId}
        columns={columns()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={rowsPerPageOptions}
        length={connectedSaases?.length}
        minRowsPerPageOption={minRowsPerPageOption}
        initialState={{
          sorting: {
            sortModel: [{
              field: "drift_time",
              sort: "desc",
            }],
          },
        }}
        toolbar={() =>
          <GridHeadingWithExport
            heading="Connected"
            shouldShowExportButton={false}
            shouldShowBackToPrevious={false}
          />}
        footer={() => CustomFooter({})}
      />
    </DefaultTableContainer>
  </>)
}
