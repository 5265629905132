import {DomainDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/DomainDialog/DomainDialog";

export const Zendesk = () => {
  return (
    <DomainDialog
      placeHolder="Your Zendesk Domain"
      errorLabel="Zendesk Domain required"
      urlPrefix=""
      urlSuffix=".zendesk.com"
    />
  )
};
