import {
  IconButton,
  InputAdornment,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

type props = {
  valueKey?: string;
  placeholder?: string;
  errorLabel?: string;
}

// Extends ValidatedField with a hidden secret (user can unhide)
export const SecretField = (
{
  valueKey = 'password',
  placeholder = 'Password',
  errorLabel = 'required',
}: props) => {

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return <ValidatedField
    valueKey={valueKey}
    type={showPassword ? "text" : "password"}
    placeholder={placeholder}
    errorLabel={errorLabel}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label="toggle secret visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ?
              <VisibilityOff /> :
              <Visibility />
            }
          </IconButton>
        </InputAdornment>
      ),
    }}
 />;
}
