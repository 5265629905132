import React, {FC, forwardRef, ReactNode} from 'react';
import {TableCell, TableCellProps, Typography} from '@mui/material';
import {stringForMissingText} from 'src/utils/constants';
import {compactInfoFontSize} from 'src/theme';

interface PropTypes extends TableCellProps {
  compact?: boolean;
  children?: ReactNode;
  defaultContent?: ReactNode;
  columnAlignment?: any;
}

const primitiveTypes: string[] = ['string', 'number'];
const emptyValues: ReactNode[] = ['', undefined, null];

export const DefaultTableCell: FC<PropTypes> = forwardRef<any, PropTypes>((
  props,
  ref,
) => {
  const {
    className,
    children,
    defaultContent = stringForMissingText,
    width,
    columnAlignment,
    ...otherProps
  } = props;

  const primitiveChild = primitiveTypes.includes(typeof children);
  const emptyChild = emptyValues.includes(children);

  return (
    <TableCell
      ref={ref}
      sx={{
        overflow: "hidden",
        textAlign: columnAlignment,
        fontSize: compactInfoFontSize,
        width: width, ///
        ...props.sx
      }}
      className={className}
      {...otherProps}
    >
      {primitiveChild ? (
        <Typography
          style={{
            textAlign: columnAlignment,
            fontSize: compactInfoFontSize,
            whiteSpace: 'nowrap',
          }}
        >
          {emptyChild ? defaultContent : children}
        </Typography>
      ) : (
        children
      )}
    </TableCell>
  );
}
);
