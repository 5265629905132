import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {borderRadiusSmall, menuFontSize} from 'src/theme';

export const useDefaultSelectStyles = makeStyles<Theme>(({ spacing }) => ({
  selectContainer: {
    borderRadius: borderRadiusSmall,
  },
  selectInput: {
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1.5),
    fontSize: menuFontSize,
    lineHeight: 1.25
  },
  selectNotchedOutline: {
    border: '1px solid #797979'
  },
  selectMenu: {
    borderRadius: 10
  }
}));
