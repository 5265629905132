import {Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/styles";

export const ErrorMessage = () => {
  const theme = useTheme();
  return <Typography
    sx={{
      color: theme.palette.text.secondary,
      fontSize: '1rem',
      padding: theme.spacing(1),
    }}
  >An error has occurred. </Typography>
}