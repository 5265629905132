import {
  Typography
} from '@mui/material';
import {Link} from 'react-router-dom';
import {
  DefaultTableCell,
  DefaultTableRow
} from 'src/components/common/DefaultTable';
import {UserCountWidget} from 'src/components/UserCountWidget';
import {
  useRowStyles
} from 'src/pages/PrivilegedAccessPage/PrivilegedAccess/PrivilegedAccessSummaryTable/styles';
import {driftsRouteFragment, exceptionRouteFragment} from 'src/routes';
import {ISaasException} from 'src/types/SaasExceptions';
import {SaasTableCell} from 'src/components/common/Tables/Utilities/tableCells';
import {privileges} from 'src/utils/constants';

type props = {
  saasException: ISaasException;
  saas: any; // ISaas;
};

// Copied from SingleSaasExceptionsTableRow when they diverged.
// There may still be artifacts from that...
export const AuthExceptionSummaryTableRow = ({
  saasException,
  saas,
}: props) => {
  const {
    name,
    highlyPrivilegedUser,
    privilegedUser,
    enabledUser,
  } = saasException;

  const {
    tableCellStyles,
    driftBoxLinkStyles,
    driftBoxNoLinkStyles,
  } = useRowStyles();

  const getSaasDriftLink = (saasIdentifier: string) => {
    return `${driftsRouteFragment}/${saas.identifier}`
  }

  const getPrivilegeMfaDisabledLink = ({
    saasIdentifier,
    privilege,
  }: any) => {
    // first, calculate privCode: hpu, pu, u
    let privCode = privileges.none;
    if(privilege === highlyPrivilegedUser) privCode = privileges.high
    else if(privilege === privilegedUser)  privCode = privileges.some

    // then, build the link
    return `${exceptionRouteFragment}/${saas.identifier}?privilege=${privCode}`
  }

  const WidgetForPrivilege = ({privilege}: any) => (
    <UserCountWidget
      accessCount={privilege.count}
    />
  );

  const LinkedWidgetForPrivilege = ({privilege}: any) => (
    privilege.count > 0 ? (
      <Link
        to={getPrivilegeMfaDisabledLink({saasIdentifier: saas.identifier, privilege})}
        style={{textDecoration: 'none'}}
      >
        <WidgetForPrivilege privilege={privilege}/>
      </Link>
    ) :
    <WidgetForPrivilege privilege={privilege}/>
  )
  // console.log({saas});
  return (
    <DefaultTableRow
    >
      <SaasTableCell
        align="left"
        saas={saas}
      />
      <DefaultTableCell
        align="center"
        width="20%"
      >
        {name}
      </DefaultTableCell>
      <DefaultTableCell align="center" width="20%">
        <LinkedWidgetForPrivilege privilege={highlyPrivilegedUser} />
      </DefaultTableCell>
      <DefaultTableCell align="center" width="20%">
        <LinkedWidgetForPrivilege privilege={privilegedUser} />
       </DefaultTableCell>
      <DefaultTableCell align="center" width="20%">
         <LinkedWidgetForPrivilege privilege={enabledUser} />
      </DefaultTableCell>
      <DefaultTableCell
        className={tableCellStyles}
        align="center"
      >
        {saasException?.driftCount ? (
          <Link
            to={getSaasDriftLink(saas.identifier)}
            style={{textDecoration: 'none'}}>
            <div
              className={driftBoxLinkStyles}
            >
              {saasException.driftCount}
            </div>
          </Link>
        ) : (
          <Typography className={driftBoxNoLinkStyles}>
            0
          </Typography>
        )}
      </DefaultTableCell>
   </DefaultTableRow>
  );
};
