import {Box, CircularProgress, Divider, Typography as T, useTheme} from '@mui/material';
import {useNavigate} from 'react-router';
import {DefaultButton} from 'src/components/common/DefaultButton';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {CustomOuterChartLabel, DashboardDonutChart} from 'src/components/PieChart';
import {dashboardRoute} from 'src/routes';
import {calculateAggregates} from 'src/pages/DashboardPage/Discovery/SummaryPanel/calculateAggregates';
import {CountSummaryColumn} from 'src/pages/DashboardPage/Discovery/SummaryPanel/CountSummaryColumn';
import {andyGreen, riskyRed} from 'src/theme';
import {DefaultContainer} from "../../../../components/common/DefaultContainer";
import {useQueryWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {getAppSummary} from "../../../../services/Discovery/Discovery";
import {FeatureCountProps} from "../../../../types/FeaturePanelProps";
import React from "react";
import {usePortalContext} from "../../../../hooks/PortalContext";
import {ErrorMessage} from "../../../../components/common/ErrorMessage/ErrorMessage";

const DonutChart = ({
  itApprovedCount,
  nonItApprovedCount,
}: {
  itApprovedCount: number,
  nonItApprovedCount: number,
}) => {

  // cheap and dirty minimum structure for the pie chart
  // the order in the array is important to get the colors
  // and proportions right
  const pieChartData = [
    {value: nonItApprovedCount},
    {value: itApprovedCount},
  ];

  return <Box sx={{
    height: '100%',
    marginTop: '-18px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }}>
    <CustomOuterChartLabel
      count={itApprovedCount}
      label={<span>IT-Approved<br />Apps</span>}
      color={andyGreen}
    />
    <DashboardDonutChart
      data={pieChartData}
      total={itApprovedCount+nonItApprovedCount}
    />
    <CustomOuterChartLabel
      count={nonItApprovedCount}
      label={<span>Non-IT-Approved<br />Apps</span>}
      color={riskyRed}
    />
  </Box>;
};

const TopPanelVerticalDivider = () => (
  <Divider orientation="vertical"
    sx={{
     // why doesn't this work? height: '100%',
     height: '240px',
     margin: 'auto',
     // width: '100%',
    }}
  />
);

const SectionHeading = (
  {
    title,
    // Why we need this shift remains unclear.
    // But ithout a shift of 13 percent centers the
    // Non IT Approved Apps
    moveToLeftByPercent = 0
  }:
  {
    title: string,
    moveToLeftByPercent?: number
  }
) => {
  return (
    <T
      sx={{
        fontSize: '1.1rem',
        fontWeight: '600',
        fontStyle: 'italic',
        textAlign: 'center',
        marginLeft: `-${moveToLeftByPercent}%`,
      }}
    >
      {title}
    </T>
  );
};

// button name toggles on click
const openButtonName = 'View Discovery Details'
const closeButtonName = 'Close Discovery Details'

interface topPanelProps extends FeatureCountProps {
  isShowingDiscoveryDetails: boolean,
  id: string,
  showViewDetails: boolean,
}

export const SummaryPanel = ({
  isFeatureOn,
  isShowingDiscoveryDetails,
  id,
  showViewDetails=true,
}: topPanelProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {featureDialog} = usePortalContext();

  const {isLoading, data, isError} = useQueryWithAccessToken({
      queryKey: [cacheKeys.discovery.appSummary],
      queryFunction: getAppSummary,
      queryFunctionVariables:{},
    }
  );

  const handleClick = () => {
    // If the feature isn't on then call the "upgrade" method
    if(!isFeatureOn) {
      if(featureDialog?.open){
        featureDialog?.open();
      }
    }
    // The feature is on, so navigate to the sub-page
    else {
      const targetURL = isShowingDiscoveryDetails ?
        `${dashboardRoute}` :
        `${dashboardRoute}?discovery=apps`
      navigate(targetURL);
    }
  }

  const calculatedValues = calculateAggregates(
    data
  );

  if(isError) {
    return <DefaultContainer><ErrorMessage /></DefaultContainer>
  }

  if(isLoading) {
    return <DefaultContainer><CircularProgress size={31} />
    </DefaultContainer>
  }

  return <DefaultContainer
    sx={{
      marginBottom: theme.spacing(1),
      paddingRight: '2%',
    }}
  >
    {/*Top Panel*/}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2%',
        alignContent: 'center',
        // justifyContent: 'space-between',
        width: '100%',
        // padding: theme.spacing(1),
        height: '100%',
      }}
    >
      {/* The upper section - everything but the Discovery Details button */}
      <Box
        sx={{
          display: 'flex',
          gap: '2%',
          alignContent: 'left',
          // justifyContent: 'space-between',
          width: '100%',
          // padding: theme.spacing(1),
          height: '250px',
        }}
      >
        {/*Donut Chart*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(3), // vertical gap
          }}
        >
          <T
            sx={{
              fontSize: '1.2rem',
            }}
          >
            Detexian has discovered <strong>{calculatedValues.totalCount ? calculatedValues?.totalCount : 0} Total Apps</strong>
          </T>
          <DonutChart
            itApprovedCount = {calculatedValues.approvedCount}
            nonItApprovedCount = {calculatedValues.nonApprovedCount}
          />
        </Box>
        <TopPanelVerticalDivider />

        {/*The counts*/}
        <Box
          sx={{
            display: 'flex',
            gap: '%',
            // alignContent: 'left',
            // justifyContent: 'space-between',
            padding: theme.spacing(1),
            // paddingLeft: '2%',
            height: '100%',
            maxwidth: '500px',
          }}
        >

          {/*Non-IT-Approved Apps*/}

          {/*
            This Box helps center the Non-IT-Approved counts in a wide window
          */}
          <Box sx={{width: '10%'}}>&nbsp;</Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            }}
          >
            <SectionHeading
              title="Non IT-Approved Apps"
              moveToLeftByPercent={13}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '30%', // 'clamp(5%, 21%, 50%)',
                paddingRight: 'clamp(0%, 13%, 34%)',
                // flex: '10 1 0',
                // flexBasis: 1,
              }}
            >
              <CountSummaryColumn
                title="Access"
                data={calculatedValues.access}
                countColor={riskyRed}
              />
              <CountSummaryColumn
                title="User Counts"
                data={calculatedValues.nonApprovedCounts}
                countColor={riskyRed}
              />
            </Box>
          </Box>

          {/*IT-Approved Apps*/}
          <TopPanelVerticalDivider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '33%',
              gap: theme.spacing(4),
              paddingLeft: '7%', // 'clamp(5px, 40px, 100px)',
              paddingRight: '5%', // 'clamp(5px, 40px, 100px)',
            }}
          >
            <SectionHeading title="IT-Approved Apps" />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0, // theme.spacing(5),
              }}
            >
              <CountSummaryColumn
                title="User Counts"
                data={calculatedValues.approvedCounts}
                countColor={andyGreen}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <DtxSpacer space={3}/>

      <Box
        sx={{
          marginTop: '-15px',
          textAlign: 'center',
          marginBottom: '-8px',
        }}
      >
        {showViewDetails && <>
        <Divider />
        <DtxSpacer space={2.0}/>
        <DefaultButton
          id={id}
          sx={{
            marginBottom: '0px',
          }}
          variant="contained"
          onClick={() => handleClick()}
        >
          {isShowingDiscoveryDetails ? closeButtonName : openButtonName}
        </DefaultButton>
        </>}
      </Box>
    </Box>
  </DefaultContainer>;
};