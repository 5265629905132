import React, {useCallback, useState} from 'react';
import {
  CustomFooter,
  DefaultGrid,
  GridHeadingWithExport,
  renderYesNoFromBoolean,
} from "../../../../components/common/Tables/DefaultGrid";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {DefaultButton} from "../../../../components/common/DefaultButton";
import {renderClientEllipsis} from "./ClientsActionMenu";
import {useQueryClient} from "@tanstack/react-query";
import {useErrorDialog} from "../../../../components/common/Dialog/ErrorDialog";
import {ICompany} from "../../../../types/Company";
import {useMutationWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {createClient, deleteClient, updateClient} from "../../../../services/Clients/ClientsQueries";
import {EditClientDialog} from "./EditClientDialog";
import {planTypes} from "../../../../utils/constants";
import {useDeleteClientConfirmationDialog} from "./useDeleteClientConfirmationDialog";


export const ClientsComponent = ({ userRole, clients }: { userRole: string, clients: ICompany[] }) => {
  const minRowsPerPageOption = 100
  const rowsPerPageOptions = [minRowsPerPageOption]

  const queryClient = useQueryClient();

  const [isUpdateClientDialogOpen, setIsUpdateClientDialogOpen] = useState(false);
  const [isClientUpdatePending, setIsClientUpdatePending] = useState(false);

  const [currentClient, setCurrentClient] = useState();

  // Construct the error dialog.
  const {
    dialog: errorDialog,
    setIsOpen: setIsErrorDialogOpen,
    setErrorMessage,
  } = useErrorDialog({
    onDismiss: ()=>{},
  })

  const {
    mutate: doUpdateClient,
  } = useMutationWithAccessToken({
    mutation: updateClient,
    tanstackOptions: {
      onSuccess: () => {
        // Invalidating the cache will cause the clients to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.clients]);
        setIsUpdateClientDialogOpen(false);
        setIsClientUpdatePending(false);
        setIsErrorDialogOpen(false);
        setErrorMessage('');
      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        setErrorMessage("Could not update client");
        setIsUpdateClientDialogOpen(false);
        setIsClientUpdatePending(false);
      }
    }
  });

  const {
    mutate: doCreateClient,
  } = useMutationWithAccessToken({
    mutation: createClient,
    tanstackOptions: {
      onSuccess: (response: any) => {
        // Invalidating the cache will cause the clients to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.clients]);
        setIsUpdateClientDialogOpen(false);
        setIsClientUpdatePending(false);
        setCurrentClient(undefined);
      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        if (error.response && error.response.errors && error.response.errors.length > 0) {
          setErrorMessage(error.response.errors[0].message);
        }
        else {
          setErrorMessage("Could not add client");
        }

        setIsUpdateClientDialogOpen(false);
        setIsClientUpdatePending(false);
      }
    }
  });


  const {
    mutate: doDeleteClient,
  } = useMutationWithAccessToken({
    mutation: deleteClient,
    tanstackOptions: {
      onSuccess: (response: any) => {
        // Invalidating the cache will cause the clients to get re-queried and updated accordingly
        queryClient.invalidateQueries([cacheKeys.clients]);
        setIsDeleteConfirmationDialogOpen(false);
        setIsClientUpdatePending(false);
        setCurrentClient(undefined);
      },
      onError: (error: any) => {
        setIsErrorDialogOpen(true);
        if (error.response && error.response.errors && error.response.errors.length > 0) {
          setErrorMessage(error.response.errors[0].message);
        }
        else {
          setErrorMessage("Could not delete client");
        }
        setIsDeleteConfirmationDialogOpen(false);
        setIsClientUpdatePending(false);
      }
    }
  });

  const onNewClient = () => {
    setCurrentClient(undefined);
    setIsUpdateClientDialogOpen(true)
  }



  const onConfirmDeleteClient = () => {
    setIsClientUpdatePending(true);
    // @ts-ignore
    doDeleteClient({client: currentClient, role: userRole});
  }

  const {
    dialog: deleteConfirmationDialog,
    setIsOpen: setIsDeleteConfirmationDialogOpen,
  } = useDeleteClientConfirmationDialog({
    client: currentClient,
    onCancel: ()=>{},
    onConfirm: onConfirmDeleteClient,
  })

  const onUpdateClientMenuClick = useCallback((client: any) => {
    setCurrentClient(client);
    setIsUpdateClientDialogOpen(true);
  },[setIsUpdateClientDialogOpen]);

  const onDeleteClientMenuClick = useCallback( (client: any) => {
    setCurrentClient(client);
    setIsDeleteConfirmationDialogOpen(true);
  },[setIsDeleteConfirmationDialogOpen]);

  const onCancelUpdateClient = () => {
    setIsClientUpdatePending(false);
    setCurrentClient(undefined);
    setIsUpdateClientDialogOpen(false);
    setCurrentClient(undefined);
  }

  const onConfirmUpdateClient = (client: any) => {
    setIsClientUpdatePending(true);
    if (currentClient) {
      // @ts-ignore
      doUpdateClient({client: client, role: userRole});
    } else {
      // @ts-ignore
      doCreateClient({client: client, role: userRole});
    }
  }

  const columns = useCallback(
    (): GridColDef [] => {
      return [
        {
          headerName: 'Name',
          field: 'name',
          minWidth: 200,
          flex: 3,
          sortable: true,

        },
        {
          headerName: 'Slug',
          field: 'slug',
          flex: 3,
          sortable: true,
        },
        {
          headerName: 'Plan type',
          field: 'planType',
          flex: 3,
          sortable: true,
          valueGetter: (params) => {
            switch (params.row.planType) {
              case planTypes.Unlimited:
                return "Unlimited";
              case planTypes.FeatureBased:
                return "Feature based";
              case planTypes.Trial:
                return "Trial";
              default:
                return params.row.planType;
            }
          }
        },
        {
          headerName: 'Disabled',
          field: 'disabled',
          flex: 1,
          sortable: true,
          valueGetter: (params) => renderYesNoFromBoolean(params),
        },
        {
          headerName: '',
          field: 'action',
          renderCell: (params: GridRenderCellParams) =>
            renderClientEllipsis({
              client: params.row,
              onUpdateClick: onUpdateClientMenuClick,
              onDeleteClick: onDeleteClientMenuClick,
            }),
          sortable: false,
          disableExport: true,
          disableReorder: true,
          filterable: false,
          disableColumnMenu: true,
          flex: 1,
        },
      ];
    },
    [onUpdateClientMenuClick, onDeleteClientMenuClick]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (<>
      <EditClientDialog
        client={currentClient}
        isOpen={isUpdateClientDialogOpen}
        setIsOpen={setIsUpdateClientDialogOpen}
        isSpinning={isClientUpdatePending}
        onCancel= {onCancelUpdateClient}
        onConfirm={onConfirmUpdateClient}
      />
      {deleteConfirmationDialog}
      {errorDialog}
        <DefaultGrid
          rows={clients}
          getRowId={row => row.id}
          columns={columns()}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={rowsPerPageOptions}
          length={clients?.length}
          minRowsPerPageOption={minRowsPerPageOption}
          initialState={{
            sorting: {
              sortModel: [{
                field: "name",
                sort: "asc",
              }],
            },
          }}
          toolbar={() =>
            <GridHeadingWithExport
              heading="Clients"
              shouldShowExportButton={false}
              shouldShowBackToPrevious={false}
              tableDescription={<><DefaultButton
                sx={{
                  width: '7rem',
                  fontWeight: '500',
                  float: 'right',
                  marginTop: '-32px',
                }}
                autoFocus
                variant="contained"
                onClick={onNewClient}
              >
                Add client
              </DefaultButton>
            </>}
            />}
          footer={() => CustomFooter({})}
        />
    </>
  )
};
