import {ConnectedSaas} from "src/types/Saas";
import {Box, Typography as T, useTheme} from "@mui/material";
import {useCallback, useState} from "react";
import {ConsentDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/ConsentDialog";
import {SaasLogoAndName} from "src/components/SaasLogo";
import {DefaultButton} from "src/components/common/DefaultButton";
import {clickableDivStyles} from "src/theme";

const stringForSaaSNotSupported = '-';

// Returns a component representing the Saas, either:
//   Icon and Name line - if connected
//   Add Saas line - if not connected but supported by Detexian
//   Otherwise, depending on the shouldShowName arg, either
//      the name of the SaaS
//      a hyphen (indicating not connectable)
//      - both "greyed" - the former used in column 1,
//                        the latter in last column
export const AddSaasLink = ({
  saas,
  connected,
  saasDisplayName,
  shouldAlwaysShowName = false,
  shouldShowStringForSaaSNotSupported = true,
  shouldShowAsButton = false,
}: {
  saas?: ConnectedSaas,
  connected?: boolean,
  saasDisplayName?: string,
  shouldAlwaysShowName?: boolean,
  shouldShowStringForSaaSNotSupported?: boolean
  shouldShowAsButton?: boolean
}) => {
  const theme = useTheme();

  // if (saas?.name === 'Slack')
  //   console.log('AddSaaSLink', {saas});

  const [
    isAddSaaSConsentDialogOpen,
    setIsAddSaaSConsentDialogOpen
  ] = useState(false);

  const handleAddSaas = () => {
    setIsAddSaaSConsentDialogOpen(true);
  }

  // const saas = app?.saas;
  // const connected = app?.connected;
  // const name = app?.saas?.name || app?.display_name;
  const name = saas?.name || saasDisplayName;

  const consentDialog = useCallback(() => (
    saas && <ConsentDialog
      open={isAddSaaSConsentDialogOpen}
      onClose={() => {
        setIsAddSaaSConsentDialogOpen(false)
      }}
      saas={saas}
    />
  ), [saas, isAddSaaSConsentDialogOpen]);

  return <Box
    sx={{
      paddingTop: '8px',
    }}
  >
    {
      saas ?
        connected ?
          <SaasLogoAndName
            saas={saas}
            size={'m'}
            shouldUseCompactVersion={true}
          />
          :
          shouldShowAsButton ?
            <DefaultButton
              sx={{
                height: '1.8rem',
                width: '3rem', // approximate - makes it smalles possible
                fontWeight: '500',
                marginBottom: '8px',
                marginLeft: '-3px',
              }}
              onClick={handleAddSaas}
              autoFocus
              variant="contained"
            >
              Add
            </DefaultButton>
            :
            <T
              variant='body2'
              sx={{
                ...clickableDivStyles,
                color: theme.more.andyBlue,
              }}
              onClick={handleAddSaas}
            >
              +Add {name}
            </T>
        :
        shouldAlwaysShowName && name ?
          <T
            variant='body2'
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {name}
          </T>
          :
          <T
            variant='body2'
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {shouldShowStringForSaaSNotSupported ?
              stringForSaaSNotSupported : ''
            }
          </T>
    }
    {consentDialog()}
  </Box>
}
