import {FC, useState} from "react";
import {Typography as T, useTheme} from "@mui/material";

import {ConfirmDialog} from "../../../../components/common/Dialog/ConfirmDialog";

export const useDeleteClientConfirmationDialog = ({
  client,
  onConfirm,
  onCancel
}: {
  client: any,
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return {
    dialog: DeleteClientConfirmationDialog({
      client,
      onCancel,
      onConfirm,
      isOpen,
      setIsOpen
    }),
    setIsOpen,
  };
}

type propTypes = {
  client: any;
  isOpen: boolean;
  setIsOpen: any;
  onCancel: () => void;
  onConfirm: () => void;
};

const DeleteClientConfirmationDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
  client,
  ...props
}) => {
  const theme = useTheme();
  return (
  <ConfirmDialog
    isOpen={isOpen}
    isSpinning={false}
    setIsOpen={setIsOpen}
    okButtonName="Delete"
    onOkButtonClick={onConfirm}
    onCancelButtonClick={onCancel}
    heading={`Delete ${client?.name}`}
  >
    <T
      sx={{
        textAlign: 'left',
        marginLeft: '3px',
      }}
    >
      <strong
        style={{color: theme.more.riskyRed}}
      >
        Warning
      </strong>: Deleting <strong>{client?.name}</strong> will delete its data.

      <br /><br />Are you sure you want to delete this client?
    </T>
  </ConfirmDialog>

  )
};


