import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";


export const Atlassian = () => {

  return <>
      <ValidatedField
          valueKey = 'admin_api_key'
          placeholder= 'Admin API Key'
          errorLabel = 'Admin API key required'
          // errorText = 'Please enter your Admin API Key'
      />
   </>;
};
