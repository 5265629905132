import {FC} from "react";
import {
  DefaultPagedTable,
  DefaultTableContainer,
  DefaultTableProps
} from "src/components/common/DefaultTable";
import {UserRoleTableRow} from "src/pages/SingleSaasRolePage/UserRoleTable/UserRoleTableRow";
import {ISaasUser} from "src/types/SaasUser";
import {lastLoginColumnLabel} from "src/utils/constants";
import {
  columnHeadingsBuilder,
  rowOpacityForUserStatus,
} from 'src/components/common/Tables/Utilities/tableUtilities';
import {
  TablePanelLabel
} from "src/components/common/Tables/Utilities/TablePanelLabel";

type props = Omit<DefaultTableProps, "columns" | "children"> & {
  users: ISaasUser[];
  heading: string;
  saasIdentifier: string;
  saasName: string;
};

export const UserRoleTable: FC<props> = ({
  users,
  saasIdentifier,
  saasName,
  heading,
  ...props
}) => {
  // note: isSortable: true currently messes with header alignment
  const columns = columnHeadingsBuilder({
    shouldShowSaasColumn: false,
    shouldShowServiceColumn: false,
    additionalColumns: [
      {id: "mfaStatus", label: "MFA", isSortable: true},
      // {id: "accountStatus", label: "Status", isSortable: true},
      {id: "lastLogin", label: lastLoginColumnLabel, isSortable: true}
    ]
  });

  // console.log("SaasUserRoleTable user 0", users[0]);

  // for paging
  const renderedRow = (user: ISaasUser, index: any) => {
    // console.log('SaasUserRoleTable - rendering user')
    return (
      <UserRoleTableRow
        sx={{opacity: rowOpacityForUserStatus(user)}}
        saasIdentifier={saasIdentifier}
        saasUser={user}
        key={`${user.name} ${index}`}
      />
    )
  }

  return (
    <DefaultTableContainer heading={
      <TablePanelLabel
        heading={heading}
        saasIdentifier={saasIdentifier}
      />
    }>
      <DefaultPagedTable
        columns={columns} {...props}
        items={users}
        childToRender={renderedRow}
      />
    </DefaultTableContainer>
  );
};

