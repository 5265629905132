import React, {useCallback} from 'react';
import {useParams} from 'react-router';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {
  UserConfigTable,
  useSaasFromIdentifier,
} from 'src/components/common/Tables/UserConfigTable';
import {searchParamKeys} from 'src/routes';
import {getGhosts} from 'src/services/shared/Ghosts';
import {cacheKeyForGhosts} from 'src/services/shared/serviceConstants';
import {privilegeStringFromCode} from 'src/utils/constants';
import {useSearchParams} from 'react-router-dom';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../../hooks/PortalContext";

export const GhostUserPage = () => {
  const documentTitle = 'Ghost Users - Detexian';
  document.title = documentTitle;

  const {saasIdentifier} = useParams<{ saasIdentifier?: string }>();
  const [searchParams] = useSearchParams();

  const privilegeCode = searchParams.get(searchParamKeys.privilege);
  const privilegeString = privilegeStringFromCode(privilegeCode);

  const cacheKey = cacheKeyForGhosts({
      saasIdentifier,
      privilegeCode
    })

    const { clientInfo} = usePortalContext();

  const queryResult = useQueryWithAccessToken({
      queryKey: cacheKey,
      queryFunction: getGhosts,
      queryFunctionVariables:{
        saasIdentifier,
        privilegeLevel: privilegeString,
        sot: clientInfo.sourceOfTruth
      },
    }
  );

  const saas = useSaasFromIdentifier(saasIdentifier)

  const pageLoadFunction = useCallback(
    () => {
      return <UserConfigTable
        heading={`Ghost ${privilegeString}s (${queryResult.data.length})`}
        users={queryResult.data}
        saas={saas}
        shouldShowSotColumn={true}
      />
    },
    [privilegeString, queryResult.data, saas]
  );

 return useComponentForQueryResult({
    queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing External Users',
  })
};
