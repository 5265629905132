import {Box, Link, Typography as T} from "@mui/material";
import upgradeImage from "../../../assets/images/other/upgrade.png";
import {linkStyleFragment} from "../../../theme";
import React from "react";

export const FeatureUpgradeMessage = () => {
  return <Box
    sx={{
      textAlign: "center",
      marginLeft: "3px",
    }}
  >
    <img src={upgradeImage} alt="Upgrade" style={{width: "90px"}}/>
    <T sx={{fontSize: "1.8rem"}}>Contact us to upgrade</T>
    <Link
      href="mailto:upgrade@detexian.com"
      sx={{
        ...linkStyleFragment,
        fontSize: "1.1rem",
      }}
    >
      upgrade@detexian.com
    </Link>

  </Box>;
}