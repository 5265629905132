import {Theme} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import React from 'react';
import {
  featuredColumn,
  renderPrivilege,
  renderUserName
} from 'src/components/common/Tables/DefaultGrid/gridCellRenderers';
import {FormattedDate} from 'src/components/common/Tables/Utilities';
import {userRouteBase} from 'src/routes';
import {
  resolvePathIntoObject
} from 'src/utils/other';
import {valueOrStringForMissingText} from "src/utils/string";

// takes a field path on latest_saas_user e.g.
// name or email or generic_user.pub_id and returns
// the value or '-'
export const valueGetter = (
  {row, fieldPath}: {row: any, fieldPath: string}
) => {
  return `${
    valueOrStringForMissingText(
      resolvePathIntoObject(
        row,
        fieldPath,
      )
    )
  }`;
};

const renderDate = (
  dateValue: string,
) => {
  return (
    <FormattedDate
      date={dateValue}
      shouldShowTime={false}
    />
  );
};

export const buildUserConsentedColumns = (theme: Theme, isPrivilegeFeatureOn: boolean): GridColDef [] => {
  const columns: GridColDef [] = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams) => {
        // console.log({params});
        return renderUserName(
          valueGetter({row: params.row, fieldPath: 'name'}),
          valueGetter({row: params.row, fieldPath: 'genericUser.pub_id'}),
          userRouteBase,
          params.row.hasUserUrl
        )
      },
      editable: false,
      flex: 1,
    },
    {
      field: 'latest_saas_user.email',
      headerName: 'Email (in M365)',
      valueGetter: (params) => valueGetter(
        {row: params.row, fieldPath: 'email'}
      ),
      editable: false,
      flex: 1,
    },
    {
      field: 'privilegeLevel',
      headerName: 'Role (in M365)',
      ...featuredColumn({
        title: 'Role (in M365)',
        featureOn: isPrivilegeFeatureOn,
        renderCell: renderPrivilege,
        valueGetter: ({row}: { row: any }) => {
          return valueGetter({row: row, fieldPath: 'privilegeLevel'})
        }
      }),
      editable: false,
      flex: 1,
    },
    {
      field: 'createdDateTime',
      headerName: 'Date Added',
      renderCell: (params) => renderDate(params.row.createdDateTime),
      editable: false,
      flex: 1,
    },
    {
      field: 'lastLogin',
      headerName: 'Last login',
      renderCell: (params) => renderDate(params.row.lastLogin),
      editable: false,
      flex: 1,
    },

  ];
  return columns;
}
