import {Box, Checkbox, CircularProgress, FormControlLabel, Typography as T} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {DtxSpacer} from 'src/components/common/DtxSpacer';

export const useDomainFilter = (isLoading: boolean) => {
  const [
    shouldIncludeInternal,
    setShouldIncludeInternal
  ] = useState(true);

  const [
    shouldIncludeExternal,
    setShouldIncludeExternal
  ] = useState(true);

  const toggleShouldIncludeExternal = useCallback (
    () => {
      // prevent turning both off
      if (
        !shouldIncludeInternal &&
        shouldIncludeExternal
      ) {
        setShouldIncludeInternal(true);
      }

      // do the toggle
      setShouldIncludeExternal(
        !shouldIncludeExternal,
      )
    },
    [shouldIncludeExternal, shouldIncludeInternal]
  )

  const toggleShouldIncludeInternal = useCallback (
    () =>
      // prevent turning both off
      {
        if (
          !shouldIncludeExternal &&
          shouldIncludeInternal
        ) setShouldIncludeExternal(true);

        // do the toggle
        setShouldIncludeInternal(
          !shouldIncludeInternal,
        );
      },
    [shouldIncludeExternal, shouldIncludeInternal]
  )

  const LoadedDomainFilter = () => {
    // console.log('useFilterMenu', {options}, {selectionState});
    return DomainFilter({
      filterControls: {
        shouldIncludeInternal,
        shouldIncludeExternal,
        toggleShouldIncludeInternal,
        toggleShouldIncludeExternal,
      },
      isLoading
     }
    )
  }

  return {
    DomainFilter: LoadedDomainFilter,
    shouldIncludeInternal,
    shouldIncludeExternal
  }
}

const DomainFilter = (
  {filterControls, isLoading = true}:
  {filterControls: any, isLoading?: boolean}
) => {
  const {
    shouldIncludeInternal,
    toggleShouldIncludeInternal,
    shouldIncludeExternal,
    toggleShouldIncludeExternal,
  } = filterControls;

  const shouldShowIncludeCheckboxes = (
    toggleShouldIncludeExternal !== undefined
  );

  return shouldShowIncludeCheckboxes ?
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '21px',
        marginBottom: '-21px', // hack to align checkbox with paging
      }}
    >
      <T
        sx={{
          // marginBottom: '-13px',
        }}
      >
        Users:
      </T>
      <DtxSpacer orientation="vertical" />
      <FormControlLabel
        label="Internal"
        control={
          <Checkbox
            checked={shouldIncludeInternal}
            onChange={toggleShouldIncludeInternal}
            inputProps={{'aria-label': 'omit internal users'}}
          />
        }
      />
      <FormControlLabel
        label="External"
        control={
          <Checkbox
            checked={shouldIncludeExternal}
            onChange={toggleShouldIncludeExternal}
            inputProps={{'aria-label': 'omit external users'}}
          />
        }
      />
      <DtxSpacer orientation="vertical" />
      {isLoading && <CircularProgress
        size={21}
        thickness={2}
      />
      }
    </Box>
    :
    <div></div>;
};
