import React, {FC, useEffect, useState} from "react";
import {
  Box,
  Dialog,
  FormControl,
  FormControlLabel, InputLabel,
  Radio,
  RadioGroup, Typography,
  useTheme
} from "@mui/material";

import {ValidatedField} from "../../AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";
import {FormValuesContext} from "../../../../components/Forms/FormValuesContext";
import {DtxSpacer} from "../../../../components/common/DtxSpacer";
import {
  planTypes
} from "../../../../utils/constants";
import {DefaultButton} from "../../../../components/common/DefaultButton";

import {dataGridRowBorder} from "../../../../theme";
import { DialogHeading } from "../../AddAndUpdateSaas/dialogs/misc/styles";
import {LoadingSpinner} from "../../../../components/common/LoadingSpinner";
import {ICompanyAccess} from "../../../../types/Company";

type propTypes = {
  client?: ICompanyAccess;
  isOpen: boolean;
  isSpinning: boolean;
  setIsOpen: any;
  onCancel: () => void;
  onConfirm: (userInfo: {
    id?: string
    name: string
    planType: string
    disabled: boolean
  }) => void;
};

export const EditClientDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
  client,
  isSpinning,
  ...props
}) => {
  const theme = useTheme();

  // we pass fieldValuesState and fieldValidationState to children
  const fieldValuesState = useState<{[key: string]: any}>({});
  const [fieldValues, setFieldValues] = fieldValuesState;

  const fieldValidationsState = useState<{[key: string]: any}>({});
  const [fieldValidations, setFieldValidations] = fieldValidationsState;

  const [planType, setPlanType] = useState(planTypes.FeatureBased)
  const [isDisabled, setIsDisabled] = useState(false);

  const [
    didFindValidationErrorOnSubmit,
    setDidFindValidationErrorOnSubmit
  ] = useState(false);

  const stateForDialog: any = {
    fieldValuesState,
    fieldValidationsState,
    didFindValidationErrorOnSubmit,
  };

  useEffect(() => {
    if (client) {
      // As the name textbox is wrapped in ValidatedComponents we need to set their
      // default values like this (we also need to set their field validation as true
      // (Assume that they are valid if they have been saved in the first place)
      setFieldValues({name: client.name})
      setFieldValidations({name: true})
      setPlanType(client.planType);
      setIsDisabled(client.disabled);
    } else {
      // There is no client (making a new one)
      // So make sure the fields are cleared out in case they were set previously
      setFieldValues({})
      setFieldValidations({})
      setPlanType(planTypes.FeatureBased)
      setIsDisabled(false)
    }
  },[client, setFieldValues, setFieldValidations]);

  const handlePlanTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlanType(event.target.value)
  }

  const hasValidationError = () => {
    let result;
    const fieldValidationArray = Object.values(fieldValidations);
    if (!fieldValidationArray.length) {
      console.log('hasValidationError - empty fieldValidations');
      return true;
    }
    result = fieldValidationArray.find(
      (valid) => {
        return valid === false;
      })
    result = result !== undefined; // convert to boolean
    return result;
  }

  const handleOkButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (hasValidationError()) {
      setDidFindValidationErrorOnSubmit(true);
    }
    else {
      // There's no validation error so call the onConfirm function
      // and pass the user to it
      onConfirm({
        id: client ? client.id : '',
        name: fieldValues.name,
        planType: planType,
        disabled: isDisabled,
      });
    }
  }

  const handleDisabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDisabled(event.target.value === "true")
  }

  return (
    <Dialog
      {...props}
      open={isOpen}
    >
        <Box
          sx={{
            border: dataGridRowBorder,
            borderRadius: theme.more.borderRadius,
            margin: 'auto',
            padding: theme.spacing(2),
            minWidth: '600px',
          }}
        >
          <DialogHeading>
            {client ? 'Update client': 'Add client'}
          </DialogHeading>
          {isSpinning ?
            <LoadingSpinner></LoadingSpinner>:
          <form onSubmit={handleOkButtonClick}>
          <FormValuesContext.Provider
            value={{...stateForDialog}}
          >
            <DtxSpacer space={2} />
            { !client
              // It's a new client so show the text box
              ? <>
            <ValidatedField
              placeholder="Name"
              valueKey="name"
              errorLabel="valid name required"
            />
             </>
              :
              // It's an existing client, so show name as read-only
            <div>
              <InputLabel id={"update-user-name"}>Name</InputLabel>
              <Typography sx={{fontSize: theme.typography.h5.fontSize}}>{client?.name}</Typography>
              <DtxSpacer space={1} />
            </div>
            }
            <DtxSpacer space={2} />
            <InputLabel id={"update-client-plan-type"}>Plan type</InputLabel>
            <FormControl>
              <RadioGroup
                name={'plan_type'}
                row
                value={planType}
                onChange={handlePlanTypeChange}
              >
                <FormControlLabel
                  value={planTypes.FeatureBased}
                  control={<Radio />}
                  label="Feature based" />
                <FormControlLabel
                  value={planTypes.Unlimited}
                  control={<Radio />}
                  label="Unlimited" />
              </RadioGroup>
            </FormControl>
            { // If it's an existing client, show the disabled radio buttons.  New clients will default to false.
              client && <>
            <DtxSpacer space={2} />
            <InputLabel id={"update-disabled"}>Disabled</InputLabel>
            <FormControl>
              <RadioGroup
                name={'disabled'}
                row
                value={isDisabled}
                onChange={handleDisabledChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No" />
              </RadioGroup>
            </FormControl>
            </>}
            <DtxSpacer space={2} />
          </FormValuesContext.Provider>
      <DtxSpacer space={2} />
      <Box sx={{textAlign: 'right'}}>
        <DefaultButton
          sx={{marginRight: theme.spacing()}}
          variant="contained"
          onClick={onCancel}
        >
          Cancel
        </DefaultButton>
        <DefaultButton
          sx={{
            marginRight: 0,
          }}
          variant="contained"
          type="submit"
        >
          {
            client ?
              "Update" :
              "Add"
          }
        </DefaultButton>
      </Box>
    </form>
          }
</Box>
</Dialog>)
};


