import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";
import {SecretField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/SecretField";
import {DtxSpacer} from "src/components/common/DtxSpacer";

export const NameAndPassword = () => {
  return <>
    <ValidatedField
      placeholder="Username"
      valueKey="username"
      errorLabel="valid user name required"
    />
    <DtxSpacer space={2} />
    <SecretField
      valueKey="password"
    />
  </>;
};
