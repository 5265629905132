import Divider from '@mui/material/Divider';
import {
  FC,
  useState
} from 'react';
import {DefaultTable, DefaultTableProps} from './index';
import {DtxPagination, itemsToDisplay} from '../Tables/Utilities/DtxPagination';


// Gives paginations to tables with minimal effort
// Usage:
//     const renderedRow = (user: ISaasUser, index: number) => {
//       // console.log('SaasUserRoleTable - rendering user')
//       return (
//         <PermissionTableRow
//           shouldShowSaasColumn={shouldShowSaasColumn}
//           user={user}
//           key={`${user.name} ${index}`}
//         />
//       )
//     }
//       <DefaultPagedTable
//         columns={columns}
//         items={users}
//         childToRender={renderedRow}
//       />

// Later Addition - if SinglePanelUrl exists show a View All link

interface props extends DefaultTableProps {
  id?: string;
  items: any[];
  childToRender: Function;
  shouldUseCompactVersion?: boolean;
  singlePanelUrl?: string;
  sx?: any;
}

export const DefaultPagedTable: FC<props> = ({
  id,
  columns,
  order,
  orderBy,
  isLoading,
  onSortColumn,
  classes,
  columnAlignment = 'left',
  items,
  shouldUseCompactVersion,
  singlePanelUrl,
  childToRender,
  sx,
}) => {

  const pageState = useState(0);
  const [page] = pageState;
  const rowsPerPage = singlePanelUrl ? 5 : 50;
;

  return (
    <>
    <DefaultTable
      id={id}
      columns={columns}
      order={order}
      orderBy={orderBy}
      isLoading={isLoading}
      onSortColumn={onSortColumn}
      classes={classes}
      shouldUseCompactVersion={shouldUseCompactVersion}
      columnAlignment={columnAlignment}
      sx={sx}
    >
      {
        itemsToDisplay(
          items,
          rowsPerPage,
          page).map((item: any, index: number) => {
        return childToRender(item, index)
        })
      }
    </DefaultTable>
    {
      // conditionally add pagination footer
      items?.length > rowsPerPage && (
      <>
        <Divider />
        <DtxPagination
          items={items}
          rowsPerPage={rowsPerPage}
          pageState={pageState}
          shouldUseCompactVersion={shouldUseCompactVersion}
          singlePanelUrl={singlePanelUrl}
        />
      </>)
    }
    </>
  );
};
