import {graphQLClient} from 'src/services/shared/GraphQLService';
import {
  transformAllGenericUsersElement,
  transformUserViewQueryResponse
} from './UserServiceTransform';
import {
  allGenericUsersQuery,
  genericUsersQueryOmitNullNames,
  userViewQuery
} from './UserServiceQueries';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {nullCollapsableClientPubId} from "../shared/nullCollapsableUtilities";
import {QueryContext} from "../../hooks/TanstackHooks";

export const getUserViewDataFromGraphQL = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
) => {
  const {
    userId
  } = parameters;

  if (!userId) throw new Error ('missing user pub id');

  const theQuery = async (client: any) => {
    return await client.request(
      userViewQuery,
      {
        pubId: userId,
        ...nullCollapsableClientPubId(queryContext.client.id)
      }
    );
  };

  let result;

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    // console.log('getUserViewDataFromGraphQL response', {response});

    const bigQueryResult = transformUserViewQueryResponse(response, userId);
    // console.log({bigQueryResult});

    result = {
      ...bigQueryResult
    };
  }
  catch(error) {
    console.error({error});
    throw error;
  }

  // console.log({result});
  return result;
}

type getAllGenericUsersOptions = {
  shouldOmitNullNames: boolean | undefined;
}

// currently omits users with null names
export const getAllGenericUsersFromGraphQL = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: getAllGenericUsersOptions}
) => {
  const {
    shouldOmitNullNames = false,
  } = parameters;
  const client = await graphQLClient({accessToken});
  const query = shouldOmitNullNames ?
    genericUsersQueryOmitNullNames :
    allGenericUsersQuery
  ;

  return client
    ?.request(query, nullCollapsableClientPubId(queryContext.client.id))
    .then((queryResult) => {
      // console.log(
      //   'UserService.getAllGenericUsersFromGraphQL\n',
      //   {queryResult}
      // );
      const result = queryResult.generic_user.map(transformAllGenericUsersElement)
      // console.log(
      //   'UserService.getAllGenericUsersFromGraphQL\n',
      //   {result}
      // );
      return result;
    }
  );
}
