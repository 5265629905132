import React, {FC} from 'react';
import {DefaultTableRow} from 'src/components/common/DefaultTable';
import {EmailTableCell, UserNameTableCell} from 'src/components/common/Tables/Utilities/tableCells';
import {ISaasUser} from 'src/types/SaasUser';
import {LastLoginTableCell} from 'src/components/common/Tables/Utilities/tableCells/LastLoginTableCell';

export type SaasUsersTableRowProps = {
  sx?: any;
  saasUser: ISaasUser;
  saasIdentifier?: string;
  saasPubId?: string;
};

export const AuthExceptionTableRow: FC<SaasUsersTableRowProps> = ({
  saasUser,
  saasIdentifier,
  ...props
}) => {
  const {
    pubId,
    hasUserUrl,
    name,
    email,
    lastLogin,
  } = saasUser;

  return (
    <DefaultTableRow {...props}>
      <UserNameTableCell
        hasUserUrl={hasUserUrl}
        name={name}
        pubId={pubId}
      />

      <EmailTableCell email={email} />
      <LastLoginTableCell lastLogin={lastLogin} />
    </DefaultTableRow>
  );
};
