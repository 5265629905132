import {useEffect, useMemo, useState} from 'react';
// import {UseQueryResult} from '@tanstack/react-query';
import {ErrorScreen} from 'src/components/common/ErrorScreen';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {isEmptyString} from "src/utils/string";


// Shared Query status handling for loading indication,
// error logging, displaying error to user and
// successful component load.

// Serious limitation - only supports one query

// types auto generated - could be simplified
type parameterType = {
  queryResult: any; // UseQueryResult<any, unknown>;

  userErrorMessage: string;
  errorMessageDoesIndicateError?: boolean;
  pageLoadFunction: {(): JSX.Element; (): JSX.Element;};
  isFullScreen?: boolean,
}

export const useComponentForQueryResult = (parameters: parameterType) => {
  const {
    queryResult, // return value from useQueryWithIdToken
    pageLoadFunction, // a function to build the page on success

    // Error message for the user, composed in advance
    // Also used by pages with multiple chained queries to indicate
    // an error in one of the queries.  See SingleSaasPage.
    // This only works if the page sets errorMessageDoesIndicateError
    userErrorMessage,
    errorMessageDoesIndicateError = false,

    isFullScreen = false,
  } = parameters;

  const loadingScreen = useMemo(() => (
    <LoadingScreen isFullScreen={isFullScreen} />
  ), [isFullScreen])

  const [
    pageToDisplay,
    setPageToDisplay,
  ] = useState(loadingScreen);

  useEffect(
    () => {
      // console.log('useEffect running', {queryResult});

      // apparently the React team calls this an anti-pattern but
      // it prevents "Can't perform a React state update" warning
      let isMounted = true;

      // console.log('query status', queryResult.status)

      const calculatePageToDisplay = () => {
        let result = loadingScreen;
        if (
          queryResult.isError || (
            errorMessageDoesIndicateError &&
            !isEmptyString(userErrorMessage)
          )
        ) {
          console.error(
            'useEffect - error',
            {userErrorMessage},
            (queryResult.data, queryResult.error),
          );
          result = <ErrorScreen
            message={userErrorMessage}
            isFullScreen={isFullScreen}
          />;
        }
        else if (queryResult.isLoading) {
          // console.log('useEffect - loading');
          result = loadingScreen;
        }
        else if (queryResult.isSuccess) { // (queryResult.data) {
            // console.log('useEffect - success - returning page');
            result = pageLoadFunction();
        }

        // console.log('ran logic, result is', result.type.name);
        return result;
      };
      if (isMounted) {
        setPageToDisplay(calculatePageToDisplay());
      }

      // I believe React runs the below function on unmount.
      // The idea is to cleanup listeners etc.  We misuse it
      // here to avoid "Can't perform a React state update"
      // warning.

      // Update: it doesn't seem to avoid the warning completely.
      return () => {isMounted = false};

      // console.log('useEffect: pageToDisplay', pageToDisplay.type.name);
    }, [pageLoadFunction, queryResult.isLoading, queryResult.data, queryResult.isError, queryResult.error, userErrorMessage, isFullScreen, loadingScreen, queryResult.isSuccess, errorMessageDoesIndicateError]
  );
  return pageToDisplay;
};

