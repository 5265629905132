import React from "react";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const getStripeKey = () : string => {
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  if (!stripeKey) {
    console.error('Stripe key not found');
    return '';
  }
  return stripeKey;
}

const stripePromise = loadStripe(getStripeKey());

export const StripeCheckout = ({clientSecret}
                  :{
  clientSecret: string,
}) => {
  const options = {clientSecret};

  return (<>
      <div id="checkout">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
}