import {externalUserQuery, userAccessSummaryQuery} from './UserAccessExceptionQueries';
import {ISaasExceptions} from 'src/types/SaasExceptions';
import {IGraphQLConnectedSaasResponse, ISaasGraphQLResponse} from 'src/types/Saas';
import {transformUser, transformSaasResponse,} from 'src/services/shared/sharedTransforms';
import {privilegeStrings} from 'src/utils/constants';
import {connectedSaasFromResponse} from "src/services/AuthExceptions/AuthExceptions";
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {QueryContext} from "../../hooks/TanstackHooks";
import {nullCollapsableClientPubId} from "../shared/nullCollapsableUtilities";

// a key into the
const userCategoryKey = {
  external: 'external_user',
  inactive: 'inactive_user',
  ghost: 'active_inactive_user',
}

// The strings used by the back end for user category
export const userCategoryName = {
  external: 'External Users',
  ghost: 'Ghost Users',
  inactive: 'Inactive Users',
}


// A bit dodgy:
// We build the rating key by adding this string e.g.
//
//   external_users -> external_users_rating.
//
// If query response doesn't change we're ok.
const suffixForRatingKey = '_rating';

const transformExceptionType = (
  clientSaas: ISaasGraphQLResponse, // IClientSaasGroupedGraphQLResponse,
  userCategoryName: string, // pick userCategoryName from e.g. "Inactive User"
  userCategoryCountKey: string // pick from userCategoryKey e.g. inactive_user
): any => {

  // this function uses the keys and values below
  // it loop through keys and uses the iteration
  // value to search the query response for the
  // iteration's privilege level
  const levelKeysToLevelString: any = {
    highlyPrivilegedUser: privilegeStrings.highlyPrivileged,
    privilegedUser:       privilegeStrings.privileged,
    enabledUser:          privilegeStrings.enabled,
  }
  const levelKeys = Object.keys(levelKeysToLevelString);
  const privilegeLevels = clientSaas.privilege_levels; // clientSaas.saas.privilege_levels;

  // we build this object in the loop below
  const transformedExceptions: any = {};

  // build a sub-object for each prvilege level
  // and put them all into object
  levelKeys.forEach((levelKey) => {
    // find the privilege level for this iteration
    const privilegeLevel = privilegeLevels && privilegeLevels.find(
      ({privilege_level}) =>
        privilege_level === levelKeysToLevelString[levelKey]
    );

    // get the exception count
    const count = (
      privilegeLevel && privilegeLevel[userCategoryCountKey]
    ) || 0;

    // build the exception-rating key and get the rating
    const userCategoryRatingKey = userCategoryCountKey+suffixForRatingKey;
    const rating = (
      privilegeLevel && privilegeLevel[userCategoryRatingKey]
    ) || 0;

    // build the count, rating object
    transformedExceptions[levelKey] = {
      count,
      rating,
    }
  });

  const result = {
    name: userCategoryName,
    ...transformedExceptions,
  }

  return result;
}


const transformSaasAccessException = (
  clientSaas: IGraphQLConnectedSaasResponse // IClientSaasGroupedGraphQLResponse
): ISaasExceptions => {
  const saas = clientSaas.saas;
  const result = {
  saas: transformSaasResponse(saas),
  connectedSaas: connectedSaasFromResponse(clientSaas),
  exceptions: [
    {
      ...transformExceptionType(
        clientSaas,
        userCategoryName.external,
        userCategoryKey.external,
      )
    },
    {
      ...transformExceptionType(
        clientSaas,
        userCategoryName.ghost,
        userCategoryKey.ghost
      )
    },
    {
      ...transformExceptionType(
        clientSaas,
        userCategoryName.inactive,
        userCategoryKey.inactive
      )
    },
  ],
}
  return result;
};

export const getUserAccessExceptions = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
) => {
  const {
    sot
  } = parameters;
  const theQuery = async (client: any) => {
    const sotPubId = sot?.pubId;
    if (!sotPubId) throw new Error('getUserAccessExceptions - missing source of truth');

    return await client.request(
      userAccessSummaryQuery,
      {
        sot_pub_id: sotPubId,
        ...nullCollapsableClientPubId(queryContext.client.id)
      },
    );
  };

  const response = await doAuthenticatedQuery(theQuery, accessToken);

  // note - the saas is grouped inside the client_saas
  // transformSaasAccessException handles/expects that
  const result = response.client_saas.map(transformSaasAccessException);
  // console.log('getUserAccessExceptions transformed', {result});'

  return result;
};

export const getExternalUsers = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
) => {
  const {
    saasIdentifier,
    privilegeLevel
  } = parameters
  // define the query, run it in next step
  const theQuery = async (client: any) => {
    return await client.request(
      externalUserQuery,
      {
        saasIdentifier,
        privilegeLevel,
        ...nullCollapsableClientPubId(queryContext.client.id)
      }
    );
  };
  const response = await doAuthenticatedQuery(theQuery, accessToken);
  // console.log('getExternalUsers', {response});

  const result = response.saas?.[0].latest_saas_users?.map(transformUser);
  if (!result) throw new Error('unrecognized response');

  // console.log('getExternalUsers', {result});
  return result;
};
