import {DomainDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/DomainDialog/DomainDialog";

export const GitHub = () => {
  return (
    <DomainDialog
      placeHolder="Your Github Organisation"
      errorLabel="GitHub Organisation required"
      urlPrefix="github.com/"
      urlSuffix="/"
    />
  )
};
