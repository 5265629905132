import {FC, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {RouteNotFound} from 'src/RoutesManager/RouteNotFound';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {RoutesManagerMain} from 'src/RoutesManager/RoutesManagerMain';
import {
  dashboardRoute,
  dashboardRouteFragment, subscriptionRouteFragment,
} from 'src/routes';
import {AzurePage} from 'src/pages/Azure/AzurePage';
import {RoutesManagerSubscription} from "./RoutesManagerSubscription";

const homeRoute = `${dashboardRoute}/`;

export const azureRoute = `/azure/`

// wrap usages in PrivateRoute, otherwise infinite redirect on login
const NavigateToHome = () => (
  <Navigate
    to={homeRoute}
    replace
  />
)

export const RoutesManager: FC = () => {
  return (
    <Suspense fallback={<LoadingScreen isFullScreen={true} />}>
      <Routes>
        <Route
          path={`/`}
          element={
            <PrivateRoute
              component={NavigateToHome}
            />
           }
        />
        <Route
          path="/index.html/"
          element={
            <PrivateRoute
              component={NavigateToHome}
            />
           }
        />
        <Route
          path={azureRoute}
          element={
            <AzurePage/>
          }
        />
        <Route
          path={`${subscriptionRouteFragment}/*`}
          element={
            <RoutesManagerSubscription />
          }
        />
        <Route
          path={`/*`}
          element={
            <PrivateRoute
              component={RoutesManagerMain}
            />
          }
        />
        <Route
          path={`${dashboardRouteFragment}/*`}
          element={
            <PrivateRoute
              component={RoutesManagerMain}
            />
          }
        />
        <Route
          path="*"
          element={<>
            <h3>RoutesManager</h3>
            <RouteNotFound path={window.location.pathname} />
          </>}
        />
      </Routes>
    </Suspense>
  );
};
