export const accessPath = 'access'; // for write_access, file_access etc
export const saasNameFieldName = 'display_name';

export interface ApplicationAccess {
  email_access: boolean;
  file_access: boolean;
  calendar_access: boolean;
  write_access: boolean;
}

export interface ApplicationSummary {
  pub_id: string;
  [saasNameFieldName]: string;
  // display_name: string;
  is_enterprise: boolean;
  access: ApplicationAccess; // {
  //   email_access: boolean;
  //   file_access: boolean;
  //   calendar_access: boolean;
  //   write_access: boolean;
  // }
  user_count: number;
  action?: string; // added elsewhere see Applications page
}

export type ApplicationAggregate = {
  is_enterprise: boolean;
  email_access: number;
  file_access: number;
  calendar_access: number;
  write_access: number;
  over25_count: number;
  tenTo25_count: number;
  fiveTo10_count: number;
  lessThan5_count: number;
}

export type DiscoveryResponseType = {
  name: string;
  expiry: string;
  subscriber_name: string;
  application_summaries: ApplicationSummary[];
  application_aggregates: ApplicationAggregate[];
}
