import {DriftGrid} from 'src/components/common/Tables/DriftGrid/DriftGrid';
import {displayNameForDriftRecords} from 'src/pages/ActivityLogPage/ActivityLogPanel';
import {getGridHeadingCount} from "../../components/common/Tables/Utilities";

type propTypes = {
  drift: any; // undefined|TQueryFnData;
  exportFileName: string;
}

export const ActivityLogComponent = ({
  drift,
  exportFileName,
}: propTypes) => {

  // console.log({drift})

  return <>
    <DriftGrid
      rowIdKey={'driftId'}
      headerTitle={getGridHeadingCount(drift, `${displayNameForDriftRecords} Log`)}
      displayNameForTypeOfRecords={`${displayNameForDriftRecords}`}
      shouldShowSaasColumn={true}
      shouldShowCombinedColumn={false}
      shouldShowSubjectColumn={true}
      shouldShowUserNameColumn={true}
      exportFileName={exportFileName}
      rows={drift}
    />
  </>;
 };

