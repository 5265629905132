import {FC} from 'react';
import {Grid} from '@mui/material';
import {SaasCard} from 'src/components/SaasCard';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

const SaasesPage: FC = () => {
  // const [saases, { isLoading }] = useSaases();
  const {
    data: saases,
    isLoading
  } = useQueryWithAccessToken({
      queryKey: [cacheKeys.connectedSaasList],
      queryFunction: getConnectedSaases,
      queryFunctionVariables:{},
    }
  );

  const documentTitle = 'SaaS - Detexian';
  document.title = documentTitle;
  return (
    <>
      {/*<PageTitle title={documentTitle} />*/}
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid spacing={3} container>
          {saases?.map((saas:any, index: any) => (
            <Grid xs={3} item key={saas.identifier+index}>
              <SaasCard saas={saas} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}; // );

export default SaasesPage;
