import memoizee from 'memoizee';
import {ISaasUser} from 'src/types/SaasUser';
import {ColumnData} from 'src/types/tables';
import {userStatusStrings, tableCellHeadings} from 'src/utils/constants';

// Creates a table headings array, mainly for shared
// tables which may or may not show the Saas column
// and which have the default columns shown below.
//
// The second argument is an array of additional columns

type optionType = {
  shouldShowSaasColumn: boolean,
  shouldShowServiceColumn?: boolean,
  shouldHideRoleColumn?: boolean,
  additionalColumns: any[],
}
export const columnHeadingsBuilder = memoizee((options: optionType
) : ColumnData[] => {
  let result = [];
  if (options.shouldShowSaasColumn) {
    result.push(
      {
        id: 'saasProvider',
        label: tableCellHeadings.saas,
        width: 200 // less than 200 and the saas icon distorts or vanishes
      }
    )
  }
  if (options.shouldShowServiceColumn) {
    result.push(
      { id: 'service', label: 'Service' },
    )
  }
  result.push(
    {id: 'name', label: 'Name'},
    {id: 'primaryEmail', label: 'Email', width: '20'},
  )

  if(!options.shouldHideRoleColumn) {
     result.push(
      {id: 'role', label: 'Role'},
    )
  }
  result.push(...options.additionalColumns)
  return result;
});

export const rowOpacityForUserStatus = (user: ISaasUser) => {
  return (
    user.status !== userStatusStrings.disabled &&
    user.status !== userStatusStrings.deleted
  ) ? '100%' : '45%';
};

// Builds the table headings in the format Entity Name (Count)
// If the data isn't present yet (ie. It is still loading) then it doesn't show the count
export const getGridHeadingCount = (items: any[], name: string) => {
  return items ? `${name} (${items?.length})` : name;
}
