import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

export const Token = ({
  label = 'Token',
  valueKey = 'token',
}: {
  label?: string,
  valueKey?: string,
}) => {
  return <ValidatedField
    valueKey={valueKey}
    placeholder={label}
    errorLabel={`${label} required`}
    // errorText=`Please enter a valid ${label}`
  />
};
