import {useEffect, useState} from 'react';
import {getAllSaas} from 'src/services/SaasService/Saases';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {useQueryWithAccessTokenWithoutContext} from "src/hooks/TanstackHooks";
import {capitalizeFirstLetter} from "src/utils/string";

// Used where the component only has the saas identifier -
// usually obtained from the url.

// Not used as widely as I had thought it would be

export const useSaasFromIdentifier = (identifier: string|undefined) => {

  const saasFromIdentifier = (saasIdentifier?: string|undefined) => {
    let saas;

    if (saasIdentifier) {
      saas = {
        name: capitalizeFirstLetter(saasIdentifier),
        identifier: saasIdentifier,
      };
    }
    return saas;
  };

  const {
    data: allSaasList,
  } = useAllSaases();
  // console.log('useSaasFromIdentifier');

  const [result, setResult] = useState(saasFromIdentifier(identifier));

  useEffect(() => {
      // console.log('useSaasFromIdentifier.useEffect');
      const foundSaas = allSaasList && allSaasList.find((saas: any) =>
        identifier === saas.identifier,
      );

      if (foundSaas) {
        // console.log({foundSaas});
        setResult(foundSaas);
      }
    },
    [allSaasList, identifier],
  );

  return result;
};

export const useAllSaases = () => {
  return useQueryWithAccessTokenWithoutContext({
    queryKey: [cacheKeys.allSaasList],
    queryFunction: getAllSaas,
    queryFunctionVariables: {}
  }
  );
}