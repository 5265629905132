import {Typography, useTheme} from '@mui/material';
import React from 'react';

const defaultPadding = 85;

export const DashboardSubHeading = ({
    text,
    paddingTop = defaultPadding,
}: {
    text: string
    paddingTop?: number,
}) => {
  const theme = useTheme();
  const {spacing, palette} = theme;
  return (
    <Typography
        sx={{
        color: palette.text.secondary,
            textAlign: 'center',
            fontSize: '1.7rem',
            padding: spacing(3)
    }}>{text}</Typography>
  );
};
