import React, {useState} from 'react';
import Joyride from "react-joyride";
import {addSaasMenuButtonId} from "../../components/Header/Navigation";
import {useLocalStorage} from "../../hooks/useLocalStorage";

export const OnboardingAssistance = ({
  summaryPanelId
}: {
    summaryPanelId: string
    paddingTop?: number,
}) => {
    const [
        experimentalOnboardingEnabled,
    ] = useLocalStorage('shouldShowExperimentalOnboarding', false);

    const shouldShowExperimentalOnboarding = experimentalOnboardingEnabled && (process.env.REACT_APP_FEATURE_FLAG_ONBOARDING_HELP === '1');

    const [joyrideSteps] = useState(
        [
            {
                target: `#${addSaasMenuButtonId}`,
                placement: 'bottom',
                content: 'Add your SaaSes to start monitoring',
            },
            {
                target: `#${summaryPanelId}`,
                placement: 'bottom',
                content: 'Click to see all apps in use',
            },
        ]
    );

    return (shouldShowExperimentalOnboarding ?
        <Joyride
            //@ts-ignore
            steps={joyrideSteps}
            styles={{
                options: {zIndex: 1000}
            }}
            disableScrolling={true}
            /> : <></>);
};