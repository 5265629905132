import {Box, useTheme} from '@mui/material';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {TablePanelLabel} from 'src/components/common/Tables/Utilities';
import {
  startDateRadioKey,
} from 'src/pages/ActivityLogPage/ActivityLogPanel';
import {StartDateRadioGroup} from './StartDateRadioGroup';

type propsType = {
  displayNameForDriftRecords: string,
  querySettings: {},
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  SaasFilterMenu: any,
  ActivityFilterMenu: any,
}

export const FilterPanel = ({
  displayNameForDriftRecords,
  querySettings,
  handleFilterChange,
  SaasFilterMenu,
  ActivityFilterMenu,
}: propsType) => {
  const theme = useTheme();
  return <DefaultTableContainer
    heading={
      <TablePanelLabel
        heading={`${displayNameForDriftRecords} Log Filters`}
      />
    }
  >
    <Box
      sx={{
        paddingTop: theme.spacing(1.6),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <StartDateRadioGroup
          value={
            querySettings[
             startDateRadioKey as
                keyof typeof querySettings
            ] as string
          }
          handleChange={handleFilterChange}
        />
        <ActivityFilterMenu />
        <SaasFilterMenu />
        {/*{*/}
        {/*  shouldShowIncludeFilters && (*/}
        {/*    <IncludeFilters*/}
        {/*      querySettings={querySettings}*/}
        {/*      toggleShouldIncludeInternal={handleFilterChange}*/}
        {/*    />*/}
        {/*  )*/}
        {/*}*/}
      </Box>
    </Box>
  </DefaultTableContainer>;
};





        // <FilterMenu
        //   label='Focus'
        //   options ={focusFilterMenuOptions}
        //   optionState={focusFilterMenuState}
        //   ariaLabel='Filter for Focus'
        //   menuId='filterMenuId'
        // />
