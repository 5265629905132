import {useAuth0} from '@auth0/auth0-react';
import {Divider, Menu, MenuItem, MenuProps, useTheme} from '@mui/material';
import {FC, useCallback} from 'react';
import {DefaultNavLink} from 'src/components/common/DefaultNavLink';
import {adminRoute} from 'src/routes';
import {compactInfoFontSize} from 'src/theme';

interface IDashboardLayoutSettingsMenuProps extends Omit<MenuProps, 'onClose'> {
  onClose: () => void;
}

export const SettingsMenu: FC<IDashboardLayoutSettingsMenuProps> = ({
  onClose,
  ...props
}) => {

  const theme = useTheme();

  const {logout} = useAuth0();

  const handleLogout = useCallback(async () => {
    logout({returnTo: window.location.origin});
    onClose();
  }, [onClose, logout]);

  return (
    <Menu
      {...props}
      onClose={onClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuItem>
        <DefaultNavLink
          to={adminRoute}
        >
          Admin
        </DefaultNavLink>
      </MenuItem>

      <Divider />
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      <Divider />

      <div
        style={{
          marginTop: '13px',
          marginLeft: '15px',
          marginRight: '15px',
          marginBottom: '8px',
          color: theme.palette.text.secondary,
          fontSize: compactInfoFontSize, // '0.85rem',
        }}
      >
        Version 2.10.0
      </div>
      {/*<MenuItem onClick={clearPersistentCache}>Clear Cache</MenuItem>*/}
    </Menu>
  );
};
