import {getUsersForApp} from 'src/services/Discovery/Discovery';
import {
  cacheKeyForDiscoveryUsersForApp
} from 'src/services/shared/serviceConstants';
import {AppUserGrid} from 'src/pages/DashboardPage/Discovery/AppUsersPanel/AppUserGrid';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../../../hooks/PortalContext";
import {useTheme} from "@mui/material";
import {buildUserConsentedColumns} from "./AppUsersColumnBuilder";
import {getGridHeadingCount} from "../../../../components/common/Tables/Utilities";


export const AppUsersPanel = ({appPubId}: {appPubId: string}) => {
  const {clientInfo} = usePortalContext();
  const theme = useTheme();
  const queryResult = useQueryWithAccessToken({
      queryKey: [cacheKeyForDiscoveryUsersForApp(appPubId)],
      queryFunction: getUsersForApp,
      queryFunctionVariables:{appPubId: appPubId}
    }
  );

  return  <AppUserGrid
    users={queryResult?.data?.latest_saas_users}
    columns={buildUserConsentedColumns(theme, clientInfo.configuration.features.privileges)}
    isLoading={queryResult.isLoading}
    panelHeading={getGridHeadingCount(queryResult?.data?.latest_saas_users, 'App Users')}
  />
}