import React, {FC, useRef} from 'react';
import {useLocation} from 'react-router';
import {NavLink, useNavigate} from 'react-router-dom';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {Typography, useTheme} from '@mui/material';
import {activityLogRoute, adminRoute, dashboardRoute,} from 'src/routes';
import {navigationItemMinWidth, useDashboardLayoutHeaderNavigationStyles} from 'src/components/MainLayout/styles';
import {isSecurityPostureRoute} from "./SecurityPostureMenu";
import {usePortalContext} from "../../hooks/PortalContext";

// The isSecurityPostureRoute functions, below, help show the
// correct state of the navigation items.  The NavLink isActive
// mechanism doesn't seem sufficient
const allNavigationItems = [
  {
    url: dashboardRoute,
    label: 'Security Posture',
    isSecurityPosture: isSecurityPostureRoute,
    disabledForTrial: false,
  },
  {
    url: activityLogRoute,
    // label: `${capitalizeFirstLetter(wordForChange)} Log`,
    label: `Activity Log`,
    isSecurityPosture: () => false,
    disabledForTrial: true,
  },
];

export const addSaasMenuButtonId = 'add-saas-menu-button';

export const Navigation: FC = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const {isTrial} = usePortalContext();
  const {
    navigationContainer,
    navigationMenu,
    navigationItem,
    navigationItemLink,
    navigationItemLinkDisabled,
    navigationItemLinkActive,
    navigationAddSaaS,
  } = useDashboardLayoutHeaderNavigationStyles();

  // Cancels the click behaviour of the link if the item is disabled
  const handleClick = (e:React.MouseEvent<HTMLElement>, disabled: boolean) => {
    if(disabled){
      e.preventDefault();
    }
  }

  let navAddSaasRef = useRef(null);

  const handleshowAddSaasListClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    // if(process.env.REACT_APP_FEATURE_FLAG_ADMIN === '1')
      navigate(adminRoute);
    // else
    //   setIsSaasListPopperOpen(!isSaasListPopperOpen);
  };

  return (
  <div
    ref={navAddSaasRef}
    className={navigationContainer}>
    <div
      className={navigationAddSaaS}
      onClick={handleshowAddSaasListClick}
    >
      <div id={addSaasMenuButtonId}>
        <AddBoxOutlinedIcon
          sx={{
            color: theme.palette.primary.main,
          }}
        />
        <Typography component="span">Add SaaS</Typography>
      </div>
    </div>
    <ul className={navigationMenu} data-testid="navigation-items-list">
      {
        allNavigationItems.map(({url, label, isSecurityPosture, disabledForTrial}) => (
          <li className={navigationItem} key={url}>
            <NavLink
              style={{
                minWidth: navigationItemMinWidth,
              }}
              // this id is part of an experiment to do first click
              // on the Security Posture button when the menu is open
              id={
                isSecurityPostureRoute(url) ?
                  'posture-buton' :
                  'activity-button'
              }
              data-testid={`nav-item-${label}`}
              className={
                // See notes above describing the isSecurityPosture()
                // function. The issue was that isActive isn't sufficient.
                ({isActive }) => {
                  if (isTrial && disabledForTrial) {
                    return navigationItemLinkDisabled
                  }
                  else {
                    return isActive ||
                    isSecurityPosture(pathname) ?
                      navigationItemLinkActive :
                      navigationItemLink
                  }
                }
              }
              to={url}
              // If this client is a trial, and this link is disabled for trials, we need to remove the click handler and
              // show a tooltip as to why it is disabled
              onClick={(e) => handleClick(e, (isTrial && disabledForTrial))}
              title={(isTrial && disabledForTrial) ? "Upgrade to access the Activity Log" : label}
            >
              {label}
            </NavLink>
          </li>
        ))
      }
    </ul>
  </div>
);

};
