import {ISaasPrivilegedAccess} from 'src/types/SaasPrivilegedAccess';
import {displayTitleForEnabledUsers, privilegeStrings, tableCellHeadings} from 'src/utils/constants';
import {PrivilegedAccessSummaryRow} from 'src/pages/PrivilegedAccessPage/PrivilegedAccess/PrivilegedAccessSummaryTable/PrivilegedAccessSummaryRow';
import {
  DefaultTable,
  DefaultTableContainer
} from 'src/components/common/DefaultTable';

type props = {
  privilegedAccesses: ISaasPrivilegedAccess[];
  getHpuLink: (saasIdentifier: string) => string; // hpu user link
  getPuLink: (saasIdentifier: string) => string; // privileged user link
  getULink: (saasIdentifier: string) => string; // enabled user link
  getDriftLink: (saasIdentifier: string) => string;
};

const columns = [
  {id: 'app', label: tableCellHeadings.saas, columnAlignOverride: 'left'},
  {id: 'hpu', label: privilegeStrings.highlyPrivileged},
  {id: 'pu', label: privilegeStrings.privileged,},
  {id: 'user', label: displayTitleForEnabledUsers},
  {id: 'drift', label: 'Drift'},
]

export const PrivilegedAccessSummaryTable = ({
  privilegedAccesses,
  getHpuLink,
  getPuLink,
  getULink,
  getDriftLink,
}: props) => {

  return (
    <DefaultTableContainer>
      <DefaultTable
        columns={columns}
        columnAlignment='center'
      >
        {privilegedAccesses?.map((saasPrivilegedAccess, index) => (
            <PrivilegedAccessSummaryRow
              key={saasPrivilegedAccess.saas.identifier + index}
              saasPrivilegedAccess={saasPrivilegedAccess}
              getHpuLink={getHpuLink}
              getPuLink={getPuLink}
              getULink={getULink}
              getDriftLink={getDriftLink}
            />
          ))}
      </DefaultTable>
    </DefaultTableContainer>

  );
};
