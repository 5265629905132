import Error from '@mui/icons-material/Error';
import {Box, useTheme} from '@mui/material';
import React, {FC} from 'react';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {SaasLogo} from 'src/components/SaasLogo';
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {iSaasForIdentifier} from "src/utils/saasUtils";
import {ContactSupport} from "src/components/common/ErrorScreen";
import {useAllSaases} from "../../../../hooks/UseSaas";

export const ErrorPage: FC = () => {
  const theme = useTheme();
  const {saasIdentifier} = useParams<{saasIdentifier: string}>();

  const [searchParams] = useSearchParams();
  const errorMessage = searchParams?.get('error');
  const tweakedErrorMessage = errorMessage?.replace(errorMessage[0], errorMessage[0].toUpperCase());
  const {
    data: allSaases,
    // isLoading
  } = useAllSaases();

  const saas = iSaasForIdentifier(saasIdentifier, allSaases);

  return (
    <Box
      p={10}
      width="100%"
      maxWidth="40rem"
      marginLeft="auto"
      marginRight="auto"
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.0rem',
      }}>
        <Error
          sx={{
            color: theme.more.riskyRed,
            fontSize: '2.5rem',
            fontWeight: 'bold',
          }}
        />
        <h3>Error adding {
          saas?.name
        }</h3>
        <SaasLogo
          saasIdentifier={saasIdentifier}
          size='m' />
      </div>
      <DtxSpacer space={5} />
      <div
        style={{marginLeft: '55px'}}
      >
        {tweakedErrorMessage || 'Error'}
        <DtxSpacer space={8}/>
        <ContactSupport/>
      </div>
      <Box
        sx={{
          marginLeft: '8px',
          // display: 'flex',
          // justifyContent: 'center',
          // width: 'auto',
        }}
      >
      </Box>
    </Box>
  );
}


      // <div>We were unable to add {
      //   saas.name
      // }. &nbsp;Contact support.</div>
