import {FC} from 'react';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {BackToPrevious} from 'src/components/common/BackToPrevious';
import {SaasLogo} from 'src/components/SaasLogo';
import {styled, Typography} from '@mui/material';
import {saasRouteFragment} from 'src/routes';
import {clickableDivStyles} from 'src/theme';
import {DtxSpacer} from 'src/components/common/DtxSpacer';

const StyledTablePanelLabelContainer = styled('div')(
  ({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.0rem',
    marginTop: '-10px',
    paddingTop: '0px',
    marginLeft: '-13px',
    marginBottom: '9px',
  })
);

type props = {
  heading: React.ReactNode;
  saasIdentifier?: string;
  singlePanelUrl?: string;
  shouldShowBackToPrevious?: boolean;
};

// Heading label tables - shows SaaS if saasIdentifier provided
export const TablePanelLabel: FC<props> = ({
  heading,
  saasIdentifier,
  singlePanelUrl,
  shouldShowBackToPrevious = true,
}) => {
  // console.log({heading})
  return (
    <StyledTablePanelLabelContainer>
      {shouldShowBackToPrevious ?
        <BackToPrevious /> :
        <DtxSpacer
          orientation='vertical'
          space={0.0}
        />
      }
      <Typography
        variant="h4"
        style={{
          // marginBottom: '5px',
          fontWeight: 'bold',
        }}
      >
        <span>{heading}</span>
      </Typography>
      { // conditionally show SaaS logo and link
        singlePanelUrl &&
        <DefaultLink
          to={singlePanelUrl}
          sx={{
            /// textDecoration: 'none',
            marginLeft: '21px',
            ...clickableDivStyles,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              // fontSize: '1.1rem'
              // marginBottom: '4px'
          } }
          >
            View All
          </Typography>
        </DefaultLink>
      }
      { // conditionally show SaaS logo and link
        saasIdentifier &&
        <DefaultLink
          to={`/${saasRouteFragment}/${saasIdentifier}`}
          sx={{
            /// textDecoration: 'none',
            marginLeft: '21px',
            ...clickableDivStyles,
          }}
        >
          <SaasLogo
            saasIdentifier={saasIdentifier || ''}
            size='m'
          />
        </DefaultLink>
      }
    </StyledTablePanelLabelContainer>
  );
}
