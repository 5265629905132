import {styled} from "@mui/system";
import detexianHero from "src/assets/images/azure/detexianHero.png";
import React from "react";
import {Typography} from "@mui/material";
import {clickableDivStyles} from "src/theme";
import {DtxSpacer} from "src/components/common/DtxSpacer";

const StyledHero = styled('img')(({theme}) => ({
  height: '200px',
  marginBottom: '0px',
  paddingBottom: '0px',
  pointerEvents: 'none',
}));

export function Header() {
  return (<>
      <Typography
        variant="h1"
        sx={{
          ...clickableDivStyles,
          paddingTop: '0px',
          marginTop: '-70px',
          fontSize: '3.7rem',
          fontWeight: '900',
        }}
      >
        Eliminate SaaS blind spots
      </Typography>
      <DtxSpacer />
      <StyledHero
        src={detexianHero}
        alt="Detexian Hero image"
      />
    </>);
}
