import React from "react";
import {Cell, Label, Pie, PieChart} from "recharts";
import {h2FontSize, theme} from "../../theme";

type UserPercentageGraphProps = {
  count: number;
  total: number;
  title: string;
}
export const UserPercentageGraph = ({count, total, title}: UserPercentageGraphProps) => {
  return (
    <PieChart
      width={190}
      height={190}
      style={{
        margin: "auto",
        textAlign: "center"
      }}
    >
      <Pie
        dataKey="value"
        data={[{name: title, value: count}, {name: 'Total', value: total - count}]}
        cx="50%"
        cy="50%"
        innerRadius={50}
        outerRadius={95}
      >
        <Cell fill={theme.more.riskyRed}/>
        <Cell fill={theme.more.andyGreen}/>

        <Label
          position="center"
          content={
            // @ts-ignore
            <InnerChartLabel count={count} total={total}/>
          }
        />
      </Pie>
    </PieChart>
  );

}

const InnerChartLabel = ({
  viewBox,
  count,
  total,
}: {
  viewBox: any;
  count: number;
  total: number;
}) => {
  // Center the labels
  const percent = Math.round(count / total * 100);
  const {cx, cy} = viewBox;
  const singleDigitXOffset = 22;
  let numberLeftShift = singleDigitXOffset;
  if (percent > 99)
    numberLeftShift = singleDigitXOffset + 23;
  else if (percent > 10)
    numberLeftShift = singleDigitXOffset + 13;

  return (
    <React.Fragment>
      <text x={cx - numberLeftShift} y={cy + 7}>
        <tspan
          style={{
            textAlign: 'right',
            fontWeight: 'normal',
            fontSize: h2FontSize,
          }}
        >
          {`${percent}%`}
        </tspan>
      </text>
      <text x={cx - 21} y={cy + 20}>
        <tspan
          style={{
            fontSize: "0.6em",
          }}
        >
          {count} / {total}
        </tspan>
      </text>
    </React.Fragment>
  );
};