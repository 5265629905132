import {singlePanelUrlWithObject} from "../../../routes";

// Builds a link to show on the dashboard panels.
export const getPanelLink = (riskPanelIdentifier: string, recordCount: number, enabled: boolean, ) => {
  if (!enabled) {
    return undefined;
  }
  return singlePanelUrlWithObject({
    searchParams: null,
    riskPanelIdentifier,
    recordCount,
    maxRecordCountToNotReturnViewAllUrl: -1})
}

// There are some dashboard panels (Inactive users for example) that may use either a full query or just a count
// depending on the feature status.  This function consolidates the state of the queries depending on the feature value
// so the implementation doesn't need to care
export const performFeatureBasedQuery = (isFeatureOn: boolean, fullQuery: any, countQuery: any) => {
  let isLoading;
  let count;
  let users;
  let isError;

  // If the feature is on then we care about the loading state of the full list
  if (isFeatureOn) {
    isLoading = fullQuery.isLoading;
    isError = fullQuery.isError;
    count = fullQuery?.data?.length;
    users = fullQuery?.data;
  }
  // If the feature is off then we care about the loading state of the count
  else {
    isLoading = countQuery.isLoading;
    isError = countQuery.isError;
    count = countQuery.data;
  }
  return {isLoading, count, users, isError};
}
