import {Paper, styled} from '@mui/material';

export const StyledGeneralContainer = styled(Paper)(
  (({
    theme: {
      spacing, defaultShadow, majorContainerBorderRadius,
    },
  }) => ({
    padding: spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    boxShadow: defaultShadow,
    borderRadius: majorContainerBorderRadius,
  })));
