import React from 'react';
// import ArrowBack from '@mui/icons-material/ArrowBack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';import {Tooltip, useTheme} from '@mui/material';
import {useNavigate} from 'react-router';
import {clickableDivStyles} from 'src/theme';

export const BackToPrevious = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  }

  return (
    <Tooltip
      title="Back to previous"
      placement="top-start"
      enterDelay={1000}
    >
      <div
        style={{
          marginBottom:  "-5px", // aligns it vertically with headings
          color: theme.palette.primary.main,
          ...clickableDivStyles,
        }}
        onClick={handleClick}
        aria-label="back to previous"
      >
        <KeyboardBackspaceIcon />
      </div>
    </Tooltip>
  );
};
