import {gql} from 'graphql-request';
import {
  nullCollapsableArguments, nullCollapsableClientPubId, nullCollapsableIncludeExternal,
  nullCollapsableIncludeInternal, nullCollapsableSaasPubId,
} from 'src/services/shared/nullCollapsableUtilities';
import {transformUser} from 'src/services/shared/sharedTransforms';
import {userStatusStrings} from 'src/utils/constants';
import {saasUserFragment} from './Fragments';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {QueryContext} from "../../hooks/TanstackHooks";

const inactiveUserQuery = gql`
  query InactiveUsers (
    $privilegeLevelBoolExp: privilege_level_comparison_exp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $connectionIdBoolExp: client_saas_bool_exp,
    $shouldIncludeInternalBoolExp: domain_status_comparison_exp,
    $shouldIncludeExternalBoolExp: domain_status_comparison_exp,
    $clientBoolExp: client_bool_exp,
  ) {
    latest_saas_user(
      where: {
        client: $clientBoolExp,
        saas: $saasIdentifierBoolExp,
        connection: $connectionIdBoolExp
        privilege_level: $privilegeLevelBoolExp,
        inactive_60days: {_eq: true}, 
        status: {_eq: ${userStatusStrings.enabled}}
        _and: [
          {domain: $shouldIncludeInternalBoolExp},
          {domain: $shouldIncludeExternalBoolExp}
        ],
      },
      order_by: {last_login: desc}
    ) {
      ...User
    }
  }
  ${saasUserFragment}
`;

const inactiveUserCountQuery = gql`
query InactiveUsersCount (
  $privilegeLevelBoolExp: privilege_level_comparison_exp,
  $saasIdentifierBoolExp: saas_bool_exp,
  $connectionIdBoolExp: client_saas_bool_exp,
  $shouldIncludeInternalBoolExp: domain_status_comparison_exp,
  $shouldIncludeExternalBoolExp: domain_status_comparison_exp,
  $clientBoolExp: client_bool_exp,
) {
  latest_saas_user_aggregate(
    where: {
    client: $clientBoolExp,
    saas: $saasIdentifierBoolExp,
      connection: $connectionIdBoolExp
    privilege_level: $privilegeLevelBoolExp,
      inactive_60days: {_eq: true},
    status: {_eq: Enabled}
    _and: [
      {domain: $shouldIncludeInternalBoolExp},
      {domain: $shouldIncludeExternalBoolExp}
    ],
  },
  order_by: {last_login: desc}
) {
    aggregate {
      count
    }
  }
}`;

export const getInactiveUsersCount = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any,}
) => {
  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    shouldIncludeExternal = true,
    shouldIncludeInternal = true,
  } = parameters;

  const variables = {
    ...nullCollapsableArguments({saasIdentifier, privilegeLevel}),
    connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    shouldIncludeInternalBoolExp:
      nullCollapsableIncludeInternal(shouldIncludeInternal),
    shouldIncludeExternalBoolExp:
      nullCollapsableIncludeExternal(shouldIncludeExternal),
    ...nullCollapsableClientPubId(queryContext.client.id)
  }

  const queryFunction = async (client: any) => {
    return await client.request(
      inactiveUserCountQuery,
      variables
    );
  };
  const response = await doAuthenticatedQuery(
    queryFunction,
    accessToken
  );

  const result = response.latest_saas_user_aggregate.aggregate.count;
  return result;

}
export const getInactiveUsers = async (
  {accessToken, queryContext, parameters}
    : {
        accessToken: any,
        queryContext: QueryContext,
        parameters: any,
  }
) => {
  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    shouldIncludeExternal = true,
    shouldIncludeInternal = true
  } = parameters;

  const variables = {
    ...nullCollapsableArguments({saasIdentifier, privilegeLevel}),
    connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    shouldIncludeInternalBoolExp:
      nullCollapsableIncludeInternal(shouldIncludeInternal),
    shouldIncludeExternalBoolExp:
      nullCollapsableIncludeExternal(shouldIncludeExternal),
    ...nullCollapsableClientPubId(queryContext.client.id)
  }

  const queryFunction = async (client: any) => {
    return await client.request(
      inactiveUserQuery,
      variables
    );
  };
  const response = await doAuthenticatedQuery(
    queryFunction,
    accessToken
  );

  const result = response.latest_saas_user.map(transformUser);
  // const result = response.latest_saas_user.map((user)
  return result;
};
