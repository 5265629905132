import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PillStyleProps} from './Pill';

export const usePillStyles = makeStyles<Theme, PillStyleProps>(
  (theme) => {
    return (
      {
        // If we make a style a function, as below, makeStyles seems to
        // call the style function with the props passed to the hook it
        // returns, and return a style name - rather
        // than returning the function as might be expected.
        pillStyle: (props) => (
          {
            // appearance
            borderRadius: '8px',
            height: '15px',
            width: props.pillWidth ?? '68px',

            border: 'none',
            margin: 0,
            backgroundColor: props.pillColor,
            padding: '3px 7px 0px 7px',
            textAlign: 'center',

            // text
            textTransform: 'uppercase',
            fontWeight: 'normal',
            fontSize: '0.6rem',
            color: 'white',

            // cursor: 'pointer',
            // overwrite MUI's hover
            '&:hover': {
              backgroundColor: props.pillColor,
            },
          }
        ),
        fontFamilyStyle: (
          {...theme.typography}
        ),
      }
    )
  }
);
