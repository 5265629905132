import {DefaultTable, DefaultTableContainer} from 'src/components/common/DefaultTable';
import {AuthExceptionSummaryTableRow} from 'src/pages/AuthExceptionsPage/AuthExceptionTables/AuthenticationExceptionSummaryTable/AuthExceptionSummaryTableRow';
import {ISaasExceptions} from 'src/types/SaasExceptions';
import {displayTitleForEnabledUsers, privilegeStrings, tableCellHeadings} from 'src/utils/constants';

const columns = [
  {id: 'application', label: tableCellHeadings.saas, columnAlignOverride: 'left'},
  {id: 'exceptions',label: 'Exceptions'},
  {id: 'hpu', label: privilegeStrings.highlyPrivileged},
  {id: 'pu', label: privilegeStrings.privileged},
  {id: 'eu', label: displayTitleForEnabledUsers},
  {id: 'drift', label: 'Drift'},
];

type props = {
  saasesExceptions: ISaasExceptions[];
};

export function AuthExceptionSummaryTable({
  saasesExceptions,
}: props): JSX.Element {
  // console.log({saasesExceptions})
  return (
    <DefaultTableContainer>
      <DefaultTable
        columns={columns}
        columnAlignment='center'
      >
        {
          saasesExceptions.map(
            (saasException, index) => {
              return (
                <AuthExceptionSummaryTableRow
                  key={saasException.exceptions[0].name + index}
                  saas={saasException.connectedSaas /*saasException.saas*/}
                  saasException={saasException.exceptions[0]}
                />
              )
            }
          )
        }
      </DefaultTable>
    </DefaultTableContainer>
  );
}
