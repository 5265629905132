import React from 'react';
import {
  CountSummaryHeaderComponent
} from 'src/pages/SingleSaasPage/CountSummaryHeader/CountSummaryHeaderComponent';
import {usePortalContext} from "../../../hooks/PortalContext";

type props = {
  summaryData: any;
  saasNameShouldBeLink: boolean
};

export const CountSummaryHeader = (
  {summaryData, saasNameShouldBeLink}: props) => {
  const {clientInfo} = usePortalContext();
  return <CountSummaryHeaderComponent
    summaryData={summaryData}
    saasNameShouldBeLink={saasNameShouldBeLink}
    sot={clientInfo.sourceOfTruth}
  />;
}
