import {gql} from 'graphql-request';
import {saasFragment} from "../shared/Fragments";

export const portalUserQuery = gql`
query PortalUserS($user_filter: cfg_user_bool_exp!) {
    cfg_user(where: $user_filter){
          pub_id
          name
          email
          is_disabled
          connection_type
          user_roles {
            client{
              pub_id
              name
              has_access
              trial_remaining_days
              plan_type
              slug
              is_disabled
              configuration
              source_of_truth_saas{
                ...Saas
              }
            }
            role
          }
      }
}${saasFragment}`;


// Mutation for updating a portal user
export const updatePortalUserMutation = gql`
mutation UpdatePortalUser($client_id: uuid, $req:UpdateUserRequest!){
  update_user(client_id: $client_id, req:$req) {
    name
    email
  }
}`;

export const createPortalUserMutation = gql`
mutation CreatePortalUser($client_id: uuid, $req:CreateUserRequest!){
  insert_user(client_id: $client_id, req:$req) {
    temp_password
  }
}`;