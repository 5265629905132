import {styled} from '@mui/material';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {widgetAdviceColor} from 'src/theme';
import {stringForMissingText} from 'src/utils/constants';

const StyledWidgetCenteringContainer = styled('div')(
  ({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
  })
);

const StyledWidgetGridContainer = styled('span')(
  (props:any) => ({
      display: 'grid',
      justifyItems: 'center',
      width: '100px',
      // ...props.hoverStyles,
      textAlign: 'center',
      textDecoration: 'none',
  })
);

const StyledWidgetCount = styled('h3')(
  ({theme}) => ({
    padding: 0,
    margin: 0,
    fontSize: '1.4rem',
    color: widgetAdviceColor,
    // cursor: 'pointer',
    textAlign: 'center',
  })
);

export type props =  {
  accessCount?: number;
};

export const UserCountWidget = (props: props) => {

  const {
    accessCount = stringForMissingText
  } = props;

  return (
    <StyledWidgetCenteringContainer>
      <StyledWidgetGridContainer>
        <StyledWidgetCount>{accessCount}</StyledWidgetCount>
      </StyledWidgetGridContainer>
    </StyledWidgetCenteringContainer>
  )
}

// TODO cut over to this one - makes it easier to not show hover effect for zeros
type LinkedRatingWidgetProps = {
  accessCount: any;
  link: any;
}

export const LinkedUserCountWidget = ({
  accessCount,
  link,
}: LinkedRatingWidgetProps) => {

  // is having this in here a performance/memory leak issue?
  const Widget = () => (
    <UserCountWidget
      accessCount={accessCount}
    />
  );

  return (
    link ?
      <DefaultLink to={link}>
        <Widget />
      </DefaultLink>
      :
      <Widget />
  );
};
