import {FC} from "react";
import {
  Box,
  Dialog,
  Typography as T,
  useTheme
} from "@mui/material";

import {DtxSpacer} from "src/components/common/DtxSpacer";
import {DefaultButton} from "src/components/common/DefaultButton";
import {LoadingSpinner} from "../LoadingSpinner";

type propTypes = {
  isOpen: boolean;                      // Is the dialog open
  setIsOpen: any;                       // Function to open/close the dialog
  cancelButtonName?: string             // "Cancel" is the default cancel button label, override it with this.
  okButtonName?: string                 // "OK" is the default confirm button label, override it with this.
  onOkButtonClick?: () => void;         // Action for clicking the OK button.  If empty the button is not shown
  onCancelButtonClick?: () => void;     // Action for clicking the cancel button.  If empty the button is not shown
  heading: string;                      // Dialog heading
  isSpinning?: boolean;                 // Is the spinner to be shown
};

export const ConfirmDialog: FC<propTypes> = ({
  isOpen,
  isSpinning,
  setIsOpen,
  okButtonName = 'OK',
  cancelButtonName = 'Cancel',
  onOkButtonClick,
  onCancelButtonClick,
  heading,
  children,
  ...props
}) => {
  // isOpen && console.log({saas})
  const theme = useTheme();

  const handleOkButtonClick = () => {
    onOkButtonClick && onOkButtonClick();
  }

  const handleCancelButtonClick = () => {
      // Close the dialog and invoke the handler if there is one
    setIsOpen(false);
    onCancelButtonClick && onCancelButtonClick();
  }

  // If isSpinning is true, the dialog content is a spinner, otherwise show the children
  const dialogContent = isSpinning ? <LoadingSpinner></LoadingSpinner> : children;

  return (
    <Dialog
      {...props}
      open={isOpen}
    >
      <Box
        sx={{
          height: '270px',
          width: '450px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: theme.spacing(3),
        }}
      >
          <T
            variant='h1'
            color="textPrimary"
          >
            {heading}
          </T>
          {dialogContent}
          <DtxSpacer space={4}/>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderRadius: theme.more.borderRadius,
            }}
          >
            {onOkButtonClick !== undefined && <>
              <DefaultButton
                variant="contained"
                onClick={handleOkButtonClick}
              >
                {okButtonName}
              </DefaultButton>

              <DtxSpacer orientation="vertical" />
            </>}
            <DefaultButton
              variant="contained"
              onClick={handleCancelButtonClick}
            >
              {cancelButtonName}
            </DefaultButton>
          </Box>
      </Box>
    </Dialog>
  );
};
