import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import memoizee from 'memoizee';
import React from 'react';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {DefaultTableNumberCell} from 'src/components/common/DefaultTable/DefaultTableNumberCell';
import {HtmlTooltip} from 'src/components/common/HtmlTooltip';
import {SaasTableCell} from 'src/components/common/Tables/Utilities';
import {compactInfoFontSize} from 'src/theme';
import {SaasIdentifier} from 'src/types/Saas';
import {tableCellHeadings} from "src/utils/constants";
// import {
//   getHpuSubText,
//   getPuSubText,
//} from 'src/utils/StatusCalculator';

const ghostSummaryCellTitle = 'Ghost\nUsers';
const buildTableHeadCells = memoizee((
  shouldShowExternalForwardColumns,
  shouldShowGhostColumn,
)=> {
  let cells = [
    'Highly\nPrivileged\nUsers',
    'Privileged\nUsers',
    // 'Ordinary\nUsers',
    'Users',
    'MFA\nDisabled',
    'Inactive\nUsers',       // 'No Login \n Last 60 Days',
  ];

  if (shouldShowGhostColumn) {
    cells = cells.concat([
      ghostSummaryCellTitle
    ]);
  }

  cells = cells.concat([
    'External\nUsers',
  ]);

  if (shouldShowExternalForwardColumns) {
    cells = cells.concat([
      'External\nForwards',
      'External\nSharing',
      'DLP\nEvent', // 'DLP \n Event',
    ]);
  }
  return cells
});

type props = {
  summaryData: any;
  saasNameShouldBeLink?: boolean;
  sot: any;
};

export const CountSummaryHeaderComponent = (
  {
    summaryData,
    saasNameShouldBeLink = false,
    sot
  }: props) => {
  // console.log({summaryData})
  const theme = useTheme();

  const saasIdentifier = summaryData.saas.identifier;

  const shouldShowExternalForwardColumns =
    saasIdentifier === SaasIdentifier.office365 ||
    saasIdentifier === SaasIdentifier.microsoft365 ||
    saasIdentifier === 'slack'
  ;

  const shouldShowGhostColumn = saasIdentifier !== sot.identifier;

  const tableHeadCells =
    buildTableHeadCells(
      shouldShowExternalForwardColumns,
      shouldShowGhostColumn,
    );

  // const {palette} = useTheme<Theme>();
  const noOfHPUs = summaryData.HPUs?.length;
  const noOfPUs = summaryData.PUs?.length;
  const noOfUs = summaryData.Us?.length;

  const tableHeadCellStyle = {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    whiteSpace: 'pre-line',
    textAlign: 'center',
    lineHeight: 1,
  }

  const tableCellStyle = {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // this causes too-wide saas column in Firefox at for wide screens
      // maxWidth: '15%',
      // minWidth: '9%',
    };

  return (
    <DefaultTableContainer>
      <Table>
        <TableHead
          // className={tableHead}
          sx={{
            backgroundColor: theme.more.summaryHeadingBackgroundColor,
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                // neither of these seem to have any effect
                // width: '50px',
                // marginRight: 'clamp(30px, 40px, 50px)',
              }}
            >
              {tableCellHeadings.saas}
            </TableCell>

            {tableHeadCells.map((cell, index) =>
              cell === ghostSummaryCellTitle ?
                (
                  <TableCell
                    key={index}
                    sx={tableHeadCellStyle}
                  >
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <span
                            style={{
                              fontSize: compactInfoFontSize
                            }}
                          >
                            <strong>Ghosts</strong> are users <em>without</em> an active account in your source of truth, {sot ? sot.name : 'loading'}
                          </span>
                        </React.Fragment>
                      }
                    >
                    <span>{cell}</span>
                    </HtmlTooltip>
                  </TableCell>
                ) :
                (
                  <TableCell
                    key={index}
                    sx={tableHeadCellStyle}
                  >
                    {cell}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <SaasTableCell
              sx={{
                maxWidth: '170px',
                minWidth: '110px',
              }}
              saas={summaryData.connectedSaas}
              shouldLinkToSaasView={saasNameShouldBeLink}
              // size="l"
            />

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={noOfHPUs}
              // secondaryText={getHpuSubText(noOfHPUs, saasIdentifier)}
              // primaryTextColor={theme.palette[getHpuColor(noOfHPUs, saasIdentifier)]?.main}
              ariaLabel="Highly Privileged Users"
            />

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={noOfPUs}
              // secondaryText={getPuSubText(noOfPUs)}
              // primaryTextColor={theme.palette[getPuColor(noOfPUs)]?.main}
              ariaLabel="Privileged Users"
            />

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={noOfUs}
              // secondaryText={getPuSubText(noOfPUs)}
              // primaryTextColor={theme.palette[getPuColor(noOfPUs)]?.main}
              ariaLabel="Users"
            />

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={summaryData.noOfMfa}
              // secondaryText="&nbsp;"
              ariaLabel="MFA Non-Compliance"
            />

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={summaryData?.inactive?.length}
              // secondaryText="&nbsp;"
              ariaLabel="Inactive Users"
            />

            {shouldShowGhostColumn && (
              <DefaultTableNumberCell
                sx={tableCellStyle}
                primaryText={summaryData.ghosts?.length}
                // secondaryText="&nbsp;"
                ariaLabel="Ghosts"
              />
            )}

            <DefaultTableNumberCell
              sx={tableCellStyle}
              primaryText={summaryData.external?.length}
              // secondaryText="&nbsp;"
              ariaLabel="External Users"
            />

            {shouldShowExternalForwardColumns && (<>
              <DefaultTableNumberCell
                sx={tableCellStyle}
                primaryText={summaryData.forwards?.length}
                // secondaryText="&nbsp;"
                ariaLabel="External Email Forwards"
              />
              <DefaultTableNumberCell
                sx={tableCellStyle}
                primaryText={summaryData.shares?.length}
                // secondaryText="&nbsp;"
                ariaLabel="External Email Sharing"
              />
              <DefaultTableNumberCell
                sx={tableCellStyle}
                primaryText={summaryData.risks?.length}
                // secondaryText="&nbsp;"
                ariaLabel="DLP Triggers"
              />
            </>  )
            }
          </TableRow>
        </TableBody>
      </Table>
    </DefaultTableContainer>
  );
}




// Ghost stuff

// Tooltip
/*
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
  },
}))(Tooltip);
*/

// Heading
             // index === 3 ? // Ghosts
              //   <HtmlTooltip
              //     title={
              //       <>
              //         <Typography color="inherit">Ghost Users</Typography>
              //         <strong><em>{'Ghosts '}</em></strong>
              //         <b>{'are no longer enabled in Office 365, your '}</b>
              //         <strong><em>{'source of truth'}</em></strong>
              //         <b>, but remain in one or more of your other SaaSes.</b>
              //       </>
              //     }
              //   placement="top-start"
              //   key={index}
              // >
              //   <TableCell className={tableHeadCell} key={index}>
              //     {cell}
              //   </TableCell>
              // </HtmlTooltip>
              // :

// Column
//             {/*/!*'Ghosts'*!/*/}
//             {/*<DefaultTableNumberCell*/}
//             {/*  className={tableCell}*/}
//             {/*  primaryText={4}*/}
//             {/*/>*/}
