// Now unused - for reference only

import {SaasIdentifier} from "src/types/Saas";

// OAuth
export const saasRequiresNoUserInput = (saasIdentifier: string) => {
  const result =
    saasIdentifier === SaasIdentifier.docusign ||
    saasIdentifier === SaasIdentifier.office365 ||
    saasIdentifier === SaasIdentifier.microsoft365 ||
    saasIdentifier === SaasIdentifier.googlews ||
    saasIdentifier === SaasIdentifier.atlassian ||
    saasIdentifier === SaasIdentifier.dropbox ||
    saasIdentifier === SaasIdentifier.employment_hero ||
    saasIdentifier === SaasIdentifier.lucid ||
    saasIdentifier === SaasIdentifier.xero ||
    saasIdentifier === SaasIdentifier.smartsheet ||
    saasIdentifier === SaasIdentifier.slack ||
    saasIdentifier === SaasIdentifier.salesforce ||
    saasIdentifier === SaasIdentifier.zoom
  ;
  return result;
}

export const isOAuthWithExtrasSaas = (saasIdentifier: string) => {
  const result =
    saasIdentifier === SaasIdentifier.zendesk ||
    saasIdentifier === SaasIdentifier.github ||
    saasIdentifier === SaasIdentifier.egnyte
  return result;
}

export const saasCanFinishConnectionSetup = (saasIdentifier: string) => {
  const result =
      saasIdentifier === SaasIdentifier.googlews ||
      saasIdentifier === SaasIdentifier.atlassian
  return result;
}
/*
Unused - historical reference only.
Also see file FieldComponentForSaas.
const connectionStructures = {
  atlassian: {
    "saas": "atlassian",
    "jira_token": "jira_token",
    "base_url": "jira_uRL",
    "admin_api_key": "admin_api_key",
    "organisation_key": "organization_key",
    "jira_email": "jira_email"
  },
  bamboohr: {
    "saas": "bamboohr",
    "url": "url",
    "secret": "secret"
  },
  box: {
    "saas": "box",
    "tenant_id": "tenant_id"
  },
  egnyte: {
    "saas": "egnyte",
    "url": "url",
    "secret": "secret"
  },
  github: {
    "saas": "github",
    "name": "name",
    "token": "token"
  },
  okta: {
    "saas": "okta",
    "url": "url",
    "secret": "secret"
  },
  gitlab: {
    "saas": "gitlab",
    "url": "url",
    "secret": "secret"
  },
  sendgrid: {
    "saas": "sendgrid",
    "token": "token"
  },
  slack: {
    "saas": "slack",
    "token": "token"
  },
  zoom: {
    "saas": "zoom",
    "name": "name",
    "token": "token"
  },

  // OAuth 2 SaaSes
  office365: {
    saas: SaasIdentifier.microsoft365
  },
  dropbox: {
    saas: SaasIdentifier.dropbox
  },
  zendesk: {
    "saas": "zendesk",
    "url": "url"
  },
};
*/

/* If we want to be good little TypeScript soldiers
// for BambooHR, Egnyte, Okta and Gitlab
export type UnnamedAddSaaSParameters = {
  url: string;
  secret: string;
}

export type AtlassianAddSaaSParameters = {
    "jira_token": string;
    "base_url": string;
    "admin_api_key": string;
    "organisation_key": string;
    "jira_email": string;
}

export type addSaasParameters = {
  saas: ISaas;
  unnamedSaaSParameters?: UnnamedAddSaaSParameters;
}
*/

