import {DriftGrid} from 'src/components/common/Tables/DriftGrid/DriftGrid';
import {ConnectedSaas} from "src/types/Saas";
import {getGridHeadingCount} from "../../components/common/Tables/Utilities";

type propTypes = {
  drifts: any;
  connectedSaases: ConnectedSaas[];
  shouldShowSaasColumn: boolean;
  saas?: string | undefined;
  tableLabel: string;
}

export const DriftsComponent = ({
  drifts,
  connectedSaases,
  shouldShowSaasColumn,
  saas,
  tableLabel
}: propTypes) => {
  return (
    <DriftGrid
      rowIdKey={'id'}
      headerTitle={getGridHeadingCount(drifts, tableLabel)}
      shouldShowSaasColumn={shouldShowSaasColumn}
      shouldShowUserNameColumn={true}
      rows={drifts}
    />
  )
};
