// shared with AppUserPage column builder
import {Box, Tooltip, Typography as T, useTheme} from '@mui/material';
import React from 'react';
import {mfaColor, privColor} from 'src/components/common/Tables/DefaultGrid/dataGridUtilities';
import {
  maxEmailLength,
  maxNameLength,
  maxOtherLength, mfaStatusDisplayString,
  TruncatableString,
} from 'src/components/common/Tables/Utilities';
import {MultipleValuesInPopup} from 'src/components/common/Tables/Utilities/Recipients';
import {UserNameLink} from 'src/components/common/Tables/Utilities/tableCells/styles';
import {calculatedMfaStatus} from 'src/services/shared/sharedTransforms';
import {IUser} from 'src/types/User';

import {truncatedString} from "src/utils/string";
import {dashboardRoute, isShowingApp, searchParamKeys} from "../../../../routes";
import {DefaultLink} from "../../DefaultLink";
import {GridColumnHeaderParams, GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {stringForMissingText} from "../../../../utils/constants";

export const renderUserName = (
  // params: any,
  name: string,
  pubId: string,
  route: string,
  hasUserUrl?: boolean,
) => {
  return (
    <TruncatableString
      maxLength={maxNameLength}
      truncateFunction={truncatedString}
      // value={params.row.name}
      value={name}
      displayComponent={(truncatedValue: any) =>
        hasUserUrl ? /// row.user.hasUserUrl ?
          (
            // <UserNameLink to={`${route}/${params.row.pubId}`}>
            <UserNameLink to={`${route}/${pubId}`}>
              {truncatedValue}
            </UserNameLink>
          ) : (
            <span>{truncatedValue}</span>
          )
      }
    />
  );
};

export const renderAppName = (
  name: string,
  pubId: string
) => {
  return (
        pubId ?
          (
            <DefaultLink
              to={`${dashboardRoute}?${searchParamKeys.discovery}=${isShowingApp}&${searchParamKeys.appId}=${pubId}`}
              style={{textDecoration: 'none'}}
            >
              <T variant='body2' >
                {name}
              </T>
            </DefaultLink>
          ) : (
            <span>{name}</span>
          )
  );
};

export const renderEmail = (params: any) => {
  // console.log('renderEmail', {params});
  return (
    <TruncatableString
      maxLength={maxEmailLength}
      truncateFunction={truncatedString}
      value={params.row.email}
      displayComponent={
        (truncatedValue: any) =>
          <span>{truncatedValue}</span>
      }
    />
  );
};

export const renderDLP = (params: any) => {
  return (
    <TruncatableString
      maxLength={maxOtherLength}
      truncateFunction={truncatedString}
      value={params.row.policy}
      displayComponent={
        (truncatedValue: any) =>
          <span>{truncatedValue}</span>
      }
    />
  );
};

export const renderSharedItem = (params: any) => {
  return (
    <TruncatableString
      maxLength={maxOtherLength}
      truncateFunction={truncatedString}
      value={params.row.sharedItem}
      displayComponent={
        (truncatedValue: any) =>
          <span>{truncatedValue}</span>
      }
    />
  );
};

export const renderSharedTo = (params: any) => {
  // console.log('renderSharedTo', {params});
  // make an array of emailAddresses from the sharedTo array field
  const emailArray = params.row.sharedTo.map(
    (share: { email: any; }) => share.email
  )
  // console.log('renderSharedTo', {emailArray});
  return (
    <MultipleValuesInPopup valueArray={emailArray} />
  );
};

export const renderLicences = (params: any) => {
  // console.log('renderLicences', {params});
  return (
    <MultipleValuesInPopup
      valueArray={params.row.userLicenceNames}
      valueLabel={'licences'}
    />
  );
};

const PrivilegeRender = ({privilegeLevel}: {privilegeLevel: string}) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        color: privColor(privilegeLevel, theme),
      }}
    >
      {privilegeLevel}
    </Box>);
};

export const renderPrivilege = (params: any) => {
  return (
    <PrivilegeRender privilegeLevel={params.value} />
  );
};

const MfaRender = ({user}: {user: IUser}) => {
  const theme = useTheme();
  const mfaStatus = calculatedMfaStatus(user)
  return (
    <Box
      component="span"
      sx={{
        color: mfaColor(mfaStatus, theme),
      }}
    >
      {mfaStatusDisplayString(mfaStatus)}
    </Box>);
};

export const renderMfa = (params: any) => {
  return (
    <MfaRender user={params.row} />
  );
};

// Provides a column definition for those that are dependent on features
// If the feature is off it will add a "hidden" icon to the grid header,
// and remove the value of the column in any rows
export const featuredColumn = ({title, featureOn, renderCell, valueGetter}:
   {
     title: string,
     featureOn: boolean,
     renderCell?: (row: GridRenderCellParams) => any,
     valueGetter: (params: GridValueGetterParams) => string
   }
) => {
  return {
    renderHeader: (params: GridColumnHeaderParams) => {
      // If the feature isn't on then show a 'hidden' icon and an upgrade tooltip
      if (!featureOn) {
        return (<Tooltip title={`${title} not enabled. Contact us to upgrade.`} arrow placement="top">
                  <span style={{paddingTop: "7px"}}>
                    <span style={{padding: "7px 2px 0 0", display: "block", float: "left"}}>
                      <VisibilityOffIcon/>
                    </span>
                    <span data-testid={`grid-heading-disabled-${title}`}>{title}</span>
                  </span>
                </Tooltip>)
      } else {
        // The feature is on so just return the name of the header
        return (<span data-testid={`grid-heading-${title}`}>{title}</span>);
      }
    },
    // If the feature is on invoke the valuegetter function, if not use the missing value string
    valueGetter: (params: GridValueGetterParams) => featureOn ? valueGetter(params) : stringForMissingText,
    renderCell: featureOn && renderCell ? renderCell : undefined,
  }
}
