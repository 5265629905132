import {styled} from "@mui/material";
import {clickableDivStyles, riskyRed, subtleRed} from "src/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ellipsisColumnWidth = 21;
export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)(
  () => (
    {
      marginLeft: '10px',
      maxWidth: `${ellipsisColumnWidth}px`,
      minWidth: `${ellipsisColumnWidth}px`,
      opacity: 1,
      fontSize: '1.3rem',
      color: riskyRed,
      '&:hover': {
        ...clickableDivStyles,
        color: subtleRed,
      },
    }
  )
);
