import {useCallback} from 'react';
import {privileges, wordForChange} from 'src/utils/constants';
import {PrivilegedAccessSummaryTable} from 'src/pages/PrivilegedAccessPage/PrivilegedAccess/PrivilegedAccessSummaryTable';
import {privilegeRoute} from 'src/routes';

export const PrivilegedAccessComponent = (
  {privilegeSummary}:
    {privilegeSummary: any}
) => {

  // This change became necessary after I messed with the
  // const {path} = useRouteMatch();
  // menus and default home page for the alpha user
  const path = privilegeRoute;

  // These functions calculate paths for router links to relevant pages
  const getHpuLink = useCallback(
    (saasIdentifier: string) => `${path}/role/${privileges.high}/${saasIdentifier}`,
    [path]
  );
  const getPuLink = useCallback(
    (saasIdentifier: string) => `${path}/role/${privileges.some}/${saasIdentifier}`,
    [path]
  );
  const getULink = useCallback(
    (saasIdentifier: string) => `${path}/role/${privileges.none}/${saasIdentifier}`,
    [path]
  );

  const getDriftLink = useCallback(
    (saasIdentifier: string) => `${path}/${wordForChange}/${saasIdentifier}`,
    [path]
  );

  return (
    <PrivilegedAccessSummaryTable
      getHpuLink={getHpuLink}
      getPuLink={getPuLink}
      getULink={getULink}
      getDriftLink={getDriftLink}
      privilegedAccesses={privilegeSummary}
    />
  );
};
