import {gql} from 'graphql-request';
import {userStatusStrings} from 'src/utils/constants';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {nullCollapsableClientPubId} from "./nullCollapsableUtilities";
import {QueryContext} from "../../hooks/TanstackHooks";

const enabledUserCountQuery = gql`
  query EnabledUserCount($clientBoolExp: client_bool_exp)  {
    latest_saas_user_aggregate(
      where: {
        client: $clientBoolExp,
        status: {_eq: ${userStatusStrings.enabled}}
      }
    )
    {
      aggregate{
        count
      }
    }
  }
`;

export const getEnabledUsersCount = async ({accessToken, queryContext}:{
  accessToken: any,
  queryContext: QueryContext}
) => {

  const queryFunction = async (client: any) => {
    return await client.request(
      enabledUserCountQuery,
      {...nullCollapsableClientPubId(queryContext.client.id)},
    );
  };
  const response = await doAuthenticatedQuery(
    queryFunction,
    accessToken
  );

  const result = response.latest_saas_user_aggregate.aggregate.count;
  return result;

}