import {TableCellProps} from '@mui/material';
import React, {FC} from 'react';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {LogoSize, SaasLogoAndName} from 'src/components/SaasLogo';
import {ConnectedSaas, ISaas} from 'src/types/Saas';

type props = TableCellProps & {
  // children?: ReactNode;
  saas?: ISaas | ConnectedSaas | any;
  size?: LogoSize;
  shouldUseCompactVersion?: boolean;
  shouldLinkToSaasView?: boolean;
  shouldShowCalculatedName?: boolean;
};

export const SaasTableCell: FC<props> = ({
  // children,
  saas,
  size,
  shouldUseCompactVersion,
  shouldLinkToSaasView = true,
  ...props
}) => {
  // console.log({saas})
  return (
    <DefaultTableCell
      align="left"
      {...props}
    >
      <SaasLogoAndName
        saas={saas}
        size={size}
        shouldUseCompactVersion={shouldUseCompactVersion}
        shouldLinkToSaasView={shouldLinkToSaasView}
      />
    </DefaultTableCell>
  );
};
