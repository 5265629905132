import {DataGrid} from "@mui/x-data-grid";
import {styled} from "@mui/material/styles";
import {
  dataGridRowBorder,
  tableBorder,
  tableContainerBorderRadius,
} from 'src/theme';

const magicHeaderHeightHack = '56px'; // fragile, but "100%" no worky

type StyledDataGridProps = {
  headerBackgroundColor?: string,
  headerTextColor?: string,
  shouldDrawHeaderOutline?: boolean,
  height?: number
};

// some styling doesn't work in the styled component below
// so we can put these in the grid's sx attribute
export const styledDataGridSxValues = {
  borderRadius: tableContainerBorderRadius,
  // border: shouldUseExportHeader ? 'none' : tableBorder,
  border: 'none',
  width: '100%',
  // marginBottom: '-13px',
  paddingBottom: '0px',
  // backgroundColor: 'red',
}
export const StyledDataGrid = styled(DataGrid)
<StyledDataGridProps>(({
  theme,
  headerBackgroundColor,
  headerTextColor,
  shouldDrawHeaderOutline,
  columnHeaderHeight,
}) => {

  return  {
    // this does nothing
    '.MuiDataGrid-root': {
      fontSize: 'rem',
    },

    '.MuiDataGrid-cell:last-of-type': {
      borderRight: tableBorder,
    },

    '.MuiDataGrid-cell:last-child': {
      borderRight: tableBorder,
    },

    // experiment to prevent header cell title wrapping
    // no worky
    // '.wrapHeader .MuiDataGrid-colCellTitle': {
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    // },

    'div.MuiDataGrid-cell--editing': {
      // backgroundColor: 'red !important',
      // maxHeight: '22px !important',
      // height: '22px !important',
      // padding: '0px !important',
      // marginTop: '15px',
      // marginBottom: '15px',
    },

   'div.MuiDataGrid-cell--editable': {
      // backgroundColor: 'green',
      // maxHeight: '22px !important',
      // height: '22px !important',
      // padding: '0px !important',
      // marginTop: '15px',
      // marginBottom: '15px',
    },

    // widen to accomodate "does not contain"
    // none of these selectors work
    // maybe not a good idea anyway because it could affect all
    // operators, even the little ones like =, !=, >, >+ etc
    // 'div > .MuiDataGrid-filterFormOperatorInput': {
    // '& div > .MuiFormControl-root.MuiDataGrid-filterFormOperatorInput': {
    // '.MuiDataGrid-filterFormOperatorInput': {
    // '.css-17vwkjt-MuiFormControl-root-MuiDataGrid-filterFormOperatorInput': {
    // '& div > .MuiDataGrid-filterFormOperatorInput': {
    // 'div > .MuiDataGrid-filterFormOperatorInput': {
    // 'div.MuiPaper-root > .MuiDataGrid-panelWrapper > .MuiDataGrid-panelContent': {
    // 'div.MuiPaper-root div.MuiDataGrid-panelWrapper div.MuiDataGrid-panelContent
      // div.MuiDataGrid-filterForm div.MuiFormControl-root
    // div.MuiDataGrid-filterFormOperatorInput': {
    'div.MuiFormControl-root div.MuiDataGrid-filterFormOperatorInput': {
      width: '144px',
      // backgroundColor: 'red',
      // color: 'red',
      // border: '1px solid red',
    },

    // color the column ellipsis menu icon
    '.MuiDataGrid-menuIconButton': {
      color: theme.palette.tableHeaderText,
    },

    '.MuiDataGrid-filterIcon': {
      color: theme.palette.tableHeaderText,
    },

    // NOTE: this hard left-aligns the Applications column.
    // Fragile and why do I have to do it?  Geez.
    // Why is there no other way to left align this column?
    // Also - export button in header experiment
    '.MuiDataGrid-cell:first-of-type': {
      textAlign: 'left',
      // borderLeft: shouldUseExportHeader ? tableBorder : 'none',
      borderLeft: tableBorder,
    },

    // Note: styling MuiDataGrid-root here doesn't seem to work.
    // *** Instead, use e.g. sx={{border: 'none'}}

    'div.MuiDataGrid-main': {
      // ignored width: '100%',
      // backgroundColor: 'red',
      borderRadius: tableContainerBorderRadius,
      // borderBottomLeftRadius: '0px',
      // borderBottomRightRadius: '0px',
    },

    // Gets rid of the little Column Header separators
    '.MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    // Allows the Column Headers and the cells (re the
    // Action column) cells to wrap
    '.MuiDataGrid-cell': {
      textOverflow: "clip",
      whiteSpace: "break-spaces",
      lineHeight: 1.1, // any tighter truncates descenders
    },

    // a little extra padding for the left-most label,
    // usually Application or user name
    'div.MuiDataGrid-columnHeader:first-of-type, div.MuiDataGrid-cell:first-of-type': {
      paddingLeft: '17px',
    },

    // I think this was here when we used to
    // break the
    // '.MuiDataGrid-columnHeaderTitle': {
    //   whiteSpace: "break-spaces",
    // },

    'div > .MuiDataGrid-columnHeader': {
      // fragile, but "100%" doesn't work
      minHeight: magicHeaderHeightHack,
      backgroundColor: headerBackgroundColor ?
        headerBackgroundColor :
        theme.palette.tableHeader,
      color: headerTextColor ?
        headerTextColor :
        theme.palette.tableHeaderText,
      fontSize: theme.more.tableHeaderFontSize,
   },

    'div > .MuiDataGrid-columnHeaders': {
      border: shouldDrawHeaderOutline ? tableBorder : 'none',
      borderTopLeftRadius: tableContainerBorderRadius,
      borderTopRightRadius: tableContainerBorderRadius,
   },

    // 'div > .MuiDataGrid-columnHeader:first-of-type': {
    //   borderLeft: shouldDrawHeaderOutline ? tableBorder : 'none',
    // },
    //
    // 'div > .MuiDataGrid-columnHeader:last-of-type': {
    //   borderRight: shouldDrawHeaderOutline ? tableBorder : 'none',
    // },

    // Row border bottom
    // Thank you Stack Overflow - this seemed impossible
    // This version much simplified from the SO answer
    'div > .MuiDataGrid-cell': {
      borderBottom: dataGridRowBorder,
      // borderCollapse: false,
    },

    // 'div.MuiDataGrid-row': {
    //   borderRight: dataGridRowBorder,
    //   // borderBottomLeftRadius: tableContainerBorderRadius,
    //   // borderBottomRightRadius: tableContainerBorderRadius,
    //   // backgroundColor: 'red',
    // },

    // Need this for the last row.  Why?
    'div.MuiDataGrid-row:last-of-type': {
      borderBottom: dataGridRowBorder,
      borderBottomLeftRadius: tableContainerBorderRadius,
      borderBottomRightRadius: tableContainerBorderRadius,
      // backgroundColor: 'red',
    },

    // Sort Icon
    'svg.MuiDataGrid-sortIcon': {
      color: theme.palette.tableHeaderText,
    },

    /*
     * Remove blue outline - start
     */

    // prevent blue outline in header on click for sort etc
    'div.MuiDataGrid-columnHeader:focus-within, div.MuiDataGrid-columnHeader:focus': {
      outline: 'none'
    },

    // remove the blue outline when focusing on the cell
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },

    // remove the blue outline when focusing on a textfield in the cell
    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
    },

    /*
     * Remove blue outline - end
     */

    // so the DefaultTableContainer padding and the pagination
    // margin don't double up - or something like that.
    'div.MuiDataGrid-footerContainer': {
      marginBottom: '-21px',
    },

    // dynamic row height experiment for valueArray - doesn't work
    // ".MuiDataGrid-row, .MuiDataGrid-root .MuiDataGrid-cell, .rendering-zone": {
    //     maxHeight: "none !important", },
    // ".MuiDataGrid-root .MuiDataGrid-window": {
    //     position: "relative !important",
    // },
    // ".MuiDataGrid-root .MuiDataGrid-viewport": {
    //     maxHeight: "none !important",
    // },
    // ".MuiDataGrid-root": { height: "auto !important", },
  }
  }
);
