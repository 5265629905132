import {useQueryWithAccessToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {UserConfigTable} from "../../../components/common/Tables/UserConfigTable";
import React from "react";
import {usePortalContext} from "../../../hooks/PortalContext";
import {getMfaDisabled} from "../../../services/shared/MFADisabled";
import {getGridHeadingCount} from "../../../components/common/Tables/Utilities";

export const MFADisabledPanel = () => {
  const {clientInfo} = usePortalContext();
  const {isLoading, data} = useQueryWithAccessToken({
    queryKey: [cacheKeys.mfaDisabled],
    queryFunction: getMfaDisabled,
    queryFunctionVariables:{},
    tanstackOptions:{
      enabled: clientInfo?.configuration?.features?.mfa
    }}
  );

  return <>
      <UserConfigTable
        heading={getGridHeadingCount(data, 'MFA Disabled')}
        users={data}
        shouldShowMfaColumn={true}
        shouldSortByPrivilege={true}
        isLoading={isLoading}
      />
  </>;
}