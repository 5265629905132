import atlassian from './atlassian.png';
import bamboohr from './bamboohr.png';
import box from './box.png';
import hibob from './hibob.png';
import docusign from './docusign.png';
import dropbox from './dropbox.png';
import dynamics_365 from './dynamics365.png';
import egnyte from './egnyte.png';
import employment_hero from './employment_hero.png';
import github from './github.png';
import gitlab from './gitlab.png';
import gsuite from './googlews.png';
import googlews from './googlews.png';
import lucid from './lucid.png';
import hubspot from './hubspot.png';
import office_365 from './office365.png';
import okta from './okta.png';
import salesforce from './salesforce.png';
import sendgrid from './sendgrid.png';
import slack from './slack.png';
import smartsheet from './smartsheet.png';
import snow from './snow.png';
import myob from './myob.png';
import monday from './monday.png';
import servicenow from './servicenow.png';
import sumologic from './sumologic.png';
import xero from './xero.png';
import zendesk from './zendesk.png';
import zoom from './zoom.png';

export const saasLogos: Record<string, string> = {
  atlassian,
  box,
  bamboohr,
  docusign,
  dropbox,
  dynamics_365,
  egnyte,
  employment_hero,
  github,
  gitlab,
  gsuite,
  googlews,
  hibob,
  hubspot,
  lucid,
  myob,
  monday,
  office_365,
  office365: office_365, // removing this breaks things
  microsoft365: office_365,
  okta,
  salesforce,
  servicenow,
  slack,
  smartsheet,
  sumologic,
  xero,
  zendesk,
  zoom,
  sendgrid,
  snow,
};
