import {FC, useState} from "react";
import {Typography as T} from "@mui/material";

import {ConfirmDialog} from "../../../../components/common/Dialog/ConfirmDialog";

export const usePasswordSetDialog = ({
  onConfirm,
}: {
  onConfirm: () => void;          // Action to take when confirming
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [password, setPassword] = useState('')
  return {
    dialog: NewUserPasswordDialog({
      onConfirm,
      isOpen,
      setIsOpen,
      password,
    }),
    setIsOpen,
    setPassword,
  };
}

type propTypes = {
  password: string;
  isOpen: boolean;
  setIsOpen: any;
  onConfirm: () => void;
};

const NewUserPasswordDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onConfirm,
  password,
  ...props
}) => {
  return (
    <ConfirmDialog
      isOpen={isOpen}
      isSpinning={false}
      setIsOpen={setIsOpen}
      cancelButtonName="OK"
      onCancelButtonClick={onConfirm}
      heading="New user created"
    >
      <T
        sx={{
          textAlign: 'left',
          marginLeft: '3px',
        }}
      >
        <p>A new user has been created with the following password.</p>
        <T sx={{fontSize: "1.1rem"}}><strong>{password}</strong></T>
        <p>Please make note of this now. The password will be unavailable once this dialog is closed</p>

      </T>
    </ConfirmDialog>

  )
};


