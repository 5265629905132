import {Typography} from '@mui/material';
import {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {useDashboardLayoutFooterStyles} from 'src/components/MainLayout/styles';

export const MainLayoutFooter: FC = () => {
  const {
    footerContainer,
    footerContent,
    footerCopyright,
    footerLink
  } = useDashboardLayoutFooterStyles();

  return (
    <div className={footerContainer}>
      <div className={footerContent}>
        <Typography className={footerCopyright}>
          Copyright Detexian {new Date().getFullYear()} All Rights Reserved
        </Typography>
        <NavLink className={footerLink} to="/terms-and-conditions">
          Terms & Conditions
        </NavLink>
        <NavLink className={footerLink} to="/privacy-policy">
          Privacy Policy
        </NavLink>
      </div>
    </div>
  );
};
