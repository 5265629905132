import {Route, Routes} from 'react-router';
import {RoutesManagerAuthException} from 'src/RoutesManager/RoutesManagerCompany/RoutesManagerAuthException';
import {RoutesManagerPrivilege} from 'src/RoutesManager/RoutesManagerCompany/RoutesManagerPrivilege';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {BusinessEmailPage} from 'src/pages/BusinessEmailPage';
import {DataSharingPage} from 'src/pages/DataSharingPage';
import {CompanyNavigationBar} from 'src/components/NavigationBar';
import {DefaultStack} from 'src/components/common/DefaultStack';
import {
  authenticationRouteFragment,
  sharingRouteFragment,
  privilegeRouteFragment,
  userAccessRouteFragment,
  forwardsRouteFragment,
} from 'src/routes';
import {RouteNotFound} from 'src/RoutesManager/index';
import {RoutesManagerUserAccessException} from 'src/RoutesManager/RoutesManagerCompany/RoutesManagerUserAccessException';

// For /company/...
export function RoutesManagerCompany(): JSX.Element {
  return (
    <DefaultStack isFlexItem>
      <CompanyNavigationBar />
      <DefaultStack isFlexItem>
        <Routes>
          <Route
            // The asterisk avoids a warning but how do
            // the Routes below still get hit?
            path={`/*`}
            element={
              <PrivateRoute
                // component={CompanyPage}
                component={RoutesManagerPrivilege}
              />
            }
          />
          <Route
            path={`${privilegeRouteFragment}/*`}
            element={
              <PrivateRoute component={RoutesManagerPrivilege}/>
            }
          />

          <Route
            path={`${authenticationRouteFragment}/*`}
            element={
              <PrivateRoute
                component={RoutesManagerAuthException}
              />
            }
          />
          <Route
            path={`/${userAccessRouteFragment}/*`}
            element={
              <PrivateRoute
                component={RoutesManagerUserAccessException}
              />
            }
          />
          <Route
            path={sharingRouteFragment}
            element={
              <PrivateRoute
                component={DataSharingPage}
              />
            }
          />
          <Route
            path={forwardsRouteFragment}
            element={
              <PrivateRoute
                component={BusinessEmailPage}
              />
            }
          />
          <Route
            path="*"
            element={<>
              <h3>RoutesManagerCompany</h3>
              <RouteNotFound path={window.location.pathname} />
            </>}
          />
        </Routes>
      </DefaultStack>
    </DefaultStack>
  );
}
