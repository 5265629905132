import React from 'react';
import {UserGrid} from 'src/components/common/Tables/UserGrid/UserGrid';

type props = {
  id?: string;
  users: any;
  heading: any;
  shouldShowSaasColumn: boolean;
  shouldShowServiceColumn?: boolean;
  shouldShowPolicyColumn?: boolean;
  shouldShowSharedToColumn?: boolean;
  sharedToColumnLabel?: string;
  shouldShowSharedItemColumn?: boolean;
  singlePanelUrl?: string; // this may exist if NOT single-panel mode
  dateColumnLabel?: string;
  isLoading?: boolean;
}

export const UserActionTable = ({
  users=[],
  heading,
  shouldShowSaasColumn,
  shouldShowServiceColumn,
  shouldShowPolicyColumn,
  shouldShowSharedToColumn,
  sharedToColumnLabel = 'Shared to',
  shouldShowSharedItemColumn,
  singlePanelUrl, // this may exist if NOT single-panel mode
  dateColumnLabel = 'Date',
  isLoading = false,
}: props) => {

  return <UserGrid
        rows={users}
        heading = {heading}
        shouldShowSaasColumn={shouldShowSaasColumn}
        shouldShowSharedToColumn={shouldShowSharedToColumn}
        sharedToColumnLabel={sharedToColumnLabel}
        shouldShowSharedItemColumn={shouldShowSharedItemColumn}
        shouldShowPolicyColumn={shouldShowPolicyColumn}
        shouldShowServiceColumn={shouldShowServiceColumn}
        shouldShadeRowForDisabledUser={false}
        dateColumnLabel={dateColumnLabel}
        singlePanelUrl={singlePanelUrl}
        isLoading={isLoading}
      />
}
