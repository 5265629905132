import {ISaas, ISaasGraphQLResponse, PrivilegeLevelUserData} from "src/types/Saas";
import {ISaasUser} from "src/types/SaasUser";
import {stringForMissingUserName} from "src/utils/constants";
import {combineSaasNicknameWithName} from "src/utils/saasUtils";

export const findNoOfUsersData = (
  privilegeLevels: PrivilegeLevelUserData[] | undefined,
  levelToFind: string,
  attribute = 'enabled'
): number => {
  // console.log({privilegeLevels})
  const privilegeLevel = privilegeLevels && privilegeLevels?.find(
    ({privilege_level}) => privilege_level === levelToFind
  );

  return (privilegeLevel && privilegeLevel[attribute]) || 0;
};

// Another version without the attribute test
export const findUsersWithLevel = (
  users: PrivilegeLevelUserData[],
  levelToFind: string
) => {
  const result = users.filter(
    ({privilege_level}) => privilege_level === levelToFind
  );
  return result;
}

// This is Duong's
export const transformSaasResponse = ({
  name,
  description,
  pub_id,
}: ISaasGraphQLResponse): ISaas => ({
  identifier: name,
  name: description,
  pubId: pub_id,
});

// Maybe mine is better, with its handling of missing saas
// Note - for reasons that should be clearer, some sharers
// of this transform don't have a saas but call it anyway
export const transformConnectedSaas = (
  saas: any,
  connection?: any,
) => {
  // console.log('transformedSaas args', {saas}, {connectionId});
  if (saas === undefined) {
    // console.warn("transformedSaas = saas argument undefined");
    return {};
  }

  const flatSaas = transformConnectedSaasFlat (saas, connection);
  return {
    saas: {...flatSaas}
  };
};

// Should probably change the users of the above to use this one.
// I wonder what horrors the above perpetrated upon the pages.
export const transformConnectedSaasFlat = (
  saas: any,
  connection?: {
    nick_name: string | undefined;
    pub_id: any;
    secret_version: Array<any>;
  }
) => {
  if (!saas) {
    return null;
  }
  const calculatedName = combineSaasNicknameWithName(
    saas.description,
    connection?.nick_name
  );

  return {
      pubId: saas?.pub_id,
      identifier: saas?.name,
      name: saas?.description,
      connectionId: connection?.pub_id,
      nickname: connection?.nick_name,
      secret_version: connection?.secret_version,
      calculatedName,
  };
};

export const transformSaasFlat = (
  saas: any,
) => {
  // console.log('transformedSaasFlat', {saas});
  if (saas === undefined) {
    // console.warn("transformedSaasFlat = saas argument undefined");
    return {};
  }
  return {
    pubId: saas.pub_id,
    identifier: saas.name,
    name: saas.description,
  };
};

// utility, used by the UI, to get MFA status
// based on transformedUser below
export const calculatedMfaStatus = (user: ISaasUser | any) => {
  const result = user?.federatedUser ?
    user.federatedUser.mfaStatus :
    user.mfaStatus;
  // console.log(
  //   {result},
  //   {user},
  //   'mfaStatus', user.mfaStatus,
  //   'federatedUser', user.federatedUser,
  // )
  return result;
}

export const transformUser = (
  user: any,
): ISaasUser | any => {
  if (!user) {
    console.error('transformedUser - no user');
    // added this after discovering occasional null users
    // so far only spotted in DLPs  3 Nov 22
    return {
      pubId: '',
      name: 'null user',
      email: '',
    };
  }
  // This is shared by transforms shared between
  // the saas query and the user query.  The sass
  // has a generic_user but the user doesn't
  // so we have to fiddle with hasUserUrl and pubId
  // as follows:
  const pubId = user.generic_user?.pub_id;
    // user.generic_user.pub_id :
    // user.unique_id;

  // hasUserUrl indicates that the user name is navigatable
  // that's only true if there's a generic_user.pubId
  const hasUserUrl = !!(pubId
    // user.generic_user &&
    // !!pubId &&
    // pubId !== undefined // undefined was slipping through the cracks
  );
  // console.log('transformedUser', {user}, {hasUserUrl}, {pubId});

  // we preserve the idea that if federated_user is null
  // the user is not federated
  const federatedUser = user.federated_user ? {
    mfaStatus: user.federated_user?.mfa_status,
  } : undefined;

  const uniqueId = user?.connection?.pub_id !== undefined ?user.connection.pub_id : user?.pub_id;

  return {
    pubId,
    uniqueId: `${uniqueId}-${user?.unique_id}`, // for React and Data Grid indexes
    userId: user?.unique_id,
    hasUserUrl,
    name: user.name || stringForMissingUserName,
    email: user.email,
    privilegeLevel: user.privilege_level,
    domain: user.domain,
    inactive60Days: user.inactive_60days,
    // see utility function, calculatedMfaStatus, above
    mfaStatus: user.mfa_status,
    federatedUser,
    status: user.status,
    lastLogin: user.last_login,
    genericUser: user.generic_user,
    saas: transformConnectedSaasFlat(
      user.saas,
      user.connection
    )
  };
};

// Some queries return records lacking any field which
// React and MUI Data Grid can use as a unique ID.
// Christian suggests query mods: "get the right pub_id".

// In the meantime, the transforms below now use this
// variable as an incrementable, temporary, unique id:
// id: uniqueId++.  Smells a bit but works well.

// One downside, we can no longer rely on React to give
// screaming notification of duplicates.

let uniqueId = 0;

export const transformPermissionStatus =
  (aLatestUser: any):ISaasUser => {
  // console.log('transformPermissionStatus', {aLatestUser});
  const lu = aLatestUser;
  if (lu === null || lu === undefined) {
    console.error("transformPermissionStatus - no user");
    throw new Error('transformPermissionStatus = no user');
  }
  const result = {
    id: uniqueId++,
    ...transformUser(lu),
    ...transformConnectedSaas( // without this, no saas logo
      lu.saas,
      lu.connection,
    ),
  };
  // console.log('transformPermissionStatus', {result});
  return result;
};

/* Query result structure

mail_forward": [
 {
    "latest_saas_user": {
      "saas": {...},
      "name": "Adrian Kitto",
      "email": "adrian@labsdetexian.onmicrosoft.com",
      "privilege_level": "Highly Privileged User"
    },
    "primary_email": "adrian@labsdetexian.onmicrosoft.com",
    "mail_forward_recipients": [
      {
        "first_detected": "2021-09-04T15:57:21",
        "email": "a@b.c"
      },
      ...
    ]
  }
]
*/

export const transformMailForward = (mailForward: any) => {
  const mf = mailForward;
  if (mf === null || mf === undefined) {
    console.error("transformMailForward - no mail forward record");
    return;
  }
  // console.log('transformMailForward', {mf});

  // get the detected time from the first recipient record
  const mailForwardInfo = mf.mail_forward_recipients?.[0];

  const result = {
    id: uniqueId++,
    ...transformUser(mf.latest_saas_user),
    ...transformConnectedSaas(
        mf.latest_saas_user.saas,
        mf.latest_saas_user.connection,
      ),
    service: mf.service,
    // email: mf.primary_email, // overwrite the user fragment value. why?  Christian suggests no.
    date: mailForwardInfo?.first_detected,
    sharedTo: mf.mail_forward_recipients,
  };
  return result;
};

/* Query result structure:

"file_share":
  [
    {
      "latest_saas_user":{4 items
      "saas":{...}3 items
      "name":"Adrian Kitto"
      "email":"adrian@detexianlabs.com"
      "privilege_level":"Highly Privileged User"
      }
      "created_time":"2021-05-04T02:42:19":
      "file_share_recipients":[see mail_forward, above]
    }
    ...
  ]
*/

export const transformExternalShare = (fileShare: any) => {
  const fs = fileShare;
  if (fs === null || fs === undefined) {
    console.error("transformFileShare - no file share record");
    return;
  }
  // console.log('transformFileShare', {fs});

  const result = {
    id: uniqueId++,
    ...transformUser(fs.latest_saas_user),
    ...transformConnectedSaas(
        fs.latest_saas_user.saas,
        fs.latest_saas_user.connection
      ),
    service: fs.service,
    date: fs.created_time,
    sharedItem: fs.shared_item,
    sharedTo: fs.file_share_recipients,
  };
  return result;
};
