import {DefaultTable, DefaultTableContainer} from 'src/components/common/DefaultTable';
import {
  UserAccessExceptionSummaryTableRow
} from 'src/pages/UserAccessExceptionsPage/UserAccessExceptionSummaryTable/UserAccessExceptionSummaryTableRow';
import {ISaasException, ISaasExceptions} from 'src/types/SaasExceptions';
import {displayTitleForEnabledUsers, privilegeStrings} from 'src/utils/constants';
import {ConnectedSaas} from "src/types/Saas";
import {tableCellHeadings} from "../../../utils/constants";

const columns = [
  {
    id: 'application',
    label: tableCellHeadings.saas,
    // width: '100', // doesn't seem to do anything
  },
  {
    id: 'exceptions',
    label: 'Exceptions',
  },
  {
    id: 'hpu',
    label: privilegeStrings.highlyPrivileged,
  },
  {
    id: 'pu',
    label: privilegeStrings.privileged,
  },
  {
    id: 'eu',
    label: displayTitleForEnabledUsers,
  },
];

type props = {
  saasesExceptions: ISaasExceptions[];
  connectedSaases: ConnectedSaas[];
};

// Makes a table with three rows for each SaaS
export function UserAccessExceptionSummaryTable({
  saasesExceptions, // array of arrays
  connectedSaases,
}: props): JSX.Element {

  return (
    <DefaultTableContainer shouldUseTableContainer={false}>
      <DefaultTable
        columns={columns}
        columnAlignment='center'
      >
        {saasesExceptions.map(
          (saasExceptions, index) => {
            const {exceptions, saas, connectedSaas} = saasExceptions;
            return exceptions.map(
              (
                exception: ISaasException,
                index: number
              ) => (
                <UserAccessExceptionSummaryTableRow
                  key={exception.name}
                  saas={saas}
                  // @ts-ignore
                  connectedSaas={connectedSaas}
                  saasException={exception}
                  noOfExceptions={exceptions.length}
                  showSaasLogo={index === 0}
                />
              )
            )
          }
        )}
      </DefaultTable>
    </DefaultTableContainer>
  );
}
