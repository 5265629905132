import '@fontsource/lato';
import {createTheme} from '@mui/material/styles';

// bad idea - use a context per MUI's sample
let isDarkMode = window.localStorage.getItem('shouldUseDarkMode')
isDarkMode = isDarkMode ? JSON.parse(isDarkMode) : false;

export const clickableDivStyles = {
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    // backgroundColor: 'none',
    opacity: 0.6,
  },
};

// better to define it here than in the theme
// otherwise everything gets this odd radius.
// Used for table and Nav bar containers
export const majorContainerBorderRadius = '20px';

// without this TypeScript complains about references
// to palette.tableHeader in our legacy MUI code
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tableHeader: string;
    tableHeaderText: string;
  }
  interface PaletteOptions {
    tableHeader: string;
    tableHeaderText: string;
  }
}

// Without the Theme interface, TypeScript complains about
// references to theme.defaultShadow. Without the ThemeOptions
// interface TypeScript doesn't allow defining defaultShadow
// in the theme.  The DeprecatedThemeOptions seems to just be
// a bookkeeping technique and has no apparent effect.

declare module '@mui/material/styles' {
  interface Theme {
    defaultShadow: string;
    majorContainerBorderRadius: string;
    more: any;
  }
  interface ThemeOptions {
    defaultShadow: string;
    majorContainerBorderRadius: string;
    more: any;
  }
  interface DeprecatedThemeOptions {
    defaultShadow: string;
    majorContainerBorderRadius: string;
    more: any;
  }
}

export const faintBlue = 'hsl(219, 80%, 90%)'

export const andyBlue =  'hsl(219, 47%, 47%)'; // '#4068B1';
export const andyGreen = 'hsl(125, 45%, 40%)'; // '#399541';

export const riskyHue = '6';
export const riskySaturation = '89%';
export const riskyRed = `hsl(${riskyHue}, ${riskySaturation}, 54%)`;
export const subtleRed = `hsl(${riskyHue}, ${riskySaturation}, 65%)`;

export const borderRadius = '13px';
export const tableContainerBorderRadius = '7px'
export const borderRadiusSmall = '5px';
const sjsOrange = 'hsl(28, 100%, 50%)';
const submitButtonColor = '#02aadc'

export const menuFontSize = '1rem';
export const h3RemFontSize = 1.7; // defined here to divide it by 4 below
export const hoverOpacity = '60%';

// So much table border stuff
export const dividerColor = 'rgba(221, 221, 221, 1)'; // leftover, used below
export const generalBorderLine = '1px solid';
export const tableBorderLine = generalBorderLine;

const generalBorderColor = 'lightgray';
export const generalBorder = `${tableBorderLine} ${generalBorderColor}`

const tableOutsideBorderColor = generalBorderColor;
export const tableColumnBorderColor = tableOutsideBorderColor;
const tableHeaderBottomBorderColor = tableOutsideBorderColor;
const tableRowBorderColor = 'hsl(0, 0%, 80%)';
export const dataGridRowBorderColor = 'hsl(0, 0%, 90%)';
export const tableBorder = `${tableBorderLine} ${dividerColor}`;

export const dataGridRowBorder =
  `${tableBorderLine} ${dataGridRowBorderColor}`
export const tableHeaderBottomBorder =
  `${tableBorderLine} ${tableHeaderBottomBorderColor}`
export const tableRowBorder =
  `${tableBorderLine} ${tableRowBorderColor}`;

// possible model for handling dark mode - currently unused
// export const borderStyle = (theme: Theme) =>
//   `${tableBorderLine} ${
//     theme.palette.mode === 'light' ?
//     theme.more.tableOutsideBorderColor : '#303030'
//   }`

// crude experiment with dark mode
export const componentBackgroundColor = isDarkMode ? 'black' : 'white';

export const widgetAdviceColor = '#343434';

export const linkStyleFragment = {
  textDecoration: "none",
  color: andyBlue,
    ':hover': {
    opacity: hoverOpacity,
  }
}

// widely-used font size
export const compactInfoFontSize = '0.9rem'; // '0.875rem';
const compactInfoFontSizeNumber = 14.5; // pixel version

export const compactRowPadding = '8px';

export const h1FontSize = '3.1rem'
export const h2FontSize = '2.3rem';
export const h5FontSize = '1.1rem';
export const theme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
    fontSize: compactInfoFontSizeNumber,
    h1: {fontSize: h1FontSize, color: andyBlue, fontWeight: 'bold'},
    h2: {fontSize: h2FontSize},
    h3: {
      fontSize: `${h3RemFontSize.toString()}rem`,
      paddingBottom: `${(h3RemFontSize/4).toString()}rem`,
    },

    h4: {fontSize: '1.3125rem'},
    h5: {fontSize: h5FontSize},
    h6: {fontSize: '1.0rem'},
  },

  // This is used here and there but isn't really that useful
  // It seems too restricticve.
  palette: {
    common: {black: '#000', white: '#fff'},
    primary: {main: '#3D68B2', dark: '#1B75BC'},
    secondary: {main: '#7CC957', dark: '#009900'},

    text: {primary: '#333333', secondary: '#999999'},

    background: {default: 'rgb(241, 241, 241)'},

    error: {main: '#FF0000'},
    warning: {main: '#FF9900'},
    success: {main: '#7CC957'},

    divider: dividerColor,

    tableHeader: 'rgba(170, 170, 170, 1)',
    tableHeaderText: 'white',
    action: {selectedOpacity: 0.1}
  },

  shape: {
    // best to not do this here - it causes too many undesired effects
    // borderRadius: 20,
  },

  defaultShadow: '2px 2px 8px rgb(0 0 0 / 30%)',
  majorContainerBorderRadius,

  // Handy place to keep colors, sizes etc outside of
  // MUI's narrow idea of theme. For example, the above
  // two values could go in here.
  // See Discovery code for examples
  more: {
    borderRadius,
    borderRadiusSmall,
    andyBlue,
    faintBlue,
    andyGreen,
    riskyRed,
    subtleRed,
    sjsOrange,
    submitButtonColor,
    tableHeaderFontSize: compactInfoFontSize, // '14px',
    tableOutsideBorderColor,
    summaryHeadingBackgroundColor: '#f2f2f2'
  },

  components: {
    // This prevents the MuiPaper corners peeking out from
    // behind theAdd SaaS dialogs whose border radii are
    // also set to our default radius.

    // I think it works like this: the relevant class name
    // is MuiDialog-paper, thus the paper override inside
    // the MuiDialog object.  Right?

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: borderRadius,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        root: {
          color: andyBlue,
          ...clickableDivStyles
        },
      },
    },

    // mainly for the recipient popup
    MuiPopover: {
      styleOverrides: {
        paper: {borderRadius: tableContainerBorderRadius}
      }
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: compactInfoFontSize,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: compactInfoFontSize,
        }
      }
    },
  },
});




// Additional component stylings from Discovery which might be useful

    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       color: 'black',
    //     }
    //   }
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: 'none',
    //     },
    //   },
    // },
    // MuiAppBar: {
    //   styleOverrides: {
    //     root: {
    //       color: andyBlue,
    //     },
    //   },
    // },
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       // border: tableRowBorder, // makes the outside border thick
    //       // borderCollapse: "unset"
    //     },
    //   },
    // }
    // These don't change the table text color
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
    // These don't change the table text color
    // MuiTableRow: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       color: 'black',
    //     },
    //   },
    // },
