import {useCallback} from 'react';
import {AuthExceptionSummaryTable} from 'src/pages/AuthExceptionsPage/AuthExceptionTables/AuthenticationExceptionSummaryTable';
import {
  useComponentForQueryResult
} from 'src/hooks/UseComponentForQueryResult';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {
  getAuthenticationExceptions
} from 'src/services/AuthExceptions/AuthExceptions';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

export const AuthExceptionsSummaryPage = () => {
  const documentTitle = 'Auth Exceptions Summary - Detexian';
  document.title = documentTitle;

  const queryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.authExceptionsSummary],
      queryFunction: getAuthenticationExceptions,
      queryFunctionVariables: {}
    }
  );
  const pageLoadFunction = useCallback(
    () => {
      return (
        <AuthExceptionSummaryTable
          saasesExceptions={queryResult?.data}
        />
      );
    },
    [queryResult?.data]
 );

  return useComponentForQueryResult({
    queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing Authentication exceptions',
  });
};
