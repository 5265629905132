import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useCardStyles = makeStyles<Theme, { spacing: number }>(
  ({ spacing }) => ({
    cardContainer: ({ spacing: cardSpacing }) => ({
      padding: spacing(3),
      marginBottom: spacing(cardSpacing),
      borderRadius: 25
    })
  })
);
