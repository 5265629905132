import {graphQLClient} from "src/services/shared/GraphQLService";

const stringIdentifyingIDTokenExpiry = 'JWTExpired'

const errorIsAccessTokenExpired = (error: Error) => {
  const message = error?.message;
  console.warn('error message', message.substring(0, 34));
  const searchStartIndex = 21;
  const result = message ?
    message.includes(stringIdentifyingIDTokenExpiry, searchStartIndex) :
    false;
  console.warn({result});
  return result;
}

// get description code from an Error which uses the optional
// 'extensions' objects
// https://hasura.io/blog/handling-graphql-hasura-errors-with-react/#:~:text=side%20React%20app-,Errors,-%3A%20REST%20Vs%20GraphQL

export const firstErrorCodeFromArrayOfErrorExtensions = (
  errors: { extensions: any; }[] | undefined
) => {
  const firstErrorExtension = errors?.[0]?.extensions;
  console.log({firstErrorExtension});
  // result = {
  //   message: firstErrorExtension.message,
  //   code: firstErrorExtension.code
  // }
  const result = firstErrorExtension.code;
  console.log({result});
  return result;
}

// looks for an error in the response per Steve B's suggestion
const errorInResolvedPromise = (
  response: any
) => {
  let result
  if (response.error) {
    console.warn('doQuery', response.error.message);
    result = {
      message: response.error.message,
      code: response.error.code // I've never seen this exist
    }
  }
  else if (response.errors) {
    // We only consider the first error from the array
    // Might present problems later
    const firstError = response.errors[0].extensions;
    result = {
      message: firstError.message,
      code: firstError.code
    }
  }
  return result;
}

export const doAuthenticatedQuery = async (
  queryFunction: Function,
  accessToken: any,
  signal?: any,
) => {
  return doQuery({queryFunction, accessToken, signal})
}

// used by the Azure Marketplace queries
export const doPublicQuery = async ({
  queryFunction,
  signal,
}: {
  queryFunction: Function,
  signal?: any,
}) => {
  return doQuery({queryFunction, signal})
}

// Shared query handler - used by the two functions above
export const doQuery = async ({
  queryFunction,
  accessToken,
  signal,
}: {
  queryFunction: Function,
  accessToken?: any,
  signal?: any,
}) => {
  let client;
  try {
    client = await graphQLClient({accessToken, signal});
  }
  catch (error: any) {
    console.log('throwing because no GraphQL client', {error})
    throw (error);
  }

  let responsePromise: any // {

  // we count on React Query to catch the error
  try {
    // console.log('doPublicQuery about to call theQuery', {theQuery})
    responsePromise = queryFunction(client);

    // we use promise then/catch because await
    // never returns if the promise is rejected

     responsePromise.then((response: any) => {
      // console.log('doPublicQuery', {response})

      // throw on errors in the response per Steve B
      // I've never seen such an error and don't know
      // how they might be formatted.
      const anError = errorInResolvedPromise(response);
      if (anError) {
        console.warn(
          'throwing bc found an error in the response, per Steve B',
          {anError}
        )
        throw new Error(anError?.message);
      }
      // return responsePromise; // makes sense but not needed. Why?
    })
    // catch a rejected promise
    .catch((error: any) => {
      console.warn('promise catch, throwing', {error});
      if (errorIsAccessTokenExpired(error)) {
        console.warn('Auth0 Access Token Expiry');
      }
      throw error;
    });
  }
  // this catches a faulty query function: theQuery
  // or some other non-GraphQL related issue
  catch (error: any) {
    console.log('throwing outside of promise handling')
    throw (error)
  };

  // throw new Error("testing");

  return responsePromise;
};

