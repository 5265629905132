import {TableSortOrder} from 'src/types/tables';

export const getTableOrderDirection = (
  column: string,
  order?: TableSortOrder,
  orderBy?: string
) => {
  if (column !== orderBy) {
    return TableSortOrder.asc;
  } else {
    switch (order) {
      case 'asc':
        return TableSortOrder.desc;
      case 'desc':
        return undefined;
      default:
        return TableSortOrder.asc;
    }
  }
};
