import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps} from '@mui/material/Select';
import React, { useCallback} from 'react';
import {useDefaultSelectStyles} from './styles';
import {FormControl, OutlinedInput} from '@mui/material';

interface IOption {
  label: React.ReactNode;
  value: any;
}

export interface IDefaultSelectProps extends SelectProps {
  options: IOption[];
  helperText?: string;
  renderItem?: any; // (option: IOption, index: number) => React.ReactNode;
  onChange?: any;
}

export const DefaultSelect: React.FunctionComponent<IDefaultSelectProps> = ({
  options,
  placeholder,
  helperText,
  multiple,
  native,
  variant = 'outlined',
  value,
  error,
  label,
  className,
  renderItem,
  ...rest
}) => {
  const {
    selectContainer,
    selectInput,
    selectNotchedOutline,
    selectMenu,
  } = useDefaultSelectStyles();
  const ItemComponent = native ? 'option' : MenuItem;

  const renderItemFn = useCallback(
    ({ label, value }: any, index: number) => {
      const ItemComponent = native ? 'option' : MenuItem;

      return (
        <ItemComponent key={index} value={value}>
          {label}
        </ItemComponent>
      );
    },
    [native]
  );

  return (
    <FormControl color="secondary" className={className}>
      <Select
        displayEmpty
        multiple={multiple}
        native={native}
        variant={variant}
        value={value}
        defaultValue="" // fixes out-of-range warning from MUI
        error={error}
        input={
          <OutlinedInput
            color="secondary"
            classes={{
              root: selectContainer,
              input: selectInput,
              notchedOutline: selectNotchedOutline,
            }}
          />
        }
        MenuProps={{ classes: { paper: selectMenu } }}
        {...rest}
      >
        <ItemComponent value="">{placeholder}</ItemComponent>
        {options.map(renderItem || renderItemFn)}
      </Select>
      {helperText && error && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
