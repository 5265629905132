import {
  Box,
  Paper,
  Popover,
  styled,
  Typography as T,
  useTheme
} from '@mui/material';
import React, {useState} from 'react';
import {maxEmailLength, TruncatableString} from 'src/components/common/Tables/Utilities/TruncatableString';
import {andyBlue, clickableDivStyles, compactInfoFontSize, faintBlue} from 'src/theme';
import {stringForMissingText} from 'src/utils/constants';
import {DtxSpacer} from 'src/components/common/DtxSpacer';

const TypographyButton = styled(T)({
  ...clickableDivStyles,
  fontSize: compactInfoFontSize,
  color: andyBlue,
});

const closeButtonStyle = {
  width: '64px',
  // backgroundColor: theme.palette.background.default,
  marginTop: '0.5rem',
  textAlign: 'center',
  paddingTop: '2px',
  paddingBottom: '1px',
  border: `1px solid ${faintBlue}`,
  borderRadius: '5px',
};

// used by the new data grid tables for multiple
// recipients and licences
//
export const MultipleValuesInPopup = ({
    valueArray,
    valueLabel = 'recipients'
  }: {
    valueArray: any[];
    valueLabel?: string;
  },
) => {
  // console.log('MultipleValuesInPopup', {valueArray});
  const theme = useTheme();
  // copy-confirm popper stuff
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleViewClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(valueArray.toString());
    setIsShowingAllValues(!isShowingAllValues)
  };
  const handlCloseButtonClick = () => {
    setAnchorEl(null);
    setIsShowingAllValues(!isShowingAllValues)
  };

  const [
    isShowingAllValues,
    setIsShowingAllValues,
  ] = useState(false);

  // const id = open ? 'copy confirmation' : undefined;
  let cellContent = <>{stringForMissingText}</>;

  if (valueArray?.length === 1)
    cellContent = (
      <TruncatableString
        value={valueArray[0]}
        maxLength={maxEmailLength}
      />
    );
  else if(valueArray?.length > 1)
    cellContent = (
      <TypographyButton
        onClick={handleViewClick}
      >
        {`${valueArray.length} ${valueLabel} - View`}
      </TypographyButton>
    );
   return <>
    {cellContent}
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlCloseButtonClick}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
        }}
      >
        {
          valueArray.map(
            (value: any) => (
              <T
                key={value}
                sx={{
                  fontSize: compactInfoFontSize,
                  lineHeight: '2.0rem',
                }}
              >
                {value}
              </T>
            ),
          )
        }
        <DtxSpacer />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <TypographyButton
            sx={closeButtonStyle}
            onClick={handlCloseButtonClick}
          >
            Close
          </TypographyButton>
        </Box>
      </Paper>
    </Popover>

  </>;
};


// used by the legacy tables
export const RecipientsInRow = ({
  recipients,
}: any) => {
  // const theme = useTheme();
  const [
    isShowingAllRecipients,
    setIsShowingAllRecipients,
  ] = useState(false);

  let result = <>{stringForMissingText}</>;
  if (recipients?.length === 1)
    // result = <>{sharedTo[0].email}</>;
    result = (
      <TruncatableString
        value={recipients[0].email}
        maxLength={maxEmailLength}
      />
    );
  else if (recipients?.length > 1)
    if (isShowingAllRecipients)
      result = (
        <>
          {
            recipients.map(
              (recipient: any) => (
                <Box
                  key={recipient.email}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                <T
                  key={recipient.email}
                  sx={{
                    fontSize: compactInfoFontSize,
                    lineHeight: '2.0rem',
                  }}
                >
                  {recipient.email}
                </T>
                </Box>
              ),
            )
          }
          <DtxSpacer />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
          <TypographyButton
            sx={closeButtonStyle}
            onClick={() => setIsShowingAllRecipients(!isShowingAllRecipients)}
          >
            Close
          </TypographyButton>
          </Box>
        </>
      );
    else
      result = (
        <TypographyButton
          onClick={() => setIsShowingAllRecipients(!isShowingAllRecipients)}
        >
          {`${recipients.length} recipients - View`}
        </TypographyButton>
      );
  return result;
};

