import React, {ReactNode} from 'react';
import {TotalPanel} from "./TotalPanel";
import {saasCoverageCount} from "../../utils/saasUtils";

export type UserTotalPanelProps = {
  users: any;
  riskName: string;
  riskDescription: ReactNode;
  numberInCircle: number;
  viewAllUrl?: string;
  largeMode?: boolean;
  featureEnabled: boolean;
  isLoading: boolean;
  isError: boolean;
  count: number;
};

export const UserTotalPanel = ({
  users,
  riskName,
  riskDescription,
  numberInCircle,
  viewAllUrl,
  largeMode = false,   // If set to true, panel shows larger count and hides numered circle
  featureEnabled,
  isLoading,
  isError,
  count,
}: UserTotalPanelProps) => {
  const theSaasCoverageCount = saasCoverageCount(users);
  return (
      <TotalPanel
          title={riskName}
          totalCount={count}
          appSplitCount={theSaasCoverageCount}
          description={riskDescription}
          numberInCircle={numberInCircle}
          viewAllUrl={viewAllUrl}
          largeMode={largeMode}
          featureEnabled={featureEnabled}
          isLoading={isLoading}
          isError={isError}
      />

  );
};
