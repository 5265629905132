// This regex matches the built-in html 5 email address check.
// That is a is a willful violation of RFC 5322, which defines
// a syntax for e-mail addresses that is simultaneously too
// strict (before the "@" character), too vague (after the
// "@" character), and too lax (allowing comments,
// whitespace characters, and quoted strings in manners
// unfamiliar to most users) to be of practical use here.

export const isValidEmailAddress = (email = '')  => {
  const result = !!email.match(
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    ) &&
    email !== "" &&
    email !== undefined &&
    email !== null;
  return result;
};

export const isFieldValueNonEmpty = (value: any) => {
  const result = value !== null &&
    value !== undefined &&
    value !== ''
  return result;
}; // check for existence
