import {StyledGeneralContainer} from 'src/components/common/styles';
import {NavigationBarMainViewSelect} from './NavigationBarMainViewSelect';
import {useNavigationBarContainerStyles} from './styles';

export function MainNavigationBar(): JSX.Element {
  const {mainViewSelect} = useNavigationBarContainerStyles();

  return (
    // <Paper className={container}>
    <StyledGeneralContainer>
      <div className={mainViewSelect}>
        <NavigationBarMainViewSelect />
      </div>
    </StyledGeneralContainer>
     // </Paper>
  );
}
