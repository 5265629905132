import {Box, TablePagination, Typography} from '@mui/material';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {clickableDivStyles, compactInfoFontSize} from 'src/theme';

/*
Usage

import {useState} from 'react';
import {
  DtxPagination,
  itemsToDisplay
} from 'src/components/tables/shared/DtxPagination';

  items = getArrayOfItems(); // your item array

  const pageState = useState(0);
  const [page] = pageState;
  const rowsPerPage = 10;

  return (
    <DefaultTableContainer>
      <DefaultTable columns={columns}>
        {
          itemsToDisplay(
            items,
            rowsPerPage,
            page
          ).map((saasPrivilegedChange) => (
            <DefaultTableRow
              ...
            />
          ))
        }
      </DefaultTable>
      <DtxPagination
        items={items}
        rowsPerPage={rowsPerPage}
        pageState={pageState}
      />
*/

export const itemsToDisplay = (
  items: any[],
  rowsPerPage: number,
  page: number
) => rowsPerPage > 0 ?
  items.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  ) :
  items;

type props = {
  items: any[];
  rowsPerPage: number;
  pageState: [number, Function];
  shouldUseCompactVersion?: boolean;
  singlePanelUrl?: string;
};

export const DtxPagination = ({
  items,
  rowsPerPage,
  pageState,
  shouldUseCompactVersion,
  singlePanelUrl,
}: props) => {
  const [page, setPage] = pageState;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: '34px',
        minHeight: '34px',
      }}
    >
      { // conditionally show pagination controls
        shouldUseCompactVersion !== true &&
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      }
      { // conditionally show SaaS logo and link
        singlePanelUrl &&
        <DefaultLink
          to={singlePanelUrl}
          sx={{
            /// textDecoration: 'none',
            marginLeft: '21px',
            ...clickableDivStyles,
          }}
        >
          <Typography style={{
            fontSize: compactInfoFontSize,
            marginBottom: shouldUseCompactVersion ? '0px' : '3px'
          }}>
            View All
          </Typography>
        </DefaultLink>
      }
    </Box>
  );
}
