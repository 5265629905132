import {FC, useState} from "react";
import {Box, Dialog, IconButton, Typography as T, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddSaasDialogContainer,
  DialogSaasContainer,
  SaasLogoContainer,
  SharedAddSaasHeading
} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/styles";
import {DefaultButton} from "src/components/common/DefaultButton";
import {SaasLogo} from "src/components/SaasLogo";
import {compactInfoFontSize, dataGridRowBorder} from "src/theme";
import {ConnectedSaas, SaasIdentifier} from "src/types/Saas";
import {goToAddSaasErrorPage, showGuide, UserInputDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/UserInputDialog";
import {saasRequiresNoUserInput} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/ConnectionStructures";
import {getOAuthConnectionRedirect} from "src/services/SaasService/AddSaas";
import {saasBlurb} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/saasBlurb";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {useMondayDialog} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/custom/MondayDialog";
import {useMutationWithAccessToken} from "src/hooks/TanstackHooks";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export type propTypes = {
  open: boolean;
  onClose: () => void;
  saas: ConnectedSaas;
  connectionId?: string;
  nickname?: string;
};


// This is the first step in both Add and Update connection.
// Its operation changes subtly based on the existence of
// a connectionId in the arguments.
export const ConsentDialog: FC<propTypes> = ({
  onClose,
  open,
  saas,
  connectionId,
  nickname,
  ...props
}) => {
  const theme = useTheme();
  const saasIdentifier = saas?.identifier || '';

  const isUpdate = !!connectionId;
  // isUpdate && console.log({isUpdate});

  const {mutate: addOrUpdateOAuthSaas} = useMutationWithAccessToken({
      mutation: getOAuthConnectionRedirect,
      mutationCreationArgs: {saas, connectionId},
      tanstackOptions: {
        enabled: true,
        onSuccess: (data: string | URL | undefined) => {
          console.log('onSuccess', {data});
          if (data !== undefined)
            window.open(data, '_self');
          else
            alert(`Error: getOAuthConnectionRedirect returned no data for ${saas.name}`);
        },
        onError: (error: any) => {
          console.error('onError', {error});
          goToAddSaasErrorPage(
            saas,
            `failed to get OAuth connection redirect for ${saas.name}`
          );
        },
      }
    }
  )

  const [
    isOAuth2CredentialsDialogOpen,
    setIsOAuth2CredentialsDialogOpen
  ] = useState(false);

  const handleClose = () => {
    onClose ()
  }

  // the Monday consent dialog has an extra "Install" button
  const isMondayDotCom = (saas.identifier === SaasIdentifier.monday);
  const {
    isMondayDialogOpen,
    setIsMondayDialogOpen,
    MondayDialog,
  } = useMondayDialog();

  const saasRequiresInfoFromUser = () => {
    return !saasRequiresNoUserInput(saasIdentifier);
  }

  const doConnection = () => {
    handleClose(); // also clears the user inputs
    addOrUpdateOAuthSaas ?
      // @ts-ignore
      addOrUpdateOAuthSaas({connectionId}) :
      console.error('addOAuthSaas mutation undefined');
    // }
  }

  const handleShowGuide = (event: any) => {
    showGuide(saas);
  }

  const handleSubmit = () => {
    if (isMondayDotCom) {
      handleClose();
      setIsMondayDialogOpen(true);
    }
    else if (saasRequiresInfoFromUser()) {
      handleClose();
      setIsOAuth2CredentialsDialogOpen(true);
    }
    else {
      doConnection();
    }
  }

  return (<>
    {/* Only one of these dialogs will be visible */}

    <UserInputDialog
      open={isOAuth2CredentialsDialogOpen}
      onClose={() => setIsOAuth2CredentialsDialogOpen(false)}
      saas={saas}
      connectionId={connectionId}
      nickname={nickname}
    />

    {(isMondayDotCom) &&
      <MondayDialog
        isOpen={isMondayDialogOpen}
        setIsOpen={setIsMondayDialogOpen}
        doConnection={doConnection}
      />
    }

    <Dialog
      // This styles the whole page - the background to the dialog
      // sx={{borderRadius: theme.more.borderRadius}}
      {...props}
      open={open}
    >
      <AddSaasDialogContainer
        width={600} // any bigger than 600 shows scrollers.  Why?
      >
        <SharedAddSaasHeading>
          <DialogSaasContainer>
            <SaasLogoContainer
              spacing={0}
            >
              {saasIdentifier ?
                <SaasLogo
                  saasIdentifier={saasIdentifier}
                />
                :
                'error:'
              }
            </SaasLogoContainer>
            <T
              variant='h3'
              color="textPrimary"
            >
              {isUpdate ? 'Update' : 'Add'}&nbsp;
              {saas?.name ? saas?.name : 'no saas'}
            </T>
          </DialogSaasContainer>
          <IconButton onClick={onClose} size="small">
            <CloseIcon color="primary" />
          </IconButton>
        </SharedAddSaasHeading>
        <T
          sx={{
            margin: '13px',
            fontSize: '0.95rem',
            fontStyle: 'italic',
            color: theme.palette.text.secondary
          }}
        >
          {
            saasBlurb[
              saasIdentifier as keyof typeof saasBlurb
            ]
          }
        </T>
        <Box
          sx={{
            border: dataGridRowBorder,
            borderRadius: theme.more.borderRadius,
            margin: 'auto',
            padding: theme.spacing(2),
          }}
        >
          <form>
          <T
            sx={{textAlign: 'left'}}
          >
            <strong>Detexian</strong> needs your permission to access {saas.name}.
          </T>
          <DtxSpacer />
          <T
            sx={{
              textAlign: 'left',
              fontSize: compactInfoFontSize,
          }}
          >
            By clicking Continue, you acknowledge Detexian will use  {saas.name} information in accordance with our&nbsp;
            <a
              style={{
                textDecoration: 'none',
                color: theme.more.andyBlue,
              }}
              href="https://www.detexian.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>.
          </T>
            <DtxSpacer space={2} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between', // 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
              <DefaultButton
                icon={AddBoxOutlinedIcon}
                onClick={handleShowGuide}
                sx={{
                  marginLeft: '-27px'
                }}
              >
                {saas.name} Setup Guide
              </DefaultButton>
              </Box>
              {/*The buttons*/}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {
                  (saas.identifier === SaasIdentifier.xero) &&
                    <DefaultButton
                      sx={{marginRight: theme.spacing()}}
                      variant="contained"
                      onClick={()=>alert('Disconnect not implemented - awaiting details')}
                    >
                      Disconnect
                    </DefaultButton>
                }
                <DefaultButton
                  sx={{marginRight: theme.spacing()}}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </DefaultButton>
                <DefaultButton
                  sx={{
                    marginRight: 0,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  // type="submit"
                >
                  Continue
                </DefaultButton>
              </Box>
            </Box>
          </form>
        </Box>
      </AddSaasDialogContainer>
    </Dialog>
  </>);
};

