import {FC} from 'react';
import {useParams, useLocation} from 'react-router';
import {Grid} from '@mui/material';
import {getSaasDetails} from 'src/services/SaasService/SaasView';
import {displayTitleForEnabledUsers, privileges} from 'src/utils/constants';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {NoMatchingRecords} from 'src/components/common/NoMatchingRecords';
import {UserRoleTable} from 'src/pages/SingleSaasRolePage/UserRoleTable';
import {cacheKeyForSaasData} from 'src/services/shared/serviceConstants';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

// Shows Users of a certain type: Highly Privileged (HPU),
// Privileged (PU) or ordinary users

export const SingleSaasRolePage: FC = () => {
  const {saasIdentifier} = useParams<{ saasIdentifier: string }>();
  // console.log('SingleSaasRolePage running', {saasIdentifier}, props);

  const {pathname} = useLocation();

  // console.log(
  //   {saasIdentifier},
  //   {connectionId},
  //   'enabled: ', !!inactiveQueryResult.data
  // );
  const {data: saasData, isLoading} = useQueryWithAccessToken({
    queryKey: cacheKeyForSaasData({saasIdentifier}),
    queryFunction: getSaasDetails,
    queryFunctionVariables: {saasIdentifier},
    // tanstackOptions: {}
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  // console.log('SingleSaasRolePage', JSON.stringify(saasData?.highlyPrivilegedUsers));

  const isHpu = pathname.includes(privileges.high);
  const isPu = pathname.includes(privileges.some);

  const usersAndHeading = () => {
    let result = {
      users: saasData && saasData.enabledUsers,
      heading: displayTitleForEnabledUsers
    }
    if (isHpu) {
      result =  {
        users: saasData && saasData.highlyPrivilegedUsers,
        heading: 'Highly Privileged Users'
      }
    }
    else if (isPu) {
      result =  {
        users: saasData && saasData.privilegedUsers,
        heading: 'Privileged Users'
      }
    }
    return result;
  }

  const {users, heading} = usersAndHeading();
  // console.log('SingleSaasRolePage rendering with', {users}, { heading });

  const pageTitle = `${saasData?.name || 'SaaS'} - Detexian`;
  document.title = pageTitle;

  return (
    <>
      {/*<PageTitle title={`SaaS Dashboard - Detexian - ${saasData?.name}`} />*/}
      {saasData && saasIdentifier &&
        <Grid container spacing={1}>
           {users && users.length > 0 ? (
            <Grid item xs={12}>
              <UserRoleTable
                users={users}
                heading={heading}
                saasIdentifier={saasIdentifier}
                saasName={saasData && saasData.name}
              />
            </Grid>)
          : <NoMatchingRecords
               message = {`No ${heading}`}
               saasIdentifier = {saasData.identifier}
            />
          }
        </Grid>
      }
    </>
  );
};

