import {useState} from 'react';
import {GridColDef} from '@mui/x-data-grid';
import {useTheme} from '@mui/material';
import {
  largerRowsPerPageOptions,
} from 'src/components/common/Tables/DefaultGrid/dataGridUtilities';
import {GridHeadingWithExport} from 'src/components/common/Tables/DefaultGrid/GridHeadingWithExport';
import {resolvePathIntoObject} from 'src/utils/other';
import {
  actionColumnIntialValue,
  addReviewItem,
  buildUserAppColumns,
  userCountFieldPath,
} from 'src/pages/DashboardPage/Discovery/Applications/ApplicationsColumnBuilder';
import {
  StyledDataGrid,
  styledDataGridSxValues
} from 'src/components/common/Tables/DefaultGrid/dataGridStyles';
import {accessPath, ApplicationSummary} from 'src/services/Discovery/QueryTypes';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {ConnectedSaas} from 'src/types/Saas';
import {getGridHeadingCount} from "../../../../components/common/Tables/Utilities";
import {useQueryWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {getUserAppCounts} from "../../../../services/Discovery/Discovery";

export const noDataString = ''; // was 'no data'

const addActionForRow = (aRow: ApplicationSummary) => {
  let text = actionColumnIntialValue;
  const accessBooleans = aRow[accessPath]
  if (accessBooleans) {
    // console.log({accessBooleans});
    if (accessBooleans?.file_access) text = addReviewItem(text, 'file');
    if (accessBooleans?.write_access) text = addReviewItem(text, 'write');
    if (accessBooleans?.email_access) text = addReviewItem(text, 'email');
    if (accessBooleans?.calendar_access) text = addReviewItem(text, 'calendar');
  }
  else {
    text = addReviewItem('', noDataString);
  }

  if (
    text !== actionColumnIntialValue &&
    text !== noDataString
  ) {
    text = `Review ${text} access`;
  }
  const userCount = resolvePathIntoObject(
    aRow,
    userCountFieldPath,
    0,
  );
  if (userCount === 0) {
    if (text === actionColumnIntialValue) {
      text = 'Review unused app'
    }
    else text = addReviewItem(text, 'unused app');
  }

  const result = aRow;
  result['action'] = text;
  return result;
}

// add the action column
const addActionColumn = (
  rows: ApplicationSummary[]
) => rows.map((aRow: any) => addActionForRow(aRow))


// filter to obtain either
// - IT-Approved apps or
// - Non-IT-Approved apps

// and modify the columns:
// - identify SaaS (and connected status)
// - optionally add the action column

export const getAppsToDisplay = ({
  apps,
  shouldFilter,
  showEnterprise,
  shouldShowAction, // show action column
} : {
  apps: ApplicationSummary[],
  shouldFilter?: boolean,
  showEnterprise?: boolean,
  shouldShowAction?: boolean
}) => {

  let result = apps;
  if (Array.isArray(apps)) {
    if (shouldFilter) {
      result = result.filter((app) => app.is_enterprise === showEnterprise);
    }

    if (shouldShowAction) {
      result = addActionColumn(result); // add action column
    }
  }
  else {
    result = [];
  }
  return result;
}

export const AppDataGrid = ({
                              apps,
                              columns,
                              panelHeading,
                              defaultPageSize = 20,
                              pageSizeOptions = largerRowsPerPageOptions,
                              isLoading
                            }: props) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });

  return (
    <DefaultTableContainer
      id={'non=it-approved-apps'}
      shouldUseTableContainer={false}
    >
      <StyledDataGrid
        sx={styledDataGridSxValues}
        rows={apps}
        getRowId={(row) => row.pubId + row.pub_id}
        columns={columns}
        // @ts-ignore
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={pageSizeOptions}
        pagination
        hideFooter={apps.length <= paginationModel.pageSize} // could be either - fix that
        disableRowSelectionOnClick={true}
        autoHeight ={true}
        rowSpacingType="border"
        disableColumnMenu={false}
        loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{
              field: userCountFieldPath,
              sort: 'desc',
            }],
          },
        }}
        components={{
          Toolbar: () => (<GridHeadingWithExport
            shouldShowBackToPrevious={false}
            heading={getGridHeadingCount(apps, panelHeading)}
          />),
        }}
      />
    </DefaultTableContainer>
  )
}

export const ItApprovedAppGrid = ({
  userCountFieldPath_ = userCountFieldPath,
  defaultPageSize = 30,
  rowsPerPageOptions = largerRowsPerPageOptions,
  sot,
}: {
  userCountFieldPath_?: string,
  defaultPageSize?: number,
  rowsPerPageOptions?: any,
  sot: ConnectedSaas,

}) => {
  // console.log('ItAproved', {discoveryAppList});
  const theme = useTheme();

  const {data: discoveryAppList, isLoading: appListLoading} = useQueryWithAccessToken({
      queryKey: [cacheKeys.discovery.userAppCounts],
      queryFunction: getUserAppCounts,
      queryFunctionVariables:{}
    }
  )

  const itApprovedApps = getAppsToDisplay({
    apps: discoveryAppList,
    shouldFilter: true,
    showEnterprise: true,
  })

  return (<AppDataGrid
    apps={itApprovedApps}
    columns={buildUserAppColumns({
      theme, countFieldPath: userCountFieldPath_,
      sot,
      forUser: false,
    })}
    panelHeading={'IT-Approved Apps'}
    defaultPageSize={defaultPageSize}
    pageSizeOptions={rowsPerPageOptions}
    isLoading={appListLoading}
  />)
}

export const NonItApprovedAppGrid = ({
  userCountFieldPath_ = userCountFieldPath,
  defaultPageSize = 30,
  rowsPerPageOptions = largerRowsPerPageOptions,
  sot,
}: {
  userCountFieldPath_?: string,
  defaultPageSize?: number,
  rowsPerPageOptions?: any,
  sot: ConnectedSaas,
}) => {
  const theme = useTheme();

  const {data: discoveryAppList, isLoading: appListLoading} = useQueryWithAccessToken({
      queryKey: [cacheKeys.discovery.userAppCounts],
      queryFunction: getUserAppCounts,
      queryFunctionVariables:{}
    }
  );

  const nonItApprovedApps = getAppsToDisplay({
    apps: discoveryAppList,
    shouldFilter: true,
    showEnterprise: false,
    shouldShowAction: true,
  })

  return (<AppDataGrid
    apps={nonItApprovedApps}
    columns={buildUserAppColumns({
      theme,
      sot,
      countFieldPath: userCountFieldPath_,
      forUser: false
    })}
    panelHeading={'Non-IT-Approved Apps'}
    defaultPageSize={defaultPageSize}
    pageSizeOptions={rowsPerPageOptions}
    isLoading={appListLoading}
  />)
}

type props = {
  apps: ApplicationSummary[];
  columns: GridColDef[];
  panelHeading: string;
  defaultPageSize?: number;
  pageSizeOptions?: any; // (largerRowsPerPageOptionsType) | (smallerRowsPerPageOptionsType);
  isLoading: boolean;
}