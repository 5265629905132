import {PrivateRoute} from 'src/components/PrivateRoute';
import {DefaultStack} from 'src/components/common/DefaultStack';
import {Route, Routes, useLocation} from 'react-router';
import {RouteNotFound} from 'src/RoutesManager/index';
import {DashboardPage} from 'src/pages/DashboardPage';

// For /dashboard/...
export function RoutesManagerDashboard(): JSX.Element {
  const {pathname} = useLocation();
  return (
    <DefaultStack isFlexItem>
      {/*<UserNavigationBar />*/}
      {/*<DefaultStack isFlexItem>*/}
        <Routes>
          <Route
            path={'*'}
            element={
              <PrivateRoute
                component={DashboardPage}
              />
            }
          />

          {/*<Route*/}
          {/*  path={`:appId`}*/}
          {/*  element={*/}
          {/*    <PrivateRoute*/}
          {/*      component={SingleUserPage}*/}
          {/*    />*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={``}*/}
          {/*  element={*/}
          {/*    <PrivateRoute*/}
          {/*      component={SingleUserPage}*/}
          {/*    />*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path="*"
            element={
              <>
                <h3>RoutesManagerDashboard</h3>
                <RouteNotFound path={pathname} />
              </>
            }
          />
        </Routes>
      </DefaultStack>
    // </DefaultStack>
  );
}
