import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {daysToInactiveStatus, stringForMissingText} from 'src/utils/constants';

import {isEmptyString} from "src/utils/string";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const formatDateTime = (
  date: string,
  format = 'DD/MM/YYYY - HH:mm',
  stringForInvalidDate = stringForMissingText,
): string => typeof date === 'string' ?
  dayjs(date).format(format) :
  stringForInvalidDate;

export const formatDurationFromNow = (date: string) =>
  dayjs.duration(dayjs().diff(date)).humanize();

export const preferredDateFormat = (date: string) => {
  let result = stringForMissingText;
  if (!isEmptyString(date))
    result = formatDateTime(date, 'DD MMM YYYY');

  return result;
}
export const preferredTimeFormat = (date: string) =>
  formatDateTime(date, 'h:mma', ''); // don't return a dash for time

const currentdate = new Date();

export const isoDateWithoutTime = (date: Date) => {
  // Cheap & dirty removal of hr, min, sec, msec from ISO Date string
  // Removes the 'T' and everything after
  const [result] = date.toISOString().split('T');
  // const result = date.toISOString() // this doesn't fix the above
  return result;
}

export const pastMonth = new Date(
  currentdate.setMonth(
    currentdate.getMonth()-1
  )
);

// bug: this one returns an extra month
const pastQuarter = new Date(
  currentdate.setMonth(
    currentdate.getMonth()-3
  )
);

// bug: this one returns an extra three months
const pastYear = new Date(
  currentdate.setFullYear(
    currentdate.getFullYear()-1
  )
);

// bug: we'll never know about this one
const pastCentury = new Date(
  currentdate.setFullYear(
    currentdate.getFullYear()-100
  )
);

export const isInactiveDate = (date: string) => {
  const dateToTest = dayjs(date);
  const inactiveCutoff = dayjs().subtract(daysToInactiveStatus, 'day');
  const result = dateToTest.isBefore(inactiveCutoff)
  return result;
}

export const pastMonthWithoutTime = isoDateWithoutTime(pastMonth);
export const pastQuarterWithoutTime = isoDateWithoutTime(pastQuarter);
export const pastYearWithoutTime = isoDateWithoutTime(pastYear);
export const pastCenturyWithoutTime = isoDateWithoutTime(pastCentury);

// sort an array by 'date' property descending i.e. newest first
export const sortArrayByDateProperty = (
  array: any[],
  dateKey: string = 'date' // usually correct after transform, exception: 'lastLogin'
) =>
  array.sort(
    (a: any, b: any) =>
      new Date(b[dateKey]).getTime() - new Date(a[dateKey]).getTime(),
  );
