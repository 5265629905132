import {styled} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {clickableDivStyles} from "src/theme";

const ellipsisColumnWidth = 21;
export const StyledMoreVertIcon = styled(MoreVertIcon)(
  ({theme}) => (
    {
      // textAlign: 'right',
      // can't seem to center it so nudge it a little to the right
      marginLeft: '10px',
      maxWidth: `${ellipsisColumnWidth}px`,
      minWidth: `${ellipsisColumnWidth}px`,
      opacity: 1,
      fontSize: '1.1rem',
      color: theme.more.andyBlue,
      '&:hover': {
        ...clickableDivStyles,
        color: theme.more.andyBlue,
      },
    }
  )
);
