import {useLayoutEffect, useRef, useState} from 'react';

// Usage

// const {ref, width} = useWidthForRef();

// Then
// add ref={ref} prop to component for which width is desired

// see EmailTableCell

// Note you may have to modify the ref target to foward a ref,
// React.forwardRef, to get this to work.  See DefaultTableCell.

export const useWidthForRef = () => {
  const ref = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(0);

  // Get the table width

  // should we define this function inside useLayoutEffect?
  const recordWidth = () => setWidth(ref?.current?.offsetWidth || 0);

  // Note vanilla useEffect results in width of 0 until resize
  useLayoutEffect(() => {
    recordWidth();
    window.addEventListener(
      'resize',
      recordWidth,
    );
  }, []);
  return {
    ref,
    width,
  };
};
