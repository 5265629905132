import {Link, Typography as T, useTheme} from "@mui/material";
import {linkStyleFragment} from "src/theme";
import React from "react";

const AnyQuestionsOrHelp = () => {
  const theme = useTheme();
  return (
    <T
      sx={{
        color: theme.palette.text.secondary,
        fontSize: '1.0rem',
      }}
    >
      Any question or support:&nbsp;
      <Link
        href="mailto:support@detexian.com"
        sx={{
          ...linkStyleFragment,
          fontSize: '1.0rem',
        }}
      >
        support@detexian.com
      </Link>
    </T>
  );
}

export function Footer() {
  return <>
    <AnyQuestionsOrHelp/>
  </>;
}
