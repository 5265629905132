import {
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import React, {ReactElement, useCallback, useState} from 'react';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
// For conditional row styling. Tell webpack we use these styles
import 'src/components/common/Tables/DefaultGrid/dataGrid.css';
import {CustomFooter} from 'src/components/common/Tables/DefaultGrid/CustomFooter';
import {
  renderDLP,
  renderEmail,
  renderMfa,
  renderPrivilege,
  renderSharedItem,
  renderSharedTo,
  renderUserName,
  featuredColumn
} from 'src/components/common/Tables/DefaultGrid/gridCellRenderers';
import {actionColumnLabel} from 'src/components/common/Tables/Utilities/Ellipsis';
import {mfaStatusDisplayString} from 'src/components/common/Tables/Utilities';
import {GridHeadingWithExport} from 'src/components/common/Tables/DefaultGrid/GridHeadingWithExport';
import {userRouteBase} from 'src/routes';
import {calculatedMfaStatus} from 'src/services/shared/sharedTransforms';
import {stringForMissingText, userStatusStrings, wordForFederated} from 'src/utils/constants';
import {
  appendedFilterOperators,
  largerMinRowsPerPageOption,
  largerRowsPerPageOptions,
  largerRowsPerPageOptionsType,
  renderDate,
  renderSaas,
  smallerMinRowsPerPageOption,
  smallerRowsPerPageOptions,
} from '../DefaultGrid/dataGridUtilities';
import {DefaultGrid} from "../DefaultGrid/DefaultGrid";
import {privilegeKey} from "../../../../services/SaasService/Transforms";
import {renderUserRemediationEllipsis} from "src/components/common/Tables/Utilities/RemediationMenus/UserRemediationMenu";
import {tableCellHeadings} from "../../../../utils/constants";
import {usePortalContext} from "../../../../hooks/PortalContext";

const recipientsValueGetter = ({row}: any) => {
  const recipientArray = row.sharedTo.map(
    (recipient: any) => recipient.email,
  );
  const result = recipientArray.join('\u2029'); // commas mess up the export
  return result;
};

// puts the federated 'yes's on top the first time the user sorts the column
const federationStatusComparator: GridComparatorFn<String> = (v1, v2) => {
  let result = 0;
  if (v1 < v2) result = 1;
  else if (v1 > v2) result = -1;
  return result;
};

type propTypes = {
  id?: string;
  ref?: any;
  rows: any;
  heading: ReactElement;
  tableDescription?: React.ReactNode;
  saas?: any;
  shouldShowSaasColumn?: boolean;
  shouldShowServiceColumn?: boolean;
  shouldShowPolicyColumn?: boolean;
  shouldShowSharedToColumn?: boolean;
  shouldShowSharedItemColumn?: boolean;
  shouldShowPrivilegeColumn?: boolean;
  shouldShowSotColumn?: boolean;
  shouldShowMfaColumn?: boolean;
  shouldShowFederationColumn?: boolean;
  shouldShowStatusColumn?: boolean;
  shouldShowSotLastUpdatedColumn?: boolean;
  shouldShowActionColumn?: boolean;
  shouldShadeRowForDisabledUser?: boolean;
  shouldShowBackToPrevious?: boolean;
  shouldShowExportButton?:boolean;

  dateColumnLabel?: string;
  shouldShowTimeWithDate?: boolean;
  shouldHighlightInactiveDates?: boolean;
  dateFieldKey?: string;

  sharedToColumnLabel?: string;
  defaultPageSize?: largerRowsPerPageOptionsType;
  singlePanelUrl?: string;
  shouldSortByPrivilege?: boolean;
  DomainFilter?: any;

  isLoading?: boolean;
}

export const UserGrid = ({
  id,
  rows,
  heading,
  tableDescription,

  saas,
  shouldShowSaasColumn,
  shouldShowBackToPrevious = true,
  shouldShowExportButton = true,

  // for user actions
  shouldShowServiceColumn,
  shouldShowPolicyColumn,
  shouldShowSharedToColumn,
  shouldShowSharedItemColumn,

  // for user config
  shouldShowPrivilegeColumn = true,
  shouldShowSotColumn,
  shouldShowMfaColumn,
  shouldShowFederationColumn,
  shouldShowStatusColumn,
  shouldShowSotLastUpdatedColumn,

  shouldShowActionColumn,
  shouldShadeRowForDisabledUser,

  dateColumnLabel = 'Date',
  shouldShowTimeWithDate,
  shouldHighlightInactiveDates = false,
  dateFieldKey = 'date', // usually works - the query transforms set it so

  sharedToColumnLabel = 'Shared to',
  defaultPageSize = 50,
  singlePanelUrl,
  shouldSortByPrivilege,

  DomainFilter = () => <span />,

  isLoading = false,
}: propTypes) => {
  const {clientInfo} = usePortalContext();
  const clientFeatures = clientInfo?.configuration?.features;

  // tweak default page size and options for the special case of panels
  // on SaaS View and User View (they will pass a singlePanelUrl)

  const minRowsPerPageOption = singlePanelUrl ?
    smallerMinRowsPerPageOption : largerMinRowsPerPageOption;

  const tweakedDefafultPageSize = singlePanelUrl ?
    smallerMinRowsPerPageOption : defaultPageSize;

  const pageSizeOptions = singlePanelUrl ?
    smallerRowsPerPageOptions : largerRowsPerPageOptions;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: tweakedDefafultPageSize,
    page: 0,
  });

  const hasServiceInfo = useCallback(() => rows?.filter(
    (row: any) => {
      return row.service !== undefined;
    }).length, [rows]);

  const dataGridColumns = useCallback(
    (): GridColDef [] => {
      const shouldShowServiceColumn = () =>
        shouldShowSharedToColumn && hasServiceInfo();

      const federatedColumn =        {
        headerName: 'Federated',
        field: 'calculatedFederationStatus',
        type: 'string',
        // minWidth: 144,
        flex: 0.7,
        ...featuredColumn( {
              title:'Federated',
              featureOn: clientFeatures?.privileges,
              valueGetter: ({row}:{row: any}) => {return row.federatedUser ? wordForFederated : stringForMissingText}
            }
          ),
          sortComparator: federationStatusComparator
      };

      // @ts-ignore
      return [
        ...(
          shouldShowSaasColumn ?
            [{
              headerName: tableCellHeadings.saas,
              field: 'saas',
              // just for export
              valueGetter: ({row}: any) => {
                const result =  row.saas?.name || stringForMissingText;
                return result;
              },
              renderCell: (params: GridRenderCellParams) =>
                renderSaas({params}),
              minWidth: 200,
              flex: 1.3,
            }] : []
        ),

        ...(
          shouldShowServiceColumn() ?
            [{
              headerName: 'Service',
              field: 'service',
              flex: 0.7
            }] : []
        ),

        {
          headerName: 'Name',
          field: 'name',
          renderCell: (params: GridRenderCellParams) =>
            renderUserName(
              params.row.name,
              params.row.pubId,
              userRouteBase,
              params.row.hasUserUrl,
            ),
          // minWidth: 200,
          flex: 1.2,
        },

        {
          headerName: 'Email',
          renderCell: renderEmail,
          field: 'email',
          minWidth: 240,
          flex: 1.3,
          filterOperators: appendedFilterOperators(),
        },

        ...(
          shouldShowPrivilegeColumn ?
            [{
              headerName: 'Role',
              field: 'privilegeLevel',
              minWidth: 144,
              flex: 1.0,
              ...featuredColumn( {
                title:'Role',
                featureOn: clientFeatures?.privileges,
                renderCell: renderPrivilege,
                valueGetter: ({row}:{row: any}) => {return row.privilegeLevel}
              }),
            }] : []
        ),

        ...(
          shouldShowSotColumn ?
            [{
              headerName: 'SoT Status',
              field: 'statusInSot',
              minWidth: 144,
              flex: 1.1,
              ...featuredColumn({
                title: 'SoT Status',
                featureOn: clientFeatures?.user_status,
                valueGetter: ({row}: { row: any }) => {
                  return row.statusInSot || stringForMissingText
                }
              }),
            }] : []
        ),

        ...(
          shouldShowMfaColumn ?
            [
              {
                headerName: 'MFA',
                field: 'mfaStatus',
                flex: 0.7,
                ...featuredColumn({
                    title: 'MFA',
                    featureOn: clientFeatures.mfa,
                    renderCell: renderMfa,
                    valueGetter: ({row}:{row: any}) => mfaStatusDisplayString(calculatedMfaStatus(row))
                  }
                ),
              },
              // also show the federated column
              federatedColumn,
            ]:[]
        ),

        ...(
          shouldShowFederationColumn &&
          !shouldShowMfaColumn // don't show the federated column twice
          ? [
              federatedColumn,
            ]:[]
        ),
        ...(
          shouldShowStatusColumn ?
          [{
            headerName: 'Status',
            field: 'status',
            minWidth: 144,
            flex: 1.1,
            ...featuredColumn({
                title: 'Status',
                featureOn: clientFeatures.user_status,
                valueGetter: ({row}:{row: any}) => {return row.status || stringForMissingText}
            }),
          }]:[]
        ),

        ...(
          shouldShowSharedToColumn ?
          [{
            headerName: sharedToColumnLabel,
            field: 'sharedTo',
            renderCell: renderSharedTo,
            valueGetter: recipientsValueGetter,
            minWidth: 200,
            flex: 1.3,
          }] : []
        ),

        ...(
          shouldShowSharedItemColumn ?
          [{
            headerName: 'Shared Item',
            renderCell: renderSharedItem,
            field: 'sharedItem',
            minWidth: 200,
            flex: 1.7,
          }] : []
        ),

        ...(
          shouldShowPolicyColumn ?
          [{
            headerName: 'Policy Match',
            field: 'policy',
            renderCell: renderDLP,
            minWidth: 200,
            flex: 1.7,
          }] : []
        ),

        ...(
          shouldShowSotLastUpdatedColumn ?
          [{
            headerName: 'Detected',
            field: 'sotLastUpdatedTime',
            renderCell: renderDate,
            minWidth: 150,
            flex: 1,
          }] : []
        ),

        {
          headerName: dateColumnLabel,
          field: 'date',
          valueGetter: ({row}: any) => row[dateFieldKey],
          renderCell: (params) => renderDate(
            params,
            shouldHighlightInactiveDates &&
            !isUserNotEnabled(params.row)
          ),
          // minWidth: 120,
          // Less than 0.6 and the right border disappears. Why?
          // maybe the hard-coded sizing on the DateTableCell
          // and FormatedDate
          maxWidth: 150,
          flex: 0.8,
        },
        ...(
          shouldShowActionColumn ?
          [{
            align: 'right',
            headerName: actionColumnLabel,
            field: '',
            renderCell: (params: GridRenderCellParams) =>
              renderUserRemediationEllipsis(params, saas),
            minWidth: 65,
            sortable: false,
            disableExport: true,
            disableReorder: true,
            filterable: false,
            disableColumnMenu: true,

            flex: 0.18,
          }] : []
        ),

      ];
    },
    [shouldShowSaasColumn, shouldShowPrivilegeColumn, shouldShowSotColumn, shouldShowMfaColumn, shouldShowFederationColumn, shouldShowStatusColumn, shouldShowSharedToColumn, sharedToColumnLabel, shouldShowSharedItemColumn, shouldShowPolicyColumn, shouldShowSotLastUpdatedColumn, dateColumnLabel, shouldShowActionColumn, hasServiceInfo, dateFieldKey, shouldHighlightInactiveDates, saas, clientFeatures]
  );

  function isUserNotEnabled(row: any) {
    const status = row.user ?
      row.user.status : // is this one ever used?  TODO check
      row.status;
    const result = status && status !== userStatusStrings.enabled
    return result;
  }

  return <>
    <DefaultTableContainer
      id={id}
      shouldUseTableContainer={false}
    >
      <DefaultGrid
        rows={rows}
        getRowId={(row: {
          connectionId: any;
          pubId: any;
          uniqueId: any;
          id: any; }
        ) =>
          row.connectionId +
          row.pubId +
          row.uniqueId +
          row.id
        }
        columns={dataGridColumns()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={pageSizeOptions}
        length={rows?.length}
        minRowsPerPageOption={minRowsPerPageOption}
        initialState={{
          sorting: {
            sortModel: shouldSortByPrivilege ?
              [{
                field: privilegeKey,
                sort: "asc",
              }]
              :
              [{
                field: "date",
                sort: "desc",
              }]
            ,
          },
        }}

        rowClassName={(params) => {
          // this greys out (reduces opacity) for
          // disabled and deleted users
          const row = params.row
          return shouldShadeRowForDisabledUser && isUserNotEnabled(row) ?
            `NonEnabledUserRow` : '';
        }}
        toolbar={() =>
          <GridHeadingWithExport
            heading={heading}
            singlePanelUrl={singlePanelUrl}
            shouldShowBackToPrevious={shouldShowBackToPrevious}
            shouldShowExportButton={shouldShowExportButton}
            tableDescription={tableDescription}
          />
        }
        footer={() => CustomFooter({
          singlePanelUrl,
          DomainFilter,
        })}
        isLoading={isLoading}
      />
    </DefaultTableContainer>
  </>;
};

