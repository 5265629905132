export enum TableSortOrder {
  asc = 'asc',
  desc = 'desc'
}

export interface TableSort {
  order: TableSortOrder | undefined;
  orderBy: string | undefined;
}

export interface ColumnData {
  id: string;
  label: string;
  width?: string | number;
  isSortable?: boolean;
  columnAlignOverride?: string; // SJS
}

export type RowData = {
  id: string;
  [column: string]: unknown;
};
