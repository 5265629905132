import {FC, useCallback, useState} from 'react';
import {useParams} from 'react-router';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {getConnectedSaases} from 'src/services/SaasService/Saases';
import {getGhosts} from 'src/services/shared/Ghosts';
import {getSaasDetails} from 'src/services/SaasService/SaasView';
import {getInactiveUsers} from 'src/services/shared/InactiveUsers';
import {getMfaDisabled} from 'src/services/shared/MFADisabled';
import {SingleSaasComponent} from './SingleSaasComponent';
import {
  cacheKeyForGhosts, cacheKeyForInactiveUsers, cacheKeyForMfaDisabled,
  cacheKeyForSaasData, cacheKeys,
} from 'src/services/shared/serviceConstants';
import {connectedSaasForConnectionId} from "src/utils/saasUtils";
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";
import {usePortalContext} from "../../hooks/PortalContext";

// handles data aquisition and decides whether to
// return the page, loading or error component
export const SingleSaasPage: FC = () => {
  const {saasIdentifier, connectionId} = useParams<
    {
      saasIdentifier: string | undefined;
      connectionId: string | undefined;
    }
  >();

  const [userErrorMessage, setUserErrorMessge] = useState('');

  // console.log({saasIdentifier}, {connectionId});

  // These three queries are chained in a way
  // The last will only be enabled when the other
  // two have received data.  This allows a simple
  // implementation of useComponentForQueryResult
  // but may result in errors in the first two going
  // undetected.
  const {clientInfo} = usePortalContext();

  const {data: connectedSaases} = useQueryWithAccessToken({
      queryKey: [cacheKeys.connectedSaasList],
      queryFunction: getConnectedSaases,
      queryFunctionVariables:{},
      tanstackOptions: {
        onError: () => {
          setUserErrorMessge('error accessing source of truth');
        }
      }
    }
  );

  // TODO passing both, redundant, should only need connectionId
  const sharedQueryVariables = {
      saasIdentifier,
      connectionId,
      sot : clientInfo.sourceOfTruth
  };

  const ghostQueryResult = useQueryWithAccessToken({
    queryKey: cacheKeyForGhosts(sharedQueryVariables),
    queryFunction: getGhosts,
    queryFunctionVariables:sharedQueryVariables,
    tanstackOptions:{
      enabled: !!connectedSaases,
      onError: () => {
        setUserErrorMessge('error accessing ghosts');
      }
    }}
  );

  // this is here so SingleSaasComponent has a cached
  // result for its own query
  const mfaDisabledResult = useQueryWithAccessToken({
    queryKey: cacheKeyForMfaDisabled(sharedQueryVariables),
    queryFunction: getMfaDisabled,
    queryFunctionVariables:sharedQueryVariables,
    tanstackOptions:{
      onError: () => {
        setUserErrorMessge('error accessing mfa disabled');
      }
    }}
  );

  // this is here so SingleSaasComponent has a cached
  // result for its own query
  const inactiveQueryResult = useQueryWithAccessToken({
    queryKey: cacheKeyForInactiveUsers(sharedQueryVariables),
    queryFunction: getInactiveUsers,
    queryFunctionVariables:sharedQueryVariables,
    tanstackOptions:{
      enabled: !!mfaDisabledResult.data,
      onError: () => {
        setUserErrorMessge('error accessing ghosts');
      }
    }}
  );

  const saasQueryResult = useQueryWithAccessToken({
    queryKey: cacheKeyForSaasData(sharedQueryVariables),
    queryFunction: getSaasDetails,
    queryFunctionVariables: sharedQueryVariables,
    tanstackOptions: {
      enabled: !!inactiveQueryResult.data,
      onError: () => {
        setUserErrorMessge('error accessing saas details');
      }
    }
  });

 // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () => {
      const connectedSaas = connectedSaasForConnectionId({
        identifier: saasIdentifier,
        connectionId: connectionId,
        connectedSaases: connectedSaases
      })

      return (
        <SingleSaasComponent
          saasData={saasQueryResult.data}
          connectedSaas={connectedSaas}

          // not strictly needed right now
          connectedSaases={connectedSaases}

          connectionId={connectionId}
          ghosts={ghostQueryResult.data}
          firstLoadMfaDisabled={mfaDisabledResult.data}
          firstLoadInactive={inactiveQueryResult.data}
          sot={clientInfo.sourceOfTruth}
        />
      );
    },
    [connectedSaases, connectionId, ghostQueryResult.data, inactiveQueryResult.data, mfaDisabledResult.data, saasIdentifier, saasQueryResult.data, clientInfo]
  );

  return useComponentForQueryResult({
    queryResult: saasQueryResult,
    pageLoadFunction,
    userErrorMessage,
    errorMessageDoesIndicateError: true
  })
};
