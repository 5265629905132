import {Box, Menu, MenuItem} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import React from "react";
import {
  ellipsisMenuProps,
  EllipsisRender
} from "src/components/common/Tables/Utilities/Ellipsis";
import {ICompanyAccess} from "../../../../types/Company";

const menuItemStyling = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '13px',
}

interface theMenuProps extends ellipsisMenuProps {
  client: ICompanyAccess,
  onUpdateClick: Function,
  onDeleteClick: Function,
}

export const ClientsActionMenu = ({
  // General menu control
  anchorEl,
  open,
  handleClose: handleCloseActionMenu,

  // specific menu props
   client,
   onUpdateClick,
  onDeleteClick,
}: theMenuProps) => {

  const handleUpdateClick = () => {
    handleCloseActionMenu();
    setTimeout(onUpdateClick(client),0)
  }

  const handleDeleteClick = () => {
    handleCloseActionMenu();
    setTimeout(onDeleteClick(client),0)
  }

  const iconSize = '1.3rem';

  return (<>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseActionMenu}
    >
      <MenuItem onClick={handleUpdateClick}>
        <Box sx={menuItemStyling}>
          <ManageAccountsIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Update`}
          </div>
        </Box>
      </MenuItem>
      { // Only show delete if the client is disabled
        client.disabled && <MenuItem onClick={handleDeleteClick}>
        <Box sx={menuItemStyling}>
          <RemoveCircleOutlineIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Delete`}
          </div>
        </Box>
      </MenuItem> }
    </Menu>
  </>);
};

// renderCell function for Client panel
export const renderClientEllipsis = ({
  client,
  onUpdateClick,
  onDeleteClick,
}: {
  client: any,
  onUpdateClick: any,
  onDeleteClick: any,
}) => {
    return (
      <EllipsisRender
        menu={ClientsActionMenu}
        menuProps={{
          client: client,
          onUpdateClick: onUpdateClick,
          onDeleteClick: onDeleteClick
        }}
      />
    );
};
