import {CircularProgress} from '@mui/material';
import clsx from 'clsx';
import React, { FC} from 'react';
import {useLoadingScreenStyles} from './styles';

interface ILoadingScreenProps {
  isFullScreen?: boolean;
  size?: number | string
}

export const LoadingScreen: FC<ILoadingScreenProps> = ({
  isFullScreen = false,
  size
}) => {
  const { container, containerFullScreen } = useLoadingScreenStyles();

  return (
    <div
      className={
        // this, shorter, version seems cool but doesn't work
        clsx(
          container,
          // cool way to do conditionally add an argument
          {[containerFullScreen]: isFullScreen}
        )
      }
    >
      <CircularProgress size={size}/>
    </div>
  );
};
