import {Box, Typography as T} from '@mui/material';
import {GridFooter} from '@mui/x-data-grid';
import React from 'react';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {clickableDivStyles} from 'src/theme';

const defaultSinglePanelUrl = '/';

// Custom pagination footer to add View All link
export const CustomFooter = ({
    singlePanelUrl = defaultSinglePanelUrl,
    DomainFilter,
  }: {
    singlePanelUrl?: string;
    DomainFilter?: any;
  },
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {DomainFilter ? <DomainFilter /> : <span/>}
      <Box
        component="span"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          // whiteSpace: 'nowrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <GridFooter
          sx={{
            marginTop: '0px',
            paddingTop: '0px',
            // paddingBottom: '0px',
            // marginBottom: '0px',
            // border: 'none',
          }}
        />

        {/* the View All button */}
        {singlePanelUrl !== defaultSinglePanelUrl &&
          (<>
            <DtxSpacer
              space={5}
              orientation="vertical"
            />
            <DefaultLink
              to={singlePanelUrl}
              sx={{
                ...clickableDivStyles,
              }}
            >
              <T
                sx={{
                  fontSize: '0.9rem',
                  paddingTop: '16px',
                  // paddingBottom: '0px',
                  marginBottom: '-5px',
                  // alignSelf: 'flex-end',
                }}
              >
                View All
              </T>
            </DefaultLink>
            <DtxSpacer
              space={5}
              orientation="vertical"
            />
          </>)
        }
      </Box>
    </Box>
  );
};
