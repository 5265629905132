import {IDailyDrift, IDailyInactiveUserDrift} from "../../types/Drift";

export type DailyDriftCounterInput = {
  date: string,
  items:any[]
}
export const countMFADrift = ({date, items}: DailyDriftCounterInput) : number => {
  // In theory there could be multiple drifts per day as the database view groups by new_value/old_value
  // So there could be a old=Enabled / new=Disabled, and also a old="" / new=Disabled record
  // This finds all the items that fit the Disabled or Enabled criteria
  let disabledDrifts = items.filter((x: any) => x.day === date && x.newValue === 'Disabled' && x.oldValue !== 'Disabled');
  let enabledDrifts = items.filter((x: any) => x.day === date && x.newValue === 'Enabled' && x.oldValue !== 'Enabled');

  // Count each of them
  let disabledCount = disabledDrifts ? disabledDrifts.reduce((acc:any, x:IDailyDrift) => acc + x.count, 0) : 0;
  let enabledCount = enabledDrifts ? enabledDrifts.reduce((acc:any, x:IDailyDrift) => acc + x.count, 0) : 0;

  // The nett amount of disabled for this day is the total disabled minus the endbled
  return disabledCount - enabledCount;
}

export const countInactiveDrift = ({date, items}: DailyDriftCounterInput) : number => {
  // Find the drifts for this day
  let driftsForDay = items.filter((x: any) => x.day === date);

  // Count each of them
  let newInactiveCount = driftsForDay ? driftsForDay.reduce((acc:any, x:IDailyInactiveUserDrift) => acc + x.inactive ?? 0, 0) : 0;
  let newZombieCount = driftsForDay ? driftsForDay.reduce((acc:any, x:IDailyInactiveUserDrift) => acc + x.zombie ?? 0, 0) : 0;
  let newDisabledCount = driftsForDay ? driftsForDay.reduce((acc:any, x:IDailyInactiveUserDrift) => acc + x.disabled ?? 0, 0) : 0;

  // The nett amount of inactive, are the new inactive, minus the new zombies and new disabled
  return newInactiveCount - newZombieCount - newDisabledCount;
}