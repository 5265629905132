import {gql} from "graphql-request";

export const getStripeProductsQuery = gql`
  query StripePackages {
    stripe_product(where: {active: {_eq: true}}) {
      price(where: {active: {_eq: true}}, order_by:{price:asc}) {
        nickname
        price
        price_id
        type
        currency
        features
        interval
        interval_count
      }
      name
      description
      product_id
    }
  }
`

export const getStripeSessionQuery = gql`
  query StripeSession($req: StripeSessionRequest!) {
    get_stripe_session(req: $req) {
        session_id
        status
    }
  }
`

export const createStripeSubscriptionMutation = gql`mutation CreateSub($req: CreateSubscriptionRequest!) {
  create_subscription(req: $req) {
    clientSecret
  }
}`;