import React, { FC} from 'react';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {MFAStatus} from 'src/types/SaasUser';
import {stringForMissingText} from 'src/utils/constants';
import {useMfaCellStyles} from 'src/components/common/Tables/Utilities/tableCells/styles';

export const mfaStatusStrings = {
  enabled: 'Enabled',
  disabled: 'Disabled',
}

// Convert boolean or undefined to a string
// Also used by UserGrid
export const mfaStatusDisplayString = (mfaStatus: MFAStatus) => {
  // console.log('mfaStatusDisplayString', {mfaStatus});
  let result = stringForMissingText;

  if (mfaStatus === true) {
    result = mfaStatusStrings.enabled; // 'Enabled';
  }
  else if (mfaStatus === false) {
    result = mfaStatusStrings.disabled; // 'Disabled';
  }
  return result;
}

type props = {
  mfaStatus: MFAStatus;
};

export const MfaStatusTableCell: FC<props> = ({
  mfaStatus,
}) => {
  const {
    mfaEnabled,
    mfaDisabled,
    mfaUndefined,
  } = useMfaCellStyles();

  const theClassName = () => {
    let result = mfaUndefined;
    if (mfaStatus === true) result = mfaEnabled
    else if(mfaStatus === false) result = mfaDisabled;
    return result
  }

  return (
    <DefaultTableCell className={theClassName()}>
      {mfaStatusDisplayString(mfaStatus)}
    </DefaultTableCell>
  );
};

