import {DtxSpacer} from "src/components/common/DtxSpacer";
import {SecretField} from "../sharedFields/SecretField";
import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

// Oddly, our Zoom API wants keys with wildly different
// names than the field labels.  Christian confirmed 06 Jun 22
export const Zoom = () => {

  const label1 = 'API Key';
  const label2 = 'API Secret';
  return <>
    <ValidatedField
      valueKey='name'
      placeholder={label1}
      errorLabel={`${label1} required`}
    />
    <DtxSpacer space={2} />
    <SecretField
      valueKey="token"
      placeholder={label2}
      errorLabel={`${label2} required`}
    />
   </>;
};
