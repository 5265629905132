import {useFilterMenu} from 'src/pages/ActivityLogPage/FilterMenu';
import {ICompanyConfiguration} from "../../types/Company";

export const activityFilterDefaultLabel = 'All Activity';
export const activityFilterMenuIds = {
  all: 'allActivity',
  offboard: 'offBoard',
  onboard: 'onboard',
  mfaDisabled: 'mfaDisabled',
  privilegeChange: 'privilegeChange',
  userActivity: 'userActivity',
}

// activityFilterOptions takes the list of features that this client has and
// returns a list of allowed drifts to get added to the "Drift type" dropdown filter
const activityFilterOptions = (features: any) => {
  // Default the items to an empty array
  let menuItems = [];

  // Go through each feature and add the appropriate item(s)
  if (features.user_status) {
    menuItems.push({
        id: activityFilterMenuIds.offboard,
        value: '',
        label: 'Offboard'
      },
      {
        id: activityFilterMenuIds.onboard,
        value: '',
        label: 'Onboard'
      })
  }

  if (features.mfa) {
    menuItems.push({
      id: activityFilterMenuIds.mfaDisabled,
      value: '',
      label: 'MFA Change'
    })
  }

  if (features.privileges) {
    menuItems.push({
      id: activityFilterMenuIds.privilegeChange,
      value: '',
      label: 'Privilege Change'
    })
  }

  // If the activity feature is enabled, add the 'User activity' item
  if (features.activity) {
    menuItems.push({
      id: activityFilterMenuIds.userActivity,
      value: '',
      label: 'User Activity'
    })
  }

  // There are no features turned on. So just return a placeholder to put in the dropdown.
  if (menuItems.length === 0){
    return [
      {
        id: 'allActivity',
        value: '',
        label: "No drifts available"
      }
    ]
  }

  // If there is more than one filter option, add the option to show all
  if (menuItems.length > 1) {
    menuItems.unshift({
      id: 'allActivity',
      value: '',
      label: activityFilterDefaultLabel
    });
  }

  return menuItems;
}

export const useActivityFilterMenu = (config: ICompanyConfiguration) => {

  const filterMenu = useFilterMenu({
    label: 'Activity',
    options: activityFilterOptions(config.features),
    ariaLabel: 'Filter for Activity',
    menuId: 'activityFilterMenuId',
  });

  return {
    FilterMenu: () => (
      <SaasFilterMenu
        filterMenu = {filterMenu}
      />
    ),
    selectionState: filterMenu.selectionState,
  };
}

const SaasFilterMenu = ({filterMenu}: {filterMenu: any}) => {
  const FilterMenuComponent = filterMenu.filterMenu;
  return <FilterMenuComponent />
};
