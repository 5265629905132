import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {NoMatchingRecords} from 'src/components/common/NoMatchingRecords';
import {UserActionTable} from 'src/components/common/Tables';
import {DLPExportFilenameFragment, DLPHeading} from 'src/pages/SingleSaasPage/SingleSaasComponent';
import {
  riskTypeIdentifiers,
  riskTypeToDisplay,
  singlePanelUrl
} from 'src/routes';
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {ConnectedSaas} from "src/types/Saas";

type propTypes = {
  dataSharing: any;
  connectedSaases: ConnectedSaas[];
}

export const DataSharingComponent = ({
  dataSharing: {
    dlps,
    externalShares,
  },
  connectedSaases,
}: propTypes) => {
  // console.log(
  //   'DataSharingComponent dataSharing',
  //   {dlps},
  //   {externalShares},
  //   {connectedSaases}
  // );

  const [searchParams] = useSearchParams();

  const hasDLPs = dlps?.length > 0;
  const hasExternalShares = externalShares.length > 0;

  const riskPolicyPanel = () => {
    return <>
      {
        hasDLPs && (
          <UserActionTable
            users={dlps}
            shouldShowSaasColumn={true}
            shouldShowPolicyColumn={true}
            shouldShowSharedItemColumn={true}
            shouldShowSharedToColumn={true}
            heading={
              <DLPHeading
                dlpCount={dlps?.length}
                exportNameFragment={DLPExportFilenameFragment(dlps)}
              />
            }
            singlePanelUrl={
              singlePanelUrl(
                searchParams,
                riskTypeIdentifiers.dlp,
                dlps?.length
              )
            }
            dateColumnLabel="Triggered"
          />
        )
      }
    </>
  };

  const externalSharesPanel = () => {
    return <>
      {
        hasExternalShares && (
          <UserActionTable
            users={externalShares}
            shouldShowSaasColumn={true}
            shouldShowSharedItemColumn={true}
            shouldShowSharedToColumn={true}
            heading={`External Shares (${externalShares?.length})`}
            singlePanelUrl={
              singlePanelUrl(
                  searchParams,
                  riskTypeIdentifiers.externalShares,
                  externalShares?.length
              )
            }
            dateColumnLabel="Date Shared"
          />
        )
      }
    </>
  };

  function allPanels() {
    return (hasDLPs || hasExternalShares) ?
      <>
        {riskPolicyPanel()}
        <DtxSpacer />
        {externalSharesPanel()}
      </>
      :
      <NoMatchingRecords
        message="No External or DLP shares found"
      />


  }

  const panelToDisplay = () => {
    let result = allPanels();

    switch(riskTypeToDisplay(searchParams)) {
      case riskTypeIdentifiers.dlp:
        result = riskPolicyPanel();
        break;
      case riskTypeIdentifiers.externalShares:
        result = externalSharesPanel();
        break;
    }
    return result;
  }

  return (<>{panelToDisplay()}</>)
};
