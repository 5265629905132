import {useEffect} from "react";
import {Box} from "@mui/material";
import {ConnectedSaases} from "src/pages/AdminPage/Panels/SaasPanel/ConnectedSasses/ConnectedSaases";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {AvailableSaases} from "src/pages/AdminPage/Panels/SaasPanel/AvailableSaases";

const ScrollToTopOnMount = () =>{
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto' })
    }, 0)
  }, []);

  return null;
}

export const SaasPanel = (
  {connectedSaases, allSaases}:
  {connectedSaases: any[], allSaases: any[]}
) => {
  // console.log({connectedSaases});

  return (
    <>
      <ScrollToTopOnMount />
      <Box
        sx={{
          display: 'flex',
          // marginLeft: '200px',
          flexDirection: 'column',
          minWidth: '1000px',
          // why don't these work?
          // width: '100%',
          // minWidth: '100%',
        }}
      >
        <ConnectedSaases
          connectedSaases={connectedSaases}
          allSaases={allSaases}
        />
        <DtxSpacer />
        <AvailableSaases
          connectedSaases={connectedSaases}
          allSaases={allSaases}
        />
       </Box>
    </>
  )
}
