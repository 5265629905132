import {FC, useState} from "react";
import {Typography as T, useTheme} from "@mui/material";

import {isEmptyString} from "src/utils/string";
import {SharedDialog} from "./SharedDialog";

export const useConfirmationDialog = ({
  saas,
  nickname,
  onConfirm,
  onCancel
}: {
  saas: any, // may not be an ISaas - may not have a pubId
  nickname?: string,
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return {
    dialog: ConfirmationDialog({
      saas,
      nickname,
      onCancel,
      onConfirm,
      isOpen,
      setIsOpen
    }),
    setIsOpen,
  };
}

type propTypes = {
  saas: any;
  nickname?: string;
  isOpen: boolean;
  setIsOpen: any;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
  saas,
  nickname,
  ...props
}) => {
  const theme = useTheme();
  return (
    <SharedDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onDefaultButtonClick={onCancel}
      onSecondButtonClick={onConfirm}
      saas={saas}
      nickname={nickname}
    >
      <T
        sx={{
          textAlign: 'left',
          marginLeft: '3px',
        }}
      >
        <strong
          style={{color: theme.more.riskyRed}}
        >
          Warning
        </strong>: removing <strong>{!isEmptyString(nickname) ? saas.calculatedName : nickname}</strong> will delete its data.

        <br /><br />If you would like to keep data for a specific risk type, use <em>Export to CSV</em>&nbsp; located at the top-right of each Security Posture report.
      </T>
    </SharedDialog>

  )
};


