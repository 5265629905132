import React, {FC} from 'react';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {useTableCellStyle} from './styles';
import {maxEmailLength, TruncatableString} from '../TruncatableString';
import {useWidthForRef} from 'src/hooks/useWidthForRef';

type EmailTableCellProps = {
  email: any;
  shouldUseCompactVersion?: boolean;
};

export const EmailTableCell: FC<EmailTableCellProps> = ({
  email,
  shouldUseCompactVersion,
}) => {
  const {tableCellStyle} = useTableCellStyle();

  const {ref, width} = useWidthForRef();

  const baseWidth = 350;

  return (
    <DefaultTableCell
      className={tableCellStyle}
      ref={ref}
    >
      <TruncatableString
        value={email}
        maxLength={maxEmailLength}
        shouldUseCompactMode={shouldUseCompactVersion}
        extraWidth={width - baseWidth}
      />
    </DefaultTableCell>
  )
};
