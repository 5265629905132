import {Paper} from '@mui/material';
import {NavigationBarMainViewSelect} from './NavigationBarMainViewSelect';
import {
  useCompanyNavigationBarStyles,
  useNavigationBarContainerStyles,
} from './styles';

import {SaasViewSelect} from './SaasViewSelect';

export function SaasNavigationBar(): JSX.Element {
  const { subViewSelect } = useCompanyNavigationBarStyles();
  const { container, mainViewSelect } = useNavigationBarContainerStyles();

  return (
    <Paper className={container}>
      <div className={mainViewSelect}>
        <NavigationBarMainViewSelect />
      </div>
      <div className={subViewSelect}>
        <SaasViewSelect />
      </div>
    </Paper>
  );
}
