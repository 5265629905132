import {styled} from '@mui/material';
import {borderRadius, clickableDivStyles} from 'src/theme';

// Shared by SaaS List Popper and Add SaaS Dialog

export const SharedAddSaasHeading = styled('div')(
  ({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    marginTop: 0,
    paddingTop: 0,
    marginBottom: theme.spacing(2),
    paddingBottom: 0,
  })
);

export const DialogHeading = styled('div')(
  ({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    marginTop: 0,
    paddingTop: 0,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.4),
    fontSize:theme.typography.h5.fontSize
  })
);

const SharedOuterContainer = styled('div')(({theme}) => ({
  padding: theme.spacing(3),
  borderRadius: 20,
  backgroundColor: '#fff',
  boxShadow: theme.shadows[4],
}));

export const SaasContainer = styled('div')(
  ({theme}) => ({
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.8),
    paddingTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '25% 53% 22%',
    gridTemplateRows: 'auto',
    justifyItems: 'start',
    alignItems: 'center',
    ...clickableDivStyles,
  })
);

export const DialogSaasContainer = styled('div')(
  ({theme}) => ({
    width: '100%',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.8),
    paddingTop: theme.spacing(1),
    display: 'grid',
    // why does 60px give a nice margin
    // when the icon size is 80, below
    gridTemplateColumns: '60px auto',
    // gridTemplateRows: 'auto',
    justifyItems: 'start',
    alignItems: 'center',
    ...clickableDivStyles,
  })
);

type SaasLogoContainerProps = {spacing?: number, width?: number};

export const SaasLogoContainer = styled('div')
  <SaasLogoContainerProps>(({theme, spacing=0, width=80}) => ({
  maxWidth: width,
  minWidth: width,
  // maxHeight: width,
  // minHeight: width,
  marginRight: 0,
  marginBottom: '-3px', // has unexpected effect on the whole popper
  '& img': {
    height: '45%',
    width: '45%',
    overflow: 'hidden',
  },
}));

export const FooterActions = styled('div')(({theme}) => ({
  paddingTop: theme.spacing(2.5),
  display: 'flex',
  justifyContent: 'space-between',
}));


// Saas List Popper

export const SaasListContainer = styled(
  SharedOuterContainer
)(({theme}) => ({
  width: 390,
}));


export const saasListPopperModifiers = [
  {
    name: "eventListeners",
    options: {
      scroll: true,
      resize: "false",
    }
  },
  {
    name: 'preventOverflow',
    // status: true,
    options: {
      altAxis: true,
      // mainAxis: true,
      // altBoundary: true,
      tether: true,
      rootBoundary: 'document',
      padding: {top: 0, right: 0, bottom: 0, left: 205},
    },
  },
  {
    name: 'offset',
    // status: true,
    options: {
      offset: [0, -15],
    },
  },
];


// Add SaaS Dialog

type AddSaasDialogContainerProps = {width?: number};

export const AddSaasDialogContainer = styled(
  'div' // SharedOuterContainer
)<AddSaasDialogContainerProps>(({theme, width = 550}) => ({
  width: width,
  padding: theme.spacing(3),
  // MuiPaper, behind it has radius 4 so protrudes
  borderRadius: borderRadius,
  backgroundColor: '#fff',
  boxShadow: theme.shadows[4],
  // '& div.MuiBackdrop-root': {
  //   borderRadius: borderRadius,
  //   backgroundColor: 'red'
  //},

}));

