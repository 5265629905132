import {ValidatedField} from "../sharedFields/ValidatedField";
import {SecretField} from "../sharedFields/SecretField";
import {DtxSpacer} from "../../../../../components/common/DtxSpacer";

export const Okta = () => {
  return <>
    <ValidatedField
        valueKey="url"
        type="url"
        placeholder="Your Okta Domain"
        errorLabel="Okta Domain required"
    />
    <DtxSpacer space={2} />
    <ValidatedField
        valueKey = 'tenant_id'
        placeholder= 'Client ID'
        errorLabel = 'Client ID required'
    />
    <DtxSpacer space={2} />
    <SecretField
        valueKey="secret"
        placeholder="Your Okta Client Secret"
        errorLabel="Okta Client Secret required"
    />
  </>
};
