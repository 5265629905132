import {graphQLClient} from 'src/services/shared/GraphQLService';
import {gql} from "graphql-request";

const removeConnectionMutation = gql`
  mutation RemoveConnection(
    $client_id: uuid,
    $req: DeleteConnectionRequest!
   ) {
    delete_connection(client_id: $client_id, req: $req) {
      saas
    }
  }
`;

export const removeConnection = async (
  parameters: any
) => {
  // console.log({parameters})
  const {
    auth0HasuraAccessToken,
    client_id,
    saas,
    connectionId,
  } = parameters

  const saasIdentifier = saas.identifier;

  if (!saasIdentifier) throw new Error('saas required');
  if (!connectionId) throw new Error('connectionId required');

  const client = await graphQLClient({accessToken: auth0HasuraAccessToken});

  const variables = {
    client_id: client_id,
    req: {
      saas: saasIdentifier,
      connection_id: connectionId,
    }
  };

  let result;
    result = await client?.request(
      removeConnectionMutation,
      variables
    )

  if (result.error) throw new Error(result.error.message);

  return result;
}
