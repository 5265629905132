
// count labels
const accessLabels = [
  'Write',
  'Email',
  'Calendar',
  'File',
];

const userCountLabels = [
  'Over 25 users',
  '10 to 25 users',
  '5 to 10 users',
  'Under 5 users',
];

export const calculateAggregates = (aggs: any[]) => {
  const calculatedValues: any = {};
  // console.log({aggs}, {apps});

  // TODO check the actual query when it drops to see if it
  // returns a string or a boolean
  const trueValue = true;

  const approvedAggs = aggs?.find((agg: any) =>
    agg.is_enterprise === trueValue);
  const approvedOver25 = approvedAggs?.over25_count;
  const approvedFiveTo10 = approvedAggs?.fiveTo10_count;
  const approvedTenTo25 = approvedAggs?.tenTo25_count;
  const approvedLessThan5 = approvedAggs?.lessThan5_count;

  calculatedValues.approvedCounts = [
    {label: userCountLabels[0], count: approvedOver25 || 0},
    {label: userCountLabels[1], count: approvedTenTo25},
    {label: userCountLabels[2], count: approvedFiveTo10},
    {label: userCountLabels[3], count: approvedLessThan5},
  ];

  calculatedValues.approvedCount = approvedAggs?.total_count;

  const nonApprovedAggs = aggs?.find((agg: any) =>
    agg.is_enterprise !== trueValue);

  const nonApprovedOver25 = nonApprovedAggs?.over25_count;
  const nonApprovedLessThan5 = nonApprovedAggs?.lessThan5_count;
  const nonApprovedFiveTo10 = nonApprovedAggs?.fiveTo10_count;
  const nonApprovedTenTo25 = nonApprovedAggs?.tenTo25_count;

  calculatedValues.nonApprovedCounts = [
    {label: userCountLabels[0], count: nonApprovedOver25 || 0},
    {label: userCountLabels[1], count: nonApprovedTenTo25},
    {label: userCountLabels[2], count: nonApprovedFiveTo10},
    {label: userCountLabels[3], count: nonApprovedLessThan5},
  ];

  calculatedValues.nonApprovedCount = nonApprovedAggs?.total_count;

  const writeAccess = nonApprovedAggs?.write_access;
  const fileAccess = nonApprovedAggs?.file_access;
  const emailAccess = nonApprovedAggs?.email_access;
  const calendarAccess = nonApprovedAggs?.calendar_access;

  calculatedValues.access = [
    {label: accessLabels[0], count: writeAccess || 0},
    {label: accessLabels[1], count: emailAccess},
    {label: accessLabels[2], count: calendarAccess},
    {label: accessLabels[3], count: fileAccess},
  ];

  calculatedValues.totalCount = calculatedValues.approvedCount + calculatedValues.nonApprovedCount;
  return calculatedValues;
};
