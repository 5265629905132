import {FC} from 'react';
import {DefaultTableRow} from 'src/components/common/DefaultTable';
import {ISaasUser} from 'src/types/SaasUser';
import {LastLoginTableCell} from 'src/components/common/Tables/Utilities/tableCells/LastLoginTableCell';
import {MfaStatusTableCell} from 'src/components/common/Tables/Utilities/tableCells/MfaStatusTableCell';
import {NameEmailRoleTableCells} from 'src/components/common/Tables/Utilities/tableCells/NameEmailRoleTableCells';
import {calculatedMfaStatus} from 'src/services/shared/sharedTransforms';

export type SaasUserRoleTableRowProps = {
  sx?: any;
  saasUser: ISaasUser;
  saasIdentifier: string;
  saasPubId?: string;
};

export const UserRoleTableRow: FC<SaasUserRoleTableRowProps> = ({
  saasUser,
  saasIdentifier,
  ...props
}) => {
  const {
    pubId,
    hasUserUrl,
    name,
    email,
    privilegeLevel,
    lastLogin,
  } = saasUser;

  return (
    <DefaultTableRow {...props}>
      <NameEmailRoleTableCells
        hasUserUrl={hasUserUrl}
        name={name}
        pubId={pubId}
        email={email}
        privilegeLevel={privilegeLevel}
      />
      <MfaStatusTableCell mfaStatus={calculatedMfaStatus(saasUser)}/>
      <LastLoginTableCell lastLogin={lastLogin} />
    </DefaultTableRow>
  );
};
