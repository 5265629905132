import {styled} from '@mui/material';
import {NavLink} from "react-router-dom";

const sharedNavLinkStyles: any = {
  position: 'relative',
  marginLeft: '-21px',
  textDecoration: 'none',
  fontSize: 14,
  fontWeight: 700,
  display: 'inline-flex',
  height: '100%',
  // alignItems: 'center',
  // justifyContent: 'center',
}

export const AdminNavLink = styled(NavLink)(({theme}) => ({
      ...sharedNavLinkStyles,
      padding: theme.spacing(1.5, 0),
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.text.secondary,
      },

  "&.active": {
    ...sharedNavLinkStyles,
      padding: theme.spacing(1.5, 0),
      color: theme.palette.text.secondary,
  }
}));
