import dayjs from 'dayjs';

// used by SingleSaasRolePage and friends
export const privileges = {
  high: 'hpu',
  some: 'pu',
  none: 'u'
}

export const displayTitleForEnabledUsers = 'User'

// used by SaasStore - could be used more widely
// These are the exact strings used in the database
export const privilegeStrings = {
  highlyPrivileged: 'Highly Privileged User',
  privileged: 'Privileged User',
  enabled: 'User',
}

const privilegeStringFromCodeLookup: any = {
  hpu: privilegeStrings.highlyPrivileged,
  pu: privilegeStrings.privileged,
  u: privilegeStrings.enabled,
}

export const privilegeStringFromCode = (
  privilegeCode?: keyof typeof privilegeStringFromCodeLookup | null
) => {
  let result;
  if (typeof privilegeCode === 'string') {
    result = privilegeStringFromCodeLookup[privilegeCode];
  }
  return result;
}

export const lastLoginColumnLabel = 'Last Login';

export const driftFromDefault = () => dayjs()
  .subtract(
    28, // isLocalhost() ? 100 : 28,
    'day'
  )
  .format('YYYY-MM-DDTHH:mm:ss');

export const stringForMissingUserName = 'missing name';
export const stringForMissingText = '-'

// these values come from the back end
export const userStatusStrings = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  deleted: 'Deleted',
}

export const daysToInactiveStatus = 60

export const oneMinute = 60 * 1000;
export const oneHour = 60 * oneMinute;
export const oneDay = 24 * oneHour;

export const wordForChange = 'drift';

export const wordForFederated = 'Yes';

export const logoWithNamePath = process.env.PUBLIC_URL + '/logoWithName.png'

export const tableCellHeadings = {
  saas : 'SaaS Name',                     // SaaS
  application: 'Application Name',        // User app
}

export const portalUserRoles = {
  admin: "admin",
  orgAdmin: "org_admin",
  user: "user",
}

export const planTypes = {
  Trial: "trial",
  FeatureBased: "feature_based",
  Unlimited: "unlimited",
}

// The states that a stripe subscription can be in
export const stripeSubscriptionState = {
  Ready : 'ready',
  Processing: 'processing',
  Incomplete: 'incomplete',
  Failed: 'failed',
}

export const Auth0UsernamePasswordConnection = 'Username-Password-Authentication';