import {gql} from 'graphql-request';
import {
  nullCollapsableArguments, nullCollapsableClientPubId, nullCollapsableIncludeExternal,
  nullCollapsableIncludeInternal, nullCollapsableSaasPubId, nullCollapsableUserPubId,
} from 'src/services/shared/nullCollapsableUtilities';
import {transformUser} from 'src/services/shared/sharedTransforms';
import {userStatusStrings} from 'src/utils/constants';
import {saasUserFragment} from './Fragments';
import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {QueryContext} from "../../hooks/TanstackHooks";

const mfaDisabledQuery = gql`
  query MfaDisabled (
    $privilegeLevelBoolExp: privilege_level_comparison_exp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $connectionIdBoolExp: client_saas_bool_exp,
    $genericUserPubIdBoolExp: generic_user_bool_exp,
    $shouldIncludeInternalBoolExp: domain_status_comparison_exp,
    $shouldIncludeExternalBoolExp: domain_status_comparison_exp,
    $clientBoolExp: client_bool_exp,
  ) {
    latest_saas_user(
      where: {
        client: $clientBoolExp,
        saas: $saasIdentifierBoolExp,
        connection: $connectionIdBoolExp,
        privilege_level: $privilegeLevelBoolExp,
        generic_user: $genericUserPubIdBoolExp,
        status: {_eq: ${userStatusStrings.enabled}},
        _and: [
          {domain: $shouldIncludeInternalBoolExp},
          {domain: $shouldIncludeExternalBoolExp}
        ],
        _or: [
          {
            federated_user: {
              mfa_status: {_eq: false}, 
            }
          },
          {
            _not: {federated_user: {}},
            mfa_status: {_eq: false}, 
          }
        ]
      },
      # limit: 5, 
      order_by: {privilege_level: asc}
    )
    {
      ...User
      connection{
        pub_id
      }
    }
  }
  ${saasUserFragment}
`;

const mfaDisabledCountQuery = gql`
  query MfaDisabled (
    $privilegeLevelBoolExp: privilege_level_comparison_exp,
    $saasIdentifierBoolExp: saas_bool_exp,
    $connectionIdBoolExp: client_saas_bool_exp,
    $genericUserPubIdBoolExp: generic_user_bool_exp,
    $shouldIncludeInternalBoolExp: domain_status_comparison_exp,
    $shouldIncludeExternalBoolExp: domain_status_comparison_exp,
    $clientBoolExp: client_bool_exp,
  ) {
    latest_saas_user_aggregate(
      where: {
        client: $clientBoolExp,
        saas: $saasIdentifierBoolExp,
        connection: $connectionIdBoolExp,
        privilege_level: $privilegeLevelBoolExp,
        generic_user: $genericUserPubIdBoolExp,
        status: {_eq: ${userStatusStrings.enabled}},
        _and: [
          {domain: $shouldIncludeInternalBoolExp},
          {domain: $shouldIncludeExternalBoolExp}
        ],
        _or: [
          {
            federated_user: {
              mfa_status: {_eq: false}, 
            }
          },
          {
            _not: {federated_user: {}},
            mfa_status: {_eq: false}, 
          }
        ]
      }
    )
    {
      aggregate{
        count
      }
    }
  }
`;

export const getMfaDisabled = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any,}
) => {

  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    genericUserId,
    shouldIncludeExternal = true,
    shouldIncludeInternal = true,
  } = parameters;

  const variables = {
    ...nullCollapsableArguments({saasIdentifier, privilegeLevel}),
    connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    genericUserPubIdBoolExp: nullCollapsableUserPubId(genericUserId),
    shouldIncludeInternalBoolExp: nullCollapsableIncludeInternal(shouldIncludeInternal),
    shouldIncludeExternalBoolExp: nullCollapsableIncludeExternal(shouldIncludeExternal),
    ...nullCollapsableClientPubId(queryContext.client.id)
  }

  const queryFunction = async (client: any) => {
    return await client.request(
      mfaDisabledQuery,
      variables,
    );
  };

  const response = await doAuthenticatedQuery(queryFunction, accessToken);
  const result = response.latest_saas_user.map(transformUser);

  return result;
};


export const getMfaDisabledCount = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any,}
) => {

  const {
    saasIdentifier,
    connectionId,
    privilegeLevel,
    genericUserId,
    shouldIncludeExternal = true,
    shouldIncludeInternal = true,
  } = parameters;

  const variables = {
    ...nullCollapsableArguments({saasIdentifier, privilegeLevel}),
    connectionIdBoolExp: nullCollapsableSaasPubId(connectionId),
    genericUserPubIdBoolExp: nullCollapsableUserPubId(genericUserId),
    shouldIncludeInternalBoolExp: nullCollapsableIncludeInternal(shouldIncludeInternal),
    shouldIncludeExternalBoolExp: nullCollapsableIncludeExternal(shouldIncludeExternal),
    ...nullCollapsableClientPubId(queryContext.client.id)
  }

  const queryFunction = async (client: any) => {
    return await client.request(
      mfaDisabledCountQuery,
      variables,
    );
  };

  const response = await doAuthenticatedQuery(queryFunction, accessToken);
  return response.latest_saas_user_aggregate.aggregate.count;
};