import {withAuthenticationRequired} from "@auth0/auth0-react";
import {LoadingScreen} from 'src/components/LoadingScreen';

export const PrivateRoute = (
  {component}: {component: any}
) => {
  // console.log({component});
  const Component = withAuthenticationRequired(
    component,
    {
      onRedirecting: () => {
        return <LoadingScreen isFullScreen />;
      },
    }
  );
  return <Component />;
};

