// Pause (ms) to give the main thread a moment for, say, UI.

// Usage:
//   pause(100).then(
//    () => {
//      queryClient.prefetchQuery(
//        `${cacheKeys.allSaasList}`, // cache key
//        () => getAllSaas(), // omit null user names
//       );
//     }
//   );
export const pause = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const isLocalhost = () => window.location.hostname === "localhost";
export const isDemoEnvironment = () =>
  process.env.REACT_APP_INSTANCE_TAG === 'demo';

// to avoid circular ref error in JSON.stringify
// usage: console.log(JSON.stringify(theObject, getCircularReplacer())
export const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key: any, value: object | null) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

// functions for getting and setting object field values
// with '.'-separated strings e.g. user.email

// recognizes false, 0 and negative numbers
// accepts default values as parameter:

// Usage:
//
// resolvePathIntoObject(window,'document.body') => <body>
// resolvePathIntoObject(window,'document.body.xyz') => undefined
// resolvePathIntoObject(window,'document.body.xyz', null) => null
// resolvePathIntoObject(window,'document.body.xyz', 1) => 1

export const resolvePathIntoObject = (
  object: any,
  path: string,
  defaultValue?: any
) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)

// set a value for a path:

// Usage:
//
// let myVar = {}
// setPathInObject(myVar, 'a.b.c', 42) => 42
// console.log(myVar) => {a: {b: {c: 42}}}

export const setPathInObject = (
  object: any,
  path: string,
  value: any
) => path
   .split('.')
   .reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)


// Used by SaasCarousel to convert an array of ISaas to chunks of three
export const chunksFromArray = (array: any[], chunkSize: number) => {
  const result = [];
  if(Array.isArray(array)) {
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
  }
  return result;
};

// return last element of url path omitting query parameters
// defaults to current location
export const lastSegmentOfLocation = (location?: string) => {
  // default to the current location
  let theLocation = location || window.location.href;
  if (
    !theLocation.startsWith('http://') ||
    !theLocation.startsWith('https://')
  ) {
    theLocation = `${window.location.origin}/${location}`
  }
  const segments = new URL(theLocation).pathname.split('/');
  // Handle potential trailing slash
  const lastSegment = segments.pop() || segments.pop();
  return lastSegment;
}

// Returns an array of path elements after "admin"
// Examples:
// /admin/finish-connection-setup/12345678-1234 will return ['finish-connection-setup','12345678-1234']
// /admin/saas will return ['saas']
export const getAdminPathFromLocation = (location: string) => {
  // default to the current location
  let theLocation = location || window.location.href;
  if (
      !theLocation.startsWith('http://') ||
      !theLocation.startsWith('https://')
  ) {
    theLocation = `${window.location.origin}/${location}`
  }
  const segments = new URL(theLocation).pathname.split('/');

  let portionAfterIndex = segments.indexOf('admin');
  if (portionAfterIndex >= 0) {
    return segments.slice(portionAfterIndex + 1);
  }

  return [];
}
