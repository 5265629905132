import React, { FC} from 'react';
import {Card, CardProps} from '@mui/material';
import clsx from 'clsx';
import {useCardStyles} from './styles';

export type DefaultCardProps = CardProps & {
  spacing?: number;
};

export const DefaultCard: FC<DefaultCardProps> = ({
  children,
  className,
  spacing = 0,
  ...cardProps
}) => {
  const { cardContainer } = useCardStyles({ spacing });

  return (
    <Card
      className={clsx(cardContainer, className)}
      elevation={4}
      {...cardProps}
    >
      {children}
    </Card>
  );
};
