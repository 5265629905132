import React from 'react';
import {riskTypeIdentifiers} from "../../../routes";
import {RiskRowBox} from "./RiskRowBox";
import {useQueryWithAccessToken} from "../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../services/shared/serviceConstants";
import {usePortalContext} from "../../../hooks/PortalContext";
import {FeatureCountProps} from "../../../types/FeaturePanelProps";
import {getPanelLink} from "../DashboardPanels/panelCommon";
import {TotalPanel} from "../TotalPanel";
import {getNewAppsInOrgCount} from "../../../services/Discovery/Discovery";

interface NewAppsIsOrgCountProps extends FeatureCountProps {
  numberInCircle: number;
}
export const NewAppsInOrgCount = (props: NewAppsIsOrgCountProps) => {

  const {isLoading, data, isError} = useQueryWithAccessToken({
      queryKey: [cacheKeys.newAppsInOrgCount],
      queryFunction: getNewAppsInOrgCount,
      queryFunctionVariables:{}
    }
  );

  const {isTrial} = usePortalContext();

  return (<RiskRowBox>
    <TotalPanel
      totalCount={data}
      title="New apps in Org"
      description={<span><em>Apps that have been added to your org in the last 28 days</em></span>}
      viewAllUrl={getPanelLink(riskTypeIdentifiers.newAppsInOrg, data, !isTrial)}
      numberInCircle={props.numberInCircle}
      featureEnabled={props.isFeatureOn}
      isLoading={isLoading}
      isError={isError}
    />
  </RiskRowBox>)
};
