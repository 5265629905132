import {Route, Routes} from 'react-router';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {UserAccessExceptionsSummaryPage} from 'src/pages/UserAccessExceptionsPage';
import {GhostUserPage} from 'src/pages/UserAccessExceptionsPage/RiskElementListPages/GhostUserPage';
import {
  externalRouteFragment,
  ghostRouteFragment,
  inactiveRouteFragment,
} from 'src/routes';
import {RouteNotFound} from 'src/RoutesManager/index';
import {ExternalUserPage} from 'src/pages/UserAccessExceptionsPage/RiskElementListPages/ExternalUserPage';
import {InactiveUserPage} from 'src/pages/UserAccessExceptionsPage/RiskElementListPages/InactiveUserPage';

// For /company/user-access...
export function RoutesManagerUserAccessException(): JSX.Element {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <PrivateRoute
            component={UserAccessExceptionsSummaryPage}
          />
        }
      />

      <Route
        path={`${externalRouteFragment}`}
        element={
          <PrivateRoute
            component={ExternalUserPage}
          />
        }
      />
      <Route
        path={`${externalRouteFragment}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={ExternalUserPage}
          />
        }
      />
      <Route
        path={`${ghostRouteFragment}`}
        element={
          <PrivateRoute
            component={GhostUserPage}
          />
        }
      />
      <Route
        path={`${ghostRouteFragment}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={GhostUserPage}
          />
        }
      />
      <Route
        path={`${inactiveRouteFragment}`}
        element={
          <PrivateRoute
            component={InactiveUserPage}
          />
        }
      />
      <Route
        path={`${inactiveRouteFragment}/:saasIdentifier`}
        element={
          <PrivateRoute
            component={InactiveUserPage}
          />
        }
      />
      <Route
        path="*"
        element={<>
          <h3>RoutesManagerUserAccessException</h3>
          <RouteNotFound path={window.location.pathname} />
        </>}
      />
    </Routes>
  );
}
