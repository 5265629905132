import {DefaultContainer} from "../../../components/common/DefaultContainer";
import {Box, Typography as T} from "@mui/material";
import {DtxSpacer} from "../../../components/common/DtxSpacer";

export const ComingSoon = ({title}: { title: string }) => {
  return (
    <DefaultContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: 'column',
          width: "70vh",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <T variant="h5">Coming Soon</T>
        <DtxSpacer/>
        <T variant="h2">{title}</T>
        <DtxSpacer space={13}/>
      </Box>
    </DefaultContainer>
  )
}
