import React, {FC, useState} from "react";
import {
  Box,
  Dialog, Typography,
  useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {ValidatedField} from "../AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";
import {FormValuesContext} from "../../components/Forms/FormValuesContext";
import {DtxSpacer} from "../../components/common/DtxSpacer";

import {DefaultButton} from "../../components/common/DefaultButton";

import {dataGridRowBorder, h2FontSize, h5FontSize} from "../../theme";
import { DialogHeading } from "../AdminPage/AddAndUpdateSaas/dialogs/misc/styles";
import {IStripePrice, IStripeProduct} from "../../types/Subscription";
import {StripeCheckout} from "./StripeCheckout";
import {usePublicMutation} from "../../hooks/TanstackHooks";
import {createStripeSubscription} from "../../services/Subscriptions/SubscriptionService";

type propTypes = {
  stripeProduct: IStripeProduct;
  stripePrice: IStripePrice;
  isOpen: boolean;
  setIsOpen: any;
  onCancel: () => void;
  // This doesn't need a "onConfirm" as the user will be taken to the Stripe page
};

export const CheckoutDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  stripeProduct,
  stripePrice,
  ...props
}) => {
  const theme = useTheme();

  // we pass fieldValuesState and fieldValidationState to children
  const fieldValuesState = useState<{[key: string]: any}>({});
  const [fieldValues, ] = fieldValuesState;

  const fieldValidationsState = useState<{[key: string]: any}>({});
  const [fieldValidations, ] = fieldValidationsState;

  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [clientSecret, setClientSecret] = useState('');

  const [
    didFindValidationErrorOnSubmit,
    setDidFindValidationErrorOnSubmit
  ] = useState(false);

  const stateForDialog: any = {
    fieldValuesState,
    fieldValidationsState,
    didFindValidationErrorOnSubmit,
  };

  const {mutate: getSecret} = usePublicMutation({
    mutation: createStripeSubscription,
    tanstackOptions:{
      onSuccess: (data:string) => {
        //@ts-ignore
        setClientSecret(data);
        setShowPaymentDialog(true);
      },
      onError: (e:any) => {
        let message = "Error creating subscription. Try again later.";

        if (e.response?.errors?.length > 0 && e.response?.errors[0].message) {
          message = e.response.errors[0].message
        }
        setErrorMessage(message);
        console.error('Error creating subscription', e);
      },
    }
  })

  const hasValidationError = () => {
    let result;
    const fieldValidationArray = Object.values(fieldValidations);
    if (!fieldValidationArray.length) {
      return true;
    }

    result = fieldValidationArray.find(
      (valid) => {
        return valid === false;
      })
    result = result !== undefined; // convert to boolean
    return result;
  }

  const handleOkButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage(undefined);

    if (hasValidationError()) {
      setDidFindValidationErrorOnSubmit(true);
      return;
    }

    const variables = {
      req: {
        name: fieldValues.name,
        email: fieldValues.email,
        company: fieldValues.company,
        skus: [{product: stripeProduct.productId, price: stripePrice.priceId}]
      }
    }

    // @ts-ignore
    getSecret({variables});
  }

  const closeButtonClicked = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage(undefined);
    // If the payment (Stripe) page is showing
    // return to the "Enter your details" page
    if (showPaymentDialog) {
      setShowPaymentDialog(false)
    }
    else {
      // We are on the "Enter your details" page so just close the dialog
      onCancel();
    }
  }

  return (
    <Dialog
      {...props}
      open={isOpen}
    >
        <Box
          sx={{
            border: dataGridRowBorder,
            borderRadius: theme.more.borderRadius,
            margin: 'auto',
            padding: theme.spacing(2),
            minWidth: '600px',
          }}
        >
          <DialogHeading>
            <Box>Checkout</Box>
            <Box><CloseIcon onClick={closeButtonClicked}/></Box>
          </DialogHeading>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
          { // Make sure that a stripe price and product are passed in
            (!stripePrice || !stripeProduct) ?
            <Typography sx={{
              color: theme.palette.text.primary,
              fontSize: theme.typography.h4.fontSize,
              padding: '10px 0 15px 0'}}>
              No product selected
            </Typography>
          :
            showPaymentDialog ?
              <StripeCheckout clientSecret={clientSecret} />
              :
            <>
          <Typography sx={{
            color: theme.palette.text.primary,
            fontSize: theme.typography.h4.fontSize,
            padding: '5px 0 15px 0'}}>
              {stripeProduct.name} - {stripePrice.nickname}
          </Typography>
          <Typography sx={{...theme.typography.h1, fontSize: h2FontSize}}>
              ${new Intl.NumberFormat('en-US').format(stripePrice.price / 100)}
          </Typography>
          <Typography sx={{...theme.typography.h1, fontSize: h5FontSize}}>
            /{stripePrice.interval}
          </Typography>
          <Typography sx={{marginTop: '20px'}}>
            Enter your details to proceed
          </Typography>
          {(errorMessage) &&
            <Typography sx={{padding: '10px', color: theme.palette.error.main}}>{errorMessage}</Typography>
          }
          <form onSubmit={handleOkButtonClick}>
          <FormValuesContext.Provider
            value={{...stateForDialog}}
          >
            <DtxSpacer space={2} />
            <ValidatedField
              placeholder="Name"
              valueKey="name"
              errorLabel="Valid name required"
            />

            <DtxSpacer space={2} />
            <ValidatedField
              placeholder="Email"
              valueKey="email"
              type={"email"}
              errorLabel="Valid email required"
            />

            <DtxSpacer space={2} />
            <ValidatedField
              placeholder="Company name"
              valueKey="company"
              errorLabel="Valid company name required"
            />
          </FormValuesContext.Provider>
      <DtxSpacer space={2} />
      <Box>
        <DefaultButton
          sx={{
            marginRight: 0,
          }}
          variant="contained"
          type="submit"
        >Next
        </DefaultButton>
      </Box>
    </form>
        </>}

          </Box>
</Box>
</Dialog>)
};


