import {useState} from 'react';
import {ErrorDialog} from "../../../../components/common/Dialog/ErrorDialog";

export const useManageDomainErrorDialog = ({
  onDismiss
}: {
  onDismiss: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  return {
    dialog: ErrorDialog({
      isOpen,
      setIsOpen,
      onDismiss,
      errorMessage,
    }),
    setIsOpen,
    setErrorMessage,
  };
}




