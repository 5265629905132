import memoizee from "memoizee";
import {ConnectedSaas, ISaas, SaasIdentifier} from "src/types/Saas";

import {isEmptyString} from "src/utils/string";

export const sotIsMicrosoft = (sot: ConnectedSaas) => {
  let result = false;
  if (!sot) {
    console.warn('sotIsMicrosoft called without an SoT arg')
    return result
  }
  result = sot.identifier === SaasIdentifier.microsoft365;
  return result;
}

export const isSaasConnected = memoizee((saas: ISaas, connectedSaases: ISaas[]) => {
  // console.log('isConnected', {saas})
  const matchingUserSaas = connectedSaases?.find((aConnectedSaas: ISaas) => saas.identifier === aConnectedSaas.identifier);
  return !!matchingUserSaas
});

// nicknames longer than this can display without the saas name
const minimumIndependentNicknameLength = 10;


// build a display name from SaaS name and nickname.
// To avoid over-long names it uses some smarts.
// In simple terms it appends or replaces the SaaS name
// with the nickname.

// If the nickname is short it appends it to the SaaS name
// e.g. giving a nickname of “1” to  Slack will result in
// a display name of “Slack 1”.

// If the nickname contains the first or last word of the
// SaaS name, the nickname replaces the SaaS name for e.g.
// giving a nickname of Hero 1 to Employment Hero would result
// in a display name of Hero 1.

// Nicknames longer than 10 characters also take over as a
// display name.  Giving a nickname of "Hoohah Forever" to
// any SaaS will stick as the display name.
export const combineSaasNicknameWithName =
  (saasName: string = '', nickname: string = '') => {
  const lcSaasName= saasName.toLowerCase() || ''
  const lcNickname = nickname?.toLowerCase() || '';

  const firstWord = lcSaasName.split(" ")[0]
  const lastWord = lcSaasName.split(" ").pop() ||
    '***nolastword***'
  let result = saasName;

  if (!isEmptyString
    (nickname)) {
    if (
      lcNickname.includes(firstWord) ||
      lcNickname.includes(lastWord) ||
      (nickname?.length || 0) >= minimumIndependentNicknameLength
    ) {
      result = nickname || '';
    }
    else {
      result = `${saasName} ${nickname}`;
    }
  }
  return result;
}

// Shared function finds an ISaas from a saasIdentifier.
// Has to handle no saas list, during loading etc
export const iSaasForIdentifier = (
  identifier: string | undefined,
  fullSaaSList: ISaas[],
) => {
  const saas: ISaas | undefined = fullSaaSList?.find(
    (aSaas: ISaas) => aSaas.identifier === identifier
  )
  // If undefined build a temporary ISaas
  // Note the cool method of upper casing the first char
  return saas ? saas : {
    name: identifier?.replace(/^./, str => str.toUpperCase()),
    identifier
  };
}

// Shared function finds an Connected Saas from a pubId.
// Has to handle no saas list, during loading etc
export const connectedSaasForConnectionId = ({
  identifier = '',
  connectionId = '',
  connectedSaases
} : {
  identifier: string | undefined,
  connectionId?: string,
  connectedSaases: ConnectedSaas[],
}) => {
  const saas: ConnectedSaas | undefined = connectedSaases?.find(
    (aSaas: ConnectedSaas) => aSaas.connectionId === connectionId
  )
  // If undefined build a temporary ISaas
  // Note the cool method of upper casing the first char
  const tempName = identifier?.replace(
    /^./, str => str.toUpperCase()
  ) || ''

  const result = saas ?
    saas :
    {
      name: tempName,
      calculatedName: tempName,
      identifier,
      connectionId,
    }
  ;
  // console.log({result});
  return result;
}

// Returns the first-found Connected SaaS with matching identifier
// Has to handle no saas list, during loading etc
export const firstConnectedSaasForIdentifier = (
  identifier: string,
  connectedSaases: ConnectedSaas[]
) => {
  // console.log({identifier}, {connectedSaases});

  const saas: ConnectedSaas | undefined = connectedSaases?.find(
    (aSaas: ConnectedSaas) => aSaas.identifier === identifier
  )
  // If undefined build a temporary ISaas
  // Note the cool method of upper casing the first char
  const tempName = identifier?.replace(
    /^./, str => str.toUpperCase()
  ) || ''

  const result = saas ?
    saas :
    {
      name: tempName,
      calculatedName: tempName,
      identifier,
    }
  ;
  // console.log({result});
  return result;
}

export const saasCoverageCount = (users: any) => {
  let result = 0;
  if (users) {
    const userSaasOnly = users.map((user: any) => user.saas.identifier);
    const userSaasSet = new Set(userSaasOnly);
    result = userSaasSet.size;
  }
  return result;
};