import {IDailyDrift, IDailyInactiveUserDrift} from "../../types/Drift";


export const transformMFADrifts = (data: any[]) : IDailyDrift[] => {
return data.map((item: any) => {
        return {
            subject: item.subject,
            day: item.day,
            newValue: item.new_value,
            oldValue: item.old_value,
            count: item.count,
        }
    });
};

export const transformInactiveDrifts = (data: any[]) : IDailyInactiveUserDrift[] => {
  return data.map((item: any) => {
    return {
      day: item.day,
      inactive: item.new_inactive_count,
      zombie: item.new_zombie_count,
      disabled: item.inactive_disabled_count,
    }
  });
};
