import React, { FC} from 'react';
import {Box, BoxProps, Link, Typography as T} from '@mui/material';
import {linkStyleFragment} from "src/theme";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {useTheme} from "@mui/styles";

export const ContactSupport = () => {
  return <Link
    href="https://www.detexian.com/contact/"
    target="_blank"
    sx={{
      ...linkStyleFragment,
      fontSize: "1.0rem",
    }}
  >
    Contact Support
  </Link>;
}

type errorScreenProps = {
  message: string | undefined;
  isFullScreen?: boolean;
}

export const ErrorScreen: FC<BoxProps & errorScreenProps> = (props) => {
  const theme = useTheme();
  return (
    <Box
      data-testid="ErrorScreen"
      p={10}
      sx={{
        height: props.isFullScreen ? '100vh' : "100%",
        width: 'auto',
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
      }}
      // {...props}
    >
      <T
        sx={{
          fontSize: '1.0rem',
        }}
      >
        <Box
          component="span"
          sx={{
            color: theme.more.subtleRed,
            // fontWeight: 500,
          }}
        >
          Error:&nbsp;
        </Box>
        {props.message}
      </T>
      <DtxSpacer space={2}/>
      <ContactSupport/>

    </Box>
  )
};
