import {privilegeStrings} from "src/utils/constants";
import {ISaasUser} from "src/types/SaasUser";
import {
  transformPermissionStatus
} from "../shared/sharedTransforms";
import {pause} from "src/utils/other";

export const privilegeKey = 'privilegeLevel';

export const transformSaasDetails = async (
  queryResponse: {
    saas: {
      description: string;
      name: string;
      pub_id: string;
      latest_saas_users: any[];
    }[];
    dlp: any [];
    file_share: any[];
  }
): Promise<any> => {
  const shouldLog = false;

  const saas = queryResponse.saas[0];
  const saasIdentifier = saas.name;
  const userRecords = saas.latest_saas_users;
  if(shouldLog)console.log({userRecords});

  let allUsers: ISaasUser[] = [];
  let privilegedUsers: any[] = [];
  let highlyPrivilegedUsers: any[] = [];
  let enabledUsers: any[] = [];
  let externalUsers: any[] = [];

  if (shouldLog) console.group('transformQueryResponse');

  const chunkSize = 300;
  const transformUsersChunk = (chunk: any[]) => {
    allUsers = allUsers.concat(chunk.map(transformPermissionStatus))
  }
  for (let i = 0; i < userRecords.length; i += chunkSize) {
    const chunk = userRecords.slice(i, i + chunkSize);
    await pause(100).then(() => transformUsersChunk(chunk));
  }

  if(shouldLog)console.log('transformed', allUsers);
  if (shouldLog) console.groupEnd();

  // Copy users into useful categories
  // Previously done in SaasStore
  await pause(100).then(() =>
    highlyPrivilegedUsers = allUsers.filter((user) => user[privilegeKey] === privilegeStrings.highlyPrivileged
    )
  );
  await pause(100).then(() =>
    privilegedUsers = allUsers.filter((user) =>
      user[privilegeKey] === privilegeStrings.privileged
    )
  );
  await pause(100).then(() =>
    enabledUsers = allUsers.filter((user) =>
      user[privilegeKey] !== privilegeStrings.highlyPrivileged &&
      user[privilegeKey] !== privilegeStrings.privileged
    )
  );
  await pause(100).then(() =>
    externalUsers = allUsers.filter((user) =>
      user.domain?.toLowerCase() !== 'internal'
    )
  );
  // Pack it all up
  const result = {
    saas: {
      name: saas.description,
      identifier: saasIdentifier,
      pubId: saas.pub_id,
    },
    // noOfMfa,
    // these three are just for the summary counts
    users: allUsers,
    highlyPrivilegedUsers,
    privilegedUsers,
    enabledUsers,
    allUsers,
    externalUsers,
  }
  if(shouldLog) console.log('transformQueryResponse', {result})
  return result;
};