import {Box, Menu, MenuItem} from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import StartIcon from '@mui/icons-material/Start';
import React from "react";
import {ISaas, SaasIdentifier} from "src/types/Saas";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {
  ellipsisMenuProps,
  EllipsisRender
} from "src/components/common/Tables/Utilities/Ellipsis";
import {useNavigate} from "react-router-dom";
import {combineSaasNicknameWithName} from "src/utils/saasUtils";
import {finishConnectionSetupRoute} from "../../../AdminNavigation";
import {saasCanFinishConnectionSetup} from "../../../AddAndUpdateSaas/dialogs/misc/ConnectionStructures";
import {compactInfoFontSize, h5FontSize, riskyRed} from "../../../../../theme";
import {HtmlTooltip} from "../../../../../components/common/HtmlTooltip";
const menuItemStyling = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '13px',
}

interface theMenuProps extends ellipsisMenuProps {
  saas: ISaas,
  connectionId: string,
  nickname?: string
  setSelectedSaas: Function,
  setSelectedConnectionId: Function,
  // is this really needed?
  setSelectedNickname: Function

  setIsConfirmationDialogOpen: Function,
  handleUpdateConnection: Function,
  needsFinishSetup: boolean,
  needsUpdate: boolean,
}

export const ConnectedSaasActionMenu = ({
  // General menu control
  anchorEl,
  open,
  doOpenMenu,
  handleClose: handleCloseActionMenu,

  // specific menu props
  saas,
  connectionId,
  nickname,
  needsUpdate,
  needsFinishSetup,
  setSelectedSaas,
  setSelectedConnectionId,
  setSelectedNickname,
  setIsConfirmationDialogOpen,
  handleUpdateConnection,
}: theMenuProps) => {

  const navigate = useNavigate();

  const handleRemoveClick = () => {
    setSelectedSaas(saas);
    setSelectedConnectionId(connectionId);
    // why do we do this?  needed?
    setSelectedNickname(nickname);

    handleCloseActionMenu();
    setIsConfirmationDialogOpen(true);
  }

  const handleUpdateConnectionClick = () => {
    setSelectedSaas(saas);
    setSelectedConnectionId(connectionId);
    handleCloseActionMenu();
    setTimeout(handleUpdateConnection(),0)
  }

  const iconSize = '1.3rem';

  const saasDisplayName = combineSaasNicknameWithName(
    saas.name,
    nickname
  )
  return (<>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseActionMenu}
    >
      <MenuItem onClick={handleUpdateConnectionClick}>
        <Box sx={{...menuItemStyling, color: needsUpdate ? riskyRed : 'inherit'}}>
          <AppRegistrationIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Update ${saasDisplayName}`} {needsUpdate && <HtmlTooltip
            title={
              <span style={{ fontSize: compactInfoFontSize}}>
                Action is required to enable this connection. Update {saasDisplayName} to re-authorize Detexian.
              </span>
            }
          >
            <ErrorOutlineIcon sx={{fontSize: h5FontSize, marginBottom: '-3px'}} data-testid={`saas-action-menu-update-warning-${saasDisplayName}`}/>
          </HtmlTooltip> }
          </div>
        </Box>
      </MenuItem>
      <MenuItem onClick={handleRemoveClick}>
        <Box sx={menuItemStyling}>
          <RemoveCircleOutlineIcon sx={{fontSize: iconSize}}/>
          <div>
            {`Remove ${saasDisplayName}`}
          </div>
        </Box>
      </MenuItem>
      {
        saasCanFinishConnectionSetup(saas.identifier) &&
        <MenuItem onClick={() => navigate(`${finishConnectionSetupRoute}/${connectionId}`)}>
          { // If the secondary onboarding needs to happen, show the menu item in an error state
            // and include a tooltip to help the user understand what to do
            // Otherwise, show menu item in a normal state to allow users to update existing secrets
            needsFinishSetup ?
          <Box sx={{...menuItemStyling, color: riskyRed}}>
            <StartIcon sx={{fontSize: iconSize}}/>
            <div>
              {`Finish Setup`} <HtmlTooltip
              title={
                <span style={{ fontSize: compactInfoFontSize }}>
                  Action is required to enable this connection. Finish setup to complete secondary onboarding steps.
                </span>
              }
            >
              <ErrorOutlineIcon sx={{fontSize: h5FontSize, marginBottom: '-3px'}} data-testid={`saas-action-menu-finish-warning-${saasDisplayName}`}/>
            </HtmlTooltip>
            </div>
          </Box> :
            <Box sx={menuItemStyling}>
              <StartIcon sx={{fontSize: iconSize}}/>
              <div>
                {saas.identifier === SaasIdentifier.googlews ? 'Update Service Account Credentials' : 'Update Admin API Key'}
              </div>
            </Box>
          }
        </MenuItem>
      }
    </Menu>
  </>);
};

// renderCell function for UserGrid
export const renderConnectionEllipsis = ({
  params,
  saas,
  setSelectedSaas,
  setSelectedConnectionId,
  setSelectedNickname,
  setIsOAuth2ConsentDialogOpen,
  setIsConfirmationDialogOpen,
  handleUpdateConnection,
}: {
  params: GridRenderCellParams,
  saas: any,
  setSelectedSaas: any,
  setSelectedConnectionId: any,
  setSelectedNickname: Function,
  setIsOAuth2ConsentDialogOpen: any,
  setIsConfirmationDialogOpen: any,
  handleUpdateConnection: any,
}) => {
  const connectionId = params.row.connectionId
  const nickname = params.row.nickname

  // The connection needs to be updated if there are no secrets
  const needsUpdate = (params.row.secret_version?.length === 0);

  // The connection needs secondary onboarding to occur if it is Google or Atlassian, and only has one secret
  const needsFinishSetup = (params.row.secret_version?.length === 1 && saasCanFinishConnectionSetup(params.row.saas.identifier));

  return (<>
    <EllipsisRender
      menu={ConnectedSaasActionMenu}
      menuProps={{
        saas: params.row.saas,
        connectionId,
        setSelectedSaas,
        setSelectedConnectionId,
        setSelectedNickname,
        setIsOAuth2ConsentDialogOpen,
        setIsConfirmationDialogOpen,
        handleUpdateConnection,
        nickname,
        needsUpdate,
        needsFinishSetup,
        errorState: needsUpdate || needsFinishSetup,    // Show the warning icon if either secrets need updating
      }}></EllipsisRender>
    </>
  );
};
