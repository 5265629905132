import React, {useCallback, useState} from 'react';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {Typography as T} from '@mui/material';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {planTypes, stringForMissingText, tableCellHeadings} from 'src/utils/constants';
import {
  appendedFilterOperators,
  emailValueGetter,
  largerRowsPerPageOptions,
  renderDateAndTime,
  renderSaas,
} from '../DefaultGrid/dataGridUtilities';
import {maxNameLength, TruncatableString} from '../Utilities';
import {GridHeadingWithExport} from 'src/components/common/Tables/DefaultGrid/GridHeadingWithExport';
import {UserNameLink} from '../Utilities/tableCells/styles';
import {userRouteBase} from 'src/routes';
import {DefaultGrid} from '../DefaultGrid/DefaultGrid';
import {CustomFooter} from "../DefaultGrid";
import {compactInfoFontSize} from "src/theme";
import {truncatedString, valueOrStringForMissingText} from "src/utils/string";
import {usePortalContext} from "../../../../hooks/PortalContext";


const activityValueGetter = ({row}: {row: any}) => `
  ${valueOrStringForMissingText(row.subject)}:
  ${valueOrStringForMissingText(row.previous)}
  to
  ${valueOrStringForMissingText(row.current)}
`;

const renderLinkedUserName = (params: GridRenderCellParams) => {
  return (
    <TruncatableString
      maxLength={maxNameLength}
      truncateFunction={truncatedString}
      value={params.row.user.name}
      displayComponent={(truncatedValue: any) =>
        <UserNameLink to={`${
          // two possible locations for the pubId
          userRouteBase}/${params.row.pubId || // for Activity Log
          params.row.user?.pubId // for everything else (untested for Auth exceptions)
        }`}>
          {truncatedValue}
        </UserNameLink>
      }
    />
  );
};

const renderEmail = (params: GridRenderCellParams) => {
  return (
    <TruncatableString
      maxLength={maxNameLength}
      truncateFunction={truncatedString}
      value={params.row.user.email}
      displayComponent={
        (truncatedValue: any) =>
          <span>{truncatedValue}</span>
      }
    />
  );
};

const renderCapitalized = (
  params: GridRenderCellParams,
) => {
  return (
    <T
      sx={{
        textTransform: 'capitalize !important',
        fontSize: compactInfoFontSize,

      }}
    >
      {params.value}
    </T>
  );
};

type propTypes = {
  rows: any;
  rowIdKey: string;

  // This is used to build the subject column heading in addition to
  // its use in building the panel header title - so don't get too
  // fancy. There was a hard-to-notice bug due to fanciness.
  displayNameForTypeOfRecords?: string;
  headerTitle: string;

  shouldShowSaasColumn?: boolean;
  shouldShowCombinedColumn?: boolean;
  shouldShowSubjectColumn?: boolean;
  shouldShowUserNameColumn?: boolean;
  exportFileName?: string;
  driftDescription?: React.ReactNode;
  isLoading?:boolean;
}

export const DriftGrid = ({
  rows = [],
  rowIdKey,
  displayNameForTypeOfRecords = 'Activity',
  headerTitle,
  shouldShowSaasColumn,
  shouldShowCombinedColumn,
  shouldShowSubjectColumn,
  shouldShowUserNameColumn = true,
  exportFileName,
  driftDescription,
  isLoading = false,
 }: propTypes) => {

  const defaultPageSize = 50;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });

  const {planType} = usePortalContext().clientInfo;

  const dataGridColumns = useCallback(
    (): GridColDef [] => {
      return [
        // @ts-ignore
        ...(
          shouldShowSaasColumn ?
          [{
            headerName: tableCellHeadings.saas,
            field: 'saas',
            renderCell: (params: GridRenderCellParams) => {
              const modifiedParams = {...params};
              modifiedParams.row = {
                saas: {
                  ...params.row.saas,
                },
                shouldDisableLink: planType !== planTypes.Unlimited
              }

              return renderSaas({
                params: modifiedParams
              });
            },
            valueGetter: ({row}: any) =>
                row.saas?.name || stringForMissingText,
            minWidth: 200,
            flex: 1.3,
          }] : []
        ),
        // @ts-ignore
        ...(
          shouldShowUserNameColumn ?
          [{
            headerName: 'Name',
            field: 'user.name',
            valueGetter: ({row}: any) => row.user?.name,
            renderCell: renderLinkedUserName,
            minWidth: 200,
            flex: 1.3,
            // sortComparator: nameComparator,
          }] : []
        ),
        {
          headerName: 'Email',
          field: 'user.email',
          // @ts-ignore
          renderCell: renderEmail,
          minWidth: 200,
          flex: 1.3,
          valueGetter: emailValueGetter,
          // @ts-ignore
          filterOperators: appendedFilterOperators(),
        },
        // @ts-ignore
        ...(
          shouldShowCombinedColumn ?
          [{
            headerName: displayNameForTypeOfRecords,
            field: 'activity',
            minWidth: 250,
            flex: 1, // flex: 1,
            valueGetter: activityValueGetter,
          }] : []
        ),
        // @ts-ignore
        ...(
          shouldShowSubjectColumn ?
          [{
            headerName: `${displayNameForTypeOfRecords} Type`,
            field: 'subject',
            minWidth: 100,
            flex: 1, // flex: 0.5,
          }] : []
        ),
        // @ts-ignore
        {
          headerName: 'Previous',
          field: 'previous',
          minWidth: 100,
          // @ts-ignore
          renderCell: renderCapitalized,
          flex: 1, // flex: 0.5,
        },
        // @ts-ignore
        {
          headerName: 'Current',
          field: 'current',
          minWidth: 50,
         // @ts-ignore
         renderCell: renderCapitalized,
          flex: 1, // flex: 0.5,
        },
        // @ts-ignore
        {
          headerName: 'Detected',
          field: 'driftTime',
          // @ts-ignore
          renderCell: renderDateAndTime,
          minWidth: 200,
          flex: 1.0, // flex: 1.3,
        },
      ];
    },
    [displayNameForTypeOfRecords, shouldShowCombinedColumn, shouldShowSaasColumn, shouldShowSubjectColumn, shouldShowUserNameColumn, planType]
  );

  return <>

    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      <DefaultGrid
        rows={rows}
        getRowId={(row: { [x: string]: any; }) => row[rowIdKey]}
        columns={dataGridColumns()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={largerRowsPerPageOptions}
        length={rows?.length}
        minRowsPerPageOption={defaultPageSize}
        initialState={{
          sorting: {
            sortModel: [{
              field: 'driftTime',
              sort: 'desc',
            }],
          },
        }}
        toolbar={() =>
          <GridHeadingWithExport
            heading={headerTitle}
            exportFileName={exportFileName}
            tableDescription={driftDescription}
          />
        }
       footer={() => CustomFooter({})}
        isLoading={isLoading}
      />
    </DefaultTableContainer>
  </>;
};

