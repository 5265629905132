import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {isFieldValueNonEmpty} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/validationFunctions";
import {ConnectedSaas} from "src/types/Saas";
import {FormValuesContext} from "../../../../../components/Forms/FormValuesContext";

export interface ValidatableComponentProps {

  valueKey: string;

  values: any; // the state in the context, only used by the text fields

  label: any; // can show validation error

  localValidState: boolean;

  onBlur:  React.ReactEventHandler;
  onFocus: React.ReactEventHandler;

  // for UploadFile
  setValueDirectly: Function;
  saas?: ConnectedSaas;

  // for text fields
  placeholder: string;
  onChange: React.ChangeEventHandler;
  InputProps?: any;
  type?: string; // 'text' or 'secret', otherwise unused
  multiline?: boolean;
  minRows?: number;

  // styling
  sx?: any;
  fullWidth?: boolean;

}

interface ValidatedBaseProps {
  ValidatableComponent: React.FC<ValidatableComponentProps>;
  valueKey?: string;
  placeholder?: string;
  validationFunction?: any;
  errorLabel?: string;

  // These textfield-only fields have to be here or the union,
  // below, doesn't work.  So what's the point of a union?
  type?: string;
  InputProps?: any;
  multiline?: boolean;
  minRows?: number;

  // styling
  sx?: any;
  fullWidth?: boolean;
}

// This union of a required InputProps and an option InputProps
// seems dodgy but it was the only solution that compiled.
// I'm surprised it compiles.
interface ValidatedTextFieldProps extends ValidatedBaseProps {
  InputProps: any;
  type: string;
}

export type ValidatedComponentProps = ValidatedTextFieldProps | ValidatedBaseProps;

// Encapsulates Detexian validation behavior.
// Used by GoogleServiceKey and ValidatedField (and so
// SecretField for passwords etc which uses ValidatedField)
export const ValidatedComponent = (
  {
    ValidatableComponent,
    validationFunction = isFieldValueNonEmpty,
    valueKey = 'field',
    type = "text",
    placeholder = 'value',
    errorLabel = 'required',
    InputProps,
    sx = {},
    fullWidth,
    multiline,
    minRows,
  }: ValidatedComponentProps
) => {
  const {
    fieldValuesState,
    fieldValidationsState,
    didFindValidationErrorOnSubmit
  }: any = useContext(FormValuesContext)

  const [values, setValues] = fieldValuesState;

  // local validation state allows us to not annoyingly show
  // an error while the user is typing, or has never entered
  // the field
  const [localValidState, setLocalValidState] = useState(true);

  const isValid = useCallback(
    () => validationFunction(values[valueKey]),
    [
      validationFunction,
      valueKey,
      // can causes React stack depth error in combination
      // with certain dependancies in other hooks
      values,
    ]
  );

  const setValidity = useCallback(() => {
    const [
      // validations
      , setValidations // we do need the comma
    ] = fieldValidationsState;
    const currentValidity = isValid();

    // Note: this problem seemed to evaporate. Two candidate reasons:
    // 1. the function version of setValidations helped -
    // it hid the dependancy on validations.
    // 2. removing dependancy of useEffect, below, on
    // this callback prevents a storm.

    // Partially obsolete comment:
    // The if below prevents infinite render loop and keeps
    // react-hooks/exhaustive-deps happy.  Simpler would
    // be to remove validations from the dependancy
    // array but then react-hooks/exhaustive-deps cries.

    // defunct if - if (validations[valueKey] !== currentValidity) {
      setValidations(
        (validations: any) => {
          return {...validations, [valueKey]: currentValidity}
        }
      );
    // }
  }, [
    fieldValidationsState,
    isValid,
    valueKey
  ]);

  const setLocalValidity = useCallback(
    () => setLocalValidState(isValid()),
    [isValid]
  );

  // If the parent dialog found validation errors
  // then show the field error.
  // We want this to only run once. It seems to.
  useEffect(
    () => {
      if (didFindValidationErrorOnSubmit) {
        console.log("useEffect: doing setLocalValidState(false)");
        setLocalValidity();
      }
    }, [
      setLocalValidity,
      didFindValidationErrorOnSubmit
    ]
  );

  // utility function to set parent dialog values in handleChange,
  // below, but used directly by the UploadFile component
  const setValueDirectly = useCallback((name: any, value: any) => {
    setValues({...values, [name]: value});
  }, [setValues, values])

  // called by the text fields but not by UploadFile - see above
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueDirectly(event.target.name, event.target.value)
  };

  const onBlur = (event: React.SyntheticEvent) => {
    setValidity();
    setLocalValidState(isValid());
  }

  const onFocus = (event: any) => {
    setLocalValidState(true);
  }

   const errorLabel_ = () => {
    let result = ''; // undefined
    if (!localValidState) {
      result = errorLabel;
    }
    else {
      result = placeholder;
    }
    return result;
  }

  return (
    <ValidatableComponent
      valueKey={valueKey}
      type={type}
      values={values}

      // used by UploadFile
      setValueDirectly={setValueDirectly}
      // used by the text fields
      onChange={handleChange}

      placeholder={placeholder}
      localValidState={localValidState}
      label={errorLabel_()}
      onBlur={onBlur}
      onFocus={onFocus}
      InputProps={InputProps}
      fullWidth={fullWidth}
      sx={sx}
      multiline={multiline}
      minRows={minRows}
    />
  )
};

