import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {DefaultContainer} from "../../components/common/DefaultContainer";

export const ComingSoonPanel = () => {
  const theme = useTheme();
  const { palette} = theme;
  return (
    <DefaultContainer
      sx={{
        display: 'flex',
        maxWidth: '460px',
        minWidth: '460px',
        marginBottom: 0,
        flexBasis: '460px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: 'auto',
          height: '100%',
        }}
      >

              <Typography
                sx={{
                  color: palette.text.secondary,
                  fontSize: '1.2rem',
                    marginTop: '38%',
                }}
              >
                More insights coming soon
              </Typography>
        </Box>
    </DefaultContainer>
  );
};
