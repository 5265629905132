import {FC, useCallback} from 'react';
import {useParams} from 'react-router';
import {
  documentToReactComponents
} from '@contentful/rich-text-react-renderer';
import {Box, styled} from '@mui/material';
import {BLOCKS, Document} from '@contentful/rich-text-types';
import {useQuery} from '@tanstack/react-query';
import {addSaasGuideRoute} from 'src/routes';
import {getSetupGuide} from 'src/services/ContentfulService';
import {cacheKeys} from 'src/services/shared/serviceConstants';
import {LoadingScreen} from 'src/components/LoadingScreen';
import {linkStyleFragment} from 'src/theme';

// Create a bespoke renderOptions object to target
// BLOCKS.EMBEDDED_ENTRY (linked entries e.g. videoEmbed
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (
      node: any,
      // children: any
    ) => {
      if (node.data.target.sys.contentType.sys.id === 'videoEmbed') {
        return (
          <iframe
            src={node.data.target.fields.embedUrl}
            height="100%"
            width="100%"
            frameBorder="0"
            scrolling="no"
            title={node.data.target.fields.title}
            allowFullScreen={true}
          />
        );
      }
    },

    [BLOCKS.EMBEDDED_ASSET]: (
      node: any,
      // children: any
    ) => {
      // render the EMBEDDED_ASSET as you need
      return (
        <img
          src={`https://${node.data.target.fields.file.url}`}
          height={node.data.target.fields.file.details.image.height}
          width={node.data.target.fields.file.details.image.width}
          alt={node.data.target.fields.description}
        />
      );
    },
  },
};

const StyledGuideBox = styled(Box)({
  fontFamily: 'Lato, serif',
  color: 'hsl(0, 0%, 34%)',
  width: '980px',
  margin: 'auto',
  backgroundColor: 'white',
  padding: '20px 50px 20px 60px',
  'a': {...linkStyleFragment}
});

type queryResult = {
  data?: {fields: {guideContent: Document}};
  isLoading: boolean;
  error: any;
}

let didOpenGenericGuideWindow = false

export const SetupGuidePage: FC = () => {
  const { saasIdentifier } = useParams<{ saasIdentifier: string }>();
  // const saasIdentifier = 'fred';
  // this is the html title - not the guide title
  const documentTitle = 'Add SaaS Guide - Detexian';
  document.title = documentTitle;

  // can't convert this to useQueryWithIdToken
  // because it doesn't use the same ID Token
  // as the Hasura queries
  const {data, isLoading, error}: queryResult = useQuery(
    [cacheKeys.addSaaSGuides + saasIdentifier],
    ()=>getSetupGuide(saasIdentifier));

  const pageLoadFunction = useCallback(
    () => {
      // console.log({error});

      // On Contentful error we show the generic instructions.
      // Feels hacky, but previously we maintained a list of
      // SaaSes which had Contentful instruction versions.
      // See src/components/AddSaas/setupGuides/guideStatus.ts
      if (error) {
        if (!didOpenGenericGuideWindow)
        {
          // note - console.error goes to sentry
          console.error('no Contentful Add SaaS guide found for ', saasIdentifier);
          window.open(
            `${addSaasGuideRoute}/${saasIdentifier}`,
            '_self'
          );
          didOpenGenericGuideWindow = true;
        }
        return <></>;
      }
      else {
          return (
          <StyledGuideBox>
            {
              documentToReactComponents(
                data?.fields.guideContent as any,
                renderOptions,
              )
            }
          </StyledGuideBox>
        )
      }
    },
    [data?.fields.guideContent, error, saasIdentifier]
  )

  return (
    isLoading ?
    <LoadingScreen /> :
    pageLoadFunction()
  )
}

export default SetupGuidePage;
