import {FC} from 'react';
import {Typography} from '@mui/material';
import {ConnectedSaas} from 'src/types/Saas';
import {useSaasCardStyles} from './styles';
import {DefaultCard} from 'src/components/common/DefaultCard';
import clsx from 'clsx';
import {SaasLogo} from 'src/components/SaasLogo';
import {DefaultNavLink} from 'src/components/common/DefaultNavLink';
import {saasRoute} from 'src/routes';

type SaasCardProps = {
  saas: ConnectedSaas;
  onClick?: (saas: ConnectedSaas) => void;
};

export const SaasCard: FC<SaasCardProps> = ({ saas, onClick }) => {
  const {
    saasCard,
    saasTitle,
    saasCardLogo,
    saasCardContent
  } = useSaasCardStyles();

  const {
    calculatedName,
    identifier,
    connectionId,
  } = saas;

  return (
    <DefaultNavLink
      to={`${saasRoute}/${identifier}/${connectionId}`
    }>
      <DefaultCard
        className={clsx(saasCard)}
      >
        <div className={saasCardLogo}>
          <SaasLogo size="l" saasIdentifier={identifier} />
        </div>
        <span className={saasCardContent}>
          <Typography variant="h3" className={saasTitle}>
              {calculatedName}
          </Typography>
        </span>
      </DefaultCard>
    </DefaultNavLink>
  );
};
