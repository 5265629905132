import {gql} from 'graphql-request';
import {saasFragment} from 'src/services/shared/Fragments';

export const getAuthenticationExceptionsQuery = gql`
  query AuthenticationExceptions(
    $drift_from: timestamp,
    $clientBoolExp: client_bool_exp
  ) {
    client_saas (
      where: {saas: {}, client: $clientBoolExp},
      order_by: {saas: {name: asc}}
    ) {
      privilege_levels {
        privilege_level
        no_mfa
        no_mfa_rating
      }
      drifts_aggregate(
        where: {
          _or: [
            {suppressed_until:{_is_null: true}},
            {suppressed_until:{_lt:"now()"}}
          ],
          subject: {_eq:"MFA_CHANGE"},
          drift_time: {_gt:$drift_from}
        }
      )
      {
        aggregate {
          count
        }
      }
      saas {
        ...Saas
        # remove this from here, it's above now
        privilege_levels {
          privilege_level
          no_mfa
          no_mfa_rating
          # no_federation
        }
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

// export const mfaDisabledUserQuery = gql`
//   query mfaDisabledUsers(
//     $saasIdentifier: String,
//     $privilegeLevel: privilege_level
//   ) {
//     latest_saas_user(
//       where: {
//         saas: {name: {_eq: $saasIdentifier}}
//         status: {_eq: ${userStatusStrings.enabled}},
//         privilege_level: {_eq: $privilegeLevel},
//         mfa_status: {_neq: "true"}
//       }
//     ) {
//       saas {
//        ...Saas
//       }
//       pub_id
//       name
//       email
//       # sot_status # :D
//       last_login
//       generic_user {
//         pub_id
//       }
//     }
//   }
//   ${saasFragment}
// `;
