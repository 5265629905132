import {AppBar, IconButton} from '@mui/material';
import {useDashboardLayoutHeaderStyles} from 'src/components/MainLayout/styles';
// import logoImage from 'src/assets/images/other/logoWithName.png';
import {Navigation} from 'src/components/Header/Navigation';
import {Link} from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import {SettingsMenu} from 'src/components/Header/SettingsMenu';
import {useCallback, MouseEvent, useState} from 'react';
import {Account} from 'src/components/Header/Account';
import {TrialInfo} from 'src/components/Header/TrialInfo';
import {logoWithNamePath} from "src/utils/constants";

export const Header = () => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const {
    headerAppBar,
    headerRight,
    headerLeft,
    appBarLogoImage,
    appBarLogo
  } = useDashboardLayoutHeaderStyles();

  return (
    <AppBar className={headerAppBar} color="default">
      <div className={headerLeft}>
        <Link className={appBarLogo} to="/">
          <img
            className={appBarLogoImage}
            src={logoWithNamePath}
            alt="Logo"
          />
        </Link>
        <Navigation />
      </div>
      <div className={headerRight}>
        <TrialInfo />
        <Account />
        <IconButton onClick={handleOpenMenu} size="large">
          <SettingsIcon color="primary" />
        </IconButton>
        <SettingsMenu
          onClose={handleCloseMenu}
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
        />
      </div>
    </AppBar>
  );
};
