import {gql} from "graphql-request";

import {doPublicQuery} from "src/services/shared/doQuery";

const getSubscriptionDetailsAction = gql`
  query GetSubscriptionDetails($azureToken: String!) {
    get_azure_subscription_details(token: $azureToken) {
      id
      subscriptionName
      offerId
      # planId
      subscription {
        id
        beneficiary {
          emailId
          puid
        }
        # purchaser {
        #   emailId
        #   puid
        # }
        term {
          termUnit
          startDate
          endDate
        }
        autoRenew
        isFreeTrial
      }
    }
  }
`

export const getSubscriptionDetails = async ({
  azureToken,
  signal,
}: {
  azureToken: string,
  signal: any,
}) => {
  // console.log('getSubscriptionDetails', {azureToken});

  const variables = {
    azureToken
  }

  console.log('Azure subscription details query', {variables});

  const queryFunction = async (client: any) => {
    return await client.request(
      getSubscriptionDetailsAction,
      variables,
      signal,
    );
  };

  try {
    // console.log('about to call doPublicQuery');
    const response = await doPublicQuery({queryFunction});
    // console.log('doPublicQuery returned', response)
    const result = response;

    return result;
  }
  catch(error) {
    console.log('getSubscriptionDetails throwing', (error));
    throw(error)
  }
};
