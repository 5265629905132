import React from 'react';
import {DtxSpacer} from "../../../../components/common/DtxSpacer";
import {ItApprovedAppGrid, NonItApprovedAppGrid} from "../Applications";
import {AppDriftsPanel} from "../AppDriftsPanel/AppDriftsPanel";

export const AppsPanel = ({sot}:{sot:any}) => {

  return (<>
    <AppDriftsPanel/>
    <DtxSpacer space={3.0}/>
    <NonItApprovedAppGrid
      sot={sot}
    />
    <DtxSpacer space={3.0}/>
    <ItApprovedAppGrid
      sot={sot}
    />
    </>)
}



