import {doAuthenticatedQuery} from "./doQuery";
import {gql} from "graphql-request";
import {saasFragment} from "./Fragments";
import {transformConnectedSaasFlat} from "./sharedTransforms";
import {ICompanyAccess, ICompanyConfiguration} from "../../types/Company";
import {planTypes} from "../../utils/constants";

export const featureNameMap: {[index: string]:string} = {
    activity: "Activity",
    mfa: "MFA",
    app: "Apps",
    status: "User Status",
    privilege: "Privileges",
    ghost: "Ghost",
    sharing: "File Shares",
    forwarding: "Mail Forwards",
    dlp: "DLP",
}
export const defaultFeatures = {features: {
        activity: false,
        mfa: false,
        apps: false,
        user_status:false,
        privileges: false,
        ghost: false,
        file_shares: false,
        mail_forwards: false,
        dlp: false,
    }};

export const transformClientConfiguration = (client: any) : ICompanyConfiguration => {
    // Default configuration
    let config : ICompanyConfiguration = defaultFeatures;

    const getFeatureValue = (featureName: string) => {
        const features = client.configuration?.features;
        if (features && features[featureName] !== undefined){
            return features[featureName];
        } else {
            // This goes to Sentry.
            console.error(`"${featureName}" feature is missing for client ${client.name}.  Defaulting to false.`);
            return false;
        }
    }

    switch (client.plan_type){
        case planTypes.FeatureBased:
            // Create a fallback configuration for the client with all features turned off
            config = {
                features: {
                    activity: getFeatureValue("activity"),
                    mfa: getFeatureValue("mfa"),
                    apps: getFeatureValue("app"),
                    user_status:getFeatureValue("status"),
                    privileges: getFeatureValue("privilege"),
                    ghost: getFeatureValue("ghost"),
                    file_shares: getFeatureValue("sharing"),
                    mail_forwards: getFeatureValue("forwarding"),
                    dlp: getFeatureValue("dlp"),
                }
            };

            break;
        // All features are on for unlimited plans
        // All features are also on for trial plans, there is further logic in the components to restrict the viewing of data
        case planTypes.Unlimited:
        case planTypes.Trial:
            config = {
                features: {
                    activity: true,
                    mfa: true,
                    apps: true,
                    user_status:true,
                    privileges: true,
                    ghost: true,
                    file_shares: true,
                    mail_forwards: true,
                    dlp: true,
                }
            };
            break;
    }

    return config;
}

export const getClientInfoQuery = async (accessToken?: any): Promise<ICompanyAccess> => {
    const theQuery = async (client: any) => {
        const result = client.request(
            clientInfoQuery,
            {}
        );
        return result;
    }

    let response;
    try {
        response = await doAuthenticatedQuery(
            theQuery, accessToken
        );
    }
    catch (error) {
        console.error('getClientInfo failed')
        throw (error)
    }

    const result = getClientInfoFromQueryResponse(response);
    return result;
};

const getClientInfoFromQueryResponse = (
    response:
        {
            client: {
                pub_id: string;
                name: string;
                has_access: boolean;
                trial_remaining_days: number;
                plan_type: string;
                configuration: any;
                source_of_truth_saas: any;
                slug: string;
                is_disabled: boolean;
            }[];},
): ICompanyAccess => {
    // The graphQL returns an array but there must only ever be one client
    if (!response || !response.client || response.client.length === 0){
        throw new Error('Could not load client');
    }
    const singleClient = response?.client[0];

    return transformCompanyAccess(singleClient);

};

export const transformCompanyAccess = (client: any) : ICompanyAccess => {
    const sotSaasInfo = transformConnectedSaasFlat(
      client?.source_of_truth_saas,
    );

    //client.plan_type = planTypes.FeatureBased;
    //client.configuration = {features: {activity: false, mfa: true, apps: true, user_status: true, privileges: true, ghost: false, file_shares: true, mail_forwards: true, dlp: true}}

    const config = transformClientConfiguration(client);

    return {
        id: client?.pub_id,
        name: client?.name,
        hasAccess: client?.has_access,
        trialRemainingDays: client?.trial_remaining_days,
        planType: client?.plan_type,
        configuration: config,
        sourceOfTruth: sotSaasInfo,
        slug: client?.slug,
        disabled: client?.is_disabled,
    }
}

const clientInfoQuery = gql`
  query ClientInfo {
    client{
      pub_id
      name
      has_access
      trial_remaining_days
      plan_type
      slug
      is_disabled
      configuration
      source_of_truth_saas{
        ...Saas
      }
    }
  } ${saasFragment}
`;