import React, {useCallback} from 'react';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {useComponentForQueryResult} from 'src/hooks/UseComponentForQueryResult';
import {useSaasFromIdentifier, UserConfigTable} from 'src/components/common/Tables/UserConfigTable';
import {searchParamKeys} from 'src/routes';
import {cacheKeyForInactiveUsers} from 'src/services/shared/serviceConstants';
import {privilegeStringFromCode} from 'src/utils/constants';
import {getInactiveUsers} from 'src/services/shared/InactiveUsers';
import {useQueryWithAccessToken} from "src/hooks/TanstackHooks";

export const InactiveUserPage = () => {
  const documentTitle = 'External Users - Detexian';
  document.title = documentTitle;

  const {saasIdentifier} = useParams<{ saasIdentifier?: string }>();
  const [searchParams] = useSearchParams();

  const privilegeCode = searchParams.get(searchParamKeys.privilege);
  const privilegeString = privilegeStringFromCode(privilegeCode);

  const cacheKey = cacheKeyForInactiveUsers({
      saasIdentifier,
      privilegeCode
    })

  const queryResult = useQueryWithAccessToken({
      queryKey: [cacheKey],
      queryFunction: getInactiveUsers,
      queryFunctionVariables: {
        saasIdentifier,
        privilegeLevel: privilegeString
      }
    }
  )

  // console.log('InactiveUserPage');
  const saas = useSaasFromIdentifier(saasIdentifier);

  const pageLoadFunction = useCallback(
    () => {
      return <UserConfigTable
        heading={
          `Inactive ${privilegeString}s (${queryResult.data.length})`
        }
        users={queryResult.data}
        saas={saas}
        shouldShowSotColumn={true}
      />
    },
    [privilegeString, queryResult.data, saas]
  );

  return useComponentForQueryResult({
    queryResult,
    pageLoadFunction,
    userErrorMessage: 'error accessing Inactive Users',
  })
};
