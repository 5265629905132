import {Paper, styled} from "@mui/material";
import {majorContainerBorderRadius} from "../../theme";

export const DefaultContainer = styled(Paper)(
  ({theme}) => ({
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    boxShadow: theme.defaultShadow,
    borderRadius: majorContainerBorderRadius,
  })
);
