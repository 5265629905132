import {TableCell, Typography} from '@mui/material';
import {ReactNode} from 'react';
import {useDefaultTableNumberCellStyles} from './styles';

type DefaultTableNumberCellProp = {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  primaryTextColor?: string;
  className?: string;
  ariaLabel?: string;
  sx?: any;
};

export const DefaultTableNumberCell = ({
  primaryText,
  secondaryText,
  primaryTextColor,
  className,
  ariaLabel,
  ...props
}: DefaultTableNumberCellProp) => {
  const { numberTextStyles, subTextStyles } = useDefaultTableNumberCellStyles();

  return (
    <TableCell
      className={className}
      align="center"
      aria-label={ariaLabel}
      {...props}
    >
      <Typography
        className={numberTextStyles}
        style={{ color: primaryTextColor }}
      >
        {primaryText}
      </Typography>
      {secondaryText && (
        <Typography className={subTextStyles}>{secondaryText}</Typography>
      )}
    </TableCell>
  );
};
