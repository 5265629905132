import {DtxSpacer} from "src/components/common/DtxSpacer";
import {SecretField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/SecretField";
import {ValidatedField} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/sharedFields/ValidatedField";

export const Salesforce = () => {

  return <>
    <ValidatedField
      valueKey = 'client_id'
      placeholder= 'Client ID'
      errorLabel = 'Client ID required'
      // errorText = 'Please enter your Client Id'
    />
    <DtxSpacer space={2} />
    <SecretField
      valueKey = 'client_secret'
      placeholder = 'Client Secret'
      errorLabel = 'Client secret required'
    />
    <DtxSpacer space={2} />
    <ValidatedField
      valueKey = 'user_name'
      placeholder= 'User Name'
      errorLabel = 'User Name required'
      // errorText = 'Please enter your login name'
    />
    <DtxSpacer space={2} />
    <SecretField
      valueKey = 'password'
      placeholder = 'Password'
      errorLabel = 'Password required'
    />
   </>;
};
