import {Typography} from '@mui/material';
import {DefaultTableCell, DefaultTableRow} from 'src/components/common/DefaultTable';
// import {Link} from 'react-router-dom';
import {DefaultLink} from 'src/components/common/DefaultLink';
import {LinkedUserCountWidget} from 'src/components/UserCountWidget';
import {SaasTableCell} from 'src/components/common/Tables/Utilities/tableCells';
import {
  useRowStyles,
} from 'src/pages/PrivilegedAccessPage/PrivilegedAccess/PrivilegedAccessSummaryTable/styles';
import {ISaasPrivilegedAccess} from 'src/types/SaasPrivilegedAccess';

type props = {
  saasPrivilegedAccess: ISaasPrivilegedAccess;
  getHpuLink: (saasIdentifier: string) => string;
  getPuLink: (saasIdentifier: string) => string;
  getULink: (saasIdentifier: string) => string;
  getDriftLink: (saasIdentifier: string) => string;
};

export const PrivilegedAccessSummaryRow = ({
  saasPrivilegedAccess: {
    saas,
    connectedSaas,
    // these are counts TODO rename at the query to indicate counts
    highlyPrivilegedUser,
    privilegedUser,
    enabledUser,
    drift,
  },
  getHpuLink,
  getPuLink,
  getULink,
  getDriftLink,
}: props) => {
  const {
    tableCellStyles,
    driftBoxLinkStyles,
    driftBoxNoLinkStyles,
    numberTextStyles,
  } = useRowStyles();

  return (
    <DefaultTableRow>
      <SaasTableCell saas={connectedSaas}/>
      <DefaultTableCell align="center">
        <LinkedUserCountWidget
          accessCount={highlyPrivilegedUser}
          link={highlyPrivilegedUser && getHpuLink(saas.identifier)}
        />
      </DefaultTableCell>
      <DefaultTableCell className={tableCellStyles}>
        <LinkedUserCountWidget
          accessCount={privilegedUser}
          link={privilegedUser && getPuLink(saas.identifier)}
        />
      </DefaultTableCell>
      {/* We build our own DefaultTableNumberCell so we can add a link.
          TODO add link feature to DefaultTableNumberCell
      */}
      <DefaultTableCell className={tableCellStyles}>
        <DefaultLink
          to={getULink(saas.identifier)}
        >
          <Typography className={numberTextStyles}>
            {enabledUser}
          </Typography>
        </DefaultLink>
      </DefaultTableCell>

      <DefaultTableCell className={tableCellStyles} align="center">
        {drift > 0 ? (
          <DefaultLink
            to={getDriftLink(saas.identifier)}
          >
            <div className={driftBoxLinkStyles}>{drift}</div>
          </DefaultLink>
        ) : (
          <Typography className={driftBoxNoLinkStyles}>
            0
          </Typography>
        )}
      </DefaultTableCell>
    </DefaultTableRow>
  );
};
