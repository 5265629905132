import {DetailedHTMLProps, FC, HTMLAttributes} from 'react';
import {useDashboardContentStyles} from 'src/components/MainLayout/styles';
import clsx from 'clsx';

export const MainContent: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className, ...props }) => {
  const { contentContainer, content } = useDashboardContentStyles();

  return (
    <div {...props} className={clsx(contentContainer, className)}>
      <div className={content}>{children}</div>
    </div>
  );
};
