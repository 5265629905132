import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useLoadingScreenStyles = makeStyles<Theme>(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  containerFullScreen: {
    height: '100vh',
    width: '100vw',
  },
}));
