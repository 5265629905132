import {GuideExample} from 'src/pages/AdminPage/AddAndUpdateSaas/setupGuides/GuideTemplate';
import {PrivateRoute} from 'src/components/PrivateRoute';
import {DefaultStack} from 'src/components/common/DefaultStack';
import {SaasNavigationBar} from 'src/components/NavigationBar';
import SaasesPage from 'src/pages/SaasesPage/SaasesPage';
import {SetupGuidePage} from 'src/pages/SetupGuidePage';
import {SingleSaasPage} from 'src/pages/SingleSaasPage';
import {
  ErrorPage,
  AddSuccessPage,
  UpdateSuccessPage,
} from 'src/pages/AdminPage/AddAndUpdateSaas/RedirectDestinationPages';
import {Route, Routes} from 'react-router';
import {
    addSaasError,
    addSaasGuideCmsRouteFragment,
    addSaasGuideRouteFragment, addSaasSuccess, updateSaasSuccess
} from 'src/routes';

export function RoutesManagerSaas(): JSX.Element {
  // const { path } = useRouteMatch();

  return (
    <DefaultStack isFlexItem>
      <SaasNavigationBar />
      <DefaultStack isFlexItem>
        <Routes>
          <Route
            path={`/`}
            element={
              <PrivateRoute component={SaasesPage} />
            }
          />

          {/*TODO remove this version when all routes are converted*/}
          <Route
            path={`/:saasIdentifier`}
            element={
              <PrivateRoute component={SingleSaasPage} />
            }
          />
          {/*keep this one*/}
          <Route
            path={`/:saasIdentifier/:connectionId`}
            element={
              <PrivateRoute component={SingleSaasPage} />
            }
          />

          {/* Add SaaS Guide paths */}
          <Route
            path={`${addSaasGuideRouteFragment}/:saasIdentifier`}
            element={
              <PrivateRoute
                component={GuideExample}
              />
            }
          />
          <Route
            path={`${addSaasGuideCmsRouteFragment}/:saasIdentifier`}
            element={
              <PrivateRoute
                component={SetupGuidePage}
              />
            }
          />

          {/* Add Saas connection status pages */}
          <Route
            path={`/${addSaasSuccess}/:saasIdentifier`}
            element={
              <PrivateRoute component={AddSuccessPage} />
            }
          />
          <Route
            path={`/${addSaasError}/:saasIdentifier`}
            element={
              <PrivateRoute component={ErrorPage} />
            }
          />
          <Route
              path={`/${updateSaasSuccess}/:saasIdentifier`}
              element={
                  <PrivateRoute component={UpdateSuccessPage} />
              }
          />
        </Routes>
      </DefaultStack>
    </DefaultStack>
  );
}
