import React, { FC} from 'react';
import {Box, BoxProps, CircularProgress} from '@mui/material';

export const LoadingSpinner: FC<BoxProps> = (props) => (
  <Box
    data-testid="LoadingSpinner"
    p={10}
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <CircularProgress />
  </Box>
);
