import {DetailedHTMLProps, FC, ImgHTMLAttributes} from 'react';
import {saasLogos} from 'src/assets/images/saasLogos';
import detexianLogo from "src/assets/images/other/logoOnly.png";

const sizes = {
  l: 40,
  m: 28,
  s: 20,
};

export type LogoSize = keyof typeof sizes;

type SaasLogoProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  saasIdentifier: string | undefined;
  size?: LogoSize;
}

export const SaasLogo: FC<SaasLogoProps> = ({
  saasIdentifier,
  size = 'l',
  ...props
}) => {

  return (
    <img
      // className={saasThumbnail}
      style={{
        height: sizes[size],
        minWidth: sizes[size],
      }}
      src={
        saasIdentifier ?
          saasLogos[saasIdentifier] :
          detexianLogo
      }
      alt={saasIdentifier}
      {...props}
    />
  );
};
