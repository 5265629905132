import {DtxSpacer} from "../common/DtxSpacer";
import {logoWithNamePath} from "../../utils/constants";
import headerGradient from "../../assets/images/azure/headerGradient.png";
import {Box} from "@mui/material";
import {theme} from "../../theme";
import React, {useEffect} from "react";
import {styled} from "@mui/system";
import {MainLayoutFooter} from "./MainLayoutFooter";

const StyledLogo = styled('img')(({theme}) => ({
  height: '70px',
  pointerEvents: 'none',
}));

const StyledGradient = styled('img')(({theme}) => ({
  height: '140px',
  pointerEvents: 'none',
}));

export const NoAuthLayout = ({children}: {children: React.ReactNode}) => {
  useEffect(
    () => {
      document.body.style.backgroundColor = theme.palette.common.white
    }, []
  );

  return (<Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 'none',
    margin: 0,
    padding: 0,
  }}>
    <DtxSpacer space={3}/>
    <StyledLogo
      src={logoWithNamePath}
      alt="Detexian Logo"
    />
    <DtxSpacer space={3}/>
    <StyledGradient
      src={headerGradient}
      alt="decorative gradient"
    />
      {children}
    <MainLayoutFooter/>
  </Box>);
}
