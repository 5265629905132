import {useEffect} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useAuth0Token} from 'src/hooks/Auth0Hooks';
import {getAllSaas} from 'src/services/SaasService/Saases';
import {
  cacheKeys,
} from 'src/services/shared/serviceConstants';

// Prefetch as much as feasible

export const BaseDataLoader = () => {
  // console.log('BaseDataLoader running');
  const queryClient = useQueryClient();
  
  const {token} = useAuth0Token();

  useEffect(()=> {
    const readyToPrefetch = !!token && !!queryClient;
    // console.log('useEffect', {token}, {queryClient}, {readyToPrefetch});
    if (readyToPrefetch) {
      // prefetch all SaaSes
      queryClient.prefetchQuery(
        [`${cacheKeys.allSaasList}`], // omit null user names
        () => getAllSaas({accessToken: token})
      );
    }
   }, [token, queryClient]
  );

  return null;
};
