import React from 'react';
import {ActivityLogPanel} from './ActivityLogPanel';
import {usePortalContext} from "../../hooks/PortalContext";
import {FeatureUpgradeMessage} from "../../components/common/UpgradeFeature/FeatureUpgradeMessage";
import {planTypes} from "../../utils/constants";

export const ActivityLogPage = () => {
  const documentTitle = 'Activity Log - Detexian';
  document.title = documentTitle;

  const {clientInfo} = usePortalContext();

  const features = clientInfo?.configuration?.features;

  // See whether this client has at least one drift based feature turned on
  // apps and ghost features have no bearing on the ActivityLog
  const hasDriftFeature = features?.activity ||
    features?.mfa ||
    features?.privileges ||
    features?.user_status;

  // There's no drift based features enabled, so show an "Upgrade Feature" message
  // We also don't want to show the Activity Log for trial clients (the menu item should be disabled anyway)
  if (!hasDriftFeature || clientInfo.planType === planTypes.Trial) {
    return <FeatureUpgradeMessage/>
  }

  // This is at least one feature, so show the ActivityLog
  return <ActivityLogPanel timeFilterDefault={'month'}/>
};

