import {TableCell, TableRow} from '@mui/material';
import {FC} from 'react';
import {LoadingSpinner} from 'src/components/common/LoadingSpinner';

type DefaultTableLoadingRowProps = {
  colSpan?: number;
};

export const DefaultTableLoadingRow: FC<DefaultTableLoadingRowProps> = ({
  colSpan = 1
}) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <LoadingSpinner p={0} />
    </TableCell>
  </TableRow>
);
