import {GridRenderCellParams} from "@mui/x-data-grid";
import {TextField} from "@mui/material";
import React, {useState} from "react";
import {ISaas} from "src/types/Saas";
import {updateSaasNickname} from "src/services/SaasService/Admin/SaasNickname";
import {compactInfoFontSize} from "src/theme";
import {useMutationWithAccessToken} from "src/hooks/TanstackHooks";

const maxNicknameLength = 20;

export const NicknameTextField = ({
  saas,
  setSelectedSaas,
  connectionId,
  nickname,
  params,
  setIsErrorDialogOpen,
  setErrorMessage,
}: {
  saas: ISaas,
  setSelectedSaas: any,
  connectionId: string,
  nickname: string,
  params: GridRenderCellParams,
  setIsErrorDialogOpen: any, // useState
  setErrorMessage: any,
}) => {
  const {
    // isLoading, isSuccess, error,
    mutate: updateNickname,
  } = useMutationWithAccessToken({
    mutation: updateSaasNickname,
    tanstackOptions: {
      enabled: true,
      onError: (error: any) => {
        console.log('nickname mutation - onError', {error});
        setIsErrorDialogOpen(true);
        setErrorMessage('setting nickname')
      },
    },
  });

  const [value, setValue] = useState(nickname);

  const handleChange = (event: any) => {
    // console.log({event});
    event.preventDefault();
    event.stopPropagation();
    let value = event.target.value;
    if (value.length > maxNicknameLength)
      value = value.substring(0,maxNicknameLength)
    setValue(value || '');

    // make sure the grid has the latest so it
    // can propagate to the action menu
    params.row.nickname = value;
  }

  const handleBlur = (event: any) => {
    setSelectedSaas(saas);
    const variables = {
      nickname: value || '',
      saas,
      connectionId
    };
    // console.log({variables});
    // @ts-ignore
    updateNickname(variables);
  }

  const keyDownHandler = (
    event: React.KeyboardEvent
  ) => {
    // console.log("Key down: ", {event}, event.key);

    // This handler has two purposes related to living
    // inside an MUI DataGrid:

    // 1. prevent the space key from exiting the field
    event.stopPropagation();

    // 2. make the enter and return keys end editing
    if (event.key === 'Enter') {
      // console.log('got Enter key');
      if (event.target instanceof HTMLElement) event.target.blur();
    }
  }

  return <TextField
    name={`${saas.identifier}-nickname-editor`}

    // style
    size="small"
    fullWidth={false}
    // important example of styling MUI low-level components
    sx={{
      '.MuiInputBase-input': {
        fontSize: compactInfoFontSize,
        padding: '6px 13px',
      }
    }}

    // value
    value={value}
    onChange={handleChange}
    placeholder={`Nickname (max ${maxNicknameLength})`}

    // end editing
    onBlur={handleBlur}
    onKeyDown={keyDownHandler}
  />
};

export const renderNickNameAsTextField = (
  params: GridRenderCellParams,
  saas: any,
  setSelectedSaas: any,
  setIsErrorDialogOpen: any,
  setErrorMessage: any
) => {
  // console.log({params}, {saas})
  return (
    <NicknameTextField
      connectionId={params.row.connectionId}
      saas={saas}
      setSelectedSaas={setSelectedSaas}
      nickname={params.row.nickname}
      params={params}
      setIsErrorDialogOpen={setIsErrorDialogOpen}
      setErrorMessage={setErrorMessage}
    />
  );
};
