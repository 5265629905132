import {gql} from 'graphql-request';

// "AddConnectionRequest" accepts different objects depending
// on the chosen saas.

export const addConnection = gql`
  mutation AddConnection(
    $client_id: uuid,
    $req: AddConnectionRequest!
  ) {
    add_connection(client_id: $client_id, req: $req) {
      saas
      saas_id
    }
  }
`;

// Creates a redirect for simple OAuth SaaS connections
// also used to renew connections
export const getOAuthConnectionRedirectMutation = gql`
  mutation GetOAuthConnectionRedirect(
    $client_id: uuid,
    $saasName: String!, 
    $tenantId: String,
    $connectionId: uuid
  ) {
    create_auth_url(
      client_id: $client_id,
      saas: $saasName, 
      url: $tenantId,
      connection_id: $connectionId
    ) {
      url
    }
  }
`;
