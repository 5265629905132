import {useCallback, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {planTypes, stringForMissingText, tableCellHeadings} from "src/utils/constants";
import {CustomFooter, DefaultGrid, GridHeadingWithExport, renderSaas} from "src/components/common/Tables/DefaultGrid";
import {DefaultTableContainer} from "src/components/common/DefaultTable";
import {ConnectedSaas, ISaas} from "src/types/Saas";

import {isSaasConnected} from "src/utils/saasUtils";
import {AddSaasLink} from "src/pages/DashboardPage/Discovery/Applications/AddSaasLink";
import {usePortalContext} from "../../../../hooks/PortalContext";

const addSaasRenderCell = ({
  row,
}: {
  row: any,
}) => {
  // if (app.display_name === 'Slack') console.log({app})
  return <AddSaasLink
    connected={false}
    saas={row}
    shouldShowAsButton
  />
}

// Can't disable pagination in the free licence
// so this is the next-best thing
const minRowsPerPageOption = 100
const rowsPerPageOptions = [minRowsPerPageOption]

export const AvailableSaases = ({
  connectedSaases = [],
  allSaases = [],
}: {
  connectedSaases: ConnectedSaas[],
  allSaases: ISaas[],
}) => {
  // console.log({allSaases});
  const {planType} = usePortalContext().clientInfo;
  const columns = useCallback(
    (): GridColDef [] => {
      return [
        {
          headerName: tableCellHeadings.saas,
          field: 'name',
          // for export and sort
          valueGetter: ({row}: any) => {
            const result = row.name || stringForMissingText;
            // console.log({result});
            return result;
          },
          renderCell: (params) => {
            // Other users of renderSaas expect a saas object
            // in the row. So we package up a saas in a shallow
            // cloned params
            // console.log(params)
            const modifiedParams = {...params};
            modifiedParams.row = {
              saas: {
                ...params.row,
              },
              shouldDisableLink: planType !== planTypes.Unlimited || !isSaasConnected(
                params.row, connectedSaases
              )
            }
            // return (modifiedParams: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => renderSaas(
            //   modifiedParams, connectedSaases
            // )
            return renderSaas({
              params: modifiedParams,
              shouldShowCalculatedName: false,
            });
          },
          // minWidth: 200,
          flex: 1.1,
        },
        {
          field: '',
          headerName: '',
          flex: 1,
          editable: false,
          align: 'left',
          headerAlign: 'left',
          renderCell: addSaasRenderCell
        },
      ];
    },
    [connectedSaases, planType]
  );

  const [paginationModel, setPaginationModel] = useState({
    pageSize: minRowsPerPageOption,
    page: 0,
  });

  return (
    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      <DefaultGrid
        rows={allSaases}
        getRowId={row => row?.identifier}
        columns={columns()}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={rowsPerPageOptions}
        length={allSaases?.length}
        minRowsPerPageOption={minRowsPerPageOption}
        initialState={{
          sorting: {
            sortModel: [{
              field: "drift_time",
              sort: "desc",
            }],
          },
        }}
        toolbar={() =>
          <GridHeadingWithExport
            heading="Available"
            shouldShowExportButton={false}
            shouldShowBackToPrevious={false}
            // exportFileName={exportFileName}
          />}
        footer={() => CustomFooter({})
        }

      />
    </DefaultTableContainer>
  )
}
