import {useTheme} from "@mui/material";
import {cacheKeys} from "src/services/shared/serviceConstants";
import {getSubscriptionDetails} from "src/services/Azure/GetSubscriptionDetails";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {EnableSigninWithMicrosoft} from "src/pages/Azure/EnableSigninWithMicrosoft";
import {firstErrorCodeFromArrayOfErrorExtensions} from "src/services/shared/doQuery";
import {useQuery} from "@tanstack/react-query";

import {isEmptyString} from "src/utils/string";

const getTrimmedSearchParam = (
  searchParams: any, key: string
) => searchParams?.get(key)?.replace(/\/$/, '');

export const AzurePage = () => {

  const [searchParams] = useSearchParams();
  // get the token and dump any trailing '/'
  // const encodedAzureToken = searchParams?.get('token')?.replace(/\/$/, '');
  // const error = searchParams?.get('error')?.replace(/\/$/, '');
  const encodedAzureToken = getTrimmedSearchParam(
    searchParams,
    'token'
  );

  const urlParameterErrorMessage  = getTrimmedSearchParam(
    searchParams,
    'error'
  ) //  || 'fred'

  const [errorMessage, setErrorMessage] = useState('');

  if (
    !isEmptyString(urlParameterErrorMessage) &&
    // eslint-disable-next-line eqeqeq
    errorMessage !== urlParameterErrorMessage
  ) {
    console.log('setting errorMessage to URL value')
    setErrorMessage(urlParameterErrorMessage)
  }

  // const [
  //   didClickShowSubscriptionButton,
  //   setDidClickShowSubscriptionButton
  // ] = useState(false);

  // if (errorMessage) setDidClickEnableButton(false);
  console.log({encodedAzureToken});
  let azureToken = encodedAzureToken ?
    decodeURIComponent(encodedAzureToken) :
    undefined;

  const testToken = decodeURIComponent(
    // 'x'+
    'vKvccX8J%2BMZU9ssg6VdYt1oHj3GAgTaa8BdJnz9ktoCmdEUD%2BPrkznQCybmtPpvO5fuiyzQ%2FVd%2Fzf7ynQdwNIbPKsv6GJZwWqixjZEjcjE4QCDmKCYddFa%2Fo6PzNaQjh11Lja%2FnGjshMN%2BuMJ53x6FZ9x3IgL%2BQCEosNuEavnUAssbqJ%2FD%2BryCNbgNMyftbFwIxczi6gYlBv0k9x6EANFw%3D%3D'
  );

  console.log({azureToken});
  // dubious idea
  if (process.env.REACT_APP_INSTANCE_TAG !== 'prod') {
    console.log('using test token');
    azureToken = azureToken || testToken
  }
  console.log({azureToken});

  type errorType = {
    response: {
      errors: { extensions: any; }[] | undefined; };
  }

  const is404Error = (error: errorType) => {
    // console.log({error});
    const errorCode = firstErrorCodeFromArrayOfErrorExtensions(
      error.response?.errors
    );
    const result = 404 === errorCode;
    // console.log({result});
    return result;
  }

  const max404FailureCount = 7;

  const shouldRetry = (
    failureCount: number,
    error: errorType,
  ) => {
    // console.log('shouldRetry', {failureCount});
    // Tanstaack retry delays, in seconds: 1, 2, 4, 8, 16, 30, 30...
    // We retry on 404 6 times ~ 61 (plus query ties) seconds
    // Probably just over 2 minutes
    let result = false;

    if (failureCount <= max404FailureCount && is404Error(error)) {
      console.log('shouldRetry - retrying', [failureCount])
      result = true; // do retry
    }
    return result;
  }

  const theme = useTheme();

  // if(!azureToken) throw new Error('missing Azure subscription token')

  const [theFailureCount, setTheFailureCount] = useState(0);

  const queryResult = useQuery({
    queryKey: [cacheKeys.subscriptionDetails, azureToken],
    queryFn: ({signal}) => getSubscriptionDetails({
      azureToken: (azureToken || ''),
      signal,
    }),
    retry: (failureCount: number, error: errorType) => {
      setTheFailureCount(failureCount)
      return shouldRetry(failureCount, error)
    },
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    onError: () => {
      console.log('query onError - setting error message');
      setErrorMessage('error accessing subscription details')
      setTheFailureCount(0)
    },
    enabled: isEmptyString(errorMessage),
  })

  const subscriptionDetails = queryResult.data
  // console.log({subscriptionDetails})

  useEffect(
    () => {
      document.body.style.backgroundColor = theme.palette.common.white},
    [theme.palette.common.white]
  );

  return (
    <EnableSigninWithMicrosoft
      subscriptionDetails={
        subscriptionDetails?.get_azure_subscription_details
      }
      isLoading={queryResult.isLoading}
      failureCount={theFailureCount}
      maxFailures={max404FailureCount}
      errorMessage={errorMessage}
      // Mock
      // subscriptionDetails={
      //   {
      //     get_azure_subscription_details: {
      //         subscription: {
      //           beneficiary: {
      //             emailId: 'andy@detexian.com'
      //           }
      //         }
      //     }
      //   }
      // }
      azureToken={azureToken}
    />
  )
}


    // isEmptyString(errorMessage) ?
      // didClickShowSubscriptionButton ?

        // :
        // <AzureLandingPage
        //   setDidClickEnableButton={setDidClickShowSubscriptionButton}
        // />
    // :
    // <AzureErrorPage
    //   // setDidClickEnableButton={setDidClickShowSubscriptionButton}
    //   errorMessage={errorMessage}
    // />
    // <ErrorScreen message={errorMessage} />


  // return useComponentForQueryResult({
  //   queryResult,
  //   pageLoadFunction,
  //   userErrorMessage: 'Error accessing subscription details',
  //   isFullScreen: true,
  // })
