import styled from '@emotion/styled';
import {TableRow} from '@mui/material';
import {compactRowPadding} from 'src/theme';


export const StyledTableRow = styled(
  TableRow,
  {shouldForwardProp: (prop) => (
    prop !== "shouldUseCompactVersion" &&
    prop !== "shouldHaveBottomBorder")
  }
)(
  ({shouldUseCompactVersion, shouldHaveBottomBorder, theme}: any) => {
    // only the fancy User Access Exception page wants a bottom border
    // on the last row
    const result: any = shouldHaveBottomBorder ?
    {
      '& td': {
        borderBottom: `solid 1px ${theme.palette.divider}`,
      },
    } :
    {
      '&:not(:last-child)':  {
        '& td': {
          borderBottom: `solid 1px ${theme.palette.divider}`,
        },
      }
    }

    if (shouldUseCompactVersion) {
      result['& td'] = {
          paddingTop: compactRowPadding,
          paddingBottom: compactRowPadding,
        }
    }
    return result;
   }
);


// type defaultTableRowProps = TableRowProps & {
//    compact?: boolean
// }

export const DefaultTableRow = ({
  className,
  shouldUseCompactVersion = false,
  shouldHaveBottomBorder = false,
  ...props
}: any) => {
  return <StyledTableRow
    className={className}
    shouldUseCompactVersion={shouldUseCompactVersion}
    shouldHaveBottomBorder={shouldHaveBottomBorder}
    {...props} />;
};
