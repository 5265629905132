import {FC} from "react";
import {ConfirmDialog} from "./ConfirmDialog";
import {Typography as T} from "@mui/material";

import {useState} from 'react';

export const useErrorDialog = ({
                                             onDismiss
                                           }: {
  onDismiss: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  return {
    dialog: ErrorDialog({
      isOpen,
      setIsOpen,
      onDismiss,
      errorMessage,
    }),
    setIsOpen,
    setErrorMessage,
  };
}


type errorDialogPropTypes = {
  isOpen: boolean;          // Is the dialog open?
  setIsOpen: any;           // Function to open/close the dialog
  onDismiss: () => void;    // Action to take when closing the dialog
  errorMessage: string;     // The message to show on the dialog
};

export const ErrorDialog: FC<errorDialogPropTypes> = ({
                                                 isOpen,
                                                 setIsOpen,
                                                 onDismiss,
                                                 errorMessage,
                                                 ...props
                                               }) => {

  return (
    <ConfirmDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      cancelButtonName="OK"
      onCancelButtonClick={onDismiss}
      heading="Error"
    >
      <T
        sx={{
          textAlign: 'left',
          marginLeft: '3px',
        }}
      >
        {errorMessage}
      </T>
    </ConfirmDialog>
  );
};