import {Theme} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useNavigationBarContainerStyles = makeStyles<Theme>(
  ({ spacing, defaultShadow, majorContainerBorderRadius }) => ({
    container: {
      padding: spacing(3),
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(3),
      boxShadow: defaultShadow,
      borderRadius: majorContainerBorderRadius,
    },
    mainViewSelect: {
      marginRight: spacing(3),
      '& > div': {
        width: 180,
      },
    },
  })
);

export const useCompanyNavigationBarStyles = makeStyles<Theme>(
  ({ spacing }) => ({
    subViewSelect: {
      '& > div': {
        width: 180,
      },
    },
    subViewItem: {
      paddingLeft: spacing(5),
    },
  })
);

export const useNavigationBarSubNavStyles = makeStyles<Theme>(
  ({ spacing, palette }) => ({
    container: {
      paddingLeft: spacing(3),
      display: 'flex',
    },
    link: {
      marginRight: spacing(1),
      paddingRight: spacing(1),
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(0, 0, 0, 0.3)',
      },
    },
  })
);
