import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope = process.env.REACT_APP_AUTH0_SCOPE

export const jwtDecode = (t: string | undefined) => {
  const token = {
    raw: '',
    header: {},
    payload: {},
  }
  if (typeof(t) === 'string') {
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
  }
  return token
};

type tokenType = {
  idToken:string | undefined;
  accessToken:string | undefined;
  token:string | undefined;
};

export const useAuth0Token = () => {
  const {
    getIdTokenClaims,
    getAccessTokenSilently,
    isLoading,
    error,
    user
  } : any = useAuth0();

  // Holds the entire state for the returned object. This is so that the state can be updated once the async function is complete
  // and unnecessary re-renders don't need to happen
  const [tokenState, setTokenState] = useState<tokenType>({idToken: undefined, accessToken: undefined, token: undefined});

  useEffect(
    () => {
      // create an async function to get an ID Token
      // and save the token in state
      const asyncFunction = async () => {
        if (
          !isLoading &&
          !error &&
          typeof getIdTokenClaims === 'function' &&
          typeof getAccessTokenSilently === 'function'
        ) {
          const idToken_ = await getIdTokenClaims();
          const accessToken_ = await getAccessTokenSilently({
            audience,
            scope
          })
          setTokenState({idToken: idToken_, accessToken: accessToken_, token: accessToken_});
        }
      };
      // call the async function
      asyncFunction();
    }, [error, getAccessTokenSilently, getIdTokenClaims, isLoading],
  );

  return {
    token: tokenState.token,
    idToken: tokenState.idToken,
    accessToken: tokenState.accessToken,
    user,
    isLoading,
    error,
  };
};

