// a pretty number inside two concentric circles, with nice shadow
import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';

const defaultHeight = 85;

export const NumberInCircle = ({
  number = 1,
  height = defaultHeight,
}: {
  number: number,
  height?: number,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        boxShadow: theme.defaultShadow,
        borderRadius: '50px',
        height: height,
        width: height,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'grey.300',
          borderRadius: '50px',
          height: '80%', // '70px',
          width: '80%', // '70px',
          padding: '10%', // '8px',
          margin: 'auto',
        }}
      >
        <Typography
          variant="h2"
          gutterBottom={false}
          sx={{
            fontSize: `${2 * height / defaultHeight}rem`,
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '8%', // '5px',
            marginBottom: 0,
            paddingBottom: 0,
          }}
        >
          {number}
        </Typography>
      </Box>
    </Box>
  );
};
