import React, { FC} from 'react';
import {DefaultTableCell} from 'src/components/common/DefaultTable';
import {EmailTableCell} from 'src/components/common/Tables/Utilities/tableCells/EmailTableCell';
import {UserNameTableCell} from 'src/components/common/Tables/Utilities/tableCells/UserNameTableCell';

type props = {
  hasUserUrl?: boolean;
  name: string;
  pubId?: string;
  email: string;
  privilegeLevel?: string;
};

export const NameEmailRoleTableCells: FC<props> = ({
  hasUserUrl,
  name,
  pubId,
  email,
  privilegeLevel
}) => {
  return (
    <>
      <UserNameTableCell
        hasUserUrl={hasUserUrl}
        name={name}
        pubId={pubId}
      />

      <EmailTableCell email={email} />

      <DefaultTableCell>{privilegeLevel}</DefaultTableCell>
    </>
  );
};
