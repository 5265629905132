import {ApplicationSummary} from "../DashboardQueryTypes";

const aggregateRowsByUserCount = (
  lowerLimit: number,
  upperLimit: number,
  enterprise: boolean, // return enterprise-enabled if true, otherwise user-consented
  rows: ApplicationSummary[]
) => {

  const filterFunction = (row: ApplicationSummary) => {

    const result = (
      row.user_count >= lowerLimit &&
      row.user_count <= upperLimit &&
      row.is_enterprise === enterprise
    );
    return result;
  }

  const filteredRows = rows.filter(filterFunction);
  return filteredRows.length;
}

export const over25 = (
  enterprise: boolean, rows: ApplicationSummary[]
) => {
  return aggregateRowsByUserCount(
    26, Number.MAX_SAFE_INTEGER, enterprise, rows
  );
}

export const tenTo25 = (
  enterprise: boolean, rows: ApplicationSummary[]
) => {
  return aggregateRowsByUserCount(10, 25, enterprise, rows);
}

export const fiveTo10 = (
  enterprise: boolean, rows: ApplicationSummary[]
) => {
  return aggregateRowsByUserCount(5, 10, enterprise, rows);
}

export const lessThan5 = (
  enterprise: boolean, rows: ApplicationSummary[]
) => {
  return aggregateRowsByUserCount(0, 5, enterprise, rows);
}

// only consider user-consented apps (is_enterprise === false)
export const accessCount = (key: string, rows: ApplicationSummary[]) => {
  return rows.filter(
    (row: ApplicationSummary) =>
    row[key as keyof ApplicationSummary] === true &&
    row.is_enterprise === false
  ).length
}
