import {Link as MaterialLink} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {clickableDivStyles} from 'src/theme';

// interface Props extends LinkProps {
//   to: string;
// }

// export const DefaultLink: FC<Props> = (props) => {
export const DefaultLink = (props: any) => {
  // console.log({props});
  return <MaterialLink
    sx={{
      paddingBottom: "0px",
      marginBottom:  "-3px",
      // backgroundColor: 'red',
      ...clickableDivStyles,
      ...props.sx
    }}
    color="primary"
    component={RouterLink}
    {...props}
  />;
};
