import {FC} from 'react';

export const RouteFound: FC = () => {
  console.warn('RouteFound page running');

  return (
    <>
      <h1>Route Found</h1>
    </>
  );
};

export const RouteNotFound = ({path}:{path?: string}) => {
  console.warn('RouteNotFound page running', {path});

  return (
    <>
      <h3>Route not found for {path}</h3>
    </>
  );
};
