import React, { FC} from 'react';
import {Box, BoxProps} from '@mui/material';
import {BackToPrevious} from 'src/components/common/BackToPrevious';
import {SaasLogo} from 'src/components/SaasLogo';

export const NoMatchingRecords: FC<BoxProps & {
  message: string,
  saasIdentifier?: string,
}> = (props) => {
  const {
    message='No Matching Records',
    saasIdentifier,
  } = props
  return (
  <Box
    // data-testid="LoadingSpinner"
    p={10}
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.0rem',

      }}
    >
      <BackToPrevious />
      <h2>{message}</h2>
      {
        saasIdentifier &&
        <SaasLogo
          saasIdentifier={saasIdentifier}
          size='m'
        />
      }
    </Box>
  </Box>
)};
