import {Route, Routes, useLocation} from 'react-router';
import {RouteNotFound} from 'src/RoutesManager/index';
import React from "react";
import {NoAuthLayout} from "../components/MainLayout/NoAuthLayout";
import {SubscriptionPage} from "../pages/Subscription/SubscriptionPage";
import {SubscriptionConfirmPage} from "../pages/Subscription/SubscriptionConfirmPage";

// For /subscription/...
export function RoutesManagerSubscription(): JSX.Element {
  const {pathname} = useLocation();
  return (<NoAuthLayout>
        <Routes>
          <Route
            path={`/new`}
            element={
              <SubscriptionPage />
            }
          />
          <Route
            path={`/confirm`}
            element={
              <SubscriptionConfirmPage />
            }
          />
          <Route
            path="*"
            element={
              <>
                <h3>RoutesManagerSubscription</h3>
                <RouteNotFound path={pathname} />
              </>
            }
          />
        </Routes></NoAuthLayout>
  );
}
