import {useState} from 'react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  IconButton,
  Typography as T,
  useTheme
} from '@mui/material';
import {DefaultButton} from 'src/components/common/DefaultButton';
import {
  addSaasError,
  addSaasGuideCmsRoute, addSaasSuccess, saasRouteFragment, updateSaasSuccess,
} from 'src/routes';
import {
  getOAuthConnectionRedirect,
  addSaaSConnection
} from 'src/services/SaasService/AddSaas';
import {dataGridRowBorder} from 'src/theme';
import {ConnectedSaas, ISaas} from 'src/types/Saas';
import {SaasLogo} from 'src/components/SaasLogo';
import {
  AddSaasDialogContainer,
  DialogSaasContainer,
  FooterActions,
  SaasLogoContainer,
  SharedAddSaasHeading,
} from 'src/pages/AdminPage/AddAndUpdateSaas/dialogs/misc/styles';
import {isOAuthWithExtrasSaas} from '../misc/ConnectionStructures';
import {FieldComponentForSaas} from '../sharedFields/FieldComponentForSaas';
import {DtxSpacer} from 'src/components/common/DtxSpacer';
import {updateConnection} from "src/services";
import { useQueryClient } from '@tanstack/react-query';
import {cacheKeyForClient, cacheKeys} from "src/services/shared/serviceConstants";
import {useMutationWithAccessToken} from "src/hooks/TanstackHooks";
import {FormValuesContext} from "../../../../../components/Forms/FormValuesContext";
import {usePortalContext} from "../../../../../hooks/PortalContext";

export const showGuide = (
  saas: any,
) => {
  setTimeout(() => window.open(
    `${addSaasGuideCmsRoute}/${saas.identifier}`,
    `${saas.name} Guide`
    )?.focus(),
    1000
  )
}

export const goToAddSaasErrorPage = (
  saas: ISaas,
  message: string
) => {
  const errorPageRedirect = `/${saasRouteFragment}${addSaasError}/${saas.identifier}?error=${message}`
  window.open(errorPageRedirect, '_self');
}

export type propTypes = {
  open: boolean;
  onClose: () => void;
  saas: ConnectedSaas;

  // these two are used when updating a connection
  connectionId?: string;
  nickname?: string;

  submitButtonTitle?: string
};

// Add SaaS dialog for SaaSes requiring user input
// Selects an input set based on SaaS requirements
export const UserInputDialog = ({
  onClose,
  open,
  saas,
  connectionId,
  nickname,

  // not currently used - allow for custom titles
  // like "Test and Confirm"
  submitButtonTitle,
  ...props
}: propTypes) => {
  // console.log('UserInputDialog', {saas});
  const theme = useTheme();
  const {clientInfo} = usePortalContext();

  const isUpdate = !!connectionId;

  // we pass fieldValuesState and fieldValidationState to children
  const fieldValuesState = useState<{[key: string]: any}>({});
  const [fieldValues, setFieldValues] = fieldValuesState;

  const fieldValidationsState = useState<{[key: string]: any}>({});
  const [fieldValidations, setFieldValidations] = fieldValidationsState;

  const [
    didFindValidationErrorOnSubmit,
    setDidFindValidationErrorOnSubmit
  ] = useState(false);

  // Not strictly needed but makes it easier to wrangle typescript
  // Used to build the AddSaasDialogContext, below in the render
  const stateForDialog: any = {
    fieldValuesState,
    fieldValidationsState,
    didFindValidationErrorOnSubmit,
  };

  // TODO review suspect logic
  const hasValidationError = () => {
    let result;
    const fieldValidationArray = Object.values(fieldValidations);
    // TODO this only catches *no* values, it doesn't
    // catch *some* values i.e. unclicked missing values
    if (!fieldValidationArray.length) {
      console.log('hasValidationError - empty fieldValidations');
      // alert('value required');
      return true;
    }
    result = fieldValidationArray.find(
      (valid) => {
      return valid === false;
    })
    result = result !== undefined; // convert to boolean
    // console.log('hasValidationError', result);
    return result;
  }

  const clearFieldState = () => {
    setFieldValues({});
    setFieldValidations({});
    setDidFindValidationErrorOnSubmit(false);
  }

  // clear the user inputs and close
  const handleClose = () => {
    clearFieldState();
    onClose ();
  }

  const queryClient = useQueryClient();

  const addSaas = useMutationWithAccessToken({
    mutation: addSaaSConnection,
    mutationCreationArgs: {
      saas,
    ...fieldValues,
    },
    tanstackOptions:{
      onSuccess: (data: any) => {
        const successPageRedirect = `/${saasRouteFragment}${addSaasSuccess}/${saas.identifier}`
        // console.log('onSuccess', {data}, {saas}, {successPageRedirect});
        window.open(successPageRedirect, '_self');
        // alert(`Successfully connected ${saas.name}`);
        queryClient.invalidateQueries({
          queryKey: [cacheKeyForClient(cacheKeys.connectedSaasList, clientInfo.id)]
        })
      },
      onError: (error: any) => {
      console.error('onError', {error});
      // const errorMessage = `Adding SaaS connection failed for ${saas.name} with error ${error.message}`;
      goToAddSaasErrorPage(
        saas,
        `Adding SaaS connection failed for ${saas.name}.`
      );
    },
  }
}
  );

  const {
    // isLoading, isSuccess, error,
    mutate: updateOAuthConnection,
  } = useMutationWithAccessToken({
    mutation: updateConnection,
    mutationCreationArgs: {saas, connectionId, nickname, ...fieldValues},
    tanstackOptions: {
      onSuccess: (data: string | URL | undefined) => {
        // console.log('onSuccess', {data});
        if (data !== undefined){
          // Redirect to the update saas confirmation page
          const successPageRedirect = `/${saasRouteFragment}${updateSaasSuccess}/${saas.identifier}`
          window.open(successPageRedirect, '_self');
        }
        else
          alert(`Error: updateConnection returned no data for ${saas.name}`);
      },
      onError: (error: any) => {
        console.log('onError', {error});
        goToAddSaasErrorPage(
          saas,
          `updateConnection failed for ${saas.name}.`
        );
        // alert(`updateConnection failed for ${saas.name} with error ${error}`);
      },
     }
  });

  // These two only used by Zendesk at present.
  // The other OAuths require no user input.
  const addOAuthSaasWithExtras = useMutationWithAccessToken({
    mutation: getOAuthConnectionRedirect, // was updateConnection
    mutationCreationArgs: {saas, tenantId: fieldValues.url},
    tanstackOptions: {
      onSuccess: (data: any) => {
        console.log('onSuccess', {data}, 'domain', fieldValues.url);
        // alert(`Successfully connected ${saas.name} via OAuth`);
        if (data !== undefined)
          window.open(data, '_blank');
        else
          alert(`Error: addOAuthSaasWithExtras returned no data for ${saas.name} and ${fieldValues.url}`);
      },
      onError: (error: any) => {
        console.log('onError', {error});
        goToAddSaasErrorPage(
          saas,
          `addOAuthSaasWithExtras failed for ${saas.name}.`
        );
        // alert(`addOAuthSaasWithExtras failed for ${saas.name} with ${error}`);
      },
    }
  });

  const {
    // isLoading, isSuccess, error,
    mutate: updateOAuthSaasWithExtras,
  } = useMutationWithAccessToken({
    mutation: getOAuthConnectionRedirect, // was updateConnection
    mutationCreationArgs: {saas, connectionId, nickname, tenantId: fieldValues.url},
    tanstackOptions: {
      onSuccess: (data: string | URL | undefined) => {
        // console.log('onSuccess', {data});
        if (data !== undefined)
          window.open(data, '_self');
        else
          alert(`Error: updateConnection returned no data for ${saas.name} ${nickname}`);
      },
      onError: (error: any) => {
        console.log('onError', {error});
        goToAddSaasErrorPage(
          saas,
          `updateConnection failed for ${saas.name}`
        );
        // alert(`updateConnection failed for ${saas.name} ${nickname} with error ${error}`);
      },
     }
  });

  const logState = (isValid: boolean) => {
    console.log(
      `handleSubmit - ${isValid ? 'valid' : 'invalid'}`,
      {fieldValidationsState},
      {fieldValuesState}
    );
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    // const email = fieldValues.email;
    if (hasValidationError()) {
      // this notifies children fields so they can show all
      // the errors. Otherwise, to avoid annoyance they only
      // show errors on blur. TODO better way?
      logState(false);
      setDidFindValidationErrorOnSubmit(true);
    }
    else {
      // logState(true);

      if (isOAuthWithExtrasSaas(saas.identifier)) {
        // Only used by Zendesk (and github?) at present.
        // The other OAuths require no user input.
        if (isUpdate) {
          // console.log(
          //   'attempting to send user domain',
          //   fieldValues.tenant_id,
          //   'to backend for Zendesk'
          // );

          // Adding the connectionId here, in addition to
          // on creation, is redundant but it might prevent
          // a possible race seen on production.
          // @ts-ignore
          updateOAuthSaasWithExtras({connectionId})
        }
        else if (addOAuthSaasWithExtras) {
          // console.log(
          //   'attempting to send user domain',
          //   fieldValues.tenant_id,
          //   'to backend for Zendesk'
          // );
          addOAuthSaasWithExtras.mutate();
        }
        else {
          console.error('addOAuthSaasWithExtras mutation undefined')
        }
      }
      else {
          // either add or update the saas
        isUpdate ?
            // @ts-ignore
            updateOAuthConnection ? updateOAuthConnection({}) :
            console.log('updateOAuthConnection mutation undefined')
            :
            addSaas ?
              addSaas.mutate() :
              console.log('addSaas mutation undefined');
      }
      handleClose(); // also clears the user inputs
    }
  }

  // routes to a page which will show a contentful guide or the default
  const handleShowGuide = (event: any) => {
    // setTimeout(() => window.open(
    //   `${addSaasGuideCmsRoute}/${saas.identifier}`,
    //   windowTitle
    //   )?.focus(),
    //   1000
    // )
    showGuide(saas);
  }

  return (<>
    <Dialog
      // This styles the whole page - not just the dialog
      // sx={{borderRadius: theme.more.borderRadius}}
      {...props}
      open={open}
    >
      <AddSaasDialogContainer>
        <SharedAddSaasHeading>
          <DialogSaasContainer>
            <SaasLogoContainer spacing={3}>
              {saas?.identifier ?
                <SaasLogo saasIdentifier={saas.identifier} />
                :
                'error:'
              }
            </SaasLogoContainer>
            <T
              variant='h3'
              color="textPrimary"
            >
              {isUpdate ? 'Update ': 'Add '}
              {saas?.name ? saas?.name : 'no saas'}
            </T>
          </DialogSaasContainer>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon color="primary" />
          </IconButton>
        </SharedAddSaasHeading>
        <Box
          sx={{
            border: dataGridRowBorder,
            borderRadius: theme.more.borderRadius,
            margin: 'auto',
            padding: theme.spacing(2),
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormValuesContext.Provider
              value={{...stateForDialog}}
            >
              {/* Barely visible but the heart of the dialog: the
                  validated input components (including the p12
                  upload component) representing required inputs
                  for connecting the SaaS
              */}
              <FieldComponentForSaas
                saas={saas}
              />
            </FormValuesContext.Provider>
            <DtxSpacer space={2} />
            <Box sx={{textAlign: 'right'}}>
              <DefaultButton
                sx={{marginRight: theme.spacing()}}
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </DefaultButton>
              <DefaultButton
                sx={{
                  marginRight: 0,
                  // paddingRight: 0
                }}
                variant="contained"
                // onClick={handleSubmit}
                type="submit"
              >
                {
                  submitButtonTitle ?
                    submitButtonTitle :
                    `${isUpdate ? 'Update': 'Add'} ${saas.name}`
                }
              </DefaultButton>
            </Box>
          </form>
        </Box>
        <FooterActions>
          <DefaultButton
            icon={AddBoxOutlinedIcon}
            onClick={handleShowGuide}
            sx={{marginLeft: '-13px'}}
          >
            {saas.name} Setup Guide
          </DefaultButton>
        </FooterActions>
      </AddSaasDialogContainer>
    </Dialog>
  </>);
};


