import {gql} from 'graphql-request';
import {saasFragment} from 'src/services/shared/Fragments';

export const connectedSaasesQuery = gql`
  query GetConnectedSaases($clientBoolExp: client_bool_exp) {
    client_saas (
      where: {
        saas: {}, # filter out disabled SaaSes
        client: $clientBoolExp
      }, 
      order_by: {saas: {name: asc}}
    ) {
      saas  {
        ...Saas
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

export const connectedSaasesDetailQuery = gql`
  query GetConnectedSaasDetails($clientBoolExp: client_bool_exp) {
    client_saas (
      where: {
        saas: {},# filter out disabled SaaSes
        client: $clientBoolExp
      }, 
      order_by: {saas: {name: asc}}
    ) {
      saas  {
        ...Saas
      }
      secret_version {
        version
        type
      }
      pub_id # the connection id
      nick_name
    }
  }
  ${saasFragment}
`;

export const getAllSaasQuery = gql`
  query AllSaas {
    saas (order_by: {name: asc}) {
      pub_id
      name
      description
    }
  }
`;
