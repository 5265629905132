import {GraphQLClient} from "graphql-request";

export const graphQLClient = async ({
  accessToken,
  signal
}: {
  accessToken?: any,
  signal?: any,
}) => {
  const endpoint = process.env.REACT_APP_GRAPHQL_API_ENDPOINT

  if (!endpoint) {
    throw new Error(
      'graphQLClient - GraphQL endpoint argument undefined'
    );
  }

  if (!accessToken) {
    console.log("creating non-authenticated GraphQL client");
  }

  let variables = {signal};

  if (accessToken) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    //   'x-hasura-role': 'dxn-admin',
    //   'x-hasura-company-override': 'Lesmills',
    // } as Record<string, string>,
    }
    // @ts-ignore
    variables.headers = headers;
  }

  return new GraphQLClient(
    endpoint,
    variables,
  );
};
