import {ISaasGraphQLResponse} from "src/types/Saas";
import {IDrift} from "src/types/Drift";
import {transformConnectedSaasFlat, transformSaasResponse} from "../shared/sharedTransforms";
import {IAppDrift} from "src/types/AppDrift";
import {transformAllGenericUsersElement} from "../UserService/UserServiceTransform";

// Transforms a drift that isn't for an app (APP_STATUS_CHANGE)
// Used by pages
//   Privileged Access Changes
//   Authentication Exceptions
//   User Exceptions (soon)

export const transformDrifts = ({
         client_saas,
         generic_user,
         old_value,
         new_value,
         drift_time,
         pub_id,
     }: {
    client_saas: {
        saas: ISaasGraphQLResponse
    };
    generic_user: {
        email: string;
        name: string;
        pub_id: string;
    };
    old_value: string;
    new_value: string;
    drift_time: string;
    pub_id: string;
}): IDrift => {
    // console.log('transformDrifts');
    return {
        saas: transformSaasResponse(client_saas.saas),
        user: {
            email: generic_user.email,
            name: generic_user.name,
            pubId: generic_user.pub_id,
        },
        current: new_value,
        previous: old_value,
        driftTime: drift_time,
        id: pub_id,
    };
};

// Transforms app drifts (APP_STATUS_CHANGE) from graphQL to typescript form
// It breaks down the addendum specifically for app drifts
export const transformAppDrifts = (appDrifts: any):IAppDrift[] => {
    return appDrifts.map((driftFromApi: any) =>  { return {
        id: driftFromApi.pub_id,
        applicationName: driftFromApi.addendum?.app_name,
        isEnterprise: driftFromApi.addendum?.is_enterprise,
        isAdminConsented: driftFromApi.addendum?.is_admin_consented,
        isNewToOrg: driftFromApi.addendum?.is_new_to_org,
        previous: driftFromApi.old_value,
        current: driftFromApi.new_value,
        driftTime: driftFromApi.drift_time,
        saas: transformConnectedSaasFlat(driftFromApi.client_saas?.saas, driftFromApi.client_saas),
        user: driftFromApi.generic_user ? transformAllGenericUsersElement(driftFromApi.generic_user) : null,
        appPubId: driftFromApi.app?.pub_id,
    }});
};