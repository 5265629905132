import {ReactNode} from "react";
import {Box} from "@mui/material";

export const RemediationMenuBox = (
  {children}: {children: ReactNode}
) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '13px',
    }}
  >
    {children}
  </Box>
)
