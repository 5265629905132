import {GridColDef} from '@mui/x-data-grid';
import {useState} from 'react';
import {DefaultTableContainer} from 'src/components/common/DefaultTable';
import {
  StyledDataGrid,
  styledDataGridSxValues
} from 'src/components/common/Tables/DefaultGrid/dataGridStyles';
import {largerRowsPerPageOptions} from 'src/components/common/Tables/DefaultGrid/dataGridUtilities';
import {
  GridHeadingWithExport
} from 'src/components/common/Tables/DefaultGrid/GridHeadingWithExport';
import {randomInt} from 'src/services/DashboardService/mock/mock';


const userRolePath = 'latest_saas_user.privilege_level';

type props = {
  users: any;
  columns: GridColDef[];
  panelHeading: any;
  isLoading: boolean;
}

export const AppUserGrid = ({
  users = [],
  columns,
  panelHeading,
  isLoading,
}: props) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  return (
    <DefaultTableContainer
      id={'non=it-approved-apps'}
      shouldUseTableContainer={false}
    >
      <StyledDataGrid
        sx={styledDataGridSxValues}
        rows={users}
        getRowId={(row) => row.pubId + randomInt(1, 30000)}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={largerRowsPerPageOptions}
        pagination
        hideFooter={users?.length <= paginationModel.pageSize}
        disableRowSelectionOnClick={true}
        autoHeight ={true}
        rowSpacingType="border"
        disableColumnMenu={false}
        loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{
              field: userRolePath,
              sort: 'asc',
            }],
          },
        }}
        components={{
          Toolbar: () => (
            <GridHeadingWithExport
              shouldShowBackToPrevious={false}
              heading={panelHeading}
            />
          )
        }}
      />
    </DefaultTableContainer>
  )
}

