import React from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer
} from "recharts";

type DriftGraphProps = {
  data: any[];
  series: {name: string; color: string }[];
  small?: boolean;
}
export const DriftGraphArea = ({data, series}: DriftGraphProps) => {
  return (<ResponsiveContainer width="100%" height="100%">
    <AreaChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" />
      <Tooltip />
      {series.map((label, index) => (
        <React.Fragment key={label.name}>
          <YAxis yAxisId={label.name} stroke={label.color} />
          <Area
            yAxisId={label.name}
            key={index}
            stroke={label.color}
            fill={`${label.color}66`}
            strokeWidth={3}
            dataKey={label.name}
          />
        </React.Fragment>
      ))}
    </AreaChart>
    </ResponsiveContainer>
  );

}