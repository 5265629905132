import React, {useEffect, useMemo, useState} from "react";
import {Box, Button, Typography as T} from "@mui/material";
import {DtxSpacer} from "src/components/common/DtxSpacer";
import {Header} from "src/pages/Azure/Header";
import {Footer} from "src/pages/Azure/Footer";
import {SubscriptionDetails} from "src/pages/Azure/SubscriptionDetails";
import {useNavigate} from "react-router";
import {dashboardRoute} from "src/routes";
import {useMutationWithAccessToken} from "src/hooks/TanstackHooks";
import {getConsentUrl} from "src/services/Azure/GetConsentUrl";
import dayjs from "dayjs";
import {isEmptyString, toTitleCase} from "src/utils/string";
import {ErrorScreen} from "src/components/common/ErrorScreen";
import {styled} from "@mui/system";
import Avatar from "@mui/material/Avatar";
import microsoftButtonIcon from "src/assets/images/azure/microsoftButtonIcon.png";
import {useTheme} from "@mui/styles";
import {LoadingScreen} from "src/components/LoadingScreen";
import {NoAuthLayout} from "../../components/MainLayout/NoAuthLayout";

export const MicrosoftButton = ({
  width,
  onClick,
  title,
  disabled = false,
}: {
  width: string,
  onClick: React.MouseEventHandler,
  title: string,
  disabled?: boolean,
}) => {
  const theme = useTheme()
  return <Button
    variant="contained"
    disabled={disabled}
    sx={{
      width: width,
      maxHeight: "35px",
      color: "white",
      paddingRight: 0,
      backgroundColor: theme.more.submitButtonColor,
      textTransform: "none",
    }}
    startIcon={
      <MicrosoftButtonImage/>
    }
    onClick={onClick}
  >
    {title}
  </Button>;
}

const EnableSigninWithMicrosoftButton = ({
  handleClick,
  disabled,
}: {
  handleClick: React.MouseEventHandler,
  disabled: boolean,
}) => {
  // const navigate = useNavigate();
  return <MicrosoftButton
    onClick={handleClick}
    width="240px"
    title="Enable Sign In with Microsoft"
    disabled={disabled}
  />
}

export const MicrosoftButtonImage = () => {
  return <Avatar
    src={microsoftButtonIcon}
    variant="square"
    sx={{
      backgroundColor: "white",
      padding: "2px",
      borderRadius: "3px",
      height: "26px",
      width: "26px",
      marginLeft: "-21px",
    }}
  />;
}

const Blurb = ({
  duration,
  isFreeTrial
} : {
  duration: string,
  isFreeTrial: boolean
  }
) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'flex-start',
      }}
    >
      <T
        sx={{
          fontWeight: '800',
          color: theme.palette.grey[700],
          fontSize: '1.3rem',
        }}
      >
        {duration} {isFreeTrial ? 'Free Trial' : 'subscription'} for
      </T>
      <T
        sx={{
          fontWeight: '800',
          color: theme.palette.grey[700],
          fontSize: '1.3rem',
        }}
      >
        Detexian SaaS Security Posture Manager
      </T>
    </Box>
  )
}

const  RetryIndicator = ({
  failureCount,
  maxFailures
}: {
  failureCount: number,
  maxFailures: number,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <LoadingScreen />
      <DtxSpacer />
      {(failureCount !== 0 && (failureCount <= maxFailures)) &&
        <T
          sx={{
            color: theme.more.andyBlue,
            fontSize: '1.0rem',
          }}
        >Retrying {failureCount} of {maxFailures}</T>
      }
    </Box>
  )
}

export const StyledAzurePageBox = styled(Box)(
  ({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 'none',
    margin: 0,
    padding: 0,
  })
);

export const EnableSigninWithMicrosoft = ({
  subscriptionDetails,
  azureToken,
  isLoading,
  failureCount,
  maxFailures,
  errorMessage,
}: {
  subscriptionDetails: any,
  azureToken: string | undefined,
  isLoading: boolean,
  failureCount: number,
  maxFailures: number,
  errorMessage: string | undefined,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // console.log({subscriptionDetails})

  const [
    getConsentUrlErrorMessage,
    setGetConsentUrlErrorMessage
  ] = useState('');

  const {
    mutate: doGetConseltUrl,
  } = useMutationWithAccessToken({
    mutation: getConsentUrl,
    mutationCreationArgs: {azureToken},
    tanstackOptions: {
      onSuccess: (data: string | URL | undefined) => {
        console.log('onSuccess', {data});
        if (data !== undefined) {
          window.open(data, '_self');
        }
        else {
          console.error('getConsentUrl - no data, consent failed');
          setGetConsentUrlErrorMessage('consent failed');
          // alert(`Error: consent failed}`);
        }
      },
      onError: (error: any) => {
        console.error('getConsentUrl - consent failed', {error});
        setGetConsentUrlErrorMessage('consent failed');
        // alert(`consent failed with error ${error}`);
      },
      enabled: !!azureToken,
    },
  });

  const handleClick = () => {
    let result = () => navigate(dashboardRoute)
    if (azureToken) {
      // console.log('setting handleClick to doGetConseltUrl')
      result = doGetConseltUrl
    }
    return result;
  }

  useEffect(
    () => {
      document.body.style.backgroundColor = theme.palette.common.white
    }, [theme.palette.common.white]
  );

  const subscriptionDuration = () => {
    const term = subscriptionDetails?.subscription?.term;
    const startDate = term?.startDate
    const endDate = term?.endDate
    let result = ''
    if (!isEmptyString(startDate)) {
      result = dayjs.duration(dayjs(endDate).diff(startDate)).humanize();
      result = result.replace('a ', 'One ');
      result = toTitleCase(result);
    }
    return result;
  }

  const calculatedErrorMessage = useMemo (() => {
    let result;
    if (!isEmptyString(errorMessage))
      result = errorMessage;
    else if (!isEmptyString(getConsentUrlErrorMessage))
      result = getConsentUrlErrorMessage
    return result || '';
  },[errorMessage, getConsentUrlErrorMessage])


  // we handle the loading and error manually rather than using
  // useComponentForQueryResult because we have no enclosing
  // layout for this page.

  // On second thought we could probably
  // get useComponentForQuery Result with some effort.
  return (<NoAuthLayout>
      <Header/>
      <DtxSpacer space={5}/>
      {
        isLoading ?
          <>
            <DtxSpacer />
            <RetryIndicator
              failureCount={failureCount}
              maxFailures={maxFailures}
            />
          </> :
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: isEmptyString(calculatedErrorMessage) ? '400px' : '600px',
            }}
          >
            {isEmptyString(calculatedErrorMessage) ?
              <>
                <Blurb
                  duration={subscriptionDuration()}
                  isFreeTrial={subscriptionDetails?.subscription?.isFreeTrial}
                />
                <DtxSpacer/>
                <SubscriptionDetails
                  subscriptionDetails={subscriptionDetails}
                />
              </>
              :
              <ErrorScreen
                message={calculatedErrorMessage}
                isFullScreen={false}
              />
            }
          </Box>
          <DtxSpacer space={5}/>

          {
            isEmptyString(calculatedErrorMessage) && <EnableSigninWithMicrosoftButton
              handleClick={handleClick()}
              disabled={!subscriptionDetails}
            />
          }

          <DtxSpacer space={7}/>
          <Footer/>
        </>
      }
    </NoAuthLayout>
  )
}
