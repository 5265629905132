import {FC} from 'react';
// import {Button, ButtonProps} from '@mui/material';
import clsx from 'clsx';
import {
  usePillStyles,
} from './styles';

export type PillStyleProps = {
  pillColor: string;
  pillWidth?: string;       // Optionally override the default pill width (68px)
  children?: any;
};

export const Pill: FC<PillStyleProps & {title: string, hoverText?: string}> = ({
  ...props
}) => {
  const styleProps = {pillColor: props.pillColor, pillWidth: props.pillWidth};
  // The styleProps end up as props for any styles which are functions.
  // See further notes in styles.ts.
  const { pillStyle } = usePillStyles(styleProps);

  return (
    <div className={clsx(pillStyle)} title={props.hoverText}>
      {props.title}
    </div>
  );
};
