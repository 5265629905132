import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import {DailyDriftCounterInput} from "./DailyDriftDataCounters";

dayjs.extend(weekday);


export interface IDriftGraphSeries {
  name: string;
  color: string;
  start: number;
  countFn: ({date, items}: DailyDriftCounterInput) => number;
  data: any[];
}
// The sample data contains a full year, so depending on the time period chosen in the UI
// the data set needs to be trimmed before going into the graph
// This may or may not be needed for the real data depending on whether we go back to the server
// to get refresh data each time the "Time period" changes
export const trimData = (data: any[], timePeriod: string) => {
  let scale = 'day';
  let trimmedData = data;

  // timePeriod comes from the "Range dropdown"
  switch (timePeriod) {
    case 'days7':
      trimmedData = data.filter((day) => dayjs(day.date) > dayjs().subtract(7, 'days'));
      scale = 'day';
      break;
    case 'days28':
      trimmedData = data.filter((day) => dayjs(day.date) > dayjs().subtract(28, 'days'));
      scale = 'day';
      break;
    case 'months3':
      trimmedData = data.filter((day) => dayjs(day.date) > dayjs().subtract(3, 'months'));
      scale = 'week';
      break;
    case 'months6':
      trimmedData = data.filter((day) => dayjs(day.date) > dayjs().subtract(6, 'months'));
      scale = 'week';
      break;
    case 'months12':
      trimmedData = data.filter((day) => dayjs(day.date) > dayjs().subtract(12, 'months'));
      scale = 'month';
      break;
    default:
      console.error('Invalid timePeriod', timePeriod);
  }
  return {scale, trimmedData};
}

// The data contains the total count for each, so depending on the time period chosen in the UI
// we need to pick the right day to show on the graph
export const filterDailyDriftByResolution = (
  {
    data,         // The full set of data
    resolution,   // The time range to pick the date by (day, week, month)
  }:{
    data:any[],
    resolution: string
  }) => {

  // Make sure the order is ascending, the graph needs it that way and we also need to know what the latest day is
  let orderedData = data.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
  let lastDay = orderedData[orderedData.length - 1];

  switch(resolution) {
    case 'day':
      // If we are showing each day on the graph, no need to filter, just return the whole set of days
      return orderedData;
    case 'week':
      // If we are showing weeks on the graph, only return the day if it is at the start of a week
      // date.weekday() === 0 means it is a Sunday
      // Also add the day if it is today
      return orderedData.filter((item, i) => {
        return dayjs(item.date).weekday() === 0 || lastDay.date === item.date;
      });
    case 'month':
      // If we are showing months on the graph, only add the day if it is the first of the month
      // Also add the day if it is today
      return orderedData.filter((item, i) => {
        return dayjs(item.date).startOf('month').isSame(dayjs(item.date))  || lastDay.date === item.date
      });
    default:
      console.error('Invalid resolution', resolution);
      return [];
  }
}

export const getDailyCounts = (driftGraphSeries: IDriftGraphSeries[], endDate?: Date , startDate?: Date) => {
  // If no end date is specified, default to today
  let currentDay = endDate ? dayjs(endDate) : dayjs();
  // If no start date is specified, default to a year before the end date
  let lastYear = startDate ? dayjs(startDate) : currentDay.subtract(1, 'year');

  let tally:any[] = [];
  let seriesCounts:{ [key: string]: number } = {};

  // We need to keep the running total as we work backwards through time
  // Using an object for this as we have an unknown amount of series
  for(let s of driftGraphSeries) {
    seriesCounts[s.name] = s.start;
  }

  // Loop through each day for the entire time period, if there is no record for that
  // date in the data set we still need to add the day for the graph
  while (!lastYear.isAfter(currentDay)) {
    let item: any = {date: `${currentDay.format('YYYY-MM-DDT00:00:00')}`};

    // Go through each series and call the countFn function to get the count for that day
    for(let s of driftGraphSeries) {
      seriesCounts[s.name] = seriesCounts[s.name] - s.countFn({date:item.date, items: s.data});
      item[s.name] = seriesCounts[s.name];
    }

    // Add the item and go to the next day
    tally.push(item);
    currentDay = currentDay.subtract(1, 'day');
  }
  return tally;
}

// Adds the label for the X-axis of the grid
// If it is the final item, set the value as "Current"
export const addDateLabels = (data: any[]) => {
  let count = data ? data.length : 0;
  return data.map((item, index) => {
    let label = index >= count -1 ? "Current" :dayjs(item.date).format('DD MMM YY');
    return {
      ...item,
      label: label
    }
  });
}