import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import React, {useContext, useState} from "react";
import {Token} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/Token";
import {NameAndPassword} from "src/pages/AdminPage/AddAndUpdateSaas/dialogs/generic/NameAndPassword";
import {FormValuesContext} from "../../../../../components/Forms/FormValuesContext";

export const HiBob = () => {
  const literal_shouldUseToken = 'token';
  const literal_shouldUsePassword = 'password';
  const[
    shouldUseApiToken,
    setShouldUseApiToken
  ] = useState(literal_shouldUsePassword)

  const {
    fieldValuesState,
    fieldValidationsState,
    // didFindValidationErrorOnSubmit
  }: any = useContext(FormValuesContext)

  const [,setFieldValuesState] = fieldValuesState;
  const [,setFieldValidationsState] = fieldValidationsState;

  const handleRadioChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log({event})
    setShouldUseApiToken(event.target.value)
    // clear the field values and validation states on radio switch
    setFieldValuesState({});
    setFieldValidationsState({});
  }

  return <>
    <FormControl>
      <RadioGroup
        name={'hibobAuthChooser'}
        row
        aria-labelledby="time-span-radio-buttons-group-label"
        // defaultValue="month"
        value={shouldUseApiToken}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value={literal_shouldUsePassword}
          control={<Radio />}
          label="API Service User" />
        <FormControlLabel
          value={literal_shouldUseToken}
          control={<Radio />}
          label="API Token" />
       </RadioGroup>
    </FormControl>
    {
      shouldUseApiToken === literal_shouldUseToken ?
        <Token
          label='API Token'
          valueKey='api_key'
        />
        :
        <NameAndPassword />
    }
   </>;
};
