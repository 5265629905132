import clsx from 'clsx';
import {DetailedHTMLProps, HTMLAttributes} from 'react';
import {useDefaultStackStyles} from './styles';

type DefaultStackProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  isFlexItem?: boolean;
};

function DefaultStack({ children, isFlexItem, ...props }: DefaultStackProps) {
  const { container, containerFlexItem } = useDefaultStackStyles();

  return (
    <div
      {...props}
      className={clsx(container, props.className, {
        [containerFlexItem]: isFlexItem,
      })}
    >
      {children}
    </div>
  );
}

export { DefaultStack };
