import {PrivateRoute} from 'src/components/PrivateRoute';
import {SingleUserPage} from 'src/pages/SingleUserPage';
import {UserNavigationBar} from 'src/components/NavigationBar';
import {DefaultStack} from 'src/components/common/DefaultStack';
import {Route, Routes, useLocation} from 'react-router';
import {RouteNotFound} from 'src/RoutesManager/index';

// For /user/...
export function RoutesManagerUser(): JSX.Element {
  const {pathname} = useLocation();
  return (
    <DefaultStack isFlexItem>
      <UserNavigationBar />
      <DefaultStack isFlexItem>
        <Routes>
          <Route
            path={`:userId`}
            element={
              <PrivateRoute
                component={SingleUserPage}
              />
            }
          />
          <Route
            path={``}
            element={
              <PrivateRoute
                component={SingleUserPage}
              />
            }
          />
          <Route
            path="*"
            element={
              <>
                <h3>RoutesManagerUser</h3>
                <RouteNotFound path={pathname} />
              </>
            }
          />
        </Routes>
      </DefaultStack>
    </DefaultStack>
  );
}
