import {alpha, Box, styled, useTheme} from '@mui/material';
import Menu, {MenuProps} from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React, {useState} from 'react';
import {borderRadiusSmall} from 'src/theme';

const minMenuWidth = 160;

// The concept is that this component defines its
// own state selectionState variable and returns
// it for the Parent to use for filtering
export const useFilterMenu = ({
  label,
  ariaLabel,
  menuId,
  options,
}: {
  label: string
  ariaLabel: string
  menuId: string
  options: any[]
}) => {
  const selectionState = useState(options[0]);

  const LoadedFilterMenu = () => {
    // console.log('useFilterMenu', {options}, {selectionState});
    return FilterMenu({
      label,
      ariaLabel,
      menuId,
      selectionState,
      options,
    })
  }

  // return a Filter menu component and the selectionState
  return {
    filterMenu: LoadedFilterMenu,
    selectionState: selectionState
  }
}

const StyledMenu = styled(
  (props: MenuProps) => (
    <Menu
      sx={{
        paddingTop: 0,
        marginTop: '-21px',
        marginLeft: 0,
        paddingLeft: 0,
        ...props.sx
      }}
      // elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  )
)(
  ({theme}) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: minMenuWidth+26,
      color:
        theme.palette.mode === 'light' ?
          'rgb(55, 65, 81)' :
          theme.palette.grey[300],
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  })
);

export interface FilterMenuProps extends Omit<MenuProps, 'open'> {
  label: string;
  options: any[];
  selectionState: any;
  ariaLabel: string;
  menuId: string;
}

const FilterMenu = ({
  label,
  options,
  selectionState,
  ariaLabel,
  menuId,
}: FilterMenuProps) => {
  // console.log('FilterMenu', {options});
  const theme = useTheme();

  const [
    anchorEl,
    setAnchorEl,
  ] = useState<null|HTMLElement>(null);

  const [selectedOption, setSelectedOption] = selectionState;

  const open = Boolean(anchorEl);

  const handleClickListItem = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    option: any,
  ) => {
    // console.log('handleMenuItemClick', {option})
    setSelectedOption(option);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // extracted this for logging and debug
  const menuItems = () => {
    // console.log('FilterMenu map', {options});
    return options.map(
      (option) => {
        // console.log('FilterMenu map iteration', {option});
        return (
          <MenuItem
            key={option.id}
            // disabled={option.id === selectedOption.id}
            // above makes it grey and I can't un-gray it -
            // it still seems to be disabled without it.
            selected={option.id === selectedOption.id}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.label}
          </MenuItem>
        );
      }
    )
  }

  return (
    <div>
      <List
        // component="nav"
        sx={{
          bgcolor: theme.palette.common.white,
          marginTop: '-13px',
          paddingTop: '0px',
          // ...sx
        }}
      >
        <ListItem
          button
          aria-haspopup="listbox"
          aria-controls={menuId}
          aria-label={ariaLabel}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={{
            marginTop: '8px',
            paddingTop: '0px',
            // override existing hover
            '&:hover': {
              backgroundColor: theme.palette.common.white,
            },
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
              paddingTop: '5px',
            }}
            secondaryTypographyProps={{
              color: theme.palette.text.primary,
              fontSize: '1.0rem',
              marginBottom: 0,
              padding: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              border: '1px solid gray',
              borderRadius: borderRadiusSmall,
              sx: {
                '&:hover': {
                 borderColor: theme.palette.common.black,
                },
              }
            }}
            primary={label}
            secondary={
              <Box
                component="span" // avoids React dom warning about div in p
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minWidth: `${minMenuWidth}px`
                }}
              >
                <span>{selectedOption?.label}</span>
                <ArrowDropDownIcon/>
              </Box>}
          />
        </ListItem>
      </List>
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': label,
          role: 'listbox',
        }}
        // sx={sx}
      >
        {menuItems()}
      </StyledMenu>
    </div>
  );
};
