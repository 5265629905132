import {IDailyDrift, IDailyInactiveUserDrift} from 'src/types/Drift';
import {doAuthenticatedQuery} from "../shared/doQuery";
import {driftTypes} from "./DriftsService";
import {dailyDriftQuery, dailyInactiveUserDriftQuery} from "./DailyDriftQueries";
import {transformInactiveDrifts, transformMFADrifts} from "./DailyDriftTransform";
import {nullCollapsableClientPubId} from "../shared/nullCollapsableUtilities";
import {QueryContext} from "../../hooks/TanstackHooks";


export const getMFADailyDrifts = async ({accessToken, queryContext}:{
  accessToken: any,
  queryContext: QueryContext}
): Promise<IDailyDrift[]> => {

  let theQuery = async (client: any) => {
      return await client.request(
        dailyDriftQuery,
        {
          "driftFilter": {"subject": {"_eq" : driftTypes.mfa}, "client_saas": {"client": {"pub_id" :{"_eq": queryContext.client.id}}}},
        },
      );
    };

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    return transformMFADrifts(response.daily_drift);
  }
  catch(error) {
    throw error;
  }
};

export const getInactiveUserDailyDrifts = async ({accessToken, queryContext, parameters}:{
  accessToken: any,
  queryContext: QueryContext,
  parameters: any}
): Promise<IDailyInactiveUserDrift[]> => {

  let theQuery = async (client: any) => {
    return await client.request(
      dailyInactiveUserDriftQuery,
      {...nullCollapsableClientPubId(queryContext.client.id)},
    );
  };

  try {
    const response = await doAuthenticatedQuery(theQuery, accessToken);
    return transformInactiveDrifts(response.inactive_zombie_drift_count);
  }
  catch(error) {
    throw error;
  }
};