import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider
} from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import {BrowserRouter} from 'react-router-dom';
import {Auth0ProviderWithHistory} from 'src/Auth0ProviderWithHistory';
// import {CompatRouter} from 'react-router';
import {RoutesManager} from 'src/RoutesManager';
// import {createStore, StoreContext} from './stores';
import {theme} from './theme';
import {BaseDataLoader} from 'src/components/BaseDataLoader';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {isLocalhost} from "src/utils/other";

// import {persistQueryClient} from 'react-query/persistQueryClient-experimental'
// import {createWebStoragePersistor} from 'react-query/createWebStoragePersistor-experimental'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// React Query
const oneMinute = 60 * 1000;
// const oneHour = 60 * oneMinute;

export const queryOptions = {
  staleTime: 10 * oneMinute, // stale after 10 minutes
  cacheTime: 30 * oneMinute, // cache GC'd after 30 minutes

  // 4 retries unless local dev
  retry: isLocalhost() ? false : 4, // was false during June 2023
  // other retry examples
  // retry: 6 - retries 6 times before showing the final error
  //   thrown by the function.
  // retry: true - infinitely retries failing requests.
  // retry: (failureCount, error) => ...
  //   allows custom logic based on the error. See AzurePage.tsx.

  // Retry Schedule
  // Start at 5sec, double each attempt, don't exceed 30sec.
  // Mainly meant to deal with timeouts while indexing etc.
  // According to Christian:
  // "The load balancer will probably throw a 503 after 30 seconds."
  retryDelay: (attemptIndex: number) =>
    Math.min(5000 * 2 ** attemptIndex, 30000),

  //
  // values for cache persistence
  // staleTime: 2 * oneHour, // stale after 2 hours
  // cacheTime: 4 * oneHour, // cache GC'd after 4 hours

}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: queryOptions
  }
});

// const initialStore = createStore();
export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/*<StoreContext.Provider value={initialStore}>*/}
        <StylesProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Auth0ProviderWithHistory>
                  <BaseDataLoader/>
                  <RoutesManager />
                </Auth0ProviderWithHistory>
                <CssBaseline />
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      {/*</StoreContext.Provider>*/}
    </QueryClientProvider>
  )
};


// use this to resurrect persistent cache

// const localStoragePersistor = createWebStoragePersistor(
//   {storage: window.localStorage}
// )

// This will clear the persistent cache, below.
// From React Query Docs:
// [To] ... immediately invalidate any and all cached data ...
// you can pass a buster string option to persistQueryClient.
// If the persistent cache does not also have that buster string,
// it will be discarded.

// exporting this so we can clear the React Query persistent cache
// without involving React context

// export const clearPersistentCache = () => {
//   if (localStoragePersistor) {
//     console.log('Clearing React Query persistent cache')
//     persistQueryClient({
//       queryClient,
//       persistor: localStoragePersistor,
//       buster: Date()
//     });
//   }
// }
//
// // for now, only persist React Query cache in dev
// if (process.env.NODE_ENV === 'development') {
//   console.log('App - persisting React Query caches');
//   persistQueryClient({
//      queryClient,
//      persistor: localStoragePersistor,
//   })
// }
