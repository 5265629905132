import {FC, useState} from "react";
import {Typography as T, useTheme} from "@mui/material";

import {ICompanyDomain} from "../../../../types/CompanyDomain";
import {ConfirmDialog} from "../../../../components/common/Dialog/ConfirmDialog";

export const useRemoveDomainConfirmationDialog = ({
  domain,
  isSpinning,
  onConfirm,
  onCancel
}: {
  domain: ICompanyDomain,         // The domain to remove
  isSpinning: boolean,            // Whether the spinner is to be shown
  onConfirm: () => void;          // Action to take when confirming
  onCancel: () => void;           // Action to take when cancelling
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return {
    dialog: ConfirmationDialog({
      domain,
      onCancel,
      onConfirm,
      isSpinning,
      isOpen,
      setIsOpen
    }),
    setIsOpen,
  };
}

type propTypes = {
  domain: ICompanyDomain;
  isOpen: boolean;
  isSpinning: boolean;
  setIsOpen: any;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
  domain,
  isSpinning,
  ...props
}) => {
  const theme = useTheme();
  return (
    <ConfirmDialog
      isOpen={isOpen}
      isSpinning={isSpinning}
      setIsOpen={setIsOpen}
      onCancelButtonClick={onCancel}
      onOkButtonClick={onConfirm}
      heading="Remove domain"
    >
      <T
        sx={{
          textAlign: 'left',
          marginLeft: '3px',
        }}
      >
        <strong
          style={{color: theme.more.riskyRed}}
        >
          Warning
        </strong>: Are you sure you want to remove <strong>{domain.name}</strong>
      </T>
    </ConfirmDialog>

  )
};


