import {stringForMissingUserName} from "src/utils/constants";
import {transformPermissionStatus} from "../shared/sharedTransforms";

export const transformAllGenericUsersElement = (user: any) => {
  const result = {
    name: user.name || stringForMissingUserName,
    pubId: user.pub_id,
  };
  // console.log('transformAllGenericUsersElement', {result});
  return result;
};

export const transformUserViewQueryResponse = (
  queryResult: {
    generic_user: {
      name: string,
      latest_saas_users: any;
    }[];
    mail_forward: any;
    file_share: any;
  } | undefined,
  generic_user_pub_id: string
) => {
  let result;
  if (
    !queryResult?.generic_user ||
    !queryResult?.generic_user.length
  ) {
    console.error(
      'transformUserViewQueryResponse - no user records'
    );
    return {};
  }
  const permissionStatusRecords =
    queryResult?.generic_user[0].latest_saas_users;

  console.log({permissionStatusRecords});
  const permissionStatus = permissionStatusRecords.map(
    transformPermissionStatus
  );

  result = {
    name: queryResult?.generic_user[0].name || stringForMissingUserName,
    permissionStatus: permissionStatus,

  }
  console.log({result});
  return result;
};