import {FC, useState} from 'react';
import {Typography as T} from '@mui/material';

import {SharedDialog} from './SharedDialog';
import {theme} from 'src/theme';
import { ContactSupport } from 'src/components/common/ErrorScreen';

export const useAddSaaSErrorDialog = ({
  saas,
  nickname,
  onDismiss
}: {
  saas: any, // may not be an ISaas - may not have a pubId
  nickname?: string,
  onDismiss: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('occured');
  return {
    dialog: ErrorDialog({
      saas,
      nickname,
      onDismiss,
      isOpen,
      setIsOpen,
      errorMessage,
    }),
    setIsOpen,
    setErrorMessage,
  };
}

type propTypes = {
  saas: any;
  nickname?: string;
  isOpen: boolean;
  setIsOpen: any;
  onDismiss: () => void;
  errorMessage: string;
};

const ErrorDialog: FC<propTypes> = ({
  isOpen,
  setIsOpen,
  onDismiss,
  saas,
  nickname,
  errorMessage,
  ...props
}) => {
  // isOpen && console.log({saas})
  return (
    <SharedDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      defaultButtonName='Ok'
      onDefaultButtonClick={onDismiss}
      saas={saas}
      nickname={nickname}
    >
      <T
        sx={{
          textAlign: 'center',
          // marginLeft: '3px',
          margin: 'auto',
          fontSize: '1rem'
        }}
      >
        <strong
          style={{color: theme.more.riskyRed}}
        >
          Error
        </strong> {errorMessage}

        <br /><br /><ContactSupport />
      </T>
    </SharedDialog>
  );
};


